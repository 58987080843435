import React, {useState, useEffect} from "react";
import { Helmet } from "react-helmet";
import { Switch, Route, Link, Redirect, useParams } from "react-router-dom";
import { Container, Row, Col, Form } from "react-bootstrap";
import { CRM, withCrm, ListView, fetchTabs, Lists } from "./crm.jsx";
import withTeam from "./withTeam.jsx";
import { db } from "./firebase.jsx";
import { NotFound } from "./interfaceListShackPro.jsx";
import { NoteInModal } from "./notes.jsx";
import { formatPhoneNumber } from "./interfaceListShackPro.jsx";
const SuperCrmRouter = props => {
  return(
    <Switch>
      <Route exact path="/supercrm/:listid">
        <SuperCrmView {...props} 
          listField="salesStatus"
        />
      </Route>
      <Route exact path="/supercrm">
        <Lists {...props} />
      </Route>
    </Switch>
  )
}

export default withCrm( withTeam( SuperCrmRouter ) );

const SuperCrmView = props => {
  let { userDoc } = props;
  let { listid } = useParams();
  const [listConfig, setListConfig] = useState(null);
  useEffect(() => {
    db.collection("crm")
      .doc(userDoc.id)
      .collection("lists")
      .doc(listid)
      .onSnapshot((doc) => {
        if (doc.exists) {
          let listConfig = doc.data();
          listConfig._docId = doc.id;
          listConfig._docRef = doc.ref;
          console.log("listConfig: ", listConfig);
          setListConfig(listConfig);
        }
      });
  }, [listid]);

  if (listid === "newusers") {
    return(
      <CRM {...props}
        title={ null }
        table
        managed
        hideHeaderButtons
        listField="salesStatus"
        list={listid}
        listConfig={listConfig}
        fetchTabs={ fetchTabs }
        collection="users"
        permissions={["read", "write", "delete"]}
        where={[{ field: "superVerified", operand: "==", term: false }, { field: "fraudulent", operand: "==", term: false} ]}
        orderBy={null} //[{field: "_docCreated", order: "desc"}]
        limit={500}
        header={() => {
          return (
            <tr>
              <th>Created</th>
              <th>Email</th>
              <th>Company</th>
              <th>Phone</th>
              <th>Status</th>
              <th>Notes</th>
            </tr>
          );
        }}
        child={({ data, index, listConfig, dups }) => {
          //console.log("data: ", data);
          return (
            <tr key={`row_${index}_${data._docId}`}>
              <td>{data._docCreated ? new Date(data._docCreated.seconds * 1000).toLocaleString() : "-"}</td>
              <td><Link to={`/superadmin/manage/${data.uid}`}>{data.email}</Link></td>
              <td>{data.companyProfile ? data.companyProfile.name : "-"}</td>
              <td>{data.companyProfile ? <a href={`tel:${data.companyProfile.phone}`}>{formatPhoneNumber(data.companyProfile.phone)}</a> : "-"}</td>
              <td>
                <Form.Control
                  as="select"
                  value={typeof data.salesStatus === "object" ? data.salesStatus.newusers : "Reclassify"}
                  onChange={ async e => {
                    let ns = e.target.value;
                    await data._docRef.update({"salesStatus.newusers": ns});
                  }}
                >
                  <option value="new">New</option>
                  {listConfig && listConfig.listStatus.map( s => {
                    return(
                      <option value={s}>{s}</option>
                    )
                  })}
                </Form.Control>
              </td>
              <td>
                <NoteInModal
                  {...props}
                  noteTitle={`Notes for ${data.companyProfile ? data.companyProfile.name : "-"}`}
                  noteid={data._docId}
                  variant="light"
                  size="sm"
                >
                  <small>View Notes</small>
                </NoteInModal>
              </td>
            </tr>
          );
        }}
        emptyChild={() => {
          return (
            <tr>
              <td colSpan="3">No users match this criteria</td>
            </tr>
          );
        }}
      />
    )
  }
  if (listid === "highvalue") {
    return(
      <CRM {...props}
        title={ null }
        table
        managed
        hideHeaderButtons
        listField="salesStatus"
        list={listid}
        listConfig={listConfig}
        fetchTabs={ fetchTabs }
        collection="users"
        permissions={["read", "write", "delete"]}
        where={[{ field: "highvalue", operand: "==", term: true }]}
        orderBy={null} //[{field: "_docCreated", order: "desc"}]
        limit={1000}
        header={() => {
          return (
            <tr>
              <th>Created</th>
              <th>Email</th>
              <th>Company</th>
              <th>Phone</th>
              <th>Status</th>
              <th>Notes</th>
            </tr>
          );
        }}
        child={({ data, index, listConfig, dups }) => {
          //console.log("data: ", data);
          return (
            <tr key={`row_${index}_${data._docId}`}>
              <td>{data._docCreated ? new Date(data._docCreated.seconds * 1000).toLocaleString() : "-"}</td>
              <td><Link to={`/superadmin/manage/${data.uid}`}>{data.email}</Link></td>
              <td>{data.companyProfile ? data.companyProfile.name : "-"}</td>
              <td>{data.companyProfile ? <a href={`tel:${data.companyProfile.phone}`}>{formatPhoneNumber(data.companyProfile.phone)}</a> : "-"}</td>
              <td>
                <Form.Control
                  as="select"
                  value={typeof data.salesStatus === "object" ? data.salesStatus.highvalue : "Reclassify"}
                  onChange={ async e => {
                    let ns = e.target.value;
                    await data._docRef.update({"salesStatus.highvalue": ns});
                  }}
                >
                  <option value="new">New</option>
                  {listConfig && listConfig.listStatus.map( s => {
                    return(
                      <option value={s}>{s}</option>
                    )
                  })}
                </Form.Control>
              </td>
              <td>
                <NoteInModal
                  {...props}
                  noteTitle={`Notes for ${data.companyProfile ? data.companyProfile.name : "-"}`}
                  noteid={data._docId}
                  variant="light"
                  size="sm"
                >
                  <small>View Notes</small>
                </NoteInModal>
              </td>
            </tr>
          );
        }}
        emptyChild={() => {
          return (
            <tr>
              <td colSpan="3">No users match this criteria</td>
            </tr>
          );
        }}
      />
    )
  }
  return(
    <NotFound />
  )
}
