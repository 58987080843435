import React from "react";
import {withRouter} from "react-router-dom";
import { object_equals } from "./interfaceListShackPro";

class ScrollToTop extends React.Component {
  componentDidUpdate(prevProps) {
    //console.log("Ran DidUpdate!");
    if ( !object_equals(this.props.location, prevProps.location) ) {
      // if the route isn't excluded, scroll to the top of the window
      if ( !this.props.excludeScrollTo.includes(this.props.location.pathname) ) {
        window.scrollTo(0, 0);
      }
      // if the route doesn't pass a regex test, scroll to the top of the window
      let tests = this.props.excludeScrollTo.filter( value => {
        let regex = new RegExp(value, "i");
        let test = regex.test(this.props.location.pathname);
        //console.log("test: ", test);
        return test;
      });
      //console.log("tests: ", tests);
      if (
        tests.length > 0
      ) {
        window.scrollTo(0,0);
      }
      
    }
  }

  componentDidMount() {
    if ( !this.props.staticRoutes.includes(this.props.location.pathname) && !this.props.isApp ) {
      this.props.handleState({isApp: true});
    }
  }

  render() {
    //console.log("ScrollToTop this.props: ", this.props);
    return this.props.children;
  }
}

export default withRouter ( ScrollToTop ) ;
