import React, {useEffect} from "react";
import { Helmet } from "react-helmet";
import { Switch, Route, Link, Redirect, useParams } from "react-router-dom";
import { Container, Row, Col, Nav, Tab } from "react-bootstrap";

const SettingsRouter = props => {
  const {handleState} = props;
  useEffect(() => {
    handleState({
      isApp: true,
      noDistract: true
    });
  }, [handleState]);

  return (
    <Switch>
      <Route exact path="/settings/:id">
        <SettingPane {...props} />
      </Route>
      <Route exact path="/settings">
        <Redirect to="/settings/profile" />
      </Route>
    </Switch>
  );
}

export default SettingsRouter;

const SettingPane = props => {
  //console.log("SettingPane props: ", props);
  let {panes} = props;
  let { id } = useParams();
  //console.log("id: ", id);
  const { role, userDoc, user } = props;
  const isAdmin = role === "admin";

  if (!user) {
    return <Redirect to={`/login?next=/settings/${id}`} />;
  }

  //console.log("customerDoc.plan_id.toLowerCase(): ", customerDoc.plan_id.toLowerCase(),"userPlan: ", userPlan);
  let pane = panes.find(p => p.id === id);

  return (
    <React.Fragment>
      <Helmet>
        <title>{pane.name}</title>
        <meta name="HandheldFriendly" content="true" />
        <meta name="apple-mobile-web-app-capable" content="YES" />
      </Helmet>
      <Container>
        <Row style={{ marginTop: "30px" }}>
          <Col style={{ minHeight: "80vh" }}>
            <div className="d-flex justify-content-between">
              <h1>Settings</h1>
              {userDoc.companyProfile !== undefined && !userDoc.userProfile && (
                <div className="text-end">
                  <p className="lead">{userDoc.companyProfile.name}</p>
                  <p>
                    Logged-in as: <code>{user.email}</code> <br />
                    Account id: <code>{userDoc.id}</code>
                  </p>
                </div>
              )}
              {userDoc.userProfile !== undefined && (
                <div className="text-end">

                  <p>
                    {userDoc.companyProfile !== undefined &&
                      <React.Fragment>
                        <span className="lead">{userDoc.companyProfile.name}</span> <br />
                      </React.Fragment>
                    }
                    {userDoc.userProfile.name} <br />
                    <small>Logged-in as: <code>{user.email}</code></small> <br />
                    <small>Account id: <code>{userDoc.id}</code></small>
                  </p>
                </div>
              )}
            </div>
            <hr />
            <Tab.Container id="left-tabs-example" defaultActiveKey="first">
              <Row>
                <Col sm={3}>
                  <Nav variant="pills" className="flex-column">
                    {panes.map(pane => {
                      //console.log("isAdmin: ", isAdmin);
                      let navItem = (
                        <Nav.Item key={pane.id}>
                          <Link
                            className={`nav-link ${
                              id === pane.id ? "active" : ""
                            }`}
                            eventkey={pane.eventKey}
                            to={`/settings/${pane.id}`}
                          >
                            {pane.name}
                          </Link>
                        </Nav.Item>
                      );
                      if (isAdmin) {
                        return navItem;
                      } else if (!pane.admin) {
                        return navItem;
                      }
                      return null;
                    })}
                  </Nav>
                </Col>
                <Col sm={9}>
                  <Tab.Content>
                    {pane.content({ ...props, isAdmin })}
                  </Tab.Content>
                </Col>
              </Row>
            </Tab.Container>
          </Col>
        </Row>
        {/*<Switch>
          <Route path={`${url}/:id`}>
            <SettingPane />
          </Route>
        </Switch>
        */}
      </Container>
    </React.Fragment>
  );
}
