import React, { useEffect } from "react";
import PropTypes from "prop-types";
import {
  Switch,
  Route,
  Link,
  NavLink,
  Redirect,
  useParams,
  withRouter
} from "react-router-dom";
import { db, storage, archiveDoc, batchWrite } from "./firebase.jsx";
import {
  Container,
  Row,
  Col,
  Button,
  Table,
  Nav,
  Tab,
  Modal,
  Form,
  Card,
  ButtonGroup,
  Dropdown,
  DropdownButton
} from "react-bootstrap";
import AutoComplete from "./autoComplete.jsx";
import SelectedParams from "./selectedParams.jsx";
import Spinner from "react-bootstrap/Spinner";
import { ShareButton } from "./share.jsx";
import DownloadPane from "./downloadPane.jsx";

class DownloadRouter extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoaded: false,
      savedDownloads: [],
      downloadsIds: {},
      selectedDownload: null,
      dlProblem: "",
      displayModal: false,
      downloadId: null
    };
    this.fetchSavedDownloads = this.fetchSavedDownloads.bind(this);
    this.handleModalClose = this.handleModalClose.bind(this);
  }

  handleModalClose() {
    this.setState({ displayModal: false });
  }

  async fetchSavedDownloads() {
    //Get data from firestore
    console.log("Fetching saved downloads!");
    const aid = this.props.userDoc.uid;
    const sdlRef = db
      .collection("downloads")
      .doc(aid)
      .collection("files")
      .where("csvFile", ">", "");

    try {
      await sdlRef.onSnapshot(async querySnapshot => {
        let downloadsIds = {};
        let fetchedDownloads = [];

        querySnapshot.forEach(doc => {
          //console.log(doc.id, ": ", doc.data());
          let docData = doc.data();
          downloadsIds[docData.timestamp.seconds] = doc.id;
          docData._docId = doc.id;
          docData._docRef = doc.ref;
          fetchedDownloads.push(docData);
        });

        let sortedDownloads = fetchedDownloads.sort((a, b) => {
          let aV = a.timestamp.seconds;
          let bV = b.timestamp.seconds;
          return aV - bV;
        });

        await this.setState({
          savedDownloads: sortedDownloads,
          downloadsIds: downloadsIds,
          isLoaded: true
        });
      });
    } catch (error) {
      await console.error("Error fetching saved downloads: ", error);

      await this.setState({
        isLoaded: true
        //Let the user know something went wrong
      });
    }
  }

  getFileMetaData = async reference => {
    let storageRef = storage.ref();
    const fileRef = storageRef.child(reference);
    try {
      let metaData = await fileRef.getMetadata();
      //await console.log("metaData: ", metaData);
      return await metaData;
    } catch (error) {
      //console.log("Uh oh something went wring checking the file size: ", error.message);
    }
  };

  reportFile = async (e, timestamp) => {
    e.preventDefault();
    let {selectedDownload} = this.state;
    // Don't let folks report files multiple times
    if (selectedDownload.reported) {
      this.props.handleAlerts(
        e,
        "This file has already been reported.",
        "info"
      );
      return this.setState({
        displayModal: false,
        dlProblem: ""
      });
    }
    let {userDoc} = this.props;
    //console.log("timestamp: ", timestamp, "downloadId: ", this.state.downloadsIds[timestamp], "selectedDocument: ", selectedDocument);
    let parsedFileName = selectedDownload.csvFile
      .match(/(downloads%2F).*.csv/)[0]
      .replace(/%2F/g, "/");
    //console.log("parsedFileName: ", parsedFileName);
    let _docId = this.state.downloadsIds[timestamp];
    let dlDoc = db
      .collection("downloads")
      .doc(userDoc.uid)
      .collection("files")
      .doc(_docId);
    let fileMetaData;
    // If they say it's blank, just double check that it's actually blank.
    if (this.state.dlProblem === "blankFile") {
      fileMetaData = await this.getFileMetaData(parsedFileName);
      if (fileMetaData.size > 0) {
        try {
          // record the user attempted to report the file
          await dlDoc.update({
            reported: true,
            reason: this.state.dlProblem,
            metaData: {
              fullPath: fileMetaData.fullPath,
              name: fileMetaData.name,
              size: fileMetaData.size,
              timeCreated: fileMetaData.timeCreated,
              updated: fileMetaData.updated
            }
          });
          // communicate the result of the rport
          this.props.handleAlerts(
            e,
            "Unfortunately, we're unable to credit your account for the leads in this file.",
            "warning"
          );
          // reset the ui
          return this.setState({
            displayModal: false,
            dlProblem: ""
          });
        } catch (error) {
          //console.log("Uh oh, something went wrong after checking metadata for the file: ", error.message);
          // if it errors out, we'll give them the benefit of the doubt
        }
      }
    }

    // If they say the leads are wrong, just do a cursory check whether the geography matches
    // This doesn't work for cities
    // Works for consumer states, zips, area codes
    if (this.state.dlProblem === "wrongLeads") {
      //const init = {
      //  headers: {
      //    method: "GET",
      //    "X-DreamFactory-API-Key": "32caa23d9d2a9ff7f84edbfff8c9d05f8e67bcf104ac5d9e30506552f1ba0168",
      //  },
      //  "Content-Type": "application/json",
      //  "Transfer-Encoding": "chunked",
      //  cache: "default",
      //}
      let postBody = selectedDownload.queryUrl;
      postBody.limit = 1;
      const init = {
        method: "POST",
        headers: {
          authorization: `Bearer ${process.env.REACT_APP_api_token}`,
          "Content-Type": "application/json"
        },
        "Transfer-Encoding": "chunked",
        cache: "default",
        accept: "application/json",
        body: JSON.stringify(postBody)
      };
      try {
        let testQuery = await fetch(`${selectedDownload.queryUrl.url}`, init);
        let response = await testQuery.json();
        let testLead = response.resource[0];
        //console.log("testLead: ", testLead);
        let geoMatch;
        let g = selectedDownload.geographyParams;
        //let s = selectedDocument.searchParams;
        for (let key of Object.keys(g)) {
          if (g[key].length > 0) {
            for (let p of g[key]) {
              geoMatch = String(p.value) === testLead[key] ? true : false;
              if (geoMatch === true) {
                break;
              }
            }
          }
        }

        //await console.log("Checking whether the geography matches the query. testLead: ", testLead, "geoMatch: ", geoMatch);

        if (geoMatch === true) {
          this.props.handleAlerts(
            e,
            `Unfortunately, we're unable to credit your account for these leads. If you think this is an error, please contact support at ${process.env.REACT_APP_contact_email}.`,
            "warning"
          );
          dlDoc.update({
            reported: true,
            reason: this.state.dlProblem,
            geoMatch: geoMatch
          });
          return this.setState({
            displayModal: false,
            dlProblem: ""
          });
        }
      } catch (error) {
        //console.log("Something went wrong checking if the leads are wrong: ", error);
        // if it fails, we'll give them the benefit of the doubt.
      }
    }

    let leadsMultiplier =
      typeof selectedDownload.leadsMultiplier === "undefined"
        ? 1
        : selectedDownload.leadsMultiplier;
    //console.log("leadsMultiplier: ", leadsMultiplier);

    // They passed the test, Give them their leads back
    try {
      await db
        .collection("downloads")
        .doc(userDoc.uid)
        .collection("files")
        .doc(_docId)
        .update({
          reported: true,
          reason: this.state.dlProblem
        });
      let newLeadsRemaining =
        parseInt(userDoc.leadsRemaining) +
        parseInt(selectedDownload.downloadCount) * leadsMultiplier;
      //console.log("newLeadsRemaining: ", newLeadsRemaining);
      await db
        .collection("users")
        .doc(userDoc.uid)
        .update({
          leadsRemaining: newLeadsRemaining
        });
      this.props.handleAlerts(
        e,
        `Thanks for reporting your file! You're account has been credited for ${parseInt(
          selectedDownload.downloadCount
        ) *
          leadsMultiplier} leads.  We'll let your know if we have any questions.`,
        "success"
      );
      this.setState({
        displayModal: false,
        dlProblem: ""
      });
    } catch (error) {
      this.props.handleAlerts(
        e,
        `Something went wrong reporting your file: ${error.message}. Please contact support at ${process.env.REACT_APP_contact_email}`,
        "warning"
      );
      console.error("Error reporting file: ", error);
      return;
    }
  };

  render() {
    let downloads = this.state.savedDownloads;
    let { downloadId } = this.state;
    //let downloadsIds = this.state.downloadsIds;
    //console.log(
    //  "DownloadRouter state: ",
    //  this.state,
    //  "DownloadRouter props: ",
    //  this.props
    //);
    //console.log("downloads: ", downloads);

    let navItems = [];
    //let tabPanes = [];
    navItems.push(
      <div key="bd_menu" className="d-flex flex-row justify-content-between">
        <AutoComplete
          className="flex-fill"
          key="niSearch"
          suggestions={downloads}
          placeholder="Search downloads"
          onSelect={suggestion => console.log("Selected: ", suggestion)}
          size="md"
          searchKey="searchName"
          suggestion={suggestion => {
            //console.log("suggestion: ", suggestion);
            return (
              <Link
                to={`/downloads/${suggestion._docId}`}
                className="nav-item d-flex justify-content-between"
                onClick={() => this.setState({ downloadId: suggestion._docId })}
                style={{
                  fontSize: ".8em",
                  margin: "0 auto",
                  padding: "0px"
                }}
              >
                <div>
                  <span>{suggestion.searchName} </span>
                  {suggestion.archived ? (
                    <span className="text-muted">
                      <i>archived</i>
                    </span>
                  ) : null}
                </div>
                <span className="text-muted">
                  {suggestion.downloadCount} leads
                </span>
              </Link>
            );
          }}
        />
        <DropdownButton
          key="bd_options"
          id="dropdown-item-button"
          variant="light"
          title=""
          style={{
            border: "solid 1px #ced4da",
            borderRadius: ".25rem",
            color: "#ced4da",
            backgroundColor: "#fff",
            fontSize: "1rem",
            lineHeight: "1.5",
            height: "38px"
          }}
        >
          <Dropdown.Item
            as="span"
            onClick={async () => {
              if (
                window.confirm(
                  "Are you sure you'd like to archive all downloads?"
                )
              ) {
                console.log("Archive all downloads");
                try {
                  let data = [];
                  for (let dl of [...downloads]) {
                    data.push({
                      ref: dl._docRef,
                      op: "update",
                      data: {
                        archived: true,
                        archivedAt: new Date()
                      }
                    });
                  }
                  await batchWrite({ data });
                } catch (err) {
                  console.log("Error archiving all downloads: ", err);
                }
              }
            }}
          >
            Archive all downloads
          </Dropdown.Item>
          {this.props.superAdmin && (
            <Dropdown.Item
              as="span"
              onClick={async () => {
                if (
                  window.confirm(
                    "Are you sure you'd like to unarchive all downloads?"
                  )
                ) {
                  console.log("Unarchive all downloads");
                  try {
                    let data = [];
                    for (let dl of [...downloads]) {
                      data.push({
                        ref: dl._docRef,
                        op: "update",
                        data: {
                          archived: false,
                          archivedAt: new Date()
                        }
                      });
                    }
                    await batchWrite({ data });
                  } catch (err) {
                    console.log("Error archiving all downloads: ", err);
                  }
                }
              }}
            >
              Unarchive all downloads
            </Dropdown.Item>
          )}
        </DropdownButton>
      </div>
    );
    for (let [i, download] of downloads.entries()) {
      //console.log(new Date(download.timestamp.seconds * 1000).toString());
      //console.log("active tab: ", downloadId, download._docId);
      if (!this.props.superAdmin && download.archived) {
        continue;
      }
      navItems.push(
        <Nav.Item key={`ni${i}`}>
          <NavLink
            to={`/downloads/${download._docId}`}
            className={`nav-link text-muted ${
              downloadId === download._docId ? "bg-light active" : ""
            } ${download.archived ? "bg-danger" : ""}`}
            onClick={() => this.setState({ downloadId: download._docId })}
          >
            {download.searchName}
          </NavLink>
        </Nav.Item>
      );
    }

    if (this.state.isLoaded) {
      return (
        <Container>
          <Row style={{ marginTop: "30px" }}>
            <Col style={{ minHeight: "80vh" }}>
              <h1>Downloads</h1>
              <hr />
              <Tab.Container id="left-tabs-example">
                <Row>
                  <Col sm={3} md={4}>
                    <Nav
                      variant="pills"
                      className="flex-column"
                      style={{
                        maxHeight: "80vh",
                        overflow: "hidden",
                        flexWrap: "nowrap",
                        overflowY: "auto",
                        backgroundColor: "#f5f5f5"
                      }}
                    >
                      {navItems.length === 1 ? (
                        <Nav.Item>
                          <Nav.Link className="active">
                            Saved downloads
                          </Nav.Link>
                        </Nav.Item>
                      ) : (
                        navItems
                      )}
                    </Nav>
                  </Col>
                  <Col sm={9} md={8}>
                    <Tab.Content>
                      {navItems.length === 1 ? (
                        <Tab.Pane className="active show">
                          <p className="lead">
                            Leads you download from the{" "}
                            <Link to="/search">Search</Link> page will be saved
                            here for your convenience.
                          </p>
                        </Tab.Pane>
                      ) : (
                        <Switch>
                          <Route exact path="/downloads/:id">
                            <DownloadPane
                              {...this.props}
                              downloads={this.state.savedDownloads}
                              handleDlState={obj => this.setState(obj)}
                            />
                          </Route>
                          <Route exact path="/downloads">
                            <Redirect
                              to={`/downloads/${downloads[downloads.length - 1]._docId}`}
                            />
                          </Route>
                        </Switch>
                      )}
                    </Tab.Content>
                  </Col>
                </Row>
              </Tab.Container>
            </Col>
          </Row>
          {this.state.selectedDownload && (
            <Modal
              show={this.state.displayModal}
              onHide={this.handleModalClose}
              size="sm"
              centered
            >
              <Modal.Body>
                <Modal.Title>Report file</Modal.Title>
                <Form>
                  <h4>Search {this.state.selectedDownload.searchName}</h4>
                  <Form.Group
                    key={this.state.selectedDownload.timestamp.seconds}
                  >
                    <Form.Label>What's the issue with this file?</Form.Label>
                    <Form.Control
                      as="select"
                      value={this.state.dlProblem}
                      onChange={e =>
                        this.setState({
                          dlProblem:
                            e.target.options[e.target.selectedIndex].value
                        })
                      }
                    >
                      <option value="">Select a reason</option>
                      <option value="blankFile">
                        The file was blank (Zero leads returned)
                      </option>
                      {this.state.selectedDownload.geographyParams !==
                        undefined && (
                        <option value="wrongLeads">
                          The leads didn't match my search
                        </option>
                      )}
                    </Form.Control>

                    <Form.Text>
                      We'll double check it and credit your account for the
                      leads.
                    </Form.Text>
                  </Form.Group>
                  <Button
                    onClick={e => {
                      if (this.state.dlProblem !== "") {
                        this.reportFile(
                          e,
                          this.state.selectedDownload.timestamp.seconds
                        );
                      }
                    }}
                    disabled={this.state.dlProblem === ""}
                  >
                    Report file
                  </Button>
                </Form>
              </Modal.Body>
            </Modal>
          )}
        </Container>
      );
    } else {
      return (
        <Container>
          <Row style={{ marginTop: "30px" }}>
            <Col style={{ minHeight: "80vh" }}>
              <h1>Downloads</h1>
              <hr />
              <br />
              <br />
              <Spinner animation="grow" variant="primary" size="lg" />
              <h2>Getting your downloads. Hold tight!</h2>
            </Col>
          </Row>
        </Container>
      );
    }
  }

  componentDidMount() {
    this.fetchSavedDownloads();
  }
}

DownloadRouter.propTypes = {
  user: PropTypes.object
};

export default withRouter(DownloadRouter);
