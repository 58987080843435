import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import { Switch, Route, Redirect, withRouter } from "react-router-dom";
import { withCrm, ListView, Lists } from "./crm.jsx";
import {Directory, RecordView} from "./directory.jsx";
import withTeam from "./withTeam.jsx";
import withElasticSearch from "./withElasticSearch";

const DirectoryRouter = props => {
  //console.log("DirectoryRouter: ", props);
  const {handleState} = props;
  useEffect(() => {
    handleState({
      isApp: true
    })
  },[]);
  return(
    <Switch>
      <Route exact path="/search/directory/:indexid/:recordid">
        <RecordView {...props} />
      </Route>
      <Route exact path={["/search/directory", "/search/directory/"]}>
        <Directory 
          {...props}
        />
      </Route>
      <Route exact path={["/search_directory", "/search_directory/"]}>
        <Directory
          {...props}
        />
      </Route>
    </Switch>
  )
}

export default withCrm( withTeam( withRouter( withElasticSearch(DirectoryRouter) ) ) );
