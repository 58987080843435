import React, { useState, useEffect } from "react";
import { Route, Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import { Badge } from "react-bootstrap";
import bizDec2018 from "./dataDict_bizDec2018";
import bizOct2017 from "./dataDict_bizOct2017.jsx";
import consumerJan2020 from "./dataDict_consumerJan2020.jsx";
import consumerMar2020 from "./dataDict_consumerMar2020.jsx";
import consumerJan2021 from "./dataDict_consumerJan2021.jsx";
import consumerMar2021 from "./dataDict_consumerMar2021.jsx";
import consumerJul2021 from "./dataDict_consumerJul2021";
import consumerOct2021 from "./dataDict_consumerOct2021";
import consumerJan2022 from "./dataDict_consumerJan2022";
import consumerApr2022 from "./dataDict_consumerApr2022";
import consumerOct2020 from "./dataDict_consumerOct2020.jsx";
import consumerMar2019 from "./dataDict_consumerMar2019.jsx";
import vinconsumer from "./dataDict_vinconsumer";
import consumerEmails from "./dataDict_consumer_emails.jsx";
import businessEmails from "./dataDict_bizEmails.jsx";
import bizoct2021 from "./dataDict_bizoct2021";
import Map from "./map.jsx";
import Maps from "./maps.jsx";
import { CRM } from "./crm.jsx";
import SuperCrmRouter from "./superCrmRouter.jsx";
import CrmRouter from "./crmRouter.jsx";
import DirectoryRouter from "./directoryRouter";
import CustomSearchRouter from "./customSearchRouter";
import ListMarketRouter from "./listMarketRouter";

import {
  Contact,
  Terms,
  Privacy,
  Faq,
  ListShackDesktop,
  DatabaseLander,
  DatabaseDetails,
  QuickStart,
  findPlan,
  ComparePlansTable,
  NoPermissions,
  Ccpa,
  CcpaOptout,
} from "./interfaceListShackPro.jsx";

import { Search, SearchLayout } from "./interfaceListShackSupport";

import Paywall from "./paywall.jsx";
import Search2 from "./search.jsx";
import Pricing from "./pricing.jsx";
import DownloadRouter from "./downloadRouter.jsx";
import { SupportCenter } from "./supportCenter.jsx";
import SearchRouter from "./searchRouter.jsx";
import { SuperadminLanding, AdminIndex, ErrorsPage } from "./superadmin.jsx";
import Api from "./api.jsx";
import { ShareSearch } from "./share.jsx";
import { Searches } from "./savedSearch.jsx";
import { EmailEditor } from "./email.jsx";
import ElasticlistsRouter from "./elasticlistsRouter";

const allDicts = [
  consumerApr2022,
  consumerJan2022,
  consumerOct2021,
  consumerJul2021,
  consumerMar2021,
  consumerJan2021,
  bizDec2018,
  consumerOct2020,
  consumerMar2019,
  vinconsumer,
  consumerEmails,
  businessEmails,
];

const Routes = (props) => {
  let { loaded, plans, customerDoc, superAdmin } = props;

  const [mostRecentConsumerDict, setMostRecentConsumerDict] = useState(null);

  useEffect(() => {
    // Find the most recent consumerDictionary
    let mrDict;
    for (let dict of allDicts) {
      if (dict.type === "Consumer") {
        if (
          new Date(dict?.summaryStats?.releasedDate).valueOf() >
            new Date(mrDict?.summaryStats?.releasedDate).valueOf() ||
          (!mrDict && dict?.summaryStats?.releasedDate)
        ) {
          mrDict = dict;
        }
      }
    }
    setMostRecentConsumerDict(mrDict);
  }, []);

  //console.log("mostRecentConsumerDict: ", mostRecentConsumerDict);
  if (!mostRecentConsumerDict) {
    return null;
  }

  return (
    <React.Fragment>
      <Route path="/pricing">
        <Helmet>
          <title>Pricing</title>
          <meta name="HandheldFriendly" content="true" />
          <meta name="apple-mobile-web-app-capable" content="YES" />
        </Helmet>
        <Pricing {...props} />
      </Route>

      <Route path="/compare_plans">
        <Helmet>
          <title>Compare Plans</title>
          <meta name="HandheldFriendly" content="true" />
          <meta name="apple-mobile-web-app-capable" content="YES" />
        </Helmet>
        <ComparePlansTable
          {...props}
          title={
            <h1 className="mt-5">
              Compare {process.env.REACT_APP_site_name} Plans
            </h1>
          }
        />
      </Route>

      <Route path="/faq">
        <Helmet>
          <title>FAQs</title>
          <meta name="HandheldFriendly" content="true" />
          <meta name="apple-mobile-web-app-capable" content="YES" />
        </Helmet>
        <Faq />
      </Route>

      <Route path="/contact">
        <Helmet>
          <title>Contact us</title>
          <meta name="HandheldFriendly" content="true" />
          <meta name="apple-mobile-web-app-capable" content="YES" />
        </Helmet>
        <Contact />
      </Route>

      <Route path="/privacy">
        <Helmet>
          <title>Privacy policy</title>
          <meta name="HandheldFriendly" content="true" />
          <meta name="apple-mobile-web-app-capable" content="YES" />
        </Helmet>
        <Privacy />
      </Route>

      <Route path="/terms">
        <Helmet>
          <title>Terms of service</title>
          <meta name="HandheldFriendly" content="true" />
          <meta name="apple-mobile-web-app-capable" content="YES" />
        </Helmet>
        <Terms />
      </Route>

      <Route path="/support/">
        <Helmet>
          <title>Support center</title>
          <meta name="HandheldFriendly" content="true" />
          <meta name="apple-mobile-web-app-capable" content="YES" />
        </Helmet>
        <SupportCenter {...props} />
      </Route>

      <Route exact path="/search/emails">
        <Helmet>
          <title>Email leads</title>
          <meta name="HandheldFriendly" content="true" />
          <meta name="apple-mobile-web-app-capable" content="YES" />
        </Helmet>
        <SearchLayout {...props}>
          <DatabaseLander
            {...props}
            title={
              <React.Fragment>
                Email Leads{" "}
                <Badge pill bg="light" className="text-dark">
                  <i>Beta</i>
                </Badge>
              </React.Fragment>
            }
            lead={`Search ${process.env.REACT_APP_site_name} for compiled email leads.`}
            subtitle="Compiled email leads"
            subPath="emails"
            description={
              <p>
                {process.env.REACT_APP_site_name} provides{" "}
                <Link to="/support/use-compiled-emails">compiled email</Link>{" "}
                leads for use in your marketing and customer communications. We
                currently offer only consumer emails. These emails can be
                searched by a variety of filters so you can run the most
                effective campaigns for your business. Read more about using
                compiled email lists{" "}
                <Link to="/support/use-compiled-emails">here</Link>.
              </p>
            }
            databases={[consumerEmails, businessEmails]}
          />
        </SearchLayout>
      </Route>

      <Route exact path="/search/emails/:path">
        <DatabaseDetails
          {...props}
          databases={[consumerEmails, businessEmails]}
          landerPath="emails"
        />
      </Route>
      {/*
        <Route exact path="/archives">
          <Helmet>
            <title>Search archives</title>
            <meta name="HandheldFriendly" content="true" />
            <meta name="apple-mobile-web-app-capable" content="YES" />
          </Helmet>

          <Archives
            {...props}
            databases={[bizOct2017, consumerMar2019, vin2015]}
          />
        </Route>

        <Route exact path="/archives/:path">
          <ArchivesDetails
            {...props}
            databases={[bizOct2017, consumerMar2019, vin2015]}
          />
        </Route>
        */}
      <Route exact path="/listshack_desktop">
        <Helmet>
          <title>ListShack Desktop</title>
          <meta name="HandheldFriendly" content="true" />
          <meta name="apple-mobile-web-app-capable" content="YES" />
        </Helmet>

        <ListShackDesktop {...props} />
      </Route>

      <Route exact path="/ccpa_optout">
        <Helmet>
          <title>California Consumer Optout</title>
          <meta name="HandheldFriendly" content="true" />
          <meta name="apple-mobile-web-app-capable" content="YES" />
        </Helmet>
        <CcpaOptout {...props} />
      </Route>

      <Route exact path="/ccpa">
        <Helmet>
          <title>CCPA Disclosures</title>
          <meta name="HandheldFriendly" content="true" />
          <meta name="apple-mobile-web-app-capable" content="YES" />
        </Helmet>
        <Ccpa />
      </Route>

      <Route path="/share/search" exact>
        <ShareSearch {...props} dicts={allDicts} />
      </Route>

      {loaded && (
        <React.Fragment>
          <Route exact path="/search">
            <Helmet>
              <title>Search for leads</title>
              <meta name="HandheldFriendly" content="true" />
              <meta name="apple-mobile-web-app-capable" content="YES" />
            </Helmet>
            <Paywall {...props}>
              <Search {...props} />
            </Paywall>
          </Route>

          <Route path="/search/consumers">
            <Helmet>
              <title>Search consumer leads</title>
              <meta name="HandheldFriendly" content="true" />
              <meta name="apple-mobile-web-app-capable" content="YES" />
            </Helmet>
            <Paywall {...props}>
              <Search2
                {...props}
                dataDict={mostRecentConsumerDict}
                userplan={findPlan(plans, customerDoc)}
              />
            </Paywall>
          </Route>

          <Route path="/search/businesses">
            <Helmet>
              <title>Search business leads</title>
              <meta name="HandheldFriendly" content="true" />
              <meta name="apple-mobile-web-app-capable" content="YES" />
            </Helmet>
            <Paywall {...props}>
              <Search2
                {...props}
                dataDict={bizDec2018}
                userplan={findPlan(plans, customerDoc)}
              />
            </Paywall>
          </Route>

          <Route path="/search/consumer_emails">
            <Helmet>
              <title>Search consumer emails</title>
              <meta name="HandheldFriendly" content="true" />
              <meta name="apple-mobile-web-app-capable" content="YES" />
            </Helmet>
            <Paywall {...props}>
              <Search2
                {...props}
                dataDict={consumerEmails}
                userplan={findPlan(plans, customerDoc)}
              />
            </Paywall>
          </Route>

          <Route path="/search/business_emails">
            <Helmet>
              <title>Search business emails</title>
              <meta name="HandheldFriendly" content="true" />
              <meta name="apple-mobile-web-app-capable" content="YES" />
            </Helmet>
            <Paywall {...props}>
              <Search2
                {...props}
                dataDict={businessEmails}
                userplan={findPlan(plans, customerDoc)}
              />
            </Paywall>
          </Route>

          <Route path="/_search">
            <Paywall {...props}>
              <SearchRouter
                {...props}
                userplan={findPlan(plans, customerDoc)}
                dicts={allDicts}
              />
            </Paywall>
          </Route>

          <Route exact path="/search/maps">
            <Helmet>
              <title>Search Maps</title>
              <meta name="HandheldFriendly" content="true" />
              <meta name="apple-mobile-web-app-capable" content="YES" />
            </Helmet>
            <Paywall {...props}>
              <Maps {...props} />
            </Paywall>
          </Route>

          <Route exact path="/search/maps/consumers">
            <Helmet>
              <title>Consumer Maps</title>
              <meta name="HandheldFriendly" content="true" />
              <meta name="apple-mobile-web-app-capable" content="YES" />
            </Helmet>
            <Paywall {...props}>
              <Map
                {...props}
                dataDict={mostRecentConsumerDict}
                userplan={findPlan(plans, customerDoc)}
              />
            </Paywall>
          </Route>

          <Route exact path="/search/maps/businesses">
            <Helmet>
              <title>Business Maps</title>
              <meta name="HandheldFriendly" content="true" />
              <meta name="apple-mobile-web-app-capable" content="YES" />
            </Helmet>
            <Paywall {...props}>
              <Map
                {...props}
                dataDict={bizDec2018}
                userplan={findPlan(plans, customerDoc)}
              />
            </Paywall>
          </Route>
        
          <Route path="/downloads">
            <Helmet>
              <title>Downloads</title>
              <meta name="HandheldFriendly" content="true" />
              <meta name="apple-mobile-web-app-capable" content="YES" />
            </Helmet>
            <Paywall {...props}>
              <DownloadRouter {...props} dicts={allDicts} />
            </Paywall>
          </Route>

          <Route exact path="/searches">
            <Helmet>
              <title>Saved Searches</title>
              <meta name="HandheldFriendly" content="true" />
              <meta name="apple-mobile-web-app-capable" content="YES" />
            </Helmet>
            <Paywall {...props}>
              <SearchLayout {...props}>
                <Searches {...props} dicts={allDicts} />
              </SearchLayout>
            </Paywall>
          </Route>

          <Route exact path="/quick_start">
            <Helmet>
              <title>Verify your company</title>
              <meta name="HandheldFriendly" content="true" />
              <meta name="apple-mobile-web-app-capable" content="YES" />
            </Helmet>
            <QuickStart {...props} isApp={true} />
          </Route>

          <Route path={["/elastic-lists", "/listcrm", "/list"]}>
            <ElasticlistsRouter {...props} />
          </Route>

          <Route path={["/marketplace", "/listmarket"]} > 
            <ListMarketRouter {...props} 
              dicts={[
                consumerJan2022,
                consumerOct2021,
                consumerJul2021,
                consumerMar2021,
                consumerJan2021,
                bizDec2018,
                consumerOct2020,
                consumerMar2019,
                vinconsumer,
                consumerEmails,
                businessEmails
              ]}
            />
          </Route>

          <Route exact path="/superadmin">
            <Helmet>
              <title>Superadmin</title>
              <meta name="HandheldFriendly" content="true" />
              <meta name="apple-mobile-web-app-capable" content="YES" />
            </Helmet>
            {superAdmin && <SuperadminLanding {...props} />}
            {!superAdmin && <NoPermissions />}
          </Route>
        </React.Fragment>
      )}
      {loaded && superAdmin && (
        <React.Fragment>
          <Route exact path={["/superadmin/manage", "/superadmin/manage/:uid"]}>
            <AdminIndex {...props} />
          </Route>
          <Route exact path="/superadmin/newusers">
            <AdminIndex
              {...props}
              title={<h4 className="mt-5">Approve New Users</h4>}
              index="users"
              usersWhere={[
                ["superVerified", "==", false],
                ["fraudulent", "!=", true],
              ]}
            />
          </Route>

          <Route exact path="/superadmin/fraudsters">
            <AdminIndex
              {...props}
              title={<h4 className="mt-5">Fraudsters</h4>}
              index="users"
              usersWhere={[["fraudulent", "==", true]]}
            />
          </Route>

          <Route exact path="/superadmin/activeusers">
            <AdminIndex
              {...props}
              title={<h4 className="mt-5">Active customers</h4>}
              hideSelectIndex={true}
              index="customers"
              customersWhere={[
                [
                  "subscription_status",
                  "in",
                  ["active", "past_due", "past due"],
                ],
              ]}
              usersWhere={[["fraudulent", "!=", true]]}
            />
          </Route>

          <Route exact path="/superadmin/notfraudulentusers">
            <AdminIndex
              {...props}
              title={<h4 className="mt-5">Not Fraudulent Users</h4>}
              index="customers"
              hideSelectIndex={true}
              usersWhere={[["fraudulent", "!=", true]]}
            />
          </Route>

          <Route exact path="/superadmin_errors">
            {superAdmin && <ErrorsPage {...props} isApp={true} />}
            {!superAdmin && <NoPermissions />}
          </Route>

          <Route path="/api">
            <Helmet>
              <title>API</title>
              <meta name="HandheldFriendly" content="true" />
              <meta name="apple-mobile-web-app-capable" content="YES" />
            </Helmet>
            <Paywall {...props}>
              {superAdmin && <Api {...props} />}
              {!superAdmin && <NoPermissions />}
            </Paywall>
          </Route>

          <Route exact path="/send_email">
            <Helmet>
              <title>API</title>
              <meta name="HandheldFriendly" content="true" />
              <meta name="apple-mobile-web-app-capable" content="YES" />
            </Helmet>
            <EmailEditor {...props} />
          </Route>

          <Route exact path="/reported_searches">
            <Helmet>
              <title>Reported Searches</title>
              <meta name="HandheldFriendly" content="true" />
              <meta name="apple-mobile-web-app-capable" content="YES" />
            </Helmet>
            <Searches
              {...props}
              title={<h1>Reported searches</h1>}
              reports={true}
              dicts={allDicts}
            />
          </Route>
          <Route path={["/crm", "/lists"]}>
            <CrmRouter {...props} dicts={[consumerJan2021, bizDec2018]} />
          </Route>

          <Route
            path={[
              "/search/directory",
              "/directory",
              "/directory/",
              "/search_directory",
              "/search_directory/",
            ]}
          >
            <DirectoryRouter
              {...props}
              dicts={[bizDec2018, mostRecentConsumerDict, bizoct2021]}
            />
          </Route>

          <Route path="/supercrm">
            <SuperCrmRouter {...props} />
          </Route>
          <Route path="/_custom_search">
            <CustomSearchRouter
              {...props}
              userplan={findPlan(plans, customerDoc)}
              dicts={allDicts}
            />
          </Route>
        </React.Fragment>
      )}
    </React.Fragment>
  );
};

export default Routes;
