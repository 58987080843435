import React, { useState, useEffect} from "react";
import { Button, Table } from "react-bootstrap";
import JSONPretty from "react-json-pretty";

const IndexTests = props => {
  //console.log("IndexTests props: ", props);
  const { dataDict, fetchCount, createRangeQuery } = props;
  const [tests, setTests] = useState(null);
  const [runningTests, setRunningTests] = useState(false);

  return(
    <div
      className={ props.className ? props.className : ""}
      style={props.style ? props.style : null}
    >
      <div className="d-flex justify-content-between align-items-center bg-light p-2 mb-3">
        <p className="lead mb-0">
          <i
            className="fa fa-eye-slash me-3"
            style={{ fontSize: "24px" }}
          ></i>
          Tests for this database
        </p>
        <div>
          {!tests && !runningTests && (
            <Button
              onClick={e => {
                e.preventDefault();
                let tests = createTests(
                  dataDict,
                  fetchCount,
                  createRangeQuery
                );
                setTests( tests ); // this might be misshaped
              }}
              variant="outline-danger"
              size="sm"
              disabled={tests}
            >
              {tests ? "Reset" : "Create tests"}
            </Button>
          )}
          {(tests || runningTests) && (
            <React.Fragment>
              <Button
                onClick={e => {
                  e.preventDefault();
                  setTests( null );
                }}
                variant="outline-warning"
                size="sm"
                disabled={ runningTests }
                className="me-1"
              >
                Reset
              </Button>
              <Button
                onClick={async e => {
                  e.preventDefault();
                  e.persist();
                  setRunningTests( true );
                  setTests( null );
                  let testResults = { ...tests };
                  for (let field of Object.entries(tests)) {
                    console.log("field: ", field);
                    let newTests = [];
                    for (let test of field[1].tests) {
                      let newTest = { ...test };
                      let result = await test.test(e);
                      newTest.result = result;
                      newTests.push(newTest);
                    }
                    testResults[field[0]].tests = newTests;
                  }
                  //await Object.entries(tests).map( async field => {
                  //  console.log("Running tests for: ", field[0]);
                  //  let newTests = [];
                  //  await field[1].tests.map( async test => {
                  //    let newTest = {...test}
                  //    let result = await test.test(e);
                  //    newTest.result = result;
                  //    newTests.push(newTest);
                  //  })
                  //  testResults[field[0]].tests = newTests;
                  //})
                  console.log("testResults: ", testResults);
                  setTests( testResults );
                  setRunningTests( false );
                }}
                variant="outline-danger"
                size="sm"
                disabled={ runningTests }
              >
                {!runningTests
                  ? "Run All Tests"
                  : "Running tests"}
              </Button>
            </React.Fragment>
          )}
        </div>
      </div>
      <Table responsive hover striped>
        <thead>
          <tr>
            <th>Test</th>
            <th>Passed</th>
            <th>Details</th>
          </tr>
        </thead>
        {tests && (
          <tbody>
            {Object.entries(tests).map(field => {
              return field[1].tests.map( (test,i) => {
                return (
                  <DisplayTest
                    key={`dt_${i}`}
                    fieldName={field[0]}
                    item={test.item}
                    test={test.test}
                    preTest={test.result}
                  />
                );
              });
            })}
          </tbody>
        )}
        {!tests && (
          <tbody>
            <tr>
              <td colSpan="3">
                {runningTests
                  ? "Running tests"
                  : "Click above to create tests"}
              </td>
            </tr>
          </tbody>
        )}
      </Table>
    </div>
  )
}

const DisplayTest = props => {
  let { test, fieldName, item, preTest } = props;
  let [result, setResult] = useState("");
  let [error, setError] = useState("");
  useEffect(() => {
    if (preTest) {
      console.log("preTest: ", preTest);
      if (preTest.data) {
        setResult(preTest.data);
      }
      if (preTest.error) {
        setError(preTest.error);
      }
    }
  }, []);
  return (
    <tr key={fieldName}>
      <td>
        <Button
          variant="outline-dark"
          size="sm"
          onClick={async e => {
            let response = await test(e);
            console.log("response: ", response);
            if (response.data.error) {
              return setError(response.data);
            }
            return setResult(response.data);
          }}
          className="me-2"
        >
          <i className="fa fa-refresh" aria-hidden="true"></i>
        </Button>
        <code>
          {fieldName}: {item.name}
        </code>
      </td>
      <td>
        {result && (
          <i className="text-success fa fa-check" aria-hidden="true"></i>
        )}
        {error && <i className="text-danger fa fa-times" aria-hidden="true"></i>}
      </td>
      <td>
        {result && (
          <span className="text-success">{result.toLocaleString()}</span>
        )}
        {error && (
          <JSONPretty
            className="text-start"
            id="json-pretty"
            data={error}
          ></JSONPretty>
        )}
      </td>
    </tr>
  );
};

const createTest = params => {
  // colshaped like
  // ["phonePref", {
  //   name: "Phone scrubbing",
  //   displayHeader: true,
  //   inputType: "select",
  //   filterType: "basic",
  //   filterCategory: "Contact preferences",
  //   items: [
  //     {index: 0, query: `(DNC_FLAG="False") and (PHONE <>"")`, value: "A", name: "DNC Scrubbed"},
  //     {index: 1, query: `(PHONE <>'')`, value: "B", name: "All records with phones (no scrubbing)"},
  //     {index: 2, value: "", name: "All records with or without phones"},
  //   ]
  // }]
  let { dataDict, field, item, fetchCount, createRangeQuery } = params;
  let type = field.inputType;
  let createTest = testParams => {
    return async e => {
      console.log(`Run test for ${field.fieldName}: ${item.name}`);
      try {
        let data = await fetchCount(e, true, testParams, true);
        return { data };
      } catch (error) {
        console.log("Test error: ", error);
        return { error };
      }
    };
  };

  let testParams, test, display;
  if (type === "select" || type === "checkbox") {
    testParams = { [field.fieldName]: [item] };
    test = createTest(testParams);
    display = (
      <DisplayTest fieldName={field.fieldName} test={test} item={item} />
    );
  }
  if (type === "range" || type === "stringRange") {
    //console.log("field: ", field);
    let min = JSON.stringify(field.items[0]);
    let max = JSON.stringify(field.items[field.items.length - 1]);
    //console.log("min max: ", min, max);
    let isString = type === "range" ? false : true;
    let rangeQuery = createRangeQuery(
      field.fieldName,
      min,
      max,
      isString,
      dataDict
    );
    testParams = { [field.fieldName]: [rangeQuery] };
    test = createTest(testParams);
    item = rangeQuery;
    display = (
      <DisplayTest fieldName={field.fieldName} test={test} item={rangeQuery} />
    );
  }
  if (test) {
    return { test, item };
  }
  return {
    test: () => console.log("No test for this."),
    display: () => <p>Test for {field.fieldName} is not Select Input</p>
  };
};

const createTests = (dataDict, fetchCount, createRangeQuery) => {
  let tests = {};
  Object.entries(dataDict.cols).map(col => {
    //console.log("col: ", col);
    let itemTests = [];
    let fieldName = col[0];
    let field = col[1];
    field.fieldName = fieldName;
    let type = col[1].inputType;
    if (type === "select" || type === "checkbox") {
      col[1].items.map(item => {
        itemTests.push(createTest({ field, item, fetchCount }));
      });
    }
    if (type === "range" || type === "stringRange") {
      let item = { name: "Max - Min" };
      itemTests.push(
        createTest({ dataDict, field, item, fetchCount, createRangeQuery })
      );
    }
    field.tests = itemTests;
    tests[fieldName] = field;
  });
  console.log("tests: ", tests);
  return tests;
};

export { IndexTests, DisplayTest, createTest, createTests };
