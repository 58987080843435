import React from "react";
import {
  Alert
} from "react-bootstrap";
import { db } from "./firebase.jsx";
import {
  setSavedSearch
} from "./savedSearch.jsx";
import { object_equals } from "./interfaceListShackPro.jsx";
import queryString from "query-string";

function withSearch( WrappedComponent, props ) {
  return class extends React.Component {
    constructor(props) {
      super(props);
      const dataDict = this.props.dataDict;
      //console.log("Database configuration for this search: ", dataDict);
      this.apiUrl = `${process.env.REACT_APP_api_url}${dataDict.apiUrl}`;
      this.state = {
        leadsCount: 0,
        leads: [], /// used for the preview page
        geoSearchBy: null,
        queryParams: [],
        geographyParams: {},
        searchParams: dataDict.defaultSearchParams,
        filterParams: [],
        queryFields: {}, // The fields that the user wants to download
        currentSearch: "", // most recent attempted search
        lastSearch: "", // last successful search sent to database
        isFetching: false,
        showSaveModal: false,
        savedSearch: undefined,
        tests: null,
        runningTests: false
      };
      // Rather than setting all the state variables manually for the whole
      // data dictionary, let's set them here with a for loop
      for (let key of Object.keys(dataDict.cols)) {
        if (dataDict.cols[key].inputType === "checkbox") {
          for (let item of dataDict.cols[key].items) {
            this.state[key + item.index] = "";
          }
        }
        if (dataDict.cols[key].inputType === "range") {
          this.state[key + "min"] = "";
          this.state[key + "max"] = "";
        }
        if (dataDict.cols[key].inputType === "select") {
          this.state[key + "select"] = "";
          this.state[key + "select"] = "";
        }
      }
      // These variables control the query fields for downloading different columns
      let dlFields = {};
      dataDict.dlCols.map( field =>   dlFields[field.value] = field.default ? true : false )
      this.state["queryFields"] = { ...dlFields };
      this.defaultQueryFields = { ...dlFields };

      // Allows for a dynamic navigation based on the tab the user is currently viewing in the UI
      this.tabs = {
        0: {
          name: "Select geography",
          eventKey: "geography"
        },
        1: {
          name: "Pick filters",
          eventKey: "filters"
        },
        2: {
          name: "Preview leads",
          eventKey: "preview"
        },
        3: {
          name: "Checkout",
          eventKey: "checkout"
        }
      };
      this.defaultSearchParams = dataDict.defaultSearchParams;
      this.emptySearchParams = {}; // Simplifies resetting queries
      for (let param of Object.keys(dataDict.cols)) {
        this.emptySearchParams[param] = [];
      } // Just empty arrays
      this.emptyGeographyParams = {}; // Simplifies resetting queries
      // Let's set the Geography parameters
      for (let geoKey of Object.keys(dataDict.geography)) {
        this.state["geographyParams"][geoKey] = [];
        this.emptyGeographyParams[geoKey] = [];
      } // Just empty arrays
      this.queryString = queryString.parse(window.location.search);
      this.fetchCount = this.fetchCount.bind(this);
      this.resetQuery = this.resetQuery.bind(this);
      this.selectGeoSearchBy = this.selectGeoSearchBy.bind(this);
      this.addSearchParam = this.addSearchParam.bind(this);
      this.removeSearchParam = this.removeSearchParam.bind(this);
      this.resetGeoParams = this.resetGeoParams.bind(this);
      this.resetSearchParams = this.resetSearchParams.bind(this);
      this.setDefaultParams = this.setDefaultParams.bind(this);
      this.displayOnMap = this.displayOnMap.bind(this);
      this.createUrl = this.createUrl.bind(this);
      this.setQueryFields = this.setQueryFields.bind(this);
      this.handleInputChange = this.handleInputChange.bind(this);
      this.rangeSelect = this.rangeSelect.bind(this);
      this.stringRangeSelect = this.stringRangeSelect.bind(this);
      this.createRangeQuery = this.createRangeQuery.bind(this);
      //this.saveSearch = this.saveSearch.bind(this);
      this.handleSearchState = this.handleSearchState.bind(this);
    }

    handleInputChange(e) {
      const target = e.target;
      const value = target.type === "checkbox" ? target.checked : target.value;
      const name = target.name;
      //console.log("value: ", value, "name: ", name);
      this.setState({
        [name]: value
      });
    }

    handleDownload(obj) {
      // Call back for the progress bar component that is tracking the download
      this.setState(obj);
    }

    setQueryFields(e, col_value, bool) {
      console.log("setQueryFields", col_value, bool);
      let cState = this.state.queryFields;
      cState[col_value] = this.state.queryFields[col_value] === bool;
      this.setState({
        queryFields: cState
      });
    }

    createUrl(
      searchParams = this.state.searchParams,
      isCount = true,
      isDownload = false
    ) {
      //console.log(
      //  "createUrl: ",
      //  searchParams,
      //  this.state.geographyParams,
      //  isCount,
      //  isDownload
      //);
      const { dataDict } = this.props;
      let { queryFields, filterParams } = this.state;
      let fields = [];
      for (let colKey in queryFields) {
        if (queryFields[colKey]) {
          fields.push(colKey);
        }
      }
      if( filterParams.length > 0 && !isDownload) {
        fields.push( dataDict.map.latitude );
        fields.push( dataDict.map.longitude );
      }
      // sort the searchParams so we can get consistent use of our indexes
      // always put the specified geographies first, they have indexes
      const keyOrder = dataDict.keyOrder;
      let searchTypeObject =
        searchParams !== null
          ? { ...searchParams, ...this.state.geographyParams }
          : { ...this.state.searchParams, ...this.state.geographyParams }; // The order of adding the objects matters

      let sortedKeys = Object.keys(searchTypeObject).sort((a, b) => {
        let alpha = typeof keyOrder[a] === "undefined" ? 10000 : keyOrder[a];
        let beta = typeof keyOrder[b] === "undefined" ? 10000 : keyOrder[b];
        return alpha - beta;
      });

      const validFilter = (key, params) => {
        if (
          !dataDict.selectFilterValidation.includes(key) ||
          (params.length < dataDict["cols"][key]["items"].length &&
            dataDict["cols"][key]["items"].length > 0)
        ) {
          return true;
        }
        return false;
      };

      const returnQueryStatement = (key, param) => {
        if (Array.isArray(param.query)) {
          let queryStatements = [];
          param.query.map(pv => {
            console.log("pv: ", pv);
            return queryStatements.push(pv);
            //console.log("searchFilters: ", searchFilters);
          });
          return queryStatements.join(" or ");
        } else {
          return `(${param.query})`;
        }
      };

      const writeQueryStatement = (key, param) => {
        //console.log(`Adding ${param.query}`);
        if (Array.isArray(param.query)) {
          return param.query.map(pv => {
            //console.log("pv: ", pv)
            return searchFilters.push(pv);
            //console.log("searchFilters: ", searchFilters);
          });
        } else {
          searchFilters.push(`(${param.query})`);
          //return console.log("searchFilters: ", searchFilters);
        }
      };

      const writeSingleStatement = (key, param) => {
        //console.log("writeSingleStatement: ", key, param);
        if (param.query) {
          return writeQueryStatement(key, param);
        } else if (param.value === "") {
          return;
        } else {
          return searchFilters.push(`(${key}='${param.value}')`);
        }
      };

      const writeAggStatement = (key, params) => {
        //console.log("writeAggStatement: ", key, params);
        let values = [];
        let statement = [];
        params.map(param => {
          if (param.query) {
            return statement.push(returnQueryStatement(key, param));
          } else if (typeof param.value === "number") {
            return values.push(param.value);
          } else {
            return values.push(`'${param.value}'`);
          }
        });
        // if they are custom queries statements, return those
        if (statement.length) {
          return `(${statement.join(" or ")})`;
        }
        // Don't write statements that include all possible values
        if (values.length > 0) {
          return `(${key} in (${values}))`;
        }
        return;
      };

      const writeAggFilter = (key, params) => {
        //console.log("writeAggFilter: ", key, params);
        params.map(param => {
          let aggColumn = Object.values(param.agg)[0];
          if (!aggFilters[key]) {
            aggFilters[key] = {};
          }
          if (!aggFilters[key][aggColumn]) {
            aggFilters[key][aggColumn] = [];
          }
          return aggFilters[key][aggColumn].push(param);
        });
      };

      let searchFilters = []; // these will be joined by an AND statement
      let aggFilters = {};
      sortedKeys.map(key => {
        let params = searchTypeObject[key];
        if (!params.length) {
          return null;
        }
        if (params[0].agg) {
          return writeAggFilter(key, params);
        }
        if (params[0].value === "") {
          return null;
        }
        if (params.length === 1) {
          return writeSingleStatement(key, params[0]);
        }
        if (params.length > 1 && validFilter(key, params)) {
          return searchFilters.push(writeAggStatement(key, params));
        }
        return null;
      });
      //console.log("aggFilters: ", aggFilters);

      // Write aggregate statements
      let aggStatements = [];
      Object.keys(aggFilters).map(key => {
        let nestedAggStatements = [];
        //console.log("aggFilters key: ", key);
        let nestedObject = aggFilters[key];
        //console.log("nestedObject: ", nestedObject);
        Object.keys(nestedObject).map(nestedKey => {
          //console.log("nestedKey: ", nestedKey);
          let aggParams = nestedObject[nestedKey];
          //console.log("aggParams: ", aggParams);
          let ap = aggParams[0].agg; // {ST: "NV"}
          let apk = Object.keys(ap)[0]; // "ST"
          let apv = Object.values(ap)[0]; // "NV"
          let ags = `(${apk}='${apv}')`; //(ST="NV")
          if (aggParams.length === 1) {
            return nestedAggStatements.push(
              `(${key}='${aggParams[0].value}') and ${ags}`
            );
          }
          if (aggParams.length > 1) {
            let as = writeAggStatement(key, aggParams);
            //console.log("as: ", as);
            let nas = `(${as} and ${ags})`;
            return nestedAggStatements.push(nas);
          }
          return null;
        });
        if (nestedAggStatements.length) {
          let nestedStatement = nestedAggStatements.join(" or ");
          aggStatements.push(`(${nestedStatement})`);
        }
        return null;
      });
      if (aggStatements.length) {
        searchFilters.push(`(${aggStatements.join(" or ")})`);
      }

      //console.log("searchFilters: ", searchFilters);
      //let fieldsString = isCount ? "" : "fields=" + fields.join(","); // if it's a count only, don't send the fields over... as that should perform faster.
      let fieldString = isCount ? "count(*)" : fields.join(",");
      //let filtersString = `${isCount ? "" : "&" }filter=` + s.join(" or ");
      let filterString = searchFilters.join(" and ");

      // In order to help keep our servers from needless searches by users, we'll only allow them to search if it's a new search.  We'll know if it's new by saving the search query URL and then comparing that to the last search that they did.  If it's the same, disable searching again.  If it's different, let them search. */}
      let queryUrl = {
        fields: fieldString,
        filter: filterString.replace(/"/g, "'"),
        count_only: isCount ? "true" : "false",
        limit: isDownload ? "" : 100,
        url: this.apiUrl,
        index: this.props.dataDict.index ? this.props.dataDict.index : null,
        dictId: this.props.dataDict.dictId ? this.props.dataDict.dictId : null
      };
      if (this.state.filterParams.length > 0) {
        queryUrl.dslFilter = this.state.filterParams
      }

      // Keep saved searches from disappearing while we are fetching preview leads
      let updateSearch;
      if (!isCount) {
        updateSearch = { ...queryUrl };
        updateSearch.count_only = "true";
        updateSearch.fields = "count(*)";
      }

      this.setState({
        currentSearch: isCount ? queryUrl : updateSearch
      });
      console.log("queryUrl: ", queryUrl);
      return queryUrl;
    }

    async fetchCount(e, isCount = true, searchParams = null, isTest = false) {
      //e.preventDefault();

      // tell the user that we're fetching
      if (!isTest) {
        await this.setState({
          isFetching: true
        });
      }

      let searchUrl = this.createUrl(searchParams, isCount);

      let postBody = JSON.stringify(searchUrl);
      // await response of fetch call
      const init = {
        method: "POST",
        headers: {
          authorization: `Bearer ${this.props.apiKey}`,
          "Content-Type": "application/json"
        },
        "Transfer-Encoding": "chunked",
        cache: "default",
        accept: "application/json",
        body: postBody
      };

      //let indexUrl = this.apiUrl;
      if (isCount) {
        console.log(`Getting count for: ${postBody}`);
      }
      try {
        let response = await fetch(this.apiUrl, init);
        //console.log("response: ", response);
        // only proceed once promise is resolved
        let data = await response.json();
        //console.log("data: ", data);
        if (data.error) {
          if (!isTest) {
            this.setState({
              leadsCount: 0,
              isFetching: false,
              lastSearch: searchUrl,
              leads: []
            });
            this.props.handleAlerts(
              e,
              <React.Fragment>
                Uh oh, there was a problem with your search. Try changing your
                search. If the problem persists send a screenshot to{" "}
                <Alert.Link href={process.env.REACT_APP_contact_email}>
                  {process.env.REACT_APP_contact_email}
                </Alert.Link>
              </React.Fragment>,
              "warning"
            );
          }
          throw data;
        }
        if (isCount) {
          await console.log("Records available: ", data);
          if (!isTest) {
            await this.setState({
              leadsCount: data, //display the count to the user
              isFetching: false, // Stop showing the loadng spinner
              lastSearch: searchUrl, // Save the query URL to prevent needless searches and for our cloud function if the user checks out.
              leads: []
            });
          }
        } else {
          let countBody = this.createUrl(null, true);
          console.log("countUrl: ", countBody);
          init.body = JSON.stringify(countBody);
          let countResponse = await fetch(this.apiUrl, init);
          let countData = await countResponse.json();
          console.log("countData: ", countData);
          if (!isTest) {
            await this.setState({
              leadsCount: countData, // still want to display the count
              leads: data.resource, // send preview records to the preview component
              isFetching: false, // Stop showing the loading spinner
              lastSearch: searchUrl // Save the URL for the cloud function if the user decides to check out
            });
          }
        }
        return data;
      } catch (error) {
        await console.error(error);
        if (!isTest) {
          await this.setState({
            isFetching: false
          });
          // Tell the user that something went wrong.
          await this.props.handleAlerts(
            e,
            <React.Fragment>
              Uh oh, something went wrong. ${error.message}. Try changing your
              search. If the problem persists send a screenshot to{" "}
              <Alert.Link href={process.env.REACT_APP_contact_email}>
                {process.env.REACT_APP_contact_email}
              </Alert.Link>
            </React.Fragment>,
            "warning"
          );
        }
        return error;
      }
    }

    async resetQuery(e) {
      //console.log("Reseting the query");
      let searchType = this.state.searchType || "cols";
      let stateObject = await this.setDefaultParams(e, searchType, {
        geoSearchBy: null, //clear the geographies
        download: null, //clear the download
        searchName: "", //clear the searchname
        downloadCount: "", //clear the download count
        leadsCount: 0, //clear the count box
        isDownloading: false, // clear checkout tab
        isBigDownload: false, // clear display of progress bar
        currentSearch: "", // clear current search so you can refresh counts
        queryFields: this.defaultQueryFields
      });
      //console.log("stateObject: ", stateObject);
      await this.setState(stateObject);
    }

    async selectGeoSearchBy(e, type) {
      e.preventDefault();
      this.setState({ geoSearchBy: type });
      // Allow users to skip the geo search and go after the whole database with a nationwide search
      if (type === "nationwide") {
        this.addSearchParam(
          "geography",
          "nationwide",
          { value: "", name: "Nationwide" },
          true
        );
      }
    }

    resetGeoParams(e) {
      //e.preventDefault();
      this.setState({
        geographyParams: { ...this.emptyGeographyParams },
        currentSearch: ""
      });
    }

    resetSearchParams(e) {
      e.preventDefault();
      // To Do: reset all the state variables that control the UI
      this.setState({
        searchParams: this.defaultSearchParams,
        currentSearch: ""
      });
    }

    async setDefaultParams(e, searchType = "cols", add_object = {}) {
      const dataDict = this.props.dataDict;
      const emptyGeographyParams = { ...this.emptyGeographyParams }; // Simplifies resetting queries
      //console.log("emptyGeographyParams: ", emptyGeographyParams);
      let defaultParams = dataDict.defaultSearchParams;
      //console.log("defaultParams: ", defaultParams);
      let database = dataDict.dictId;
      let userDoc;
      // Check if the user has saved default search parameters
      await db
        .collection("searches")
        .doc(this.props.user.uid)
        .collection("params")
        .doc(database)
        .get()
        .then(function(doc) {
          if (doc.exists) {
            userDoc = doc.data();
            console.log("Default search data: ", userDoc);
          } else {
            // doc.data() will be undefined in this case
            console.log("No default search document!");
          }
        })
        .catch(function(error) {
          console.log("Error getting document:", error);
        });
      // A function to check whether the search params are the same
      function arraysEqual(a, b) {
        if (a === b) return true;
        if (a == null || b == null) return false;
        if (a.length !== b.length) return false;

        // If you don't care about the order of the elements inside
        // the array, you should sort both arrays here.
        // Please note that calling sort on an array will modify that array.
        // you might want to clone your array first.

        a = { ...a.sort() };
        b = { ...b.sort() };

        for (var i = 0; i < Object.values(a).length; i++) {
          //console.log(i);
          //console.log(a[i], "!==", b[i]);
          //console.log(a[i] !== b[i]);
          if (a[i] !== b[i]) return false;
        }
        return true;
      }

      // if the user has saved default use it
      if (userDoc && userDoc.queryUrl.index === dataDict.index) {
        console.log("The user has saved default search params.");
        // Check whether the saved search params match the data dictionary
        let dataDictDefault = Object.keys(dataDict.defaultSearchParams);
        let userSavedDefault = Object.keys(userDoc.searchParams);
        //console.log("dataDictDefault: ", dataDictDefault, "userSavedDefault: ", userSavedDefault);

        let dataDictSame = arraysEqual(dataDictDefault, userSavedDefault);
        //console.log(dataDictSame);
        if (dataDictSame) {
          console.log(
            "The data dictionaries match, so we're setting default search params from the database.",
            userDoc.searchParams
          );
          defaultParams = userDoc.searchParams;
          for (let geoKey of Object.keys(emptyGeographyParams)) {
            defaultParams[geoKey] = [];
          }
          //console.log(defaultParams);
        } else {
          console.log(
            "The data dictionaries do not match, using the data dictionaries default search params."
          );
          //console.log("defaultParams: ", defaultParams);
          this.props.handleAlerts(
            "",
            "We've updated the filters that are available to search by. You may need to reset your default search parameters.",
            "info"
          );
        }
      }

      // Create the proper state variables to keep the filters in sync
      // with the default search parameters
      let stateObject = {};
      for (let key of Object.keys(defaultParams)) {
        //console.log("key: ", key);
        let inputType = dataDict["cols"][key].inputType;
        if (defaultParams[key].length > 0) {
          // Look up what type of input it is
          if (inputType === "checkbox") {
            // create an object with a shape like;
            // PHONE_OCCURRENCE1: true
            for (let item of dataDict["cols"][key].items) {
              if (
                defaultParams[key].some(param => {
                  // Check if the item is a saved Param
                  return (
                    JSON.stringify(Object.values(item).sort()) ===
                    JSON.stringify(Object.values(param).sort())
                  );
                })
              ) {
                // if so set value to true, so it stays checked in filters
                stateObject[key + item.index] = true;
              } else {
                // if not set value to "" so it is not checked in filters
                stateObject[key + item.index] = "";
              }
            }
            //for (let param of defaultParams[key]) {
            //  stateObject[key + param.index] = true
            //}
          }
          if (inputType === "select") {
            // create an object with a shape like:
            // phonePrefselect: "A"
            stateObject[key + "select"] = defaultParams[key][0].value;
          }
          if (inputType === "range" || inputType === "stringRange") {
            // create an object with a shape like:
            // AGEmin: "{"value":19,"order":19,"name":19}"
            // AGEmax: "{"value":99,"order":99,"name":99}"
            //console.log("dataDict: ", dataDict);
            //console.log("key: ", key);
            //console.log("dataDict[cols][key]: ", dataDict["cols"][key]);
            let minValue = defaultParams[key][0].value[0];
            let maxValue = defaultParams[key][0].value[1];
            let dmni = dataDict["cols"][key].items.find(
              item => item.value === minValue
            );
            let dmxi = dataDict["cols"][key].items.find(
              item => item.value === maxValue
            );
            stateObject[key + "min"] = JSON.stringify(dmni);
            stateObject[key + "max"] = JSON.stringify(dmxi);
          }
        } else {
          // If the array is empty, reset the state variable to an empty string
          if (inputType === "checkbox") {
            // create an object with a shape like;
            // PHONE_OCCURRENCE1: ""
            for (let item of dataDict["cols"][key].items) {
              stateObject[key + item.index] = "";
            }
          }
          if (inputType === "select") {
            // create an object with a shape like:
            // phonePrefselect: ""
            stateObject[key + "select"] = "";
          }
          if (inputType === "range" || inputType === "stringRange") {
            // create an object with a shape like:
            // AGEmin: ""
            // AGEmax: ""
            stateObject[key + "min"] = "";
            stateObject[key + "max"] = "";
          }
        }
      }
      let geographyParams;
      if (userDoc && userDoc.geographyParams) {
        console.log("The user has saved geography params.");

        //console.log("dataDict.geography:", Object.keys(dataDict.geography), userDoc.geographyParams);

        let dataDictSame = arraysEqual(
          Object.keys(dataDict.geography),
          Object.keys(userDoc.geographyParams)
        );
        //console.log(dataDictSame);

        if (dataDictSame) {
          console.log(
            "The geography data dictionaries match, so we're setting default geography params from the database.",
            userDoc.geographyParams
          );
          geographyParams = userDoc.geographyParams;
        } else {
          console.log(
            "The data dictionaries do not match, using the data dictionaries default search params."
          );
          geographyParams = emptyGeographyParams;
          this.props.handleAlerts(
            "",
            "We've updated the filters that are available to search by. You may need to reset your default search parameters.",
            "info"
          );
        }
      } else {
        geographyParams = emptyGeographyParams;
      }
      //console.log("userDoc.queryUrl: ", userDoc.queryUrl);
      stateObject.geographyParams = geographyParams;
      stateObject.searchParams = defaultParams;
      stateObject.savedSearch = userDoc;
      stateObject.currentSearch = userDoc
        ? userDoc.queryUrl
          ? userDoc.queryUrl
          : ""
        : "";

      for (let addKey of Object.keys(add_object)) {
        stateObject[addKey] = add_object[addKey];
      }
      // Reset the dl_col state variables
      //for (let dl_var of dataDict.dlCols) {
      //  let dl_name = `dl_${dl_var.value}`;
      //  if (dl_var.default) {
      //    stateObject[dl_name] = true;
      //  } else {
      //    stateObject[dl_name] = false;
      //  }
      //}
      //console.log("stateObject: ", stateObject);
      return await stateObject;
    }

    addSearchParam(dict, key, valuesArray, isSelect = false) {
      //console.log(
      //  'dict: ', dict,
      //  'key: ', key,
      //  'valuesArray: ', valuesArray,
      //  //'state params: ', eval(`this.state.${dict}Params`),
      //  'isSelect: ', isSelect
      //);

      let stateRef =
        dict === "geography"
          ? this.state.geographyParams
          : this.state.searchParams;
      //console.log("stateRef: ", stateRef);
      let paramType = dict === "geography" ? "geographyParams" : "searchParams";
      //console.log("paramType: ", paramType);
      let nQuery = stateRef;
      //console.log("nQuery: ", nQuery);
      let cParam = !isSelect ? nQuery[key] : [];
      //console.log("cParam: ", cParam);
      // Add all the new values to the current values in the array
      let nParam = cParam.concat(valuesArray);
      //console.log("nParam: ", nParam);
      // Set the new values equal to the same index place in old values
      nQuery[key] = nParam;
      //console.log("nQuery: ", nQuery);
      // Save the new values to state
      this.setState({
        [paramType]: nQuery
      });
      this.createUrl();
    }

    removeSearchParam(dict, key, valuesArray) {
      //console.log(
      //  'dict', dict,
      //  'key: ', key,
      //  'valuesArray: ', valuesArray,
      //  'state params: ', eval(`this.state.${dict}Params`)
      //);

      let stateRef =
        dict === "geography"
          ? this.state.geographyParams
          : this.state.searchParams;

      let paramType = dict === "geography" ? "geographyParams" : "searchParams";

      let nQuery = stateRef;

      let cParam = nQuery[key];

      // Filter out all the of values that are included in the array sent over
      let nParam = cParam.filter(array => {
        //This is an entire array ... not just an an element
        return JSON.stringify(array) !== JSON.stringify(valuesArray); // This needs to compare two arrays, not just two elements in the array.
      });
      // Set the new values equal to the same index place in old values
      nQuery[key] = nParam;
      const dataDict = this.props.dataDict;
      let inputType = dataDict["cols"][key].inputType;
      let stateKey, stateKey2, stateValue;
      if (inputType === "checkbox") {
        stateKey = key + valuesArray.index;
        stateValue = false;
      } else if (inputType === "select") {
        stateKey = key + "select";
        stateValue = "";
      } else if (inputType === "range" || inputType === "stringRange") {
        stateKey = key + "min";
        stateKey2 = key + "max";
        stateValue = "";
      }

      // Save the new values to state
      this.setState({
        [paramType]: nQuery,
        [stateKey]: stateValue,
        [stateKey2]: stateValue
      });

      this.createUrl();
    }

    // To Do: allow folks to click a link and open up a map of the geography on Google. Makes it easier to see where you are searching.
    displayOnMap(e, key, param) {
      e.preventDefault();
      console.log(key, param);
    }

    createRangeQuery(key, min, max, string = false, dataDict) {
      //console.log("key: ", key, min, max);
      if (min !== "" && max !== "") {
        // Calculate min and max to dummy proof the range select
        let parsedMin = JSON.parse(min);
        let parsedMax = JSON.parse(max);
        let calcMin = parsedMin.order < parsedMax.order ? parsedMin : parsedMax;
        let calcMax = parsedMin.order < parsedMax.order ? parsedMax : parsedMin;
        let item;
        if (!string) {
          item = {
            query: `(${key} >= ${calcMin.value}) and (${key} <= ${calcMax.value})`,
            value: [calcMin.value, calcMax.value],
            name: `between ${calcMin.name} and ${calcMax.name}`
          };
        }
        if (string) {
          let stringArray = [];
          for (let item of dataDict.cols[key].items) {
            if (item.order >= calcMin.order && item.order <= calcMax.order) {
              stringArray.push(`'${item.value}'`);
            }
          }
          item = {
            query: [`(${key} IN (${stringArray.join(",")}) )`],
            value: [calcMin.value, calcMax.value],
            name: `between ${calcMin.name} and ${calcMax.name}`
          };
        }
        return item;
      }
    }

    async rangeSelect(e, key, min, max) {
      e.preventDefault();
      await this.setState({
        [key + "min"]: min,
        [key + "max"]: max
      });
      if (min !== "" && max !== "") {
        let item = this.createRangeQuery(key, min, max);
        await this.addSearchParam(this.state.searchType, key, [item], true);
        return item;
      }
    }

    async stringRangeSelect(e, key, min, max) {
      e.preventDefault();
      //console.log("stringRangeSelect: ", key, min, max);
      await this.setState({
        [key + "min"]: min,
        [key + "max"]: max
      });
      if (min !== "" && max !== "") {
        let item = this.createRangeQuery(
          key,
          min,
          max,
          true,
          this.props.dataDict
        );
        //console.log("stringArray: ", stringArray);
        await this.addSearchParam(this.state.searchType, key, [item], true);
      }
    }

    formatAdvancedFilters(array_filters) {
      // just loop through the filters and create a new object shaped like:
      // {
      //     "Mortgage information": ["Col1", "Col2", "Col3"],
      //     "Financial information": ["Col4", "Col5"]
      // }

      //console.log("array_filters: ", array_filters);
      const dataDict = this.props.dataDict;
      let formattedCols = {};
      for (let col of array_filters) {
        let colCategory = dataDict["cols"][col]["filterCategory"];
        //console.log("colCategory: ", colCategory);
        if (colCategory !== undefined) {
          if (formattedCols[colCategory] === undefined) {
            formattedCols[colCategory] = [col];
          } else {
            formattedCols[colCategory].push(col);
          }
        }
        //console.log("formattedCols: ", formattedCols);
      }
      return formattedCols;
    }

    // Check whether there are any searchParameters
    checkParamCount() {
      const geographyParams = { ...this.state.geographyParams };
      const searchParams = { ...this.state.searchParams };
      const allParams = Object.assign(searchParams, geographyParams);
      let paramCount = 0;
      for (let col of Object.values(allParams)) {
        paramCount += col.length;
      }
      return paramCount > 0 ? true : false;
    }

    async selectCheckoutTab() {
      this.setState({ selectedTab: "3" });
      let currentFilter = this.state.currentSearch.filter;
      let duplicateSum = 0;
      let maxOffset = 0;
      let fetchedDownloads = [];
      let offsets = [];
      let nonDupeCount;
      let downloadsRef = db
        .collection("downloads")
        .doc(this.props.userDoc.uid)
        .collection("files")
        .where("queryUrl.filter", "==", currentFilter);
      let sameDownloads = await downloadsRef.get();
      sameDownloads.forEach(doc => {
        let docData = doc.data();
        docData.id = doc.id;
        // Only show files where the database is the same
        if (docData.queryUrl.url !== this.apiUrl) {
          return null;
        }
        // Only show files where the download completed and it hasn't been reported
        if (docData.csvFile && !docData.reported) {
          fetchedDownloads.push(docData);
        }
        // check if they are actually duplicates
        if (!offsets.includes(docData.offset)) {
          offsets.push(docData.offset);
          duplicateSum += parseInt(docData.downloadCount);
        }
        // prepare for fetching a non-duplicate count
        if (maxOffset < docData.offset) {
          maxOffset = docData.offset;
        }
      });
      //console.log("fetchedDownloads: ", fetchedDownloads, "duplicateSum: ", duplicateSum, "offsets: ", offsets, "currentFilter: ", currentFilter, "this.apiUrl: ", this.apiUrl);
      if (maxOffset > 0) {
        let newSearch = { ...this.state.currentSearch };
        newSearch.offset = maxOffset;
        newSearch.count_only = "true";
        //console.log("newSearch: ", newSearch);
        let postBody = JSON.stringify(newSearch);
        // await response of fetch call
        const init = {
          method: "POST",
          headers: {
            authorization: `Bearer ${this.props.apiKey}`,
            "Content-Type": "application/json"
          },
          "Transfer-Encoding": "chunked",
          cache: "default",
          accept: "application/json",
          body: postBody
        };

        let indexUrl = this.apiUrl;
        try {
          let response = await fetch(indexUrl, init);
          nonDupeCount = await response.json();
        } catch (err) {
          console.log("Something went wrong fetching nonDupeCount: ", err);
        }
      }

      let sortedDownloads = fetchedDownloads.sort((a, b) => {
        let aV = a.timestamp.seconds;
        let bV = b.timestamp.seconds;
        return aV - bV;
      });

      let duplicateDownloads = {
        duplicateSum,
        maxOffset,
        nonDupeCount,
        items: sortedDownloads
      };
      console.log("duplicateDownloads: ", duplicateDownloads);
      this.setState({
        isDuplicate: sortedDownloads.length > 0 ? true : false,
        duplicateDownloads
      });
    }

    handleSearchState(obj) {
      this.setState(obj)
    }

    render() {
      //console.log("searchAgain this.props: ", this.props, "this.state: " this.state);
      const {
        geographyParams,
        searchParams,
        filterParams,
        currentSearch,
        lastSearch,
        savedSearch
      } = this.state;
      function paramLength(params) {
        let length = 0;
        Object.values(params).map( p => length += p.length)
        return length;
      }
      let hasGeo = paramLength(geographyParams) !== 0;
      let hasFilters = paramLength(searchParams) !==0;
      let hasMap = filterParams.length > 0;
      //console.log("hasGeo: ", hasGeo, "hasFilters: ", hasFilters, "hasMap: ", hasMap);
      let canFetch =
        JSON.stringify(currentSearch) !== JSON.stringify(lastSearch) &&
        currentSearch !== "";
      //console.log("savedSearch: ", savedSearch, "currentSearch: ", currentSearch);
      //let canSave = typeof savedSearch !== "undefined" ? ( JSON.stringify(currentSearch) !== JSON.stringify( savedSearch.queryUrl ) ) : true;
      let canSave =
        typeof savedSearch !== "undefined"
          ? !object_equals(currentSearch, savedSearch.queryUrl)
          : true;
      //console.log("canSave: ", canSave);
      //if (canSave && savedSearch) { console.log(JSON.stringify(currentSearch), JSON.stringify(savedSearch.queryUrl)) }
      let searchParamsCount = 0;
      Object.values(this.state.searchParams).map(
        (array) => (searchParamsCount += array.length)
      );
      const geo_bounding_box = this.state.filterParams.find( p => Object.keys(p).includes("geo_bounding_box"))

      return (
        <WrappedComponent
          {...this.props}
          {...this.state}
          hasGeo={hasGeo}
          hasMap={hasMap}
          geoBoundingBox={geo_bounding_box}
          hasFilters={hasFilters}
          searchParamsCount={searchParamsCount}
          canFetchSearch={canFetch}
          canSaveSearch={canSave}
          handleInputChange={this.handleInputChange}
          handleDownload={this.handleDownload}
          setQueryFields={this.setQueryFields}
          fetchCount={this.fetchCount}
          resetQuery={this.resetQuery}
          selectGeoSearchBy={this.selectGeoSearchBy}
          resetGeoParams={this.resetGeoParams}
          resetSearchParams={this.resetSearchParams}
          setDefaultParams={this.setDefaultParams}
          // Required for Filters component
          addSearchParam={this.addSearchParam}
          removeSearchParam={this.removeSearchParam}
          rangeSelect={this.rangeSelect}
          stringRangeSelect={this.stringRangeSelect}
          createUrl={this.createUrl}
          apiUrl={this.apiUrl}
          // Required for IndexTests component
          createRangeQuery={this.createRangeQuery}
          //Generic must haves
          handleSearchState={this.handleSearchState}
        />
      );
    }

    async componentDidMount() {
      let { handleAlerts, dataDict, user } = this.props;
      await this.resetQuery();
      if (this.checkParamCount()) {
        this.createUrl();
      }
      // Handle Shared Searches and Reusing searches from Downloads
      setSavedSearch(
        {
          handleState: obj => {
            this.setState(obj);
          },
          dataDict,
          user
        },
        (error, result) => {
          if (error) {
            handleAlerts(
              "",
              `Unable to set saved search: ${error.message}`,
              "warning"
            );
          }
          if (result) {
            handleAlerts(
              "",
              <React.Fragment>
                Yay, your viewing{" "}
                {result.type === "download"
                  ? "the search used for download"
                  : "the saved search"}{" "}
                <b>{result.savedSearch.searchName}</b>.
              </React.Fragment>
            );
          }
        }
      );
    }
  }
}

export default withSearch;