import React from "react";
import PropTypes from "prop-types";
import { db } from "./firebase.jsx";
import { Row, Col, Alert } from "react-bootstrap";
import ProgressBar from "react-bootstrap/ProgressBar";

class DownloadProgress extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      fetchProgress: 0
      //storingFile: false, // To do: Fix the lag between the time when the file is ready to download and the progress bar is 100%
    };
    this.listenForDownload = this.listenForDownload.bind(this);
  }

  listenForDownload() {
    // Start listening for changes so we can display the progress bar
    // Looking for the following attributes
    // {
    //    fetchProgress: 10,
    //    storingFile: true,
    //    csvFile: "someUrlToDownload"
    // }
    const uid = this.props.user.uid;
    const doc_id = this.props.doc_id;

    let dbRef = db
      .collection("downloads")
      .doc(uid)
      .collection("files")
      .doc(doc_id);

    dbRef.onSnapshot(doc => {
      let data = doc.data();
      this.setState({
        fetchProgress: data.fetchProgress,
        storingFile: data.storingFile
      });
      if (data.csvFile) {
        if (this.props.handleDownload) {
          this.props.handleDownload({
            download: data.csvFile,
            isDownloading: false,
            searchName: data.searchName,
            downloadCount: data.downloadCount
          });
        }
        if (this.props.handleAlerts) {
          this.props.handleAlerts(
            "",
            <React.Fragment>
              Your file is ready. Download it{" "}
              <Alert.Link href={this.props.download}>here</Alert.Link>.
            </React.Fragment>,
            "success",
            "Download complete"
          );
        }
      }
    });
  }

  render() {
    let fetchProgress = this.state.fetchProgress;

    return (
      <Row>
        <Col xs={12}>
          <ProgressBar
            variant="primary"
            now={fetchProgress}
            key={1}
            label={`${fetchProgress}%`}
          />
        </Col>
      </Row>
    );
  }

  componentDidMount() {
    this.listenForDownload(this.props.doc_id);
  }

  componentWillUnmount() {
    this.listenForDownload(this.props.doc_id);
  }
}

DownloadProgress.propTypes = {
  user: PropTypes.object,
  doc_id: PropTypes.string,
  searchName: PropTypes.string,
  handleDownload: PropTypes.func,
  download: PropTypes.string,
  handleAlerts: PropTypes.func
};

export default DownloadProgress;
