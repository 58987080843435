import React, { useState, useEffect, useRef } from "react";
import { Link, useParams } from "react-router-dom";
import { Collection, CollectionContext } from "./firebaseView.jsx";
import { db, firebase, storeMedia, batchWrite, getDocs } from "./firebase.jsx";
import {
  Container,
  Row,
  Col,
  Table,
  Tabs,
  Tab,
  Button,
  Form,
  Modal,
  ProgressBar,
  ButtonGroup,
  Dropdown,
  Card,
} from "react-bootstrap";
import {
  object_equals,
  indexOfArray,
  makeid,
  formatPhoneNumber,
  NotFound,
  santizeFileName,
} from "./interfaceListShackPro.jsx";
import { FileDrop } from "react-file-drop";
import {
  previewCsv,
  parseCsv,
  unparseToCsv,
  formatState,
} from "./appendCsv.jsx";
import {
  ThreeDots,
  BookmarkStarFill,
  BookmarkPlus,
  CheckSquareFill,
  DashSquareFill,
  Download,
  CloudUpload,
  Trash,
  NodePlus,
  Triangle,
  XSquare,
  Circle,
  CheckCircleFill,
  CircleFill,
  ChevronLeft,
  DashCircleFill,
  PlusCircleFill,
  Plus
} from "react-bootstrap-icons";
import Spinner from "react-bootstrap/Spinner";
import { sendEmail } from "./email.jsx";
import AutoComplete from "./autoComplete";

function withCrm(WrappedComponent, props) {
  return class extends React.Component {
    constructor(props) {
      super(props);
      this.state = {
        handleCrmState: (obj) => {
          this.setState(obj);
        },
        crmLoaded: true,
        formatContactMethod,
        addToCrm,
        createList,
        Phone,
        Addr,
        Email,
        selectedData: [],
        toggleSelected: (docId) => {
          let newSelected = [...this.state.selectedData];
          if (newSelected.includes(docId)) {
            newSelected = newSelected.filter((item) => item !== docId);
          } else {
            newSelected.push(docId);
          }
          this.setState({ selectedData: newSelected });
        },
        lists: null
      };
    }

    render() {
      return <WrappedComponent {...this.props} {...this.state} />;
    }

    componentDidMount() {
      const {userDoc} = this.props;
      getDocs({
        ref: db.collection("crm").doc(userDoc.id).collection("lists")
      },
        (error, docs) => {
          if (docs) {
            this.setState({
              lists: docs
            })
          }
          if (error) {
            console.log("Error getting user lists: ", error);
          }
        }
      )
    }
  };
}

const updateList = async (params, callback) => {
  console.log("updateList params: ", params);
  let { listConfig, aid, uid } = params;
  try {
    let listRef = db
      .collection("crm")
      .doc(aid)
      .collection("lists")
      .doc(listConfig._docId);
    listConfig._lastChanged = Math.round(new Date().getTime() / 1000);
    let update = await listRef.update(listConfig);
    if (callback) {
      callback(null, update);
    }
    return update;
  } catch (err) {
    console.log("Something went wrong updating the list: ", err);
    if (callback) {
      callback(err);
    }
  }
};

const createList = async (params, callback) => {
  //listConfig should be shaped like:
  //{
  //  listName: "string",
  //  listStatus: ["s1", "s2"],
  //  showAllTab: true,
  //  showNewTab: true,
  //  showOtherTab: false,
  //  tabs: [ {fieldValue: ["s1", "s2"], tabTitle: "S1 & S2"}]
  //}

  let { listConfig, aid, uid, currentLists } = params;
  let defaultListConfig = {
    listName: `List ${currentLists.length + 1}`,
    description: `List created ${new Date().toLocaleString()}`,
    listStatus: [],
    showAllTab: true,
    showNewTab: true,
    showOtherTab: false,
    tabs: [],
    fields: [],
    displayFields: ["NAME", "ADDR", "PHONE", "EMAIL"],
    fieldMapping: {},
    createdBy: uid,
    favorite: false,
    archived: false,
    _created: Math.round(new Date().getTime() / 1000),
    _lastChanged: Math.round(new Date().getTime() / 1000),
  };
  if (!listConfig) {
    listConfig = defaultListConfig;
  } else {
    Object.keys(defaultListConfig).map((k) => {
      if (!listConfig[k]) {
        listConfig[k] = defaultListConfig[k];
      }
    });
  }
  try {
    let listRef = db.collection("crm").doc(aid).collection("lists");
    await listRef.add(listConfig);
  } catch (err) {
    console.err("Something went wrong in createList: ", err);
  }
};

const ManageList = (props) => {
  const {
    userDoc,
    user,
    handleState,
    createList,
    editList,
    listConfig,
    currentLists,
  } = props;
  const [newListName, setNewListName] = useState("");
  const [themeColor, setThemeColor] = useState("light");
  const [description, setDescription] = useState("");
  const [showCreateList, setShowCreateList] = useState(false);
  //console.log("ManageList listConfig: ", listConfig);
  useEffect(() => {
    if (editList) {
      setNewListName(listConfig.listName);
      setThemeColor(listConfig.themeColor);
      setDescription(listConfig.description);
    }
  }, []);
  return (
    <React.Fragment>
      <a
        className={props.className ? props.className : ""}
        style={props.style ? props.style : null}
        onClick={() => setShowCreateList(true)}
      >
        {props.children}
      </a>
      {showCreateList && (
        <Modal
          show={showCreateList}
          onHide={() => setShowCreateList(false)}
          autoFocus={false}
        >
          <Modal.Body className={`bg-${themeColor}`}>
            <Form.Group>
              <Form.Control
                size="lg"
                type="text"
                value={newListName}
                onChange={(e) => setNewListName(e.target.value)}
                placeholder="Add list name"
                autofocus="true"
              />
            </Form.Group>
            <Form.Group>
              <Form.Control
                as="textarea"
                rows={2}
                value={description}
                onChange={(e) => setDescription(e.target.value)}
                placeholder="Add an optional description for your list"
              />
            </Form.Group>
            <Form.Group className="bg-white p-2 rounded">
              <Form.Label>Select color</Form.Label>
              <div className="d-flex flex-row bg-white">
                {[
                  "light",
                  "primary",
                  "secondary",
                  "success",
                  "info",
                  "danger",
                  "warning",
                  "dark",
                ].map((t) => {
                  let cColor = t === "light" ? "dark" : "white";
                  return (
                    <div
                      key={`color-${t}`}
                      onClick={() => setThemeColor(t)}
                      className={`bg-white`}
                    >
                      {themeColor === t && (
                        <CheckSquareFill
                          className={`text-${t} m-1 bg-${cColor}`}
                          size={30}
                        />
                      )}
                      {themeColor !== t && (
                        <DashSquareFill
                          className={`text-${t} m-1 bg-${cColor}`}
                          size={30}
                        />
                      )}
                    </div>
                  );
                })}
              </div>
            </Form.Group>
          </Modal.Body>
          <Modal.Footer className="d-flex justify-content-between">
            <Button
              variant="link"
              size="sm"
              className="text-muted"
              onClick={() => {
                console.log("Clicked nevermind!");
                setShowCreateList(false);
              }}
            >
              Nevermind
            </Button>
            {!editList && (
              <Button
                onClick={() => {
                  console.log("Clicked create new list!");
                  createList({
                    listConfig: {
                      listName: newListName,
                      themeColor,
                    },
                    aid: userDoc.id,
                    uid: user.uid,
                    currentLists,
                  });
                  setNewListName("");
                  setThemeColor("light");
                  setShowCreateList(false);
                }}
              >
                Create list
              </Button>
            )}
            {editList && (
              <Button
                onClick={(e) =>
                  updateList(
                    {
                      listConfig: {
                        listName: newListName,
                        themeColor,
                        description,
                        _docId: listConfig._docId,
                      },
                      aid: userDoc.id,
                      uid: user.uid,
                    },
                    (error, update) => {
                      if (error) {
                        console.log("Error updating list: ", error);
                      }
                      if (update) {
                        console.log("List updated: ", update);
                      }
                      setShowCreateList(false);
                    }
                  )
                }
              >
                Update list
              </Button>
            )}
          </Modal.Footer>
        </Modal>
      )}
    </React.Fragment>
  );
};

const addToCrm = async (params, callback) => {
  let { list, data, queryUrl, uid, aid, bulkData, source, listStatus } = params;
  list = list ? list : ["leads"];
  queryUrl = queryUrl ? queryUrl : null;

  function formatCrmDoc(d, id) {
    return {
      data: d,
      id,
      list,
      listStatus: listStatus ? listStatus : { leads: "new" },
      queryUrl,
      source,
      uid,
      aid,
      archived: false,
      _created: Math.round(new Date().getTime() / 1000),
    };
  }

  if (!aid) {
    return console.log("An account id is required to add data to crm.");
  }
  try {
    const dataRef = db.collection("data");
    let id;
    if (bulkData) {
      let batch = db.batch();
      let batchCount = 0;
      let docIds = [];
      for (let [i, data] of bulkData.entries()) {
        id = makeid(15);
        batch.set(dataRef.doc(id), formatCrmDoc(data,id));
        batchCount += 1;
        docIds.push(id);
        if ((i + 1) % 500 === 0) {
          await batch.commit().then((result) => {
            console.log("Wrote data through record: ", i);
            //batch = db.batch();
            //batchCount = 0;
          });
          batchCount = 0;
          batch = db.batch();
        }
      }

      if (batchCount > 0) {
        await batch.commit().then((result) => {
          console.log("Final write count: ", batchCount);
        });
      }
      console.log("Finished writing bulkData to Firestore.");
      console.log("Total documents written to crm: ", docIds.length);

      let batchSummary = {
        docIds,
        list,
        queryUrl,
        source,
        uid,
        aid,
      };
      if (callback) {
        callback(null, batchSummary);
      }
      return batchSummary;
    }
    id = makeid(15);
    let docRef = await dataRef.doc(id).set(formatCrmDoc(data,id));

    let writeSummary = {
      docId: id,
      list,
      queryUrl,
      source,
      uid,
      aid,
    };
    console.log("writeSummary: ", writeSummary);
    if (callback) {
      callback(null, writeSummary);
    }
    return writeSummary;
  } catch (err) {
    console.log("Error adding record to CRM: ", err);
    if (callback) {
      callback(err);
    }
    //throw new Error(err)
  }
};

const appendToCrm = async (params, callback) => {
  let {
    dataId,
    uid,
    aid,
    apiKey,
    appendType,
    dataDict,
    appendFields,
    currentDoc,
  } = params;

  let matchCount = 0;
  let writes = [];
  let formattedMatches, update;
  // Fetch the record
  let dataRef = db.collection("data");

  if (!currentDoc) {
    await dataRef
      .doc(dataId)
      .get()
      .then((doc) => {
        currentDoc = doc.data();
        currentDoc._docId = doc.id;
        currentDoc._docRef = doc.ref;
      });
  } else {
    currentDoc._docRef = dataRef.doc(dataId);
    currentDoc._docId = dataId;
  }

  console.log("currentDoc: ", currentDoc);

  // Search elastic for matching records
  let url = `${process.env.REACT_APP_api_url}${dataDict.apiUrl}`;
  let filters = [];
  Object.keys(appendType).map((type) => {
    console.log("type: ", type, appendType[type]);
    if (appendType[type]) {
      console.log("Using key: ", type);
      if (currentDoc.data[type]) {
        let values = [];
        if (["PHONE", "EMAIL"].includes(type)) {
          currentDoc.data[type].map((m) => {
            values.push(`'${m.data}'`);
          });
          filters.push(`(${type} in (${values.join(", ")}))`);
        }
        if (["ADDR"].includes(type)) {
          currentDoc.data[type].map((m) => {
            let ad = m.ADDR ? `(ADDR = '${m.ADDR}') and` : "";
            let apt = m.APT ? ` (APT = '${m.APT}') and` : "";
            let zip = m.ZIP ? ` (ZIP = '${m.ZIP}')` : "";
            if (ad && zip) {
              filters.push(`(${ad}${apt}${zip})`);
            }
          });
        }
      }
    }
  });
  if (!filters.length) {
    console.log("This record doesn't contain a key: ", dataId);
  } else {
    let fields = [];
    let formatDict = {};
    Object.keys(appendFields).map((f) => {
      if (appendFields[f]) {
        // Create the formatting dictionary
        let appendCol = dataDict.appendCols.find((col) => col.value === f);
        if (appendCol.format) {
          formatDict[f] = {};
          dataDict.cols[f].items.map((option) => {
            formatDict[f][option.value] = option.name;
          });
        }
        // Fetch the column when searching for data
        if (appendCol.fields) {
          return appendCol.fields.map((fo) => fields.push(fo));
        }
        return fields.push(f);
      }
      return;
    });
    let postBody = {
      dictId: dataDict.dictId,
      index: dataDict.index,
      fields: fields.join(","),
      filter: filters.join(" or "),
      url,
    };
    console.log("postBody: ", postBody);
    const init = {
      method: "POST",
      headers: {
        authorization: `Bearer ${apiKey}`,
        "Content-Type": "application/json",
      },
      "Transfer-Encoding": "chunked",
      cache: "default",
      accept: "application/json",
      body: JSON.stringify(postBody),
    };
    let response = await fetch(url, init);
    let matched = await response.json();
    console.log("matched: ", matched);

    //Make the matches human readable
    function formatMatches(array_of_arrays, header_array, formatDict) {
      console.log(
        "array_of_arrays: ",
        array_of_arrays,
        "header_array: ",
        header_array,
        "formatDict: ",
        formatDict
      );
      // turn arrar_of_arrays into an object with keys
      let array_of_objects = [];
      array_of_arrays.map((a) => {
        let object = {};
        header_array.map((h, i) => {
          // IF it's an address, treat it special here by creating an object shaped like:
          // {
          //   ADDR: "string",
          //   APT: "string",
          //   CITY: "string",
          //   ST: "string",
          //   ZIP: "string",
          //   Z4: "string"
          // }
          if (
            ["ADDR", "APT", "CITY", "ST", "ZIP", "Z4", "LAT", "LONG"].includes(
              h
            )
          ) {
            if (typeof object["ADDR"] === "undefined") {
              object["ADDR"] = {};
            }
            object["ADDR"][h] = a[i];
            return (object["ADDR"] = formatContactMethod(
              "ADDR",
              null,
              object["ADDR"]
            ));
          }
          if (["PHONE", "EMAIL"].includes(h)) {
            if (a[i]) {
              // only create the method if it's not an empty string
              return (object[h] = formatContactMethod(h, a[i]));
            }
            return;
          }
          return (object[h] = a[i]);
        });
        array_of_objects.push(object);
      });
      // translates fields that need to be formatted for human readability
      array_of_objects.map((o) => {
        Object.keys(formatDict).map((k) => {
          o[k] = formatDict[k][o[k]];
        });
      });
      return array_of_objects;
    }
    if (matched.resource.length) {
      matchCount = matched.resource.length;
      formattedMatches = formatMatches(matched.resource, fields, formatDict);
      console.log("formattedMatches: ", formattedMatches);
      // Update each record with a match
      let newData = { ...currentDoc.data };
      // Iterate through each match
      formattedMatches.map((m, i) => {
        // Iterate through each key of the match
        Object.keys(m).map((k) => {
          // if the key already exists in the CRM document
          if (newData[k]) {
            // Check if it's the same information
            let alreadyExists = false;
            if (Array.isArray(newData[k])) {
              // if crm document field is an array check if the value is already in the array
              // if it's a PHONE, EMAIL, ADDR we'll need to check the data field of each one.
              if (["PHONE", "EMAIL", "ADDR"].includes(k)) {
                newData[k].map((e) => {
                  if (e.data === m[k].data) {
                    alreadyExists = true;
                  }
                });
              }
            } else {
              // It's not an array, so just check if the values match
              if (`${newData[k]}` === `${m[k]}`) {
                alreadyExists = true;
              }
            }
            if (!alreadyExists) {
              writes.push({ data: m[k], column: k });
              // If it's an array, we'll add it to the array
              if (Array.isArray(newData[k])) {
                return newData[k].push(m[k]);
              } else {
                // We'll just add something to the end of the key
                console.log(
                  `The key already exists with different data. Adding similar key ${k}_1 with value ${m[k]}.`
                );
                newData[`${k}_${i}`] = m[k];
              }
            } else {
              // it already exists in the CRM, so do nothing with it.
              console.log(
                `${m[k]} already exists in the CRM under field ${k}.`
              );
            }
          } else {
            // the Key doesn't already exists, so let's just add it to the crm document
            console.log(
              `${m[k]} doesn't exist in the CRM under field ${k}. Adding it.`
            );
            if (["PHONE", "EMAIL", "ADDR"].includes(k)) {
              newData[k] = [m[k]];
            } else {
              newData[k] = m[k];
            }
          }
        });
      });

      console.log("update: ", update);
      currentDoc._docRef.update({
        data: newData,
        _appended: Math.round(new Date().getTime() / 1000),
        appendUrl: postBody,
        source: firebase.firestore.FieldValue.arrayUnion({
          type: "listshack append",
          dictId: postBody.dictId,
        }),
      });
    }
  }

  let writeSummary = {
    docId: currentDoc._docId,
    matchCount,
    formattedMatches: formattedMatches ? formattedMatches : null,
    writes,
    writeCount: writes.length,
    update: update ? update : null,
    uid,
    aid,
  };
  if (callback) {
    callback(null, writeSummary);
  }
  return writeSummary;
};

const exportContactMethod = (method, object, options) => {
  let { iteration } = options;
  let formattedMethod = {};
  Object.keys(object).map((k) => {
    let newk = k;
    // handles different types of data fields inside contact methods
    if (k === "method") {
      return;
    }
    if (k === "data") {
      if (["PHONE", "EMAIL"].includes(method)) {
        newk = method;
      }
      if (["ADDR"].includes(method)) {
        newk = "FULL_ADDR";
      }
    } else {
      newk = `${method}_${k}`;
    }
    // handles the scenario where export multiple of the same contact method type
    if (iteration) {
      newk = `${newk}_${iteration}`;
    }
    if (["string", "number", "boolean"].includes(typeof object[k])) {
      formattedMethod[newk] = object[k];
    }
    // handles confirmed objects inside contact methods
    if (typeof k === "object") {
      Object.keys(k).map((kk) => {
        if (kk === "method") {
          return;
        }
        newk = `${newk}_${kk}`;
        formattedMethod[newk] = k[kk];
      });
    }
  });
  //console.log("formattedMethod: ", formattedMethod);
  return formattedMethod;
};

const formatExport = ({ fields, docs }) => {
  if (!fields) {
    return console.log("The ListConfig document must include a fields key.");
  }
  if (!docs) {
    return console.log("The list must contain docs in order to export.");
  }
  let fdocs = [];
  let ffields = [...fields.filter((f) => !reservedFieldNames.includes(f))];
  docs.map((d, i) => {
    console.log("Formatting document: ", i);
    // Format the ADDR, PHONE, EMAIL into the proper object shape
    let fDoc = {};
    fields.map((f) => {
      let data = d.data[f];
      //console.log("data: ", data);
      let display;
      if (
        ["string", "number", "boolean", "null", "undefined"].includes(
          typeof data
        ) &&
        !reservedFieldNames.includes(f)
      ) {
        fDoc[f] = data;
      }
      if (["object"].includes(typeof data)) {
        if (["PHONE", "EMAIL", "ADDR"].includes(f)) {
          return data.map((cm, i) => {
            let desCm = exportContactMethod(f, cm, { iteration: i });
            return (fDoc = { ...fDoc, ...desCm });
          });
        }
        fDoc[f] = JSON.stringify(data, null, 3);
      }
    });
    let cells = [];
    Object.keys(fDoc)
      .sort()
      .map((k) => {
        if (!ffields.includes(k)) {
          ffields.push(k);
        }
      });
    fdocs.push(fDoc);
    //console.log("fDoc: ", fDoc);
  });
  return {
    fields: ffields,
    docs: fdocs,
  };
};

const createCrmExportUrl = async (params, options) => {
  //{
  //  fileName: "string",
  //  listName: "string",
  //  timestamp: "datetime",
  //  exportUrl: {
  //    aid: "string",
  //    uid: "string",
  //    fields: "array", //unformatted from the listConfig
  //    docIds: "array", // used when only exporting a subset of records
  //    firestoreRef: "string" // used to create the exact same reference as currently shown to the user
  //  },
  //  archived: "boolean",
  //  leadsRemaining: "number",
  //  leadsMultiplier: "number",
  //}
  const { listName, fields, user, userDoc, firestoreRef } = params; // these are all Required
  const { docIds, leadsMultiplier, archived } = options;
  let exportRef = db.collection("crm").doc(userDoc.id).collection("exports");
  let exportDoc = {
    fileName: santizeFileName(listName),
    listName,
    timestamp: new Date(),
    exportUrl: {
      aid: userDoc.id,
      uid: user.uid,
      fields,
      docIds,
      firestoreRef,
    },
    archived: archived ? archived : false,
    leadsRemaining: userDoc.leadsRemaining,
    leadsMultiplier: leadsMultiplier ? leadsMultiplier : 1,
  };
  let exportDocRef = await exportRef.add(exportDoc);
  exportDoc._docId = exportDocRef.id;
  return exportDoc;
};

const exportFromCrm = async (exportParams, callback) => {
  // Returns an object that includes the exportUrl plus the results of the export
  //{
  //  ===added===
  //  exportCount: "number",
  //  csvFile: "string",
  //  success: "boolean",
  //  exportProgress: "number",
  //  offset: "string", // last document id downloaded from the exportUrl
  //  ===added===
  //  fileName: "string",
  //  listName: "string",
  //  _docId: "string", // document id of the export document in firestore
  //  timestamp: "datetime",
  //  exportUrl: {
  //    aid: "string",
  //    uid: "string",
  //    fields: "array", //unformatted from the listConfig
  //    docIds: "array", // used when only exporting a subset of records
  //    firestoreRef: "string" // used to create the exact same reference as currently shown to the user
  //  },
  //  archived: "boolean",
  //  leadsRemaining: "number",
  //  leadsMultiplier: "number",
  //}
  console.log("Started exportFromCrm.");
  // Fetch documents from firestore
  let { exportUrl, listName, timestamp, fileName, _docId } = exportParams;
  let { docIds, firestoreRef, fields, aid } = exportUrl;
  let { colPath, where, orderBy } = firestoreRef;
  let docs = [];
  let colRef = db.collection(colPath);
  // Fetch all documents
  if (!docIds) {
    if (where) {
      for (let query of where) {
        colRef = colRef.where(query.field, query.operand, query.term);
      }
    }
    if (orderBy) {
      for (let orderItem of orderBy) {
        if (orderItem.order) {
          colRef = colRef.orderBy(orderItem.field, orderItem.order);
        } else {
          colRef = colRef.orderBy(orderItem.field);
        }
      }
    }
    await colRef.get().then((query) => {
      query.forEach((doc) => {
        let docData = doc.data();
        docs.push(docData);
      });
    });
  } else {
    // Fetch the selected documents
    let refs = docIds.map((id) => db.doc(`${colRef}/${id}`));
    await db.getAll(...refs).then((records) => {
      records.map((doc) => {
        let docData = doc.data();
        docs.push(docData);
      });
    });
  }
  console.log("docs: ", docs);

  // Ignoring limit for now since we'll handle limited exports with the docIds field.
  //if (limit) {
  //  colRef = colRef.limit(limit);
  //}

  // Format documents for export
  let formattedExport = await formatExport({ fields, docs });
  console.log("formattedExport: ", formattedExport);
  // Unparse objects to csv format
  let csv = unparseToCsv({
    array: formattedExport.docs,
    columns: formattedExport.fields,
  });
  console.log("csv: ", csv);
  // Create a file object
  let file = new Blob([csv], { type: "text/csv" });
  const finishExportDoc = (updateDoc) => {
    let exportDoc = { ...exportParams, ...updateDoc };
    exportDoc.exportCount = docs.length;
    exportDoc.offset = docIds ? docIds[docIds.length - 1] : null;

    return exportDoc;
  };
  // Store the file
  return new Promise(async (resolve, reject) => {
    let exportDocRef = db
      .collection("crm")
      .doc(aid)
      .collection("exports")
      .doc(_docId);
    try {
      let fileUpload = await storeMedia(
        `exports/${aid}/ListCrm_${fileName}`,
        file,
        // progressCallBack
        (progress) => {
          console.log("progress: ", progress);
        },
        // downloadCallBack
        async (downloadUrl) => {
          console.log("dowloadUrl: ", downloadUrl);
          // Update the export document
          let exportDoc = finishExportDoc({
            csvFile: downloadUrl,
            success: true,
            exportProgress: 100,
          });
          await exportDocRef.update(exportDoc);
          // Return the update exportUrl object
          if (callback) {
            callback(null, exportDoc);
          }
          resolve(exportDoc);
        },
        // metadataCallBack
        (metadata) => {
          console.log("metadata: ", metadata);
        }
      );
    } catch (err) {
      console.log("Error storing file: ", err);
      let failedDoc = finishExportDoc({
        success: false,
        error: err.message,
      });
      exportDocRef.update(failedDoc);
      if (callback) {
        callback(failedDoc);
      }
      reject(failedDoc);
    }
  });
};

const Phone = (props) => {
  let { phone, dups } = props;
  if (!phone) {
    return null;
  }
  if (["string", "number"].includes(typeof phone)) {
    phone = formatContactMethod("PHONE", phone);
  }
  let phones = [];
  phone.map((p, i) => {
    let isDupe = dups.includes(p.data);
    phones.push(
      <div
        key={`p_${i}`}
        className={`d-inline-block ${isDupe ? "bg-warning" : ""}`}
      >
        {formatPhoneNumber(p.data)}
      </div>
    );
  });
  return phones;
};

const Email = (props) => {
  let { email } = props;
  if (!email) {
    return null;
  }
  if (["string", "number"].includes(typeof email)) {
    email = formatContactMethod("EMAIL", email);
  }
  let emails = [];
  if (Array.isArray(email)) {
    email.map((e, i) => {
      emails.push(<div key={`e_${i}`}>{e.data}</div>);
    });
  } else {
    console.log("emails data is formatted wrong for: ", email);
  }

  return emails;
};

const Addr = (props) => {
  let { addr } = props;
  //console.log("addr: ", addr);
  if (!addr) {
    return null;
  }
  if (["string", "number"].includes(typeof addr)) {
    addr = formatContactMethod("ADDR", addr);
  }
  let addrs = [];
  addr.map((a, i) => {
    //console.log("a: ", a.data);
    return addrs.push(<div key={`addr_${i}`}>{`${a.data}`}</div>);
  });
  //console.log("addrs: ", addrs);
  return addrs;
};

const isDuplicate = (array, truthArray) => {
  //console.log("array: ", array, "truthArray: ", truthArray);
  let dups = [];
  let uniques = [];
  if (!array) {
    return { dups, uniques };
  }
  array.map((a) => {
    let check = truthArray.includes(a.data);
    if (check) {
      dups.push(a.data);
    } else {
      uniques.push(a.data);
    }
  });
  //console.log("dups: ", dups);
  return { dups, uniques };
};

const TBLS = (props) => {
  const {
    listField,
    list,
    fetchTabs,
    child,
    table,
    header,
    emptyChild,
    collection,
    onSelectTab
  } = props;
  const otherTabObject = {
    fieldValue: (v, tabs) => {
      console.log("v: ", v, "tabs: ", tabs);
      let usedValues = ["undefined"];
      tabs.map((t) => {
        //console.log("Array.isArray: ", Array.isArray(t.fieldValue));
        if (Array.isArray(t.fieldValue)) {
          t.fieldValue.map((uv) => {
            usedValues.push(uv);
          });
        }
      });
      let test = !usedValues.includes(v);
      //console.log(`${v} test: `, test);
      return test;
    },
    tabTitle: "Other",
  };

  const newTabObject = {
    fieldValue: (v, tabs) => {
      //console.log("newTabObject fieldValue v: ", v, "tabs: ", tabs, "typeof v: ", typeof v);
      if (v === "undefined") {
        return true;
      }
      if (v === "new") {
        return true;
      }
      return false;
    },
    tabTitle: "New",
  };

  const allTabObject = {
    fieldValue: (v, tabs) => {
      //console.log("allTabObject fieldValue v: ", v, "tabs: ", tabs, "typeof v: ", typeof v);
      if (v !== "dups") {
        return true;
      }
    },
    tabTitle: "All",
  };

  const dupsTabObject = {
    fieldValue: (v, tabs) => {
      if (v === "dups") {
        return true;
      }
      return false;
    },
    tabTitle: "Duplicates",
  };

  let setKey = (tab, callback) => {
    let tabTitle;
    if (tab.tabTitle === "New") {
      tabTitle = "tab_new";
    }
    if (tab.tabTitle === "Other") {
      tabTitle = "tab_other";
    }
    if (tab.tabTitle === "All") {
      tabTitle = "tab_all";
    }
    if (tab.tabTitle === "Duplicates") {
      tabTitle = "dups";
    }
    tabTitle = tab.tabTitle;
    if (callback) {
      callback(null, tabTitle);
    }
    return tabTitle;
  };

  const [tabs, setTabs] = useState(props.tabs ? props.tabs : []);
  const [showOtherTab, setShowOtherTab] = useState(
    props.showOther ? props.showOther : false
  );
  const [showNewTab, setShowNewTab] = useState(
    props.showNewTab ? props.showNewTab : false
  );
  const [listConfig, setListConfig] = useState(
    props.listConfig ? props.listConfig : null
  );
  const [showAllTab, setShowAllTab] = useState(
    props.showAllTab ? props.showAllTab : false
  );
  const [showDupTab, setShowDupTab] = useState(
    props.showDupTab ? props.showDupTab : false
  );
  const [selectedTab, setSelectedTab] = useState(
    tabs[0] ? setKey(tabs[0]) : ""
  );
  console.log("selectedTab: ", selectedTab);

  const setConfigDoc = (configDoc) => {
    console.log("configDoc: ", configDoc);
    let savedTabs = [];
    if (configDoc.showAllTab) {
      savedTabs.push(allTabObject);
    }
    if (configDoc.showNewTab) {
      savedTabs.push(newTabObject);
    }
    savedTabs = [...savedTabs, ...configDoc.tabs];
    if (configDoc.showOtherTab) {
      savedTabs.push(otherTabObject);
    }
    if (configDoc.showDupTab) {
      savedTabs.push(dupsTabObject);
    }
    setSelectedTab(setKey(savedTabs[0]));
    setTabs(savedTabs);
    setShowOtherTab(configDoc.showOtherTab);
    setShowNewTab(configDoc.showNewTab);
    setShowAllTab(configDoc.showAllTab);
    setShowDupTab(configDoc.showDupTab);
    setListConfig(configDoc);
  };

  useEffect(() => {
    if (props.listConfig) {
      return setConfigDoc(props.listConfig);
    }
    if (fetchTabs) {
      fetchTabs({userDoc: props.userDoc, listid: props.list},(error, configDoc) => {
        console.log("Running fetchTabs callback.");
        if (error) {
          console.log("Error fetching tabs: ", error);
        }
        if (configDoc) {
          setConfigDoc(configDoc);
        }
      });
    }
  }, [props.listConfig]);
  const docs = props.tabDocs;
  console.log("TBLS docs: ", docs, "tabs: ", tabs);

  let tabsObject = {
    dups: [],
    undefined: [],
  };

  let phones = [];
  let emails = [];
  let addresses = [];
  let dupDocIds = [];

  docs.map((d, i) => {
    // listStatus should be shaped like:
    //{
    //  [list]: "status_is_single_string"
    //}
    let dups = {
      PHONE: [],
      EMAIL: [],
      ADDR: [],
    };
    if (d[listField]) {
      //console.log("d[listField]: ", d[listField]);
    } else {
      //return
    }
    if (typeof d[listField] === "undefined") {
      return tabsObject["undefined"].push(
        child({ data: d, index: i, listConfig, dups })
      );
    }
    //console.log("d: ", d, "listField", listField, "list", list);
    if (typeof tabsObject[d[listField][list]] === "undefined") {
      tabsObject[d[listField][list]] = [];
    }
    if (showDupTab) {
      let hasDuplicate = false;
      let { PHONE, EMAIL, ADDR } = d.data;
      let dupPhone = isDuplicate(PHONE, phones);
      if (dupPhone.dups.length) {
        hasDuplicate = true;
        phones = [...phones, ...dupPhone.dups];
        dups.PHONE = dupPhone.dups;
        dupDocIds.push(d._docId);
      } else {
        phones = [...phones, ...dupPhone.uniques];
      }
      let dupEmail = isDuplicate(EMAIL, emails);
      if (dupEmail.dups.length) {
        hasDuplicate = true;
        emails = [...emails, ...dupEmail.dups, ...dupEmail.uniques];
        dups.EMAIL = dupEmail.dups;
        dupDocIds.push(d._docId);
      } else {
        emails = [...emails, ...dupEmail.uniques];
      }
      //Add addresses check here
      if (hasDuplicate) {
        tabsObject.dups.push(child({ data: d, index: i, listConfig, dups }));
      }
    }
    return tabsObject[d[listField][list]].push(
      child({ data: d, index: i, listConfig, dups })
    );
  });
  console.log("tabsObject: ", tabsObject);
  let tabComponents = [];

  tabs.map((t, i) => {
    if (typeof t === "function") {
      return tabComponents.push(t(tabs));
    }
    if (t.tabTitle === "All" && !showAllTab) {
      //console.log("This is the all tab: ", t);
      return false;
    }

    if (t.tabTitle === "New" && !showNewTab) {
      //console.log("This is the new tab: ", t);
      return false;
    }

    if (t.tabTitle === "Other" && !showOtherTab) {
      //console.log("This is the other tab: ", t);
      return false;
    }

    return tabComponents.push(
      <Tab
        key={`tab_${t.fieldValue}`}
        eventKey={setKey(t)}
        title={t.tabTitle}
      >
        {table && (
          <Table
            striped
            hover
            responsive
            className={props.className ? props.className : ""}
            style={props.style ? props.style : null}
          >
            <thead>{header(docs, t, dupDocIds)}</thead>
            <tbody>
              {typeof tabsObject[t.fieldValue] !== "undefined" &&
                !Array.isArray(t.fieldValue) &&
                tabsObject[t.fieldValue]}
              {typeof t.fieldValue === "function" &&
                Object.keys(tabsObject).map((key) => {
                  //console.log("key: ", key);
                  if (t.fieldValue(key, tabs)) {
                    return tabsObject[key];
                  }
                })}
              {Array.isArray(t.fieldValue) &&
                t.fieldValue.map((v) => {
                  return tabsObject[v];
                })}
              {typeof tabsObject[t.fieldValue] === "undefined" &&
                typeof t.fieldValue !== "function" &&
                !Array.isArray(t.fieldValue) &&
                emptyChild()}
            </tbody>
          </Table>
        )}
        {!table && (
          <div
            className={props.className ? props.className : ""}
            style={props.style ? props.style : null}
          >
            {typeof tabsObject[t.fieldValue] !== "undefined" &&
              !Array.isArray(t.fieldValue) &&
              tabsObject[t.fieldValue]}
            {typeof t.fieldValue === "function" &&
              Object.keys(tabsObject).map((key) => {
                console.log("key: ", key);
                if (t.fieldValue(key, tabs)) {
                  return tabsObject[key];
                }
              })}
            {Array.isArray(t.fieldValue) &&
              t.fieldValue.map((v) => {
                return tabsObject[v];
              })}
            {typeof tabsObject[t.fieldValue] === "undefined" &&
              typeof t.fieldValue !== "function" &&
              !Array.isArray(t.fieldValue) &&
              emptyChild()}
          </div>
        )}
      </Tab>
    );
  });

  return (
    <Tabs
      defaultActiveKey={"tab_all"}
      activeKey={selectedTab}
      onSelect={(k) => {
        setSelectedTab(k);
        if (onSelectTab) {
          onSelectTab(k)
        }
      }}
      id={`tabbedLists_${collection}`}
    >
      {tabComponents}
      <Tab
        title={
          <AddTabModal {...props}
            className="p-0"
            variant="link"
            onCreate={(error, newTab) => {
              console.log("onCreate");
              if (error) {
                console.log("Error adding new tab: ", error);
              }
              if (newTab) {
                setSelectedTab( setKey(newTab) );
              }
            }}
          >
            <Plus size={16} />
          </AddTabModal>
        }
        key="add_tab"
        eventKey="add_tab"
      >
        <p>Select another tab to view records</p>
      </Tab>
    </Tabs>
  );
};

const TabbedLists = (props) => {
  console.log("rendered TabbedLists props: ", props);
  const { colRef, collection, permissions, title, callback, managed } = props;
  const [where, setWhere] = useState(props.where);
  const [orderBy, setOrderBy] = useState(props.orderBy);
  const [limit, setLimit] = useState(props.limit);

  if (managed) {
    return (
      <Collection
        title={title}
        colRef={colRef ? colRef : db}
        collection={collection ? collection : null}
        permissions={permissions ? permissions : []}
        where={where ? where : null}
        orderBy={orderBy ? orderBy : null}
        limit={limit ? limit : null}
        callback={callback ? callback : null}
      >
        <CollectionContext.Consumer>
          {(context) => {
            const { docs } = context;
            console.log("context: ", context);
            return (
              <React.Fragment>
                <TBLS
                  {...props}
                  setWhere={setWhere}
                  setOrderBy={setOrderBy}
                  setLimit={setLimit}
                  tabDocs={docs}
                />
              </React.Fragment>
            );
          }}
        </CollectionContext.Consumer>
      </Collection>
    );
  } else {
    return <TBLS {...props} />;
  }
};

const ExportRecordsButton = (props) => {
  const { selectedData } = props;
  const [showModal, setShowModal] = useState(false);
  return (
    <React.Fragment>
      <Button
        variant={props.variant ? props.variant : ""}
        className={props.className ? props.className : ""}
        style={props.style ? props.style : null}
        onClick={() => {
          setShowModal(true);
        }}
      >
        {props.children}
      </Button>
      {showModal && (
        <Modal show={showModal} onHide={() => setShowModal(false)} size="xl">
          <Modal.Body>
            <Modal.Title>Export Records from CRM</Modal.Title>
            <ExportRecordsFromCrm {...props} />
          </Modal.Body>
          <Modal.Footer className="d-flex flex-row justify-content-between">
            <Button
              size="sm"
              onClick={() => setShowModal(false)}
              variant="light"
            >
              Nevermind
            </Button>
            <div>&nbsp;</div>
          </Modal.Footer>
        </Modal>
      )}
    </React.Fragment>
  );
};

const ExportRecordsFromCrm = (props) => {
  console.log("ExportRecordsFromCrm props: ", props);
  const {
    selectedData,
    listConfig,
    docs,
    userDoc,
    user,
    handleAlerts,
    apiKey,
    firestoreRef,
  } = props;
  //preview export
  const [previewDocs, setPreviewDocs] = useState([]);
  const [previewFields, setPreviewFields] = useState([]);
  const [isExporting, setIsExporting] = useState(false);
  const [exportDoc, setExportDoc] = useState("");
  const [isBigExport, setIsBigExport] = useState(false);
  useEffect(() => {
    //preview export
    if (docs && listConfig) {
      let formattedForExport = formatExport({
        docs: docs.slice(0, 3),
        fields: listConfig.fields,
      });
      setPreviewFields(formattedForExport.fields);
      setPreviewDocs(formattedForExport.docs);
    }
  }, [docs, listConfig]);

  return (
    <React.Fragment>
      {!exportDoc && !isExporting && (
        <React.Fragment>
          <p className="lead text-muted">
            Exporting{" "}
            {selectedData.length > 0 ? selectedData.length : <b>all</b>} records
            from CRM.
          </p>
          <h5>Select which columns you would like to export</h5>
          <Table responsive>
            <thead>
              <tr>
                {previewFields.map((f, i) => {
                  return <th key={`pf_${i}`}>{f}</th>;
                })}
              </tr>
            </thead>
            <tbody>
              {previewDocs.map((fDoc, i) => {
                return (
                  <tr key={`epr_${i}`}>
                    {previewFields.map((k) => {
                      return <td key={`epd_${i}_${k}`}>{fDoc[k]}</td>;
                    })}
                  </tr>
                );
              })}
            </tbody>
          </Table>
          <Button
            className="pull-right"
            onClick={async (e) => {
              console.log("Began export.");
              setIsExporting(true);
              e.preventDefault();
              let exportUrl = await createCrmExportUrl(
                {
                  listName: listConfig.listName,
                  fields: listConfig.fields,
                  firestoreRef,
                  user,
                  userDoc,
                },
                {
                  docIds: selectedData.length > 0 ? selectedData : null,
                }
              );
              console.log("exportUrl: ", exportUrl);
              let exportDoc = await exportFromCrm(
                exportUrl,
                (errorDoc, exportDoc) => {
                  if (errorDoc) {
                    setExportDoc(errorDoc);
                    setIsExporting(false);
                    handleAlerts(
                      `Uh oh, something went wrong with your export: ${errorDoc.error}`,
                      "warning"
                    );
                  }
                  if (exportDoc) {
                    setExportDoc(exportDoc);
                    setIsExporting(false);
                    {
                      /*handleAlerts(
                    <React.Fragment>
                      Your export from list {exportDoc.listName} is complete. <a className="btn btn-outline-success" href={exportDoc.csvFile} download={`${exportDoc.fileName.csv}`}>Download Export</a>
                    </React.Fragment>,
                    "success",
                    "Export finished!"
                  );*/
                    }
                  }
                }
              );
              await console.log("exportDoc: ", exportDoc);
            }}
          >
            Begin Export
          </Button>
        </React.Fragment>
      )}
      {isExporting && !exportDoc && (
        <Col xs={12} className="text-center">
          {!isBigExport && (
            <React.Fragment>
              <Spinner
                variant="primary"
                animation="grow"
                style={{ margin: "30px" }}
              />
              <h4>We're preparing your export. Hold tight!</h4>
            </React.Fragment>
          )}
          {isBigExport && (
            <React.Fragment>
              <h4>
                Wow, that's a lot of records! Give us a few minutes to package
                them together and we'll let you know when the file is ready for
                download.
              </h4>
            </React.Fragment>
          )}
        </Col>
      )}
      {exportDoc && !isExporting && (
        <Row>
          <Col xs={12} className="text-center">
            <h3>Your export is ready!</h3>
            <Table bordered hover className="text-start">
              <tbody>
                <tr>
                  <td>
                    <b>List name:</b>{" "}
                  </td>
                  <td>{exportDoc.listName}</td>
                </tr>
                <tr>
                  <td>
                    <b>Records exported:</b>{" "}
                  </td>
                  <td>{exportDoc.exportCount}</td>
                </tr>
                <tr>
                  <td>
                    <b>Export date:</b>{" "}
                  </td>
                  <td>{new Date().toLocaleString()}</td>
                </tr>
              </tbody>
            </Table>
            <Button
              variant="success"
              size="lg"
              style={{ marginTop: "30px" }}
              href={exportDoc.csvFile}
              download={`${exportDoc.fileName}.csv`}
            >
              Download CSV
            </Button>
            <Button
              variant="outline-success"
              className="ms-3"
              style={{ marginTop: "30px" }}
              onClick={() => {
                let emailRecipients = [userDoc.email];
                if (typeof userDoc.companyProfile !== "undefined") {
                  if (userDoc.email !== userDoc.companyProfile.email) {
                    emailRecipients.push(userDoc.companyProfile.email);
                  }
                }
                let msg = {
                  to: emailRecipients,
                  from: "help@listshack.support",
                  subject: `Download your export from ${process.env.REACT_APP_site_name}`,
                  text: `Download the export for the list <strong>${exportDoc.listName} from ${process.env.REACT_APP_site_name}</strong> by clicking the button below by or copying and pasting the link into your browser. ${exportDoc.csvFile}.`,
                  html: `<p>Download the export for the list ${
                    exportDoc.listName
                  } from ${
                    process.env.REACT_APP_site_name
                  } by clicking the button below or copying and pasting the link into your browser:</p><div style="text-align: center; display: block"><a style="padding: 8px 16px; margin: 30px; font-size: 20px; background-color: #17a2b8; color: #ffffff;border-radius:.3rem;text-decoration: none" href="${
                    exportDoc.csvFile
                  }">${exportDoc.listName}</a></div><p>${
                    exportDoc.csvFile
                  }</p><p><small>This export contains ${exportDoc.exportCount.toLocaleString()} records and you have ${userDoc.leadsRemaining.toLocaleString()} left during this billing cycle. If you have any issues with this file, such as a blank file, log into your account and report the file from the <a href="https://www.listshack.support/exports">Exports page</a>.</small></p>`,
                };

                sendEmail({
                  msg,
                  handleAlerts,
                  apiKey,
                });
                handleAlerts(
                  "",
                  `A link to download your export was sent to the following email addresses: ${emailRecipients.join(
                    " and "
                  )}`,
                  "success",
                  "Email sent"
                );
              }}
            >
              Email me this file
            </Button>
            <br />
            <small className="text-muted">
              Find this export later and report problems with the file from the{" "}
              <Link to="/exports">exports page</Link>.
            </small>
          </Col>
        </Row>
      )}
    </React.Fragment>
  );
};

const addCsvData = async (
  { results, fieldMapping, list, user, userDoc, listConfig, file, fileLink, assignedUid, listStatus },
  callback
) => {
  console.log("Csv successfully parsed: ", results);
  let mapped = [];
  let formatted = [];
  results.data.map((r) => {
    mapped.push(mapImport(r, fieldMapping));
  });
  mapped.map((r) => {
    formatted.push(formatImport(r));
  });

  console.log("formatted results: ", formatted);
  let impRef = await db
    .collection("crm")
    .doc(userDoc.id)
    .collection("imports")
    .add({
      archived: false,
      csvFile: fileLink,
      fileName: file.name,
      timestamp: new Date(),
      listName: listConfig.listName,
      list,
    });
  let importId = await impRef.id;

  addToCrm(
    {
      list: [list],
      listStatus: listStatus ? listStatus : { [list]: "new" },
      uid: assignedUid ? assignedUid : user.uid,
      aid: userDoc.id,
      bulkData: formatted,
      source: [
        {
          type: "csv upload",
          importId,
        },
      ],
    },
    (error, writeSummary) => {
      // let the user know that something happened
      if (error) {
        console.log("Something went adding to CRM: ", error);
        if (callback) {
          callback(error);
        }
      }
      if (writeSummary) {
        console.log("Documents added to CRM: ", writeSummary);
        //Add header to the fields in the list configuration
        listConfig._docRef.update({
          fields: firebase.firestore.FieldValue.arrayUnion(
            ...results.meta.fields
          ),
        });
        if (callback) {
          callback(null, writeSummary);
        }
      }
    }
  );
};

const formatName = (mappedDoc) => {
  // columns could be FN, LN, MI, TITLE, CO_NAME
  //{
  //  CO_NAME: "Acme Inc",
  //  FN: "John",
  //  MI: "A",
  //  LN: "Doe",
  //  TITLE: "CEO",
  //  FULL_NAME: "John A Doe"
  //}
  let {CO_NAME, FN, LN, MI, TITLE, FULL_NAME } = mappedDoc;
  if (CO_NAME){
    return CO_NAME
  }
  if (FULL_NAME) {
    return `${FULL_NAME}${TITLE ? `, ${TITLE}` : ""}`
  }
  return `${FN ? FN : ""}${MI ? ` ${MI}` : ""}${LN ? ` ${LN}` : ""}${TITLE ? `, ${TITLE}` : ""}`.trim()
}

const formatContactMethod = (method, data, options) => {
  if (!method) {
    return console.log(
      "Please include a method of phone, email, or address when configuring a contact method."
    );
  }
  let type, confirmed, confirmedAt;
  if (options) {
    type = options.type ? options.type : null;
    confirmed = options.confirmed ? options.confirmed : null;
    confirmedAt = options.confirmedAt ? options.confirmedAt : null;
  }

  let formatted = {
    method,
    data: data ? data : "",
    type: type ? type : null,
    confirmed: { value: confirmed ? confirmed : null, timestamp: confirmedAt ? confirmedAt : null },
  };

  if (method === "EMAIL") {
    if (options) {
      formatted.optIn = options.optIn ? options.optIn : "";
      formatted.optOut = options.optOut ? options.optOut : "";
    }
  }

  if (method === "PHONE") {
    if (options) {
      formatted.dncFlag = options.dncFlag ? options.dncFlag : "";
    }
  }

  if (method === "ADDR") {
    let newopts = {};
    if (options) {
      newopts = { ...options };
      let { ZIP, Z4, ST } = options;
      if (ZIP) {
        ZIP = formatZip(ZIP);
        newopts = { ...newopts, ...ZIP };
      }
      if (Z4) {
        Z4 = formatZ4(Z4);
        newopts = { ...newopts, ...Z4 };
      }
      if (ST) {
        let ns = formatState(ST);
        ST = ns ? ns : ST;
        newopts["ST"] = ST;
      }
    }
    let s1 = `${newopts.ADDR ? `${newopts.ADDR}, ` : ""}`;
    let s2 = `${newopts.APT ? `${newopts.APT}, ` : ""}`;
    let c = `${newopts.CITY ? `${newopts.CITY} ` : ""}`;
    let st = `${newopts.ST ? `${newopts.ST} ` : ""}`;
    let zip = `${newopts.ZIP ? newopts.ZIP : ""}`;
    let z4 = `${newopts.Z4 ? `-${newopts.Z4}` : ""}`;
    let addrData = `${s1}${s2}${c}${st}${zip}${z4}`;
    console.log("addrData: ", addrData);
    formatted.ADDR = newopts.ADDR ? newopts.ADDR : "";
    formatted.APT = newopts.APT ? newopts.APT : "";
    formatted.CITY = newopts.CITY ? newopts.CITY : "";
    formatted.ST = newopts.ST ? newopts.ST : "";
    formatted.ZIP = newopts.ZIP ? newopts.ZIP : "";
    formatted.Z4 = newopts.Z4 ? newopts.Z4 : "";
    formatted.data = addrData;
  }
  console.log(
    "formatContactMethod method: ",
    method,
    "data: ",
    data,
    "options: ",
    options,
    "formatted: ",
    formatted
  );
  return formatted;
};

const formatZip = (zipstring) => {
  let ziparray = zipstring.split("-");
  let ZIP = ziparray[0].toString();
  let Z4 = ziparray[1];
  if (ZIP.length > 5) {
    ZIP = ZIP.substring(0, 5);
  }
  if (Z4) {
    Z4 = formatZ4(Z4);
  }

  return { ZIP, ...Z4 };
};

const formatZ4 = (z4string) => {
  let Z4 = z4string.toString();
  if (Z4.length > 4) {
    Z4 = Z4.substring(0, 4);
  }
  return { Z4 };
};

const fieldTransformations = {
  PHONE: (arr) => {
    // input shaped like:
    //{
    //  data: "5555678890",
    //  dncFlag: "true",
    //  confirmed: "true",
    //  confirmedAt: "false",
    //}
    let farr = [];
    arr.map((obj) => {
      let transformed = formatContactMethod("PHONE", obj.data, obj);
      // Don't add contact methods that are empty strings
      if (transformed.data !== "") {
        farr.push(transformed);
      }
    });
    return farr;
  },
  ADDR: (arr) => {
    // input shaped like:
    //{
    //  ADDR: "123 main",
    //  APT: "UNIT 3",
    //  CITY: "San Mateo",
    //  ST: "CA",
    //  ZIP: "zipString",
    //  Z4: "z4string",
    //  COUNTRY: "Some country",
    //  LAT: "-41",
    //  LONG: "97",
    //  confirmed: "true",
    //  confirmedAt: "sometimestamp",
    //}
    let farr = [];
    arr.map((obj) => {
      let transformed = formatContactMethod("ADDR", null, obj);
      if (transformed.data !== "") {
        farr.push(transformed);
      }
    });
    return farr;
  },
  EMAIL: (arr) => {
    // input shaped like:
    //{
    //  data: "5555678890",
    //  confirmed: "true",
    //  optIn: "true",
    //  optOut: "true",
    //  confirmedAt: "false",
    //}
    let farr = [];
    arr.map((obj) => {
      let transformed = formatContactMethod("EMAIL", obj.data, obj);
      if (transformed.data !== "") {
        farr.push(transformed);
      }
    });
    return farr;
  },
};

const formatImport = (imp) => {
  let fimp = {
    NAME: formatName(imp)
  };
  Object.keys(imp).map((k) => {
    if (fieldTransformations[k]) {
      return (fimp[k] = fieldTransformations[k](imp[k]));
    }
    return (fimp[k] = imp[k]);
  });
  console.log("fimp: ", fimp);
  return fimp;
};

const mapImport = (data, mapping) => {
  let imp = {};
  let mappedColumns = Object.keys(mapping);
  console.log("mappedColumns: ", mappedColumns);
  Object.values(mapping).map((v, i) => {
    if (["string", "number"].includes(typeof v)) {
      return (imp[v] = data[mappedColumns[i]]);
    }
    if (Array.isArray(v)) {
      let field = v[0];
      let number = v[1];
      let nestedField = v[2];

      if (typeof imp[field] === "undefined") {
        imp[field] = [];
      }
      if (typeof imp[field][number] === "undefined") {
        imp[field][number] = {};
      }
      imp[field][number][nestedField] = data[mappedColumns[i]];
    }
  });
  console.log("imp: ", imp);
  return { ...data, ...imp };
};

const AddListStatus = props => {
  const {inline, modal, listConfig} = props;
  const [newStatus, setNewStatus] = useState("");
  const [showModal, setShowModal] = useState(false);

  if(inline) {
    return(
      <Form.Group
        className="d-flex flex-start"
      >
        {props.label &&
          <Form.Label>{props.label}</Form.Label>
        }
        <Form.Control
          size={props.size ? props.size : "sm"}
          style={{width: "200px"}}
          type="text"
          value={newStatus}
          onChange={(e) => {
            setNewStatus(e.target.value);
          }}
        />
        <Button
          variant={props.variant ? props.variant : "light"}
          size={props.size ? props.size : "sm"}
          onClick={() => {
            console.log("newStatus: ", newStatus);
            listConfig._docRef.update({
              listStatus: firebase.firestore.FieldValue.arrayUnion(
                newStatus
              ),
            });
            setNewStatus("");
          }}
        >
          <small>add</small>
        </Button>
      </Form.Group>
    )
  }
  if(modal) {
    return(
      <React.Fragment>
        <Button
          variant={props.variant ? props.variant : ""}
          size={props.size ? props.size : "sm"}
          onClick={() => setShowModal(true)}
        >
          Add New Status
        </Button>
        {showModal &&
          <Modal size="sm" show={showModal} onHide={() => setShowModal(false)}>
            <Modal.Header>
              <Modal.Title>Add List Status</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Form.Group
                className={props.className ? props.className : ""}
                style={props.style ? props.style : null}
              >
                {props.label &&
                  <Form.Label>{props.label}</Form.Label>
                }
                <Form.Control
                  size={props.size ? props.size : "sm"}
                  style={{width: "200px"}}
                  type="text"
                  value={newStatus}
                  onChange={(e) => {
                    setNewStatus(e.target.value);
                  }}
                />

              </Form.Group>
              <Button
                className="pull-right"
                variant={props.variant ? props.variant : "light"}
                size={props.size ? props.size : "sm"}
                onClick={() => {
                  console.log("newStatus: ", newStatus);
                  listConfig._docRef.update({
                    listStatus: firebase.firestore.FieldValue.arrayUnion(
                      newStatus
                    ),
                  });
                  setNewStatus("");
                  setShowModal(false);
                }}
              >
                <small>add</small>
              </Button>
            </Modal.Body>
          </Modal>
        }
      </React.Fragment>
    )
  }
  return null;
}

const CsvPreview = props => {
  const {preview, mapping} = props;
  const mappedColumns = Object.keys(mapping);
  return(
    <Table size="sm" bordered responsive>
      <thead>
        <tr>
          {preview.meta.fields.map( (th,i) => {
            return(
              <th key={`ph_${i}`}>
                <span className={mappedColumns.includes(th) ? "text-success" : ""}>{th}</span>
              </th>
            )
          })}
        </tr>
      </thead>
      <tbody>
        {preview.data.map( (tr,i) => {
          let tds = [];
          preview.meta.fields.map( (th,ind) => {
            tds.push(
              <td key={`tr_${tr}_${ind}`}>
                <span style={{fontSize: ".5rem"}}>{tr[th]}</span>
              </td>
            )
          })
          return(
            <tr key={`tr_${i}`}>
              {tds}
            </tr>
          )
        })}
      </tbody>
    </Table>
  )
}

const MapContactForm = (props) => {
  let {
    attribute,
    group,
    title,
    allowMultiple,
    counter,
    setCounter,
    reservedFields,
    fields,
    uploadMapping,
    setUploadMapping,
    size,
  } = props;
  if(typeof counter === "undefined") {
    counter = 1;
  }
  let items = [];
  let mappedColumns = Object.keys(uploadMapping);
  for (let count = 0; count < counter; count++) {
    items.push(
      <Form.Group
        key={`fg_${title}_${count}`}
        className={props.className ? props.className : ""}
        style={props.style ? props.style : null}
      >
        <div className="d-flex flex-row justify-content-between align-items-center">
          <Form.Label>
            {title}
            {count > 0 ? ` ${count + 1}` : ""}
          </Form.Label>
          {count > 0 ? (
            <Button
              onClick={() => {
                if (
                  window.confirm(
                    `Are you sure you'd like to remove this ${title.toLowerCase()} from your import?`
                  )
                ) {
                  setCounter(counter - 1);
                }
              }}
              size="sm"
              variant="link"
              className="text-danger"
            >
              <DashCircleFill size={24} />
            </Button>
          ) : (
            ""
          )}
        </div>

        {reservedFields.map((f) => {
          if (f[attribute] === group) {
            return (
              <Form.Control
                size={size}
                key={`control_${f.value}`}
                as="select"
                name={f.value}
                group={f.group}
                onChange={(e) => {
                  let selVal = e.target.value;
                  let v = JSON.parse(selVal);
                  let selField  = v.field;
                  let selGroup = v.group;
                  let nm = {...uploadMapping};
                  console.log("nm: ", nm, "selVal: ", selVal, "selField: ", selField, "selGroup: ", selGroup, "name: ", e.target.name);
                  if(mappedColumns.includes(selField)) {
                    delete nm[selField];
                    console.log("nm: ", nm);
                    return setUploadMapping(nm);
                  }
                  if (![""].includes(selVal)) {

                    setUploadMapping({
                      ...uploadMapping,
                      [selField]: [
                        "ADDR",
                        "PHONE",
                        "EMAIL",
                      ].includes(selGroup)
                        ? [f.group, count, e.target.name]
                        : e.target.name,
                    });
                  }
                }}
              >
                <option>Add {f.name.toLowerCase()}</option>
                {fields.map((field) => {
                  return (
                    <React.Fragment>
                      <option
                        key={`option_${field}`}
                        value={JSON.stringify({ field, group: f.group })}
                        disabled={mappedColumns.includes(field)}
                      >
                        {field}
                      </option>
                    </React.Fragment>
                  );
                })}
              </Form.Control>
            );
          }
        })}
      </Form.Group>
    );
  }
  return (
    <React.Fragment>
      {items}
      {(allowMultiple || counter === 0) &&
        <Button
          onClick={() => setCounter(counter + 1)}
          variant="link"
          className="text-muted"
        >
          <PlusCircleFill size={20} />
          <span className="ms-1 text-capitalize">{title}</span>
        </Button>
      }
    </React.Fragment>
  );
};

const MapPreviewTable = props => {
  const {method, mappedObject, groups, uploadMapping, setUploadMapping, mappedColumns, tableDataArray, title} = props;

  let contactMethodDetails = mappedObject[method];
  if (typeof contactMethodDetails === "undefined") {
    //return null;
  }
  let cms = [];
  tableDataArray.map((a, i) => {
    //console.log(method, a, i);
    //console.log(groups[method]);
    let bodyRows = [];
    groups[method].map((f, index) => {
      let cmvalue = a[f.value];
      let rmap = [method,i, f.value];
      //console.log("rmap: ", rmap);

      if (typeof cmvalue !== "undefined") {

        if (
          f.value === "data" &&
          method === "PHONE"
        ) {
          cmvalue = formatPhoneNumber(cmvalue);
        }
        bodyRows.push(
          <tr
            key={`cmtr_${method}_${i}_${f.value}_${index}`}
          >
            <td className="text-muted">{f.name}</td>
            <td>{cmvalue}</td>
            <td>
              <Button
                className="text-muted"
                variant="link"
                onClick={(e) => {
                  e.preventDefault();
                  let nm = {...uploadMapping};
                  let mappings = Object.values(uploadMapping);
                  let rmindex = indexOfArray( mappings, rmap );
                  let mappedColumn;
                  if (rmindex !== -1) {
                    mappedColumn = mappedColumns[ rmindex ];
                  } else {
                    rmap = f.value;
                    mappedColumn = mappedColumns[ mappings.indexOf(rmap) ];
                  }
                  console.log("mappings: ", mappings, "rmap: ", rmap, "rmindex: ", rmindex, "mappedColumn: ", mappedColumn);
                  delete nm[mappedColumn];
                  setUploadMapping(nm);
                }}
              >
                &times;
              </Button>
            </td>
          </tr>
        );
      }
    })

    cms.push(
      <Table
        key={`cmtable_${method}_${i}`}
        style={{width: "100%"}}
        borderless
        size="sm"
      >
        <thead>
          <tr>
            <th colSpan="3">
              {title} {i > 0 ? i + 1 : ""}
            </th>
          </tr>
        </thead>
        <tbody>
          {bodyRows.length > 0 && bodyRows}
          {!bodyRows.length &&
            <tr>
              <td colSpan="3" className="text-muted">
                Select a column header from your CSV file under
                the {title.toLowerCase()} category.
              </td>
            </tr>
          }
        </tbody>
      </Table>
    );
  });
  return cms;
}

const contactMethods = {
  ADDR: "Address",
  PHONE: "Phone number",
  EMAIL: "Email",
};

const reservedNameFields = ["FN", "MI", "LN", "TITLE", "CO_NAME", "FULL_NAME"];

const reservedFieldNames = [
  "ADDR",
  "APT",
  "CITY",
  "ST",
  "ZIP",
  "Z4",
  "EMAIL",
  "PHONE",
];

const reservedFields = [
  { value: "CO_NAME", name: "Company name", group: "Name" },
  { value: "FN", name: "First name", group: "Name" },
  { value: "LN", name: "Last name", group: "Name" },
  { value: "FULL_NAME", name: "Full name", group: "Name" },
  { value: "TITLE", name: "Title", group: "Name" },
  { value: "ADDR", name: "Street address", group: "ADDR" },
  { value: "APT", name: "Apartment number", group: "ADDR" },
  { value: "CITY", name: "City", group: "ADDR" },
  { value: "ST", name: "State", group: "ADDR" },
  { value: "ZIP", name: "Postal code", group: "ADDR" },
  { value: "Z4", name: "Zip 4", group: "ADDR" },
  { value: "COUNTRY", name: "Country", group: "ADDR" },
  { value: "latitude", name: "Latitude", group: "ADDR" },
  { value: "longitude", name: "Longitude", group: "ADDR" },
  { value: "confirmed", name: "Address confirmed", group: "ADDR" },
  { value: "confirmedAt", name: "Address confirmed timestamp", group: "ADDR" },
  { value: "data", name: "Phone", group: "PHONE" },
  { value: "dncFlag", name: "DNC", group: "PHONE" },
  { value: "confirmed", name: "Phone confirmed", group: "PHONE" },
  { value: "confirmedAt", name: "Phone confirmed timestamp", group: "PHONE" },
  { value: "data", name: "Email", group: "EMAIL" },
  { value: "optIn", name: "Opt in", group: "EMAIL" },
  { value: "optOut", name: "Opt out", group: "EMAIL" },
  { value: "confirmed", name: "Email confirmed", group: "EMAIL" },
  { value: "confirmedAt", name: "Email confirmed timestamp", group: "EMAIL" },
];

const transformHeader = (h) =>
  h.trim().replace("  ", "_").replace(" ", "_").toLowerCase();

const UploadToCrmButton = props => {
  const [showModal, setShowModal] = useState(false);
  return(
    <React.Fragment>
      <Button
        variant={props.variant ? props.variant : ""}
        className={props.className ? props.className : ""}
        style={props.style ? props.style : null}
        onClick={() => {
          setShowModal(true);
        }}
      >
        {props.children}
      </Button>
      {showModal &&
        <Modal show={showModal} onHide={() => setShowModal(false)} size="xl">
          <Modal.Header>
            <Modal.Title>Upload records to List</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <UploadRecordsToCrm {...props} uploadToCrmCallback={() => setShowModal(false)} />
          </Modal.Body>
          <Modal.Footer className="d-flex flex-row justify-content-between">
            <Button size="sm" onClick={() => setShowModal(false)} variant="light">
              Close
            </Button>
            <div>&nbsp;</div>
          </Modal.Footer>
        </Modal>
      }
    </React.Fragment>
  )
}

const UploadRecordsToCrm = (props) => {
  const fileInputRef = useRef(null);
  const dropAreaRef = useRef(window.document.getElementById("file_drop"));
  const storeInputFile = async (file) => {
    // upload the file to storage
    let fileOfInputs = await storeMedia(
      `uploads/${props.userDoc.id}/${file.name}`,
      file,
      // progressCallBack
      (progress) => {
        console.log("progress: ", progress);
      },
      // downloadCallBack
      (downloadUrl) => {
        console.log("dowloadUrl: ", downloadUrl);
        setUploadedFile(downloadUrl);
      },
      // metadataCallBack
      (metadata) => {
        console.log("metadata: ", metadata);
      }
    );
  };
  const onFileInputChange = (event) => {
    const { files } = event.target;
    // do something with your files...
    selectFile(files);
  };
  const onTargetClick = () => {
    fileInputRef.current.click();
  };
  const selectFile = (files) => {
    let newFile = files[0];
    setFile(newFile);
    storeInputFile(newFile);
    previewCsv(
      {
        file: files[0],
        transformHeader,
      },
      (error, results) => {
        if (error) {
          console.log("Error previewing csv upload: ", error);
        }
        if (results) {
          console.log("preview results: ", results);
          setPreview(results);
        }
      }
    );
  };
  //props variables
  const { userDoc, user, list, addToCrm, handleCrmState, listConfig, teamMembers, uploadToCrmCallback } = props;
  // state variables
  // upload workflow state variables
  const [uploadedFile, setUploadedFile] = useState(null);
  const [file, setFile] = useState(false);
  const [preview, setPreview] = useState(false);
  const [confirmedFile, setConfirmedFile] = useState(false);
  const [headerIndex, setHeaderIndex] = useState(0);
  const [uploadMapping, setUploadMapping] = useState({});
  const [mappingComplete, setMappingComplete] = useState(false);
  const [previewConfirmed, setPreviewConfirmed] = useState(false);
  const [uploadProgress, setUploadProgress] = useState(0);
  // Mapping state variables
  const [nameCount, setNameCount] = useState(0);
  const [addressCount, setAddressCount] = useState(0);
  const [phoneCount, setPhoneCount] = useState(0);
  const [emailCount, setEmailCount] = useState(0);
  const [dataIndex, setDataIndex] = useState(0);

  // Upload configuration state variables
  const [checkForDuplicates, setCheckForDuplicates] = useState(false);
  const [assignRecordStatus, setAssignRecordStatus] = useState(false);
  const [predefinedRecordStatus, setPredefinedRecordStatus] = useState("new");
  const [assignTeamMember, setAssignTeamMember] = useState(false);
  const [assignedUid, setAssignedUid] = useState(userDoc.uid);
  // uploadPreview
  let headerValue, addresses, phones;
  let groups = {};
  let mappedObject = {};
  let names = [];
  let mappedColumns = Object.keys(uploadMapping);
  if (preview) {
    headerValue = preview.meta.fields[headerIndex];
    reservedFields.map((f) => {
      if (typeof f.group === "undefined") {
        f = { ...f, group: "Other" };
      }
      //console.log("f: ", f);
      if (typeof groups[f.group] === "undefined") {
        groups[f.group] = [];
      }
      groups[f.group].push(f);
    });
    //console.log("groups: ", groups);
    mappedObject = mapImport(preview.data[dataIndex], uploadMapping);
  }
  console.log(
    "UploadRecordsToCrm props: ",
    props,
    "uploadMapping: ",
    uploadMapping,
    "groups: ",
    groups
  );
  return (
    <Container>
      {!file && (
        <React.Fragment>
          <h6 className="mt-3">
            <i>Upload records from a csv file.</i>
          </h6>
          <div className="border rounded file-drop-container">
            <FileDrop
              id="file_drop"
              onFrameDragEnter={(event) => {
                console.log("onFrameDragEnter", event);
                event.preventDefault();
              }}
              onFrameDragLeave={(event) => {
                console.log("onFrameDragLeave", event);
                event.preventDefault();
              }}
              onFrameDrop={(event) => {
                console.log("onFrameDrop", event);
                event.preventDefault();
              }}
              onDragOver={(event) => {
                console.log("onDragOver", event);
                event.preventDefault();
              }}
              onDragLeave={(event) => console.log("onDragLeave", event)}
              onDrop={(files, event) => {
                console.log("onDrop!", files, event);
                event.preventDefault();
                selectFile(files);
              }}
              onTargetClick={onTargetClick}
              frame={document}
            >
              Drag a CSV file here.
            </FileDrop>
            <input
              onChange={onFileInputChange}
              ref={fileInputRef}
              type="file"
              className="d-none"
              accept="text/csv, .csv"
            />
          </div>
        </React.Fragment>
      )}
      {preview && !confirmedFile && !mappingComplete && (
        <Row>
          <Col>
            <h5>Is this the correct file?</h5>
            <CsvPreview preview={preview} mapping={uploadMapping} />
          </Col>
          <Col xs={12} className="d-flex flex-row justify-content-between align-items-center">
            <Button
              onClick={ (e) => {
                e.preventDefault();
                setFile(false);
                setPreview(false);
                setUploadedFile(false);
              }}
            >
              &lt; Back
            </Button>
            <Button
              onClick={(e) => {
                e.preventDefault();
                setConfirmedFile(true);
              }}
            >
              Yes, keep going &gt;
            </Button>
          </Col>
        </Row>
      )}
      {preview && confirmedFile && !mappingComplete && !previewConfirmed && (
        <Row>
          <Col>
            <h5>Does your list contain names, addresses, phone numbers, or emails?</h5>
            <Form>
              <MapContactForm
                className=""
                size="sm"
                attribute={"group"}
                group={"Name"}
                title={"Name"}
                counter={nameCount}
                setCounter={setNameCount}
                allowMultiple={false}
                fields={preview.meta.fields}
                reservedFields={reservedFields}
                uploadMapping={uploadMapping}
                setUploadMapping={setUploadMapping}
              />
              <MapContactForm
                className="mt-3"
                size="sm"
                attribute={"group"}
                group={"ADDR"}
                title={"Address"}
                counter={addressCount}
                setCounter={setAddressCount}
                allowMultiple={true}
                fields={preview.meta.fields}
                reservedFields={reservedFields}
                uploadMapping={uploadMapping}
                setUploadMapping={setUploadMapping}
              />
              <MapContactForm
                className="mt-3"
                size="sm"
                attribute={"group"}
                group={"PHONE"}
                title={"Phone"}
                counter={phoneCount}
                setCounter={setPhoneCount}
                allowMultiple={true}
                fields={preview.meta.fields}
                reservedFields={reservedFields}
                uploadMapping={uploadMapping}
                setUploadMapping={setUploadMapping}
              />
              <MapContactForm
                className="mt-3"
                size="sm"
                attribute={"group"}
                group={"EMAIL"}
                title={"Email"}
                counter={emailCount}
                setCounter={setEmailCount}
                allowMultiple={true}
                fields={preview.meta.fields}
                reservedFields={reservedFields}
                uploadMapping={uploadMapping}
                setUploadMapping={setUploadMapping}
              />
            </Form>
          </Col>
          {(nameCount + addressCount + emailCount + phoneCount) > 0 &&
              <Col>
                <h6>Record preview</h6>
                <ButtonGroup className="text-center mb-2">
                  <Button size="sm" variant="secondary"
                    onClick={() => setDataIndex(dataIndex - 1)}
                    disabled={dataIndex === 0}
                  >
                    &lt;
                  </Button>
                  <Button className="text-dark" size="sm" variant="link" disabled>
                    Previewing record {dataIndex +1} of {preview.data.length}
                  </Button>
                  <Button size="sm" variant="secondary"
                    onClick={() => setDataIndex(dataIndex +1)}
                    disabled={dataIndex +1 === preview.data.length}
                  >
                    &gt;
                  </Button>
                </ButtonGroup>
                <Card bg="light">
                  <Card.Body>
                    {["Name","ADDR", "PHONE", "EMAIL"].map((method) => {
                      return(
                        <MapPreviewTable
                          method={method}
                          groups={groups}
                          mappedObject={mappedObject}
                          uploadMapping={uploadMapping}
                          setUploadMapping={setUploadMapping}
                          mappedColumns={mappedColumns}
                          tableDataArray={mappedObject[method] ? mappedObject[method] : [mappedObject]}
                          title={contactMethods[method] ? contactMethods[method] : "Name"}
                        />
                      )
                    })}
                  </Card.Body>
                </Card>
              </Col>
          }
          <Col xs={12} className="d-flex flex-row justify-content-between align-items-center">
            <Button
              onClick={ (e) => {
                e.preventDefault();
                setConfirmedFile(false);
              }}
            >
              &lt; Back
            </Button>
            <Button
              onClick={(e) => {
                e.preventDefault();
                //let mappedValues = Object.values(uploadMapping);
                //let mappedNames = mappedValues.filter( v => reservedNameFields.includes(v) );
                //if(mappedNames.length === 0) {
                //  console.log("User must map at least one name field.");
                //}
                setPreviewConfirmed(true);
              }}
            >
              Yes, keep going &gt;
            </Button>
          </Col>
        </Row>
      )}
      {previewConfirmed && (
        <Row>
          <Col>
            <h5>Confirm your upload</h5>
            <p><i>Green headers indicate the column is mapped to a name, address, phone, or email.</i></p>
            <CsvPreview preview={preview} mapping={uploadMapping} />
            {/*<Form.Group>
              <Form.Check
                id="dup_check_tog"
                type="switch"
                label="Check for duplicates"
                checked={checkForDuplicates}
                onChange={() => setCheckForDuplicates(!checkForDuplicates)}
              />
            </Form.Group>*/}
            <Form.Group>
              <Form.Check
                id="status_asg_tog"
                type="switch"
                label="Assign record status"
                checked={assignRecordStatus}
                onChange={() => setAssignRecordStatus(!assignRecordStatus)}
              />
              {assignRecordStatus &&
                <Card bg="light" className="mt-3">
                  <Card.Body>
                    <fieldset>
                      <Form.Label>
                        Select record status
                      </Form.Label>
                    {["new", ...listConfig.listStatus].map( (status,i) => {
                        return(
                          <Form.Check
                            type="radio"
                            label={ status }
                            value={ status }
                            onChange={ () => setPredefinedRecordStatus(status) }
                            checked={ predefinedRecordStatus === status }
                            id={ `status_${i}` }
                            key={ `status_${i}` }
                          />
                        )
                      })}
                    </fieldset>
                    <AddListStatus
                      size="sm"
                      listConfig={listConfig}
                      inline={true}
                      variant="secondary"
                    />
                  </Card.Body>
                </Card>
              }
            </Form.Group>
            <Form.Group>
              <Form.Check
                id="tm_mbr_tog"
                type="switch"
                label="Assign to team member"
                checked={assignTeamMember}
                onChange={() => setAssignTeamMember(!assignTeamMember)}
              />
            {assignTeamMember &&
                <Card bg="light" className="mt-3">
                  <Card.Body>
                    <fieldset>
                      <Form.Label>
                        Select team member
                      </Form.Label>
                      {[{name: "Account owner", email: userDoc.email},...userDoc.companyUsers].map( (u,i) => {
                        let member = [{uid: userDoc.uid, email: userDoc.email}, ...teamMembers].find( m => u.email === m.email);
                        let uid, email;
                        if (member) {
                          uid = member.uid;
                          email = member.email;
                        }
                        return(
                          <Form.Check
                            type="radio"
                            label={ `${u.name} (${email ? email : "pending"})` }
                            name={ u.name }
                            onChange={ () => setAssignedUid(uid) }
                            checked={ assignedUid === uid }
                            id={ `tm_${i}` }
                            key={ `tm_${i}` }
                            disabled={ !member }
                          />
                        )
                      })}
                    </fieldset>
                  </Card.Body>
                </Card>
              }
            </Form.Group>
          </Col>
          <Col xs={12} className="d-flex flex-row justify-content-between align-items-center">
            <Button
              onClick={(e) => {
                e.preventDefault()
                setPreviewConfirmed(false)
              }}
            >
              &lt; Back
            </Button>
            <Button
              className=""
              onClick={(e) => {
                e.preventDefault();
                console.log("CSV import started!");
                handleCrmState({ crmLoaded: false });
                parseCsv(
                  {
                    file,
                    transformHeader,
                  },
                  (error, results) => {
                    if (error) {
                      console.log("Error parsing upload to Crm: ", error);
                    }
                    if (results) {
                      addCsvData(
                        {
                          results,
                          fieldMapping: uploadMapping,
                          list,
                          user,
                          userDoc,
                          listConfig,
                          file,
                          fileLink: uploadedFile,
                          assignedUid,
                          listStatus: {[list]: predefinedRecordStatus}
                        },
                        (error, writeSummary) => {
                          if (error) {
                            console.log(
                              "Error adding documents to CRM: ",
                              error
                            );
                          }
                          if (writeSummary) {
                            console.log(
                              "Successfully added documents to CRM: ",
                              writeSummary
                            );
                            handleCrmState({ crmLoaded: true });
                          }
                        }
                      );
                    }
                  }
                );
                if(uploadToCrmCallback) {
                  uploadToCrmCallback(null, true);
                }
              }}
            >
              Begin CSV Import
            </Button>
          </Col>
      </Row>
      )}
    </Container>
  );
};

const AppendToCrm = (props) => {
  // Props variables
  let {
    selectedData,
    list,
    dicts,
    userDoc,
    user,
    apiKey,
    handleCrmState,
  } = props;
  // State variables
  const [step, setStep] = useState(0);
  const [selectedAppendTypes, setSelectedAppendTypes] = useState({
    EMAIL: false,
    PHONE: false,
    ADDR: false,
  });
  const [dataDict, setDataDict] = useState("");
  const [appendFields, setAppendFields] = useState(null);
  const [progress, setProgress] = useState(0);
  console.log("AppendToCrm props: ", props);
  const supportedAppendTypes = [
    { name: "Email Address", field: "EMAIL" },
    { name: "Phone", field: "PHONE" },
    { name: "Address", field: "ADDR" },
  ];

  return (
    <React.Fragment>
      <p className="lead">
        Appending <span className="text-success">{selectedData.length}</span>{" "}
        Records.
      </p>
      {step === 0 && (
        <Form.Group>
          <Form.Label>
            What would you like to use for the key for your append?
          </Form.Label>
          {supportedAppendTypes.map((type) => {
            return (
              <Form.Check
                key={`sapt_${type.field}`}
                id={`sapt_${type.field}`}
                type="checkbox"
                label={type.name}
                value={selectedAppendTypes[type.field]}
                onChange={() =>
                  setSelectedAppendTypes({
                    ...selectedAppendTypes,
                    [type.field]: !selectedAppendTypes[type.field],
                  })
                }
              />
            );
          })}
        </Form.Group>
      )}
      {step === 1 && (
        <Form.Group>
          <Form.Label>Which type of append would you like to do?</Form.Label>
          <Form.Control
            as="select"
            onChange={(e) => {
              let dictId = e.target.value;
              let dict = dicts.find((dict) => dict.dictId === dictId);
              console.log("dict: ", dict);
              let defaultAppendFields = {};
              dict.appendCols.map((col) => {
                defaultAppendFields[col.value] = col.default
                  ? col.default
                  : false;
              });
              console.log("defaultAppendFields: ", defaultAppendFields);
              setAppendFields(defaultAppendFields);
              setDataDict(dict);
            }}
            value={dataDict.dictId}
          >
            <option>Select append type</option>
            {dicts.map((dict) => {
              return (
                <option key={`opt_${dict.dictId}`} value={dict.dictId}>
                  {dict.title}
                </option>
              );
            })}
          </Form.Control>
        </Form.Group>
      )}

      {step === 2 && (
        <Form.Group as={Row} className="p-3">
          <Col xs={12}>
            <Form.Label>What information would you like to append?</Form.Label>
          </Col>
          {dataDict.appendCols.map((col) => {
            return (
              <Col xs="6" key={`acol_${col.value}`} className="mb-1">
                <Form.Check
                  id={`acol_${col.value}`}
                  type="checkbox"
                  label={col.name}
                  checked={appendFields[col.value]}
                  onChange={() =>
                    setAppendFields({
                      ...appendFields,
                      [col.value]: !appendFields[col.value],
                    })
                  }
                />
              </Col>
            );
          })}
        </Form.Group>
      )}
      {step === 3 && (
        <React.Fragment>
          <h5>Append progress</h5>
          <ProgressBar>
            <ProgressBar
              striped
              variant="success"
              now={(progress / selectedData.length) * 100}
              key={1}
              label="Appended"
            />
          </ProgressBar>
        </React.Fragment>
      )}
      <div className="d-flex flex-row justify-content-between">
        {step === 0 && <div>&nbsp;</div>}
        {step !== 0 && (
          <Button variant="link" onClick={() => setStep(step - 1)}>
            Back
          </Button>
        )}
        {step < 2 && (
          <Button variant="link" onClick={() => setStep(step + 1)}>
            Next
          </Button>
        )}
        {step === 2 && (
          <Button
            variant="primary"
            size="lg"
            onClick={async () => {
              handleCrmState({ crmLoaded: false });
              setStep(step + 1);
              let writes = [];
              let errors = [];
              for (let [i, dataId] of selectedData.entries()) {
                try {
                  let write = await appendToCrm(
                    {
                      dataId,
                      appendType: selectedAppendTypes,
                      appendFields,
                      dataDict,
                      apiKey,
                      list,
                      aid: userDoc.id,
                      uid: user.uid,
                    },
                    (error, writeSummary) => {
                      if (error) {
                        console.log("Error appending records to CRM: ", error);
                      }
                      if (writeSummary) {
                        console.log(
                          "Successfully completed append: ",
                          writeSummary
                        );
                      }
                    }
                  );
                  writes.push(write);
                  setProgress(writes.length + errors.length);
                } catch (error) {
                  console.log("Error: ", error);
                  errors.push(error);
                  setProgress(writes.length + errors.length);
                  continue;
                }
              }
              handleCrmState({ crmLoaded: true });
            }}
          >
            Append to CRM
          </Button>
        )}
      </div>
    </React.Fragment>
  );
};

const AppendModalButton = (props) => {
  const [showModal, setShowModal] = useState(false);

  return (
    <React.Fragment>
      <Button
        variant={props.variant ? props.variant : ""}
        className={props.className ? props.className : ""}
        style={props.style ? props.style : null}
        onClick={() => {
          setShowModal(true);
        }}
        disabled={!props.selectedData.length}
      >
        {props.children}
      </Button>
      {showModal && (
        <Modal show={showModal} onHide={() => setShowModal(false)} size="xl">
          <Modal.Body>
            <Modal.Title>Append data to CRM Records</Modal.Title>
            <AppendToCrm {...props} />
          </Modal.Body>
          <Modal.Footer className="d-flex flex-row justify-content-between">
            <Button
              size="sm"
              onClick={() => setShowModal(false)}
              variant="light"
            >
              Nevermind
            </Button>
            <div>&nbsp;</div>
          </Modal.Footer>
        </Modal>
      )}
    </React.Fragment>
  );
};

const BulkMarkStatus = (props) => {
  const { userDoc, list, selectedData, handleCrmState, listConfig } = props;
  const [showModal, setShowModal] = useState(false);
  const [status, setStatus] = useState("");
  const [customStatus, setCustomStatus] = useState(false);

  useEffect( () => {
    if (props.setCustomStatus) {
      setCustomStatus(true);
    }
  }, []);

  return (
    <React.Fragment>
      <Button
        variant={props.variant ? props.variant : ""}
        className={props.className ? props.className : ""}
        style={props.style ? props.style : null}
        onClick={() => {
          setShowModal(true);
        }}
        disabled={!props.selectedData.length}
      >
        {props.children}
      </Button>
      {showModal && (
        <Modal show={showModal} onHide={() => setShowModal(false)} size="xl">
          <Modal.Body>
            <Modal.Title>Change Record Status</Modal.Title>
            {customStatus &&
              <Form.Group>
                <Form.Label>Set Custom Status</Form.Label>
                <Form.Control
                  size="lg"
                  value={status}
                  onChange={(e) => setStatus(e.target.value)}
                  placeholder="Type new status"
                />
              </Form.Group>
            }
            {!customStatus &&
              <Form.Group>
                <Form.Label>Select Record Status</Form.Label>
                <Form.Control
                  as="select"
                  value={status}
                  onChange={(e) => {
                    let v = e.target.value;
                    if (v === "custom") {
                      return setCustomStatus(true)
                    }
                    setStatus(v);
                  }}
                >
                  <option>Select new record status</option>
                  {listConfig.listStatus.map( (s,i) => {
                    return(
                      <option key={`bms_${s}_${i}`} value={s}>{s}</option>
                    )
                  })}
                  <option value="archived">Archive</option>
                  <option value="custom">Add new status</option>
                </Form.Control>
              </Form.Group>
            }
            <Button
              variant="primary"
              size="lg"
              onClick={() => {
                handleCrmState({ crmLoaded: false });
                batchWrite(
                  {
                    method: (docId) => {
                      return {
                        ref: db
                          .collection("data")
                          .doc(docId),
                        op: "update",
                        data: {
                          listStatus: {
                            [list]: status,
                          },
                        },
                      };
                    },
                    data: selectedData,
                  },
                  (error, results) => {
                    if (error) {
                      console.log("Error changing status: ", error);
                    }
                    if (results) {
                      console.log("Successfully changed status: ", results);
                      handleCrmState({ crmLoaded: true });
                      showModal(false);
                    }
                  }
                );
              }}
            >
              Update Status
            </Button>
          </Modal.Body>
        </Modal>
      )}
    </React.Fragment>
  );
};

const BulkDelete = (props) => {
  const { userDoc, list, selectedData, handleCrmState } = props;
  const [showModal, setShowModal] = useState(false);
  const [status, setStatus] = useState("");

  return (
    <React.Fragment>
      <Button
        variant={props.variant ? props.variant : ""}
        className={props.className ? props.className : ""}
        style={props.style ? props.style : null}
        onClick={() => {
          setShowModal(true);
        }}
        disabled={!props.selectedData.length}
      >
        {props.children}
      </Button>
      {showModal && (
        <Modal show={showModal} onHide={() => setShowModal(false)} size="sm">
          <Modal.Body>
            <p className="text-danger">
              Are you sure that you want to delete {selectedData.length}{" "}
              records? This CANNOT be undone and will delete these record from ALL LISTS and for ALL USERS.
            </p>
            <Button
              variant="danger"
              size="sm"
              onClick={() => {
                handleCrmState({ crmLoaded: false });
                batchWrite(
                  {
                    method: (docId) => {
                      return {
                        ref: db
                          .collection("data")
                          .doc(docId),
                        op: "delete",
                      };
                    },
                    data: selectedData,
                  },
                  (error, results) => {
                    if (error) {
                      console.log("Error changing status: ", error);
                    }
                    if (results) {
                      console.log("Successfully deleted: ", results);
                      handleCrmState({ crmLoaded: true, selectedData: [] });
                      setShowModal(false);
                    }
                  }
                );
              }}
            >
              Yes, I want to delete these records.
            </Button>
          </Modal.Body>
        </Modal>
      )}
    </React.Fragment>
  );
};

const BulkArchive = (props) => {
  const { userDoc, list, selectedData, handleCrmState } = props;
  const [showModal, setShowModal] = useState(false);
  const [status, setStatus] = useState("");

  return (
    <React.Fragment>
      <Button
        variant={props.variant ? props.variant : ""}
        className={props.className ? props.className : ""}
        style={props.style ? props.style : null}
        onClick={() => {
          setShowModal(true);
        }}
        disabled={!props.selectedData.length}
      >
        {props.children}
      </Button>
      {showModal && (
        <Modal show={showModal} onHide={() => setShowModal(false)} size="sm">
          <Modal.Body>
            <p className="text-muted">
              Are you sure that you want to archive {selectedData.length}{" "}
              records? This CANNOT be undone.
            </p>
            <Button
              variant="danger"
              size="sm"
              onClick={() => {
                handleCrmState({ crmLoaded: false });
                batchWrite(
                  {
                    method: (docId) => {
                      return {
                        ref: db
                          .collection("data")
                          .doc(docId),
                        op: "update",
                        data: {
                          archived: true,
                          _archived: Math.round(new Date().getTime() / 1000),
                        },
                      };
                    },
                    data: selectedData,
                  },
                  (error, results) => {
                    if (error) {
                      console.log("Error changing status: ", error);
                    }
                    if (results) {
                      console.log("Successfully deleted: ", results);
                      setShowModal(false);
                      handleCrmState({ crmLoaded: true, selectedData: [] });
                    }
                  }
                );
              }}
            >
              Yes, I want to archive these records.
            </Button>
          </Modal.Body>
        </Modal>
      )}
    </React.Fragment>
  );
};

const FavoriteList = (props) => {
  const { listConfig, size } = props;
  let favorite, themeColor;
  if (listConfig) {
    favorite = listConfig.favorite;
    themeColor = listConfig.themeColor;
  }
  const toggleFavorite = (listConfig) => {
    let newDoc = { ...listConfig };
    newDoc.favorite = !newDoc.favorite;
    newDoc._lastChanged = Math.round(new Date().getTime() / 1000);
    newDoc._docRef.update(newDoc);
  };

  return (
    <Button
      onClick={() => toggleFavorite(listConfig)}
      variant="link"
      className={props.className ? props.className : ""}
      style={props.style ? props.style : null}
    >
      {favorite && <BookmarkStarFill size={size} />}
      {!favorite && (
        <BookmarkPlus
          size={size}
          className={
            themeColor === "dark" ? "text-white" : `text-${themeColor}`
          }
        />
      )}
    </Button>
  );
};

const ListViewHeader = (props) => {
  const { userDoc, listConfig, hideHeaderButtons } = props;
  let favorite, themeColor;
  if (listConfig) {
    favorite = listConfig.favorite;
    themeColor = listConfig.themeColor;
  }
  return (
    <div className="d-flex flex-row justify-content-between m-2 mb-5">
      <div className="d-flex flex-row justify-content-start">
        <Link
          to="/lists"
          className={`me-2 p-0 d-flex flex-column align-items-center justify-content-center border rounded bg-light border-${
            listConfig ? listConfig.themeColor : "light"
          }`}
        >
          <ChevronLeft size={24} className={`text-${themeColor}`} />
        </Link>

        <div
          className={`d-flex flex-row align-items-center p-1 pb-0 border rounded bg-light border-${
            themeColor ? themeColor : "light"
          }`}
        >
          <FavoriteList
            size={25}
            listConfig={listConfig}
            className={`display-inline p-0 ${
              favorite ? `text-${themeColor} ` : ""
            }`}
          />
          <span className="mb-0 pb-0 pe-3" style={{ fontSize: "20px" }}>
            {listConfig ? listConfig.listName : "Getting List Name"}
          </span>
        </div>

        <Dropdown>
          <Dropdown.Toggle
            id="list-menu"
            className={`ms-2 p-2 border rounded bg-light border-${
              listConfig ? listConfig.themeColor : "light"
            }`}
          >
            <ThreeDots size={24} className={`text-${themeColor}`} />
          </Dropdown.Toggle>
          <Dropdown.Menu align="left">
            <Link
              className="dropdown-item text-dark"
              to="/lists"
              onClick={() => {
                console.log("Archive this list.");
                if (window.confirm("Are you sure you want to archive this list? It cannot be undone.")) {
                  db.collection("crm")
                  .doc(userDoc.id)
                  .collection("lists")
                  .doc(listConfig._docId)
                  .update({
                    archived: true,
                    _archived: Math.round(new Date().getTime() / 1000),
                  });
                }
              }}
            >
              Archive List
            </Link>
            <ManageList className="dropdown-item" {...props} editList={true}>
              Edit name & theme color
            </ManageList>
            <ManageTabsModal
              {...props}
              className="dropdown-item"
            />
            <DisplayedFieldsModal {...props}
              variant="link"
              className="dropdown-item"
            >
              Edit displayed columns
            </DisplayedFieldsModal>
          </Dropdown.Menu>
        </Dropdown>
      </div>
      {!hideHeaderButtons &&
          <ButtonGroup>
          <UploadToCrmButton {...props} variant={`outline-${themeColor}`}>
            <CloudUpload size={24} />
          </UploadToCrmButton>
          <ExportRecordsButton {...props} variant={`outline-${themeColor}`}>
            <Download size={24} />
          </ExportRecordsButton>
        </ButtonGroup>
      }
    </div>
  );
};

const ListBulkActions = (props) => {
  //console.log("ListBulkActions props: ", props);
  const { userDoc, selectedData } = props;
  let showBulkActions = selectedData ? selectedData.length ? true : false : false;
  return (
    <div className="d-flex flex-row justify-content-between m-2">
      <div>&nbsp;</div>
      {showBulkActions && (
        <ButtonGroup>
          <AppendModalButton {...props} variant="outline-dark">
            Append <NodePlus size={24} />
          </AppendModalButton>
          <BulkMarkStatus {...props} variant="outline-dark">
            Status <Triangle size={15} />
          </BulkMarkStatus>
          <BulkDelete {...props} variant="outline-danger">
            <Trash size={24} />
          </BulkDelete>
          {/*
            // Removed because adding the additional query parameter for
            // excluded archived records would require custom
            // indexes.  We'll just let people delete records.
            <BulkArchive {...props} variant="outline-dark">
            <XSquare size={18} /> Archive
            </BulkArchive>
          */}
        </ButtonGroup>
      )}
    </div>
  );
};

const CRM = (props) => {
  const {
    handleState,
    userDoc,
    crmLoaded,
    handleCrmState,
    selectedData,
    listConfig,
    where,
    orderBy
  } = props;
  console.log("rendered CRM.", props);

  //Handle Collection and Query Pagination
  const [limit, setLimit] = useState(props.limit);
  const [page, setPage] = useState(1);
  const [endAt, setEndAt] = useState(props.endAt);
  const [startAfter, setStartAfter] = useState(props.startAfter);

  useEffect(() => {
    handleState({
      isApp: true,
      noDistract: true
    });
  }, []);

  return (
    <React.Fragment>
      <Collection
        title={props.title ? props.title : ""}
        colRef={props.colRef ? props.colRef : db}
        collection={props.collection ? props.collection : null}
        permissions={props.permissions ? props.permissions : []}
        listen={true}
        where={where ? where : undefined}
        orderBy={orderBy ? orderBy : undefined}
        endAt={endAt ? endAt : undefined}
        startAfter={startAfter ? startAfter : undefined}
        limit={limit ? limit : undefined}
        callback={props.callback ? props.callback : null}
      >
        <CollectionContext.Consumer>
          {(context) => {
            const { docs, firestoreRef, snapShot } = context;
            console.log("context: ", context);
            return (
              <React.Fragment>
                <ListViewHeader
                  {...props}
                  docs={docs}
                  firestoreRef={firestoreRef}
                />
                <ListBulkActions
                  {...props}
                  docs={docs}
                  firestoreRef={firestoreRef}
                />
                {crmLoaded && (
                  <React.Fragment>
                    <AutoComplete
                      path="/record"
                      onSearch={ async (value) => {
                        console.log("onSearch");
                        const result = await db
                          .collection("searchtext")
                          .add({
                            request: {
                              index: "data",
                             body: {
                               query: {
                                 query_string: {
                                   query: value,
                                   default_field: "NAME"
                                 } 
                               }
                             }
                            },
                            response: null,
                          })
                        //console.log("result: ", result);
                        result.onSnapshot((doc) => {
                          //console.log("doc: ", doc);
                          let docData = doc.data();
                          if (docData.response !== null) {
                            // Do things
                            if (docData.response.hits.hits.length > 0) {
                              console.log("A Match!!!")
                            }
                            console.log(docData);
                          }
                        })
                        return [
                          {
                            id: "something",
                            name: "something",
                            meta: "something else"
                          }
                        ];
                      }}
                      placeholder="Search"
                    />
                    <TBLS
                      {...props}
                      tabDocs={docs}
                    />

                      <div className="mb-2 d-flex flex-row justify-content-between">
                        <div>
                          <small className="text-muted">
                            Viewing records {docs.length > 0 ? page * limit - limit + 1 : 0} - { page * limit - (limit - docs.length) }
                          </small>
                        </div>
                        <div>
                          <Button
                            variant="link"
                            size="sm"
                            onClick={() => {
                              //let firstDoc = docs[0];
                              let firstDoc = snapShot.docs[0]
                              console.log("firstDoc: ", firstDoc);
                              setEndAt(null);
                              setStartAfter(null);
                              setPage(1);
                            }}
                            disabled={page ===1}
                          >
                            &lt;&lt; Start over
                          </Button>
                          <Button
                            variant="link"
                            size="sm"
                            onClick={() => {
                              //let lastDoc = docs[docs.length-1];
                              let lastDoc = snapShot.docs[snapShot.docs.length -1]
                              console.log("lastDoc: ", lastDoc);
                              setEndAt(null);
                              setStartAfter(lastDoc);
                              setPage(page +1)
                            }}
                            disabled={docs.length === 0 || docs.length < limit}
                          >
                            Next &gt;
                          </Button>
                        </div>
                      </div>

                  </React.Fragment>

                )}
                {!crmLoaded && (
                  <p className="lead">Hold tight, we're updating your data.</p>
                )}
              </React.Fragment>
            );
          }}
        </CollectionContext.Consumer>
      </Collection>
    </React.Fragment>
  );
};

const DisplayedFieldsModal = props => {
  const {listConfig} = props;

  const [displayModal, setDisplayModal] = useState(false);

  const DisplayFieldCheck = props => {
    let {field} = props;
    let uk = `disfield_${field}`
    let checked = listConfig.displayFields.includes(field);
    return(
      <Form.Check
        id={uk}
        key={uk}
        type="switch"
        checked={checked}
        onChange={() => {
          listConfig._docRef.update({
            displayFields: checked ? firebase.firestore.FieldValue.arrayRemove(field) : firebase.firestore.FieldValue.arrayUnion(field)
          })
        }}
        label={field}
      />
    )
  }

  return(
    <React.Fragment>
      <Button
        variant={props.variant ? props.variant : "dark"}
        className={props.className ? props.className : ""}
        style={props.style ? props.style : null}
        onClick={() => setDisplayModal(true)}
      >
        {props.children ? props.children : "Columns"}
      </Button>
      {displayModal &&
        <Modal show={displayModal} onHide={() => setDisplayModal(false)}>
          <Modal.Header>
            <Modal.Title>Select which columns to display</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form><Form.Group>
              {["NAME", "ADDR", "PHONE", "EMAIL"].map( f => {
                return(
                  <DisplayFieldCheck field={f} />
                )
              })}
              {listConfig.fields.filter(f => !["NAME", "ADDR", "PHONE", "EMAIL"].includes(f)).map( f => {
                return(
                  <DisplayFieldCheck field={f} />
                )
              })}
            </Form.Group></Form>
          </Modal.Body>
        </Modal>
      }
    </React.Fragment>
  )

}

const ManageTabsModal = props => {
  const {tabsObject, listConfig} = props;
  const [displayModal, setDisplayModal] = useState(false);

  return(
    <React.Fragment>
      <Button
        onClick={()=> setDisplayModal(true)}
        variant={props.variant ? props.variant : "link"}
        className={props.className ? props.className : ""}
        style={props.style ? props.style : null}
      >
        {props.children ? props.children : "Manage Tabs"}
      </Button>
      {displayModal &&
        <Modal
          show={displayModal}
          onHide={() => setDisplayModal(false)}
          size="xl"
        >
          <Modal.Body>
            <ManageTabs
              {...props}
              tabs={listConfig.tabs}
              setShowOtherTab={(bool) => {
                listConfig._docRef.update({
                  showOtherTab: bool,
                });
              }}
              setShowNewTab={(bool) => {
                listConfig._docRef.update({
                  showNewTab: bool,
                });
              }}
              setShowAllTab={(bool) => {
                listConfig._docRef.update({
                  showAllTab: bool,
                });
              }}
              setShowDupTab={(bool) => {
                listConfig._docRef.update({
                  showDupTab: bool,
                });
              }}
            />
          </Modal.Body>
          <Modal.Footer>
            <Button size="sm" variant="light"
              onClick={() => setDisplayModal(false)}
            >
              Close
            </Button>
          </Modal.Footer>
        </Modal>
      }
    </React.Fragment>
  )
}

const AddTabModal = props => {
  const {listConfig, onCreate} = props;
  const [displayAddTabModal, setDisplayAddTabModal] = useState(false);
  const [newTabTitle, setNewTabTitle] = useState("");
  return(
    <React.Fragment>
      <Button
        size={props.size ? props.size : "sm"}
        variant={props.variant ? props.variant : "dark"}
        onClick={() => setDisplayAddTabModal(true)}
      >
        {props.children ? props.children : <small>Add Tab</small>}
      </Button>
      {displayAddTabModal &&
        <Modal
          show={displayAddTabModal}
          onHide={() => setDisplayAddTabModal(false)}
        >
          <Modal.Body>
            <Modal.Title>Add New Tab</Modal.Title>
            <Form>
              <Form.Group>
                <Form.Label>Tab Name</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter tab name"
                  value={newTabTitle}
                  onChange={(e) => setNewTabTitle(e.target.value)}
                />
                <Form.Text>
                  Add the name of the tab that you'd like to add
                </Form.Text>
              </Form.Group>
              <Button
                className="pull-right"
                variant="primary"
                onClick={() => {
                  if (!newTabTitle) {
                    return props.handleAlerts(
                      "",
                      "Please add a name to your tab.",
                      "warning"
                    );
                  }
                  let savedTabs = [...listConfig.tabs].filter(
                    (t) => !["New", "Other", "All"].includes(t.tabTitle)
                  );
                  let newTab = {
                    tabTitle: newTabTitle,
                    fieldValue: [newTabTitle.toLowerCase()],
                  };
                  savedTabs.push(newTab);

                  listConfig._docRef.update({
                    tabs: savedTabs,
                  });
                  setDisplayAddTabModal(false);
                  setNewTabTitle("");
                  if (onCreate) {
                    onCreate(null, newTab);
                  }
                }}
              >
                Add {newTabTitle} Tab
              </Button>
            </Form>
          </Modal.Body>
        </Modal>
      }
    </React.Fragment>
  )
}

const ManageTabs = (props) => {
  //console.log("ManageTabs props: ", props);
  const {
    tabsObject,
    tabs,
    handleTabs,
    showOtherTab,
    setShowOtherTab,
    showNewTab,
    setShowNewTab,
    showAllTab,
    setShowAllTab,
    showDupTab,
    setShowDupTab,
    listConfig,
    userDoc,
    handleAlerts,
  } = props;
  const [templateName, setTemplateName] = useState("");
  const [displaySaveTemplateModal, setDisplayAddTemplateModal] = useState(
    false
  );
  const [selectedTemplate, setSelectedTemplate] = useState(null);
  const [templates, setTemplates] = useState([]);

  useEffect(() => {
    db.collection("crm")
      .doc(userDoc.uid)
      .collection("templates")
      .where("type", "==", "tabbedList")
      .get()
      .then((querySnapshot) => {
        let t = [];
        querySnapshot.forEach((doc) => {
          let docData = doc.data();
          docData._docId = doc.id;
          t.push(docData);
        });
        setTemplates(t);
      });
  }, []);

  return (
    <div className="mt-3">
      <div className="d-flex justify-content-between mb-3">
        <h5>Manage Tabs</h5>
        <AddTabModal {...props} />
      </div>
      <div
        className="d-flex flex-column justify-content-between align-items-left mb-3"
        style={{
          width: "100%",
        }}
      >
        <Form.Check
          checked={listConfig.showAllTab}
          onChange={() => setShowAllTab(!listConfig.showAllTab)}
          label={'Display "All" Tab'}
        />
        <Form.Text>
          The "All" Tab displays all records regardless of their status.
        </Form.Text>
      </div>
      <div
        className="d-flex flex-column justify-content-between align-items-left mb-3"
        style={{
          width: "100%",
        }}
      >
        <Form.Check
          checked={listConfig.showNewTab}
          onChange={() => setShowNewTab(!listConfig.showNewTab)}
          label={'Display "New" Tab'}
        />
        <Form.Text>
          The "New" Tab displays records that don't match the other tabs that
          you have configured.
        </Form.Text>
      </div>

      {listConfig.tabs.map((t, i) => {
        if (Array.isArray(t.fieldValue) || typeof t.fieldValue === "string") {
          return (
            <div
              key={`tab_mt_${i}`}
              className="d-flex flex-row justify-content-start align-items-center mb-3"
              style={{
                width: "100%",
              }}
            >
              <div>
                Tab {i + 1}: {t.tabTitle}
              </div>
              <ManageTab {...props} tab={t} />
            </div>
          );
        }
      })}

      <div
        className="d-flex flex-column justify-content-between align-items-left mb-3"
        style={{
          width: "100%",
        }}
      >
        <Form.Check
          checked={listConfig.showOtherTab}
          onChange={() => setShowOtherTab(!listConfig.showOtherTab)}
          label={'Display "Other" Tab'}
        />
        <Form.Text>
          The "Other" Tab displays records that don't match the other tabs that
          you have configured.
        </Form.Text>
      </div>

      <div
        className="d-flex flex-column justify-content-between align-items-left mb-3"
        style={{
          width: "100%",
        }}
      >
        <Form.Check
          checked={listConfig.showDupTab}
          onChange={() => setShowDupTab(!listConfig.showDupTab)}
          label={'Display "Duplicates" Tab'}
        />
        <Form.Text>
          The "Duplicates" Tab displays all the records that have a duplicate
          phone number, email, or mailing address.
        </Form.Text>
      </div>
      <div>
        <div className="d-flex justify-content-between">
          <p className="lead">Configure from template</p>
          <div>
            <Button
              variant="warning"
              onClick={() => setDisplayAddTemplateModal(true)}
            >
              Save as Template
            </Button>
            <Modal
              show={displaySaveTemplateModal}
              onHide={() => setDisplayAddTemplateModal(false)}
            >
              <Modal.Body>
                <Form>
                  <Form.Group>
                    <Form.Label>Template Name</Form.Label>
                    <Form.Control
                      size="lg"
                      placeholder="Type template name here"
                      type="text"
                      value={templateName}
                      onChange={(e) => {
                        setTemplateName(e.target.value);
                      }}
                    />
                  </Form.Group>
                </Form>
              </Modal.Body>
              <Modal.Footer className="d-flex justify-content-between">
                <Button
                  variant="light"
                  className="pull-left"
                  onClick={() => setDisplayAddTemplateModal(false)}
                >
                  Close
                </Button>
                <Button
                  variant="primary"
                  className="pull-right"
                  onClick={async () => {
                    try {
                      await db
                        .collection("crm")
                        .doc(userDoc.uid)
                        .collection("templates")
                        .add({
                          templateName,
                          type: "tabbedList",
                          data: listConfig,
                          timestamp: new Date(),
                        });
                      handleAlerts(
                        "",
                        "Tab configuration saved as template",
                        "success"
                      );
                    } catch (err) {
                      console.log("Error saving template: ", err);
                      handleAlerts(
                        "",
                        "Uh oh, the tab configuration was not saved",
                        "warning"
                      );
                    }
                  }}
                >
                  Save Template
                </Button>
              </Modal.Footer>
            </Modal>
          </div>
        </div>

        <Form.Group>
          <Form.Label>Select template</Form.Label>
          <Form.Control
            as="select"
            onChange={(e) => {
              let s = templates.find((t) => t.templateName === e.target.value);
              console.log("s: ", s);
              setSelectedTemplate(s);
            }}
            value={selectedTemplate ? selectedTemplate.templateName : ""}
          >
            <option value="">Select a template</option>
            {templates.map((t, i) => {
              return (
                <option key={`template_o_${i}`} value={t.templateName}>
                  {t.templateName}
                </option>
              );
            })}
          </Form.Control>
        </Form.Group>
        <Button
          variant="secondary"
          onClick={() => {
            if (
              window.confirm(
                "Are you sure you'd like to over ride your current settings with this template? This cannot be undone."
              )
            ) {
              listConfig._docRef.update({
                ...selectedTemplate.data,
              });
            }
          }}
        >
          Set template
        </Button>
      </div>
    </div>
  );
};

class ManageTab extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      newTabs: [...this.props.listConfig.tabs],
      tabOrder: this.props.listConfig.tabs.indexOf(this.props.tab),
      tabTitle: this.props.tab.tabTitle,
      displayModal: false,
      newStatus: "",
    };
  }

  componentDidUpdate(prevProps) {
    let propsUpdated = !object_equals(prevProps, this.props);
    if (propsUpdated ) {
      let {listConfig, tab} = this.props;
      if (listConfig ) {
        let so = {};
        let update = false;
        listConfig.listStatus.map((k) => {
          let checked = tab.fieldValue.includes(k);
          so[k] = checked;
        });
        this.setState(so);
      }
    }

  }

  render() {
    //console.log("ManageTab this.state: ", this.state, "this.props: ", this.props);
    const { tabs, handleTabs, tab, listConfig } = this.props;
    const { newStatus, displayModal } = this.state;
    if (displayModal) {
      console.log("ManageTab tab: ", tab);
    }
    return (
      <React.Fragment>
        <Button
          className="ms-2"
          variant="outline-dark"
          size="sm"
          onClick={() => this.setState({ displayModal: true })}
        >
          <small>Edit</small>
        </Button>
        {displayModal &&
          <Modal
            show={this.state.displayModal}
            onHide={() => this.setState({ displayModal: false })}
          >
            <Modal.Body>
              <Form>
                <Form.Group>
                  <Form.Label>Change tab title</Form.Label>
                  <Form.Control
                    type="text"
                    value={this.state.tabTitle}
                    onChange={(e) => {
                      this.setState({ tabTitle: e.target.value });
                    }}
                  />
                </Form.Group>
                <Form.Group>
                  <Form.Label>Change displayed values</Form.Label>

                  {listConfig && listConfig.listStatus.map((k, i) => {
                    return (
                      <Form.Check
                        key={`check_${tab.tabTitle}+${i}`}
                        type="checkbox"
                        label={k}
                        checked={this.state[k]}
                        onChange={() => this.setState({ [k]: !this.state[k] })}
                      />
                    );
                  })}
                  <AddListStatus listConfig={listConfig} inline={true} />
                </Form.Group>
                <Form.Group>
                  <Form.Label>Change Tab Order</Form.Label>
                  <Form.Control
                    as="select"
                    value={this.state.tabOrder}
                    onChange={(e) => {
                      e.preventDefault();
                      let { newTabs } = this.state;
                      let so = e.target.value;
                      let rt = newTabs.filter(tab => tab.tabTitle !== this.props.tab.tabTitle);
                      let nta = [
                        ...rt.slice(0, so),
                        { ...this.props.tab },
                        ...rt.slice(so),
                      ];
                      console.log(
                        "tabs: ", this.props.tabs,
                        "rt: ", rt,
                        "nta: ", nta,
                        "so: ", so
                      )
                      this.setState({
                        tabOrder: so,
                        newTabs: nta,
                      });
                    }}
                  >
                    <option>Select Order</option>
                    {tabs.map((t, i) => {
                      return (
                        <option key={`select_order_to_${i}`} value={i}>
                          {i + 1}
                        </option>
                      );
                    })}
                  </Form.Control>
                </Form.Group>
                <Button
                  size="sm"
                  className="pull-left"
                  variant="danger"
                  onClick={() => {
                    if (
                      window.confirm(
                        `Are you sure you'd like to delete the tab called ${tab.tabTitle}? This cannot be undone.`
                      )
                    ) {
                      listConfig._docRef.update({
                        tabs: [...tabs].filter(
                          (t) =>
                            !["New", "Other", "All", tab.tabTitle].includes(
                              t.tabTitle
                            )
                        ),
                      });
                    }
                  }}
                >
                  <small>Delete</small>
                </Button>
                <Button
                  className="pull-right"
                  variant="primary"
                  onClick={() => {
                    let newTab = { ...tab };
                    let newFieldValue = [];
                    this.props.listConfig.listStatus.map((k) => {
                      if (this.state[k]) {
                        console.log("Adding: ", k);
                        newFieldValue.push(k);
                      }
                    });
                    newTab.fieldValue = newFieldValue;
                    newTab.tabTitle = this.state.tabTitle;
                    let newTabs = [];
                    this.state.newTabs.map(nt => {
                      if (nt.tabTitle === newTab.tabTitle) {
                        newTabs.push(newTab);
                      } else {
                        newTabs.push(nt);
                      }
                    })

                    //handleTabs(newTabs);
                    // remove other and new tabs before saving
                    console.log("newTabs: ", newTabs);
                    let savedTabs = newTabs.filter(
                      (t) => !["Other", "New", "All"].includes(t.tabTitle)
                    );
                    console.log("savedTabs: ", savedTabs);
                    listConfig._docRef.update({
                      tabs: savedTabs,
                    });
                    this.setState({ displayModal:false });
                  }}
                >
                  Update Tab Settings
                </Button>
              </Form>
            </Modal.Body>
          </Modal>
        }
      </React.Fragment>
    );
  }
}

const Lists = (props) => {
  const { userDoc, user, handleState, createList } = props;

  useEffect(() => {
    handleState({ isApp: true });
  }, []);
  return (
    <React.Fragment>
      <Collection
        colRef={db.collection("crm").doc(userDoc.id)}
        collection="lists"
        permissions={["read", "write", "delete"]}
        where={[{ field: "archived", operand: "!=", term: true }]}
        orderBy={[{ field: "archived" }, { field: "_created" }]}
      >
        <CollectionContext.Consumer>
          {(context) => {
            const { docs, updateDoc, displayDate } = context;
            let favoriteLists = [];
            let allLists = [];
            docs.map((list, i) => {
              const {
                listName,
                description,
                _created,
                _lastChanged,
                favorite,
                themeColor,
                _docId,
              } = list;
              console.log("list: ", list);
              let card = (
                <Col>
                  <Card
                    key={`list_${i}`}
                    bg={themeColor ? themeColor : "light"}
                    style={{ height: "165px" }}
                    className={`h-100
                      ${themeColor
                        ? themeColor === "light"
                          ? "h-100"
                          : "h-100 text-white"
                        : "h-100"
                      }
                    `}
                  >
                    <Card.Body
                      className="d-flex flex-column justify-content-between"
                      style={{ height: "165px" }}
                    >
                      <FavoriteList
                        size={30}
                        className={
                          themeColor
                            ? themeColor === "dark"
                              ? "text-white"
                              : "text-dark"
                            : "text-dark"
                        }
                        style={{
                          position: "absolute",
                          right: "-10px",
                          top: "0px",
                        }}
                        listConfig={list}
                      />
                      <Card.Title>
                        <Link
                          className={
                            themeColor === "dark" ? "text-white" : "text-dark"
                          }
                          to={`/supercrm/${_docId}`}
                        >
                          {listName}
                        </Link>
                      </Card.Title>
                      <Card.Text
                        style={{
                          maxHeight: "48px",
                          overflow: "hidden",
                        }}
                      >
                        {description}
                      </Card.Text>
                      <Card.Text>
                        <small>
                          {_lastChanged
                            ? `Updated ${displayDate(_lastChanged)}`
                            : ""}
                        </small>
                      </Card.Text>
                    </Card.Body>
                  </Card>
                </Col>
              );
              if (favorite) {
                favoriteLists.push(card);
              }
              return allLists.push(card);
            });
            return (
              <Container>
                <Row>
                  <Col>
                    <h5 className="mt-2">
                      <BookmarkStarFill className="me-2" />
                      Favorite lists
                    </h5>
                    {!favoriteLists.length && (
                      <div
                        className="d-block bg-white border rounded mb-5 p-3 pb-5"
                        style={{
                          height: "100px",
                        }}
                      >
                        <p className="text-muted">
                          <small>
                            Add lists to your favorites by clicking the bookmark
                            on a list below.
                          </small>
                        </p>
                      </div>
                    )}
                    {favoriteLists.length > 0 && (
                      <Row xs={1} sm={2} md={3} className="pb-5">
                        {favoriteLists}
                      </Row>
                    )}

                    <h5>All lists</h5>
                    <Row xs={1} sm={2} md={3}>
                      {allLists}
                      <ManageList
                        {...props}
                        currentLists={docs}
                        editList={false}
                      >
                        <Card
                          bg="white"
                          style={{
                            minHeight: "165px",
                          }}
                        >
                          <div
                            style={{ height: "160px" }}
                            className="text-center text-muted d-flex flex-column justify-content-center align-items-center"
                          >
                            <span>Create new list</span>
                          </div>
                        </Card>
                      </ManageList>
                    </Row>
                  </Col>
                </Row>
              </Container>
            );
          }}
        </CollectionContext.Consumer>
      </Collection>
    </React.Fragment>
  );
};

const fetchTabs = async (params, callback) => {
  console.log("running fetchTabs.");
  const {userDoc, listid} = params;
  try {
    return await db
      .collection("crm")
      .doc(userDoc.uid)
      .collection("lists")
      .doc(listid)
      .onSnapshot((doc) => {
        if (doc.exists) {
          let configDoc = doc.data();
          configDoc._docRef = doc.ref;
          configDoc._docId = doc.id;
          if (callback) {
            callback(null, configDoc);
          }
          console.log("configDoc: ", configDoc);
          return configDoc;
        }
      });
  } catch (err) {
    console.log("Error fetching tabs: ", err);
    if (callback) {
      callback(err);
    }
    throw err;
  }
}

const ListView = (props) => {
  let {
    userDoc,
    Phone,
    Email,
    Addr,
    selectedData,
    handleCrmState,
    toggleSelected,
    listField
  } = props;
  let { listid } = useParams();
  const [listConfig, setListConfig] = useState(null);
  useEffect(() => {
    db.collection("crm")
      .doc(userDoc.id)
      .collection("lists")
      .doc(listid)
      .onSnapshot((doc) => {
        if (doc.exists) {
          let listConfig = doc.data();
          listConfig._docId = doc.id;
          listConfig._docRef = doc.ref;
          console.log("listConfig: ", listConfig);
          setListConfig(listConfig);
        }
      });
  }, [listid]);

  const [listStatus, setListStatus] = useState(null);
  let where = [
    { field: "aid", operand: "==", term: userDoc.id },
    { field: "list", operand: "array-contains", term: listid },
    //{ field: "archived", operand: "!=", term: true }
  ];
  console.log("listStatus: ", listStatus);
  if (listStatus !== null) {
    where.push({field: `listStatus.${listid}`, operand: "in", term: listStatus});
  }
  console.log("where: ", where);
  if (typeof listid !== "undefined") {
    return (
      <React.Fragment>
        <CRM
          {...props}
          title={null}
          table
          managed
          listField={ listField ? listField : "listStatus"}
          list={listid}
          listConfig={listConfig}
          fetchTabs={fetchTabs}
          colRef={db}
          collection="data"
          permissions={["read", "write", "delete"]}
          where={where}
          orderBy={null}
          limit={100}
          onSelectTab={(tabTitle) => {
            if (tabTitle === "add_tab") {
              return true
            }
            let tabObject = listConfig.tabs.find(t => t.tabTitle === tabTitle);
            if (!["New", "All", "Other", "Duplicates"].includes(tabTitle)) {
              console.log("tabObject.fieldValue: ", tabObject.fieldValue);
              setListStatus(tabObject.fieldValue);
            } else {
              setListStatus(null);
            }
          }}
          header={(docs, tab, dupDocIds) => {
            return (
              <tr>
                <th className="d-flex align-items-center justify-content-start">
                  {selectedData && (
                    <Button
                      variant="link"
                      onClick={() => {
                        console.log(
                          "clicked tab: ",
                          tab,
                          "dupDocIds: ",
                          dupDocIds
                        );
                        if (selectedData.length > 0) {
                          handleCrmState({ selectedData: [] });
                        } else {
                          let allDocIds = [];
                          docs.map((d) => {
                            let docListStatus = d.listStatus
                              ? d.listStatus[listid]
                                ? d.listStatus[listid]
                                : "undefined"
                              : "undefined";
                            if (typeof tab.fieldValue === "function") {
                              //console.log("docListStatus: ", docListStatus)
                              if (tab.tabTitle === "Duplicates") {
                                if (
                                  dupDocIds.find((docId) => docId === d._docId)
                                ) {
                                  return allDocIds.push(d._docId);
                                }
                              }
                              if (tab.fieldValue(docListStatus)) {
                                allDocIds.push(d._docId);
                              }
                            }
                            if (Array.isArray(tab.fieldValue)) {
                              if (tab.fieldValue.includes(docListStatus)) {
                                allDocIds.push(d._docId);
                              }
                            }
                            if (
                              ["string", "number"].includes(
                                typeof tab.fieldValue
                              )
                            ) {
                              if (`${tab.fieldValue}` === docListStatus) {
                                allDocIds.push(d._docId);
                              }
                            }
                          });
                          handleCrmState({ selectedData: allDocIds });
                        }
                      }}
                    >
                      {selectedData.length === docs.length &&
                        selectedData.length > 0 && (
                          <>
                            <CheckCircleFill size={30} />
                            <span className="ms-1">{selectedData.length}</span>
                          </>
                        )}
                      {selectedData.length === 0 && <Circle size={30} />}
                      {selectedData.length > 0 &&
                        selectedData.length < docs.length && (
                          <>
                            <CircleFill size={30} />
                            <span className="ms-1">{selectedData.length}</span>
                          </>
                        )}
                    </Button>
                  )}
                </th>
                {listConfig &&
                  listConfig.displayFields.map( f => {
                    return(
                      <th key={`dth_${f}`}>{f}</th>
                    )
                  })
                }
                <th>
                  <DisplayedFieldsModal {...props}
                    listConfig={listConfig}
                    variant="link"
                  >
                    <Plus size={20} />
                  </DisplayedFieldsModal>

                </th>
              </tr>
            );
          }}
          child={({ data, index, listConfig, dups }) => {
            //console.log("data: ", data);
            let selected = selectedData.includes(data._docId);
            return (
              <tr key={`row_${index}_${data._docId}`}>
                <td>
                  <Button
                    variant="link"
                    onClick={() => toggleSelected(data._docId)}
                  >
                    {!selected && <Circle size={30} />}
                    {selected && <CheckCircleFill size={30} />}
                  </Button>
                </td>
                {listConfig &&
                  listConfig.displayFields.map( f => {
                    if ( f === "PHONE") {
                      return(
                        <td key={`dtd_${f}`}><Phone phone={data.data.PHONE} dups={dups.PHONE} /></td>
                      )
                    }
                    if ( f === "ADDR") {
                      return(
                        <td key={`dtd_${f}`}><Addr addr={data.data.ADDR} dups={dups.ADDR} /></td>
                      )
                    }
                    if ( f === "EMAIL") {
                      return(
                        <td key={`dtd_${f}`}><Email email={data.data.EMAIL} dups={dups.EMAIL} /></td>
                      )
                    }
                    return(
                      <td key={`dtd_${f}`}>{data.data[f]}</td>
                    )
                  })
                }
                <td>
                  <Link
                    className={`btn btn-sm btn-outline-${listConfig ? listConfig.themeColor : "primary"}`}
                    to={`/record/${data.id}`}>
                    <small>View</small>
                  </Link>
                </td>
                {/*<td>
                  {data.id ? data.id : ""}{" "}
                  {data.data.FN ? data.data.FN : ""}{" "}
                  {data.data.LN ? data.data.LN : ""}
                  <br />
                  <Addr addr={data.data.ADDR} />
                  <Phone phone={data.data.PHONE} dups={dups.PHONE} />
                  <Email email={data.data.EMAIL} dups={dups.EMAIL} />
                </td>
                <td>
                  <Form.Group as={Row}>
                    <Col xs="auto">
                      <Form.Control
                        as="select"
                        size="sm"
                        value={
                          data.listStatus ? data.listStatus[listid] : "new"
                        }
                        onChange={async (e) => {
                          let ns = e.target.value;
                          await data._docRef.update({
                            listStatus: { [listid]: ns },
                          });
                        }}
                      >
                        <option value="new">New</option>
                        {listConfig &&
                          listConfig.listStatus.map((s, i) => {
                            return (
                              <option key={`${i}_lso`} value={s}>
                                {s}
                              </option>
                            );
                          })}
                      </Form.Control>
                    </Col>
                  </Form.Group>
                </td>
                */}
              </tr>
            );
          }}
          emptyChild={() => {
            return (
              <tr>
                <td colSpan="4">No items match this list</td>
              </tr>
            );
          }}
        />
      </React.Fragment>
    );
  }

  return <NotFound />;
};

export { CRM, TabbedLists, addToCrm, withCrm, ManageList, Lists, ListView, fetchTabs, transformHeader, reservedFields, mapImport, CsvPreview, contactMethods, formatImport };
