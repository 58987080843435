import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import {
  Container,
  Row,
  Col,
  Button,
  Form,
  Card,
  ButtonGroup,
  OverlayTrigger,
  Popover
} from "react-bootstrap";
import { db, functions, getDoc, listenDoc, firebase } from "./firebase.jsx";
import Spinner from "react-bootstrap/Spinner";
import DownloadProgress from "./downloadProgress.jsx";
import { santizeFileName } from "./interfaceListShackPro.jsx";
import { DisplayDuplicateDownloads, suppressSearchQuery } from "./searchSuppressions.jsx";
import DownloadPane from "./downloadPane.jsx";
import { queryUrlToDslQuery, addDownloadedByToIndex, createList } from "./elasticlists.jsx";

class Download extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isDuplicate: false,
      suppressSearch: this.props.userplan.searchSuppressions ? this.props.userplan.searchSuppressions : this.props.userDoc.searchSuppressions ? this.props.userDoc.searchSuppressions : false,
      duplicateDownloads: null,
      isDownloading: false, // show spinner while waiting for download link for your new shiny file
      downloadCount: 0,
      searchName: "",
      displayCol: this.props.dataDict.displayCol ? this.props.dataDict.displayCol : this.props.dataDict.dlCols.length,
      download: null,
      isBigDownload: false, // So browser connections don't time out before the search results are received.
      downloadDoc: null,
      leadsMultiplier: this.props.dataDict.leadsMultiplier
    }
    // Set state for the download columns
    this.props.dataDict.dlCols.map( field => this.state[`dl_${field.value}`] = field.default ? true : false);

    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleDlCols = this.handleDlCols.bind(this);
  }

  handleInputChange(e) {
    const target = e.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;
    //console.log("value: ", value, "name: ", name);
    this.setState({
      [name]: value
    });
  }
  // Set Search State and Download state to ensure the user gets the right column back.
  handleDlCols(e) {
    const {handleSearchState, queryFields} = this.props
    const target = e.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;
    //console.log("value: ", value, "name: ", name, "cState[name]", value);
    let cState = { ...queryFields };
    cState[name] = value;
    let dl_name = `dl_${name}`;
    this.setState({
      [dl_name]: value
    });
    handleSearchState({
      queryFields: cState
    })
  }

  // Allow the user to download leads
  async downloadList(e) {
    e.preventDefault();
    let {
      searchName,
      downloadCount,
      suppressSearch,
      isDuplicate,
      duplicateDownloads
    } = this.state;
    let { dataDict, userDoc, handleAlerts, createUrl, geographyParams, searchParams, user } = this.props;
    let aid = userDoc.uid;

    //console.log("searchName: ", searchName, "Count: ", count);
    if (searchName === "") {
      this.props.handleAlerts(
        e,
        "Please name your search to continue.",
        "danger"
      );
      return;
    }
    if (!downloadCount) {
      handleAlerts(
        e,
        "Please include the number of leads you would like to download.",
        "danger"
      );
      return;
    }
    if (downloadCount > userDoc.downloadLimit) {
      handleAlerts(
        e,
        <React.Fragment>
          Uh oh, that's more leads than you current download limit. Reduce the
          number of leads or{" "}
          <Link to="/settings/billing" className="alert-link">
            upgrade your account
          </Link>
          .
        </React.Fragment>,
        "danger"
      );
      return;
    }

    if (
      downloadCount * dataDict.leadsMultiplier >
      userDoc.leadsRemaining
    ) {
      handleAlerts(
        e,
        <React.Fragment>
          Uh oh, that's more leads than you have available this month. Reduce
          the number of leads to less than{" "}
          {this.props.userDoc.leadsRemaining.toLocaleString()} or{" "}
          <Link to="/settings/billing" className="alert-link">
            upgrade your account
          </Link>
          .
        </React.Fragment>,
        "danger"
      );
      return;
    }

    if (
      suppressSearch &&
      isDuplicate &&
      downloadCount > duplicateDownloads.nonDupeCount
    ) {
      handleAlerts(
        e,
        <React.Fragment>
          Uh oh, that's more leads than we have available. Reduce the number of
          leads to less than {duplicateDownloads.nonDupeCount.toLocaleString()},
          uncheck "Suppress duplicates", or change your search.
        </React.Fragment>,
        "danger"
      );
      return;
    }

    let query = await createUrl(null, false, true);
    await console.log("Downloading list for: ", query);
    let leadsMultiplier =
      typeof dataDict.leadsMultiplier === "undefined"
        ? 1
        : typeof this.state.leadsMultiplier === "number" ? this.state.leadsMultiplier : dataDict.leadsMultiplier;
    if (isDuplicate && suppressSearch) {
      console.log(
        "Suppressing the this search by sending this with an offset: ",
        duplicateDownloads.maxOffset
      );
      query.offset = duplicateDownloads.maxOffset;
    }
    console.log("leadsMultiplier: ", leadsMultiplier, "query: ", query);
    let fileName = santizeFileName(searchName);
    let userDocId = userDoc._docId;
    const downloadParams = await {
      searchName: searchName,
      fileName,
      timestamp: new Date(),
      downloadCount: parseInt(downloadCount),
      leadsRemaining: userDoc.leadsRemaining,
      leadsMultiplier: leadsMultiplier,
      queryUrl: query,
      geographyParams: geographyParams,
      searchParams: searchParams,
      email: user.email,
      uid: user.uid,
      aid,
      userDocId
    };

    const downloadCsv = functions.httpsCallable("downloadCsv");

    // create a document to keep track of the download information
    let newDoc = await db
      .collection("downloads")
      .doc(aid)
      .collection("files")
      .add(downloadParams);

    let downloadDoc = await newDoc.data();
    downloadDoc._docId = newDoc.id;
    downloadDoc._docRef = newDoc.ref;

    await this.setState({ downloadDoc });

    let csvParams = {
      doc_id: downloadDoc._docId,
      downloadCount: parseInt(downloadCount),
      leadsRemaining: userDoc.leadsRemaining,
      queryUrl: query,
      searchName,
      fileName,
      leadsMultiplier,
      aid,
      userDocId
    };

    try {
      this.setState({
        isBigDownload: downloadCount <= 10000 ? false : true,
        isDownloading: true
      });

      let csvFileUrl;
      if (downloadCount <= 1000000) {
        // Setting this really big so that it always runs temp hack
        let callFunction = await downloadCsv(csvParams);
        csvFileUrl = await callFunction.data;
        console.log("Download csv from: ", csvFileUrl);

        return await this.setState({
          download: csvFileUrl,
          isDownloading: false
        });
      } else {
        return downloadCsv(csvParams); // This is supposed to ensure that the front end doesn't wait for the file to finish, but it's still waiting and throwing errors.
      }
    } catch (error) {
      console.log("error.type: ", error.constructor.name);
      console.error(
        "Something went wrong with the cloud function call.",
        error
      );
      this.setState({
        isDownloading: false
      });
      // Log this error so we can check it out later
      db.collection("errors").add({
        uid: this.props.userDoc.uid || "",
        id: this.props.userDoc.id || "",
        email: this.props.userDoc.email || "",
        state: JSON.stringify(this.state),
        error: error.toString(),
        location: {
          host: window.location.host,
          href: window.location.href,
          origin: window.location.origin,
          pathname: window.location.pathname,
          port: window.location.port,
          protocol: window.location.protocol,
          search: window.location.search
        },
        device: window.navigator.userAgent,
        timeStamp: new Date(),
        type: "downloadList"
      });
      return handleAlerts(
        e,
        `Uh oh, something went wrong with the download:  ${error.message}. Try changing your search, downloading fewer leads, or sending an email to ${process.env.REACT_APP_contact_email}.`,
        "warning"
      );

      //else {
      //  if (error.constructor.name === "deadline-exceeded") {
      //    return
      //  } else {
      //    await this.setState({
      //      isDownloading: false,
      //    });
      //    return await this.props.handleAlerts(e,`Uh oh, something went wrong with the download:  ${error.message}. Try changing your search, downloading fewer leads, or sending an email to ${process.env.REACT_APP_contact_email}.`, "warning");
      //  }
      //}
    }
  }

  render() {
    //console.log("Download this.props: ", this.props, "Download this.state: ", this.state);
    const { suppressSearch, isDuplicate, duplicateDownloads, isDownloading, download, isBigDownload, downloadDoc, searchName, downloadCount} = this.state;
    const {userDoc, handleAlerts, dataDict, apiKey,handleState, geographyParams, searchParams, createUrl, user,fetchFromApi} = this.props;
    const { dlCols, leadsMultiplier } = dataDict;
    let downloadHelperText =
      userDoc.leadsRemaining * leadsMultiplier < userDoc.downloadLimit
        ? `You have ${userDoc.leadsRemaining.toLocaleString()} leads remaining this month.`
        : `Your can download up to ${userDoc.downloadLimit.toLocaleString()} leads at a time.`;
    if (leadsMultiplier === 0) {
      downloadHelperText = `These leads won't count toward your monthly limit!  You can download up to ${userDoc.downloadLimit.toLocaleString()} leads at a time.`;
    }
    if (suppressSearch && isDuplicate) {
      downloadHelperText =
        duplicateDownloads.nonDupeCount <
          userDoc.leadsRemaining * leadsMultiplier &&
        duplicateDownloads.nonDupeCount < userDoc.downloadLimit
          ? `You can download up to ${duplicateDownloads.nonDupeCount} unique leads. Un-check Suppress duplicates if you would like to download more leads.`
          : downloadHelperText;
    }

    return(
      <Container>
        {!isDownloading && !download && (
          <Row>
            <Col xs={12} className="text-start">
              <h3>Checkout.</h3>
              <p>Save your search and download leads.</p>
            </Col>
            <Col xs={12} className="text-start">
              <Form>
                <Form.Group>
                  <Form.Label>Search name*</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Name your search"
                    name="searchName"
                    onChange={this.handleInputChange}
                    value={searchName}
                  />
                </Form.Group>

                <Form.Group>
                  <Form.Label>
                    How many leads would you like?*
                  </Form.Label>
                  <Form.Control
                    type="number"
                    placeholder="Enter number of leads"
                    name="downloadCount"
                    onChange={e => this.setState({ downloadCount: parseInt(e.target.value) })}
                    value={downloadCount ? downloadCount : 0}
                  />
                  <Form.Text className="text-muted">
                    {downloadHelperText}
                  </Form.Text>
                </Form.Group>
              </Form>
            </Col>
            <Col xs={12} className="text-start mb-4">
              <DisplayDuplicateDownloads
                {...this.props}
                handleUpgrade={() => handleState({displayCheckoutModal: true})}
                dupesCallback={(error, dd) => {
                  if(dd) {
                    this.setState({
                      isDuplicate: dd.items.length > 0 ? true : false,
                      duplicateDownloads: dd
                    })
                  }
                }}
                suppressSearchCallback={(bool) => {
                  this.setState({suppressSearch: bool});
                }}
                allowToggle={true}
                tableVisible={false}
              />
            </Col>
            <Col xs={12} className="text-start">
              <Form>
                {/* Dry this out */}

                <Form.Group as={Row}>
                  <Form.Label column xs="12">
                    Select columns*
                  </Form.Label>
                  {dlCols
                    .slice(0, this.state.displayCol)
                    .map(opt => (
                      <Col
                        key={`col-${opt.value}`}
                        className="mb-1"
                        xs="6"
                      >
                        <Form.Check
                          type="checkbox"
                          name={opt.value}
                          label={opt.name}
                          key={"col-" + opt.value}
                          onChange={this.handleDlCols}
                          checked={
                            this.state[`dl_${opt.value}`]
                          }
                        />
                      </Col>
                    ))}
                </Form.Group>
                <small className="text-muted">
                  Check the columns to included in your
                  CSV download. Downloads will be
                  available as long as your account
                  remains active.
                </small>
              </Form>
              {(this.state.displayCol <
                dataDict["dlCols"].length && this.props.dataDict.allowUpgrade) && (
                <div className="my-3 p-2 d-block bg-light">
                  <h6>Would you like to append additional columns to your list?</h6>
                  <p><small>
                    There are additonal columns that can be appended to your list:
                  </small></p>
                  <ul>
                    {[...dlCols].splice(this.state.displayCol).map(opt => (
                      <li key={`col-add-${opt.value}`}>{opt.name}</li>
                    ))}
                  </ul>
                  <p><small>Adding these columns will deduct an additional {this.state.downloadCount.toLocaleString()} leads from your account.</small></p>
                  <Button
                    variant="outline-primary"
                    size="sm"
                    className=""
                    onClick={() =>
                      this.setState({
                        displayCol: dataDict["dlCols"].length,
                        leadsMultiplier: dataDict.leadsMultiplier * 2
                      })
                    }
                    disabled={
                      this.props.userDoc.moreCols ? this.props.userDoc.moreCols : false
                    }
                  >
                    Yes, Append more Columns to my List
                  </Button>
                </div>
              )}
              {(this.props.dataDict.displayCol < this.props.dataDict.dlCols.length && this.state.displayCol === this.props.dataDict.dlCols.length) && (
                <div className="my-3 p-2 d-block bg-light">
                  <h6>You are appending additional columns to your list</h6>
                  <p><small>The total leads deducted from your account for this list will be {(this.state.downloadCount * this.state.leadsMultiplier).toLocaleString()}</small></p>
                  <Button 
                    variant="outline-danger" 
                    size="sm" 
                    onClick={() => this.setState({displayCol: dataDict.displayCol, leadsMultiplier: dataDict.leadsMultiplier})}
                  >
                    Nevermind, don't append additional columns to my list
                  </Button>
                </div>
              )}
              <ButtonGroup
                className="mt-3 pull-right"
              >
                <OverlayTrigger 
                    trigger="hover" 
                    placement="left" 
                    defaultShow={true}
                    overlay={
                      <Popover 
                        id="createCSVPop"
                      >
                        <Popover.Body>
                          <b>Create CSV</b> will create a comma separated values (CSV) file that you can download. You'll need to open the file with a spreadsheet program like Excel or Google Sheets.
                        </Popover.Body>
                      </Popover>
                    }
                  >
                <Button
                  variant={dataDict.reindex ? "outline-success" : "success"}
                  size="lg"
                  onClick={ async e => {
                    e.preventDefault();
                    let {downloadLimit, leadsRemaining, email} = userDoc;
                    let aid = userDoc.id;
                    let userDocId = userDoc._docId;
                    let uid = user.uid;
                    let leadsMultiplier = dataDict.leadsMultiplier;
                    try {
                      this.setState({
                        isDownloading: true,
                        isBigDownload: downloadCount <= 10000 ? false : true,
                      });
                      let queryUrl = await createUrl(null, false, true);
                      if (isDuplicate && suppressSearch) {
                        queryUrl = suppressSearchQuery({queryUrl, duplicateDownloads, aid});
                      }
                      //console.log("queryUrl: ", queryUrl);
                      let downloadDocId = await createDownloadDoc({
                        searchName,
                        downloadCount,
                        suppressSearch,
                        isDuplicate,
                        duplicateDownloads,
                        downloadLimit,
                        leadsRemaining,
                        leadsMultiplier,
                        queryUrl,
                        geographyParams,
                        searchParams,
                        email,
                        uid,
                        aid,
                        userDocId
                      }, (err, doc) => {
                        if (err) {
                          return
                        }
                        this.setState({downloadDoc: doc})
                      });
                      // If it's a big download, we need to listen for the document to finish being created
                      // So we can tell the user that it is done
                      if (isBigDownload) {
                        listenDoc({
                          ref: db.collection("downloads").doc(userDoc.id).collection("files").doc(downloadDocId)
                        }, (doc) => {

                          this.setState({
                            downloadDoc: doc,
                            download: doc.csvFile ? doc.csvFile : ""
                          })
                        })
                      }
                      
                      //return console.log("downloadDocId: ", downloadDocId);
                      let csvFileUrl = await createCsvFile(
                        downloadDocId,
                        downloadCount,
                        leadsRemaining,
                        queryUrl,
                        searchName,
                        santizeFileName(searchName),
                        dataDict.leadsMultiplier,
                        aid,
                        userDocId
                      );
                      return this.setState({
                        download: csvFileUrl,
                        isDownloading: false
                      });
                    } catch(err) {
                      console.log("Error creating csv: ", err);
                      validateDownloadInputs(err,handleAlerts);
                    }
                  }}
                  disabled={this.state.isDownloading}
                >
                  {this.state.isDownloading
                    ? "Creating CSV"
                    : "Create CSV"}
                </Button>
                </OverlayTrigger>
                {dataDict.reindex &&
                  <OverlayTrigger 
                    trigger="hover" 
                    placement="right"
                    defaultShow={true} 
                    overlay={
                      <Popover id="createListPop">
                        <Popover.Body>
                          <b>Create list</b> will add your leads to ListCrm, where you can view each record, add notes, click to call, edit, and track your records interactively. You can download your list to CSV from ListCrm at any time as well.
                        </Popover.Body>
                      </Popover>
                    }
                  >
                  <Button
                    variant="success"
                    size="lg"
                    onClick={async e => {
                      try {
                        e.preventDefault();
                        let {downloadLimit, leadsRemaining, email} = userDoc;
                        let aid = userDoc.id;
                        let userDocId = userDoc._docId;
                        let uid = user.uid;
                        let leadsMultiplier = dataDict.leadsMultiplier;
                        this.setState({isDownloading: true});
                        let queryUrl = await createUrl(null, false, true);
                        if (isDuplicate && suppressSearch) {
                          queryUrl = suppressSearchQuery({queryUrl, duplicateDownloads, aid});
                        }
                        let dslQuery = await queryUrlToDslQuery({queryUrl, fetchFromApi});
                        console.log("queryUrl: ", queryUrl, "dslQuery: ", dslQuery);
                        let downloadDocId = await createDownloadDoc({
                          searchName,
                          downloadCount,
                          suppressSearch,
                          isDuplicate,
                          duplicateDownloads,
                          downloadLimit,
                          leadsRemaining,
                          leadsMultiplier,
                          queryUrl,
                          geographyParams,
                          searchParams,
                          email,
                          uid,
                          aid,
                          userDocId,
                          otherParams: {
                            dslQuery: JSON.stringify(dslQuery.body.query) // Firestore is tripping over dot notation used in elasticsearch field.keyword:value pairs
                          }
                        }, (err, downloadDoc) => {
                          if (err) {
                            return
                          }
                          this.setState({downloadDoc})
                        });
                        let index = `crm_${userDoc.id}`.toLowerCase();
                        let reindexFields = dlCols.slice(0, this.state.displayCol)
                          .map( col => {
                            if ( this.state[`dl_${col.value}`] ) {
                              return col.value
                            }
                          });
                        let includes = [];
                        reindexFields.forEach( f => {
                          if (!includes.includes(f)) {
                            includes.push(f);
                            if ( typeof dataDict.reindex._source[f] !== "undefined" ) {
                              includes = [...includes, ...dataDict.reindex._source[f]];
                            }
                          }
                        })
                        let reindexQuery = {
                          max_docs: downloadCount,
                          source: {
                            index: dataDict.index,
                            query: dslQuery.body.query,
                            _source: {
                              includes
                            }
                          },
                          dest: {
                            index
                          },
                          script: {
                            id: dataDict.reindex.script.id,
                            params: {
                              ACCOUNTID: userDoc.id,
                              LISTMAP: {
                                "all": "new"
                              },
                              CREATEJOBID: downloadDocId,
                              CREATEDBY: user.uid
                            }
                          }
                        }
                        let wait_for_completion = downloadCount > 10000 ? false : true;
                        return await createList({
                          fetchFromApi,
                          reindexQuery,
                          wait_for_completion,
                          refresh: true,
                          reindexFields: reindexFields.filter(f => !["APT", "CITY", "ST", "ZIP", "Z4", "AREA_CODE", "DOB_MON", "OPT_IN"].includes(f)),
                          createJobId: downloadDocId,
                          aid: userDoc.id,
                          uid: user.uid,
                          name: searchName,
                          themeColor: dataDict.themeColor ? dataDict.themeColor : "light",
                          sources: [
                            {type: "listshack_search", downloadDocId}
                          ]
                        }, async (error, result) => {
                          let dref = db.collection("downloads").doc(aid).collection("files").doc(downloadDocId);
                          if (error) {
                            dref.update({
                              error: JSON.stringify(error)
                            });
                            throw new Error(error);
                          }
                          if (result) {
                            console.log("createList result: ", result);
                            let {elasticlistDoc, task} = result;
                            // Reduce the users leadsRemaining
                            let leadsUsed = Math.round(downloadCount * dataDict.leadsMultiplier);
                            if(leadsUsed > 0){
                              let usersDocRef = db.collection("users").doc(userDoc._docId);
                              usersDocRef.update({
                                leadsRemaining: firebase.firestore.FieldValue.increment(-leadsUsed)
                              })
                            }
                            // Update the downloadDoc with the elasticlistDoc
                            
                            if (elasticlistDoc) {
                              dref.update({
                                elasticlist: elasticlistDoc._docId,
                                csvFile: `/list/${elasticlistDoc._docId}`,
                                reindexTaskId: task ? task : false
                              });
                              console.log(`Added elastic list id ${result.elasticlistDoc._docId} to download doc with id ${downloadDocId}.`);
                            }
                            // We have a race happening so we need to get the elastic refreshed before we do this. 
                            // Mark leads from the original index as downloaded
                            addDownloadedByToIndex({
                              index,
                              createJobId: downloadDocId,
                              accountId: userDoc.id,
                              fetchFromApi,
                              reindexTaskId: task ? task : false
                            });
                            this.setState({
                              isDownloading: false,
                              download: `/list/${result.elasticlistDoc._docId}`
                            });
                          }
                        });
                      } catch(err) {
                        console.log("Error CreateList: ", err);
                        validateDownloadInputs(err,handleAlerts);
                        this.setState({isDownloading: false});
                      }
                    }}
                  >
                    Create List
                  </Button>
                  </OverlayTrigger>
                }
              </ButtonGroup>
              
            </Col>
          </Row>
        )}
        {isDownloading && isBigDownload && downloadDoc && (
          <div
            style={{
              maxWidth: "500px",
              margin: "0 auto",
              marginTop: "30px",
              marginBottom: "30px"
            }}
          >
            <DownloadProgress
              searchName={this.state.searchName}
              doc_id={this.state.downloadDoc._docId}
              handleDownload={(obj) => this.setState(obj)}
              download={this.state.download}
              user={this.props.user}
              handleAlerts={this.props.handleAlerts}
            />
          </div>
        )}
        {isDownloading && (
          <Row>
            <Col xs={12} className="text-center">
              {!this.state.isBigDownload && (
                <React.Fragment>
                  <Spinner
                    variant="primary"
                    animation="grow"
                    style={{ margin: "30px" }}
                  />
                  <h4>
                    We're preparing your list. Hold
                    tight!
                  </h4>
                </React.Fragment>
              )}
              {this.state.isBigDownload && (
                <React.Fragment>
                  <h4>
                    Wow, that's a lot of leads! Give us a
                    few minutes to package them together
                    and we'll let you know when the list
                    is ready.
                  </h4>
                  <Button
                    variant="outline-primary"
                    onClick={this.resetQuery}
                  >
                    Start a new search
                  </Button>
                </React.Fragment>
              )}
            </Col>
          </Row>
        )}
        {!isDownloading && this.state.download && (
          <Row>
            <Col xs={12} className="text-center">
              <h3>Your list is ready!</h3>
              <DownloadPane {...this.props} 
                download={this.state.downloadDoc}
                disallowReportAndArchive={true}
                hideSearchPreview={true}
                showEmailButton={this.state.downloadDoc.dslQuery ? false : true}
                displayTaskProgress={true}
              />
              <br />
              <small className="text-muted">
                Find this download later and report
                problems with the file from the {" "}
                <Link to="/downloads">
                  downloads page
                </Link>
                .
              </small>
              <br />
              {this.state.dl_EMAIL === true && (
                <Card className="border-warning mt-5">
                  <Card.Body>
                    This list may contain compiled emails. Read how to use compiled emails <Link to="/support/use-compiled-emails" target="_blank" rel="noopener noreferrer">here</Link>.
                  </Card.Body>
                </Card>
              )}
            </Col>
          </Row>
        )}
      </Container>
    )
  }

}

Download.propTypes = {
  user: PropTypes.object.isRequired,
  handleSearchState: PropTypes.func.isRequired,
  userDoc: PropTypes.object.isRequired,
  handleAlerts: PropTypes.func.isRequired,
  setQueryFields: PropTypes.func.isRequired,
  dataDict: PropTypes.object.isRequired,
  userplan: PropTypes.object.isRequired,
  apiKey: PropTypes.string.isRequired,
  handleState: PropTypes.func.isRequired,
  currentSearch: PropTypes.object.isRequired,
  apiUrl: PropTypes.string.isRequired,
  createUrl: PropTypes.func.isRequired,
  geographyParams: PropTypes.object.isRequired,
  searchParams: PropTypes.object.isRequired
}

// Create a document in downloads to let the user and us track activity
const createDownloadDoc = async (params, callback) => {
  let {
    searchName,
    downloadCount,
    downloadLimit,
    suppressSearch,
    isDuplicate,
    duplicateDownloads,
    leadsMultiplier,
    leadsRemaining,
    queryUrl,
    geographyParams,
    searchParams,
    email,
    uid,
    aid,
    userDocId,
    otherParams
  } = params;
  try {
    // Validate require information for creating a download document
    if (!searchName) {
      throw new Error("Must include search name.")
    }
    if (!downloadCount) {
      throw new Error("Insufficent download count.");
    }
    if (downloadCount > downloadLimit) {
      throw new Error("Insufficient download limit.")
    }
    if (downloadCount * leadsMultiplier > leadsRemaining) {
      throw new Error("Insufficient leads remaining.")
    }
    if (
      suppressSearch && 
      isDuplicate && 
      downloadCount > duplicateDownloads.nonDupeCount
    ) {
      throw new Error("Insufficent leads available after suppression.")
    }

    console.log("Downloading list for: ", queryUrl);
  
    let fileName = santizeFileName(searchName);
    const downloadParams = {
      searchName: searchName,
      fileName,
      timestamp: new Date(),
      downloadCount: parseInt(downloadCount),
      leadsRemaining,
      leadsMultiplier,
      queryUrl,
      geographyParams,
      searchParams,
      email,
      uid,
      aid,
      userDocId,
      suppressSearch,
      ...otherParams
    };

    // create a document to keep track of the download information
    let response = await db
      .collection("downloads")
      .doc(aid)
      .collection("files")
      .add(downloadParams);

    let newDoc = await listenDoc({ ref: db.collection("downloads").doc(aid).collection("files").doc(response.id) }, callback);
    //console.log("newDoc: ", newDoc);
    // Tell the User about progress of the download
    //if (callback) { callback(null, newDoc)}
    return response.id;
  } catch(error) {
    console.log("Error createDownloadDoc: ", error);
    if (callback) {
      callback(error);
    }
    throw error;
  }
}

// Create the CSV File
const createCsvFile = async (
  downloadDocId,
  downloadCount,
  leadsRemaining,
  queryUrl,
  searchName,
  fileName,
  leadsMultiplier,
  aid,
  userDocId
) => {
  try {
    let createCsvInCloud = functions.httpsCallable("downloadCsv");
    // Setting this really big so that it always runs temp hack
    let response = await createCsvInCloud({
      doc_id: downloadDocId,
      downloadCount,
      leadsRemaining,
      queryUrl,
      searchName,
      fileName,
      leadsMultiplier,
      aid,
      userDocId
    });
    let csvFileUrl = response.data;
    console.log("Download csv from: ", response, csvFileUrl);
    return csvFileUrl;
  } catch(err) {
    console.log("Error createCsvFile: ", err);
    throw err;
  }
  
}

const validateDownloadInputs = (err, handleAlerts) => {
  switch(err.message) {
    case "Must include search name.": 
      handleAlerts("", "Please name your search to continue.", "warning");
      break;
    case "Insufficent download count.": 
      handleAlerts("", "Please include the number of leads you would like to download.", "warning");
      break;
    case "Insufficient download limit.": 
      handleAlerts("", 
      <React.Fragment>
        Uh oh, that's more leads than you current download limit. Reduce the
        number of leads or{" "}
        <Link to="/settings/billing" className="alert-link">
          upgrade your account
        </Link>
        .
      </React.Fragment>, 
        "warning"
      );
      break;
    case "Insufficient leads remaining.": 
      handleAlerts("",
        <React.Fragment>
          Uh oh, that's more leads than you have available this month. Reduce
          the number of leads to less than{" "}
          {this.props.userDoc.leadsRemaining.toLocaleString()} or{" "}
          <Link to="/settings/billing" className="alert-link">
            upgrade your account
          </Link>
          .
        </React.Fragment>,
        "warning"
      );
      break;
    case "Insufficent leads available after suppression.": 
      handleAlerts("",
        "Uh oh, we don't have that many leads available after we suppress the leads that you've already used. To continue, uncheck 'Suppress duplicates' or change your search.",
        "warning"
      );
      break;
  }
}

export { Download, createCsvFile, createDownloadDoc, validateDownloadInputs };
