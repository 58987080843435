import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import esb from "elastic-builder";
import { object_equals } from "./interfaceListShackPro";
import queryString from "query-string";
// searchtext
// searchtype
// searchsize
// searchfrom
// searchfilters
// searchfields


function withElasticSearch( WrappedComponent, props ) {
  return class extends React.Component {
    constructor(props) {
      super(props);
      const dicts = this.props.dicts;
      this.state = {
        //searchTypes: null, // Set on initialization
        //selectedRecord: "", // Pull out of this component
        //searchtext: "", // Pull out of this component, it will get passed in as a parameter in an EsQuery
        //searchfields: [], // Array of of objects shaped like {name, boost}
        searchsize: 10, // Number, number of results to get from ElasticSearch
        searchfrom: 0, // Number, offset number or where to start getting results
        searchmusts: [], // Array of esb.query object e.g. esb.
        searchfilters: [], // Array of esb.query objects e.g esb.rangeQuery
        searchmustnots: [], // Array of esb.query objects e.g. esb.termQuery
        searchshoulds: [], // Array of esb.query object e.g. esb.
        query: null,
        searchResults: null, // Set after a search is completed
        lastSearch: "", // Set after a search is completed
      };
      this.handleElasticSearchState = this.handleElasticSearchState.bind(this);
      this.stringToEsQuery = this.stringToEsQuery.bind(this);
      this.createSearchQuery = this.createSearchQuery.bind(this);
      this.fetchSearchResults = this.fetchSearchResults.bind(this);
    }
    handleElasticSearchState(obj) {
      this.setState(obj)
    }
    // This function allows you to convert a url encoded string to a query or to pass in the equivalent arguments to a query
    // Example query string
    // "multiMatch=>*hello*=>CO_NAME,OWNER"
    stringToEsQuery(type, ...args) {
      if (!args.length) {
        let nargs = type.split("=>");
        type = nargs.shift();
        args = nargs;
        if (!args.length) {
          return console.error("createEsQuery requires at least one argument.")
        }
      }
      let querybody;
      if (type === "multiMatch") {
        let searchtext = args[0];
        let searchfields = args[1];
        // Make sure that searchfields is an array
        if ( !Array.isArray(searchfields) && typeof searchfields === "string" ) {
          searchfields = searchfields.split(",")
        }
        querybody = esb.multiMatchQuery(
          searchfields,
          searchtext
        )
      }

      if (type === "rangeQuery") {
        //destructure argrument
        
      }
      if (type === "termQuery") {
        // destructure arguments
      }

      if (type === "existsQuery") {
        // destructure arguments
      }

      if (type === "boolQuery") {
        // Define arguments;
        let musts = args[0];
        let filters = args[1];
        let mustnots = args[2];
        let shoulds = args[3];
        let minShouldMatch = args[4];
        let boost = args[5];
        // Use explicit values or state to create the query
        let surchMusts = musts ? musts : this.state.searchmusts.length > 0 ? this.state.searchmusts : null;
        let surchFilters = filters ? filters : this.state.searchfilters.length > 0 ? this.state.searchfilters : null;
        let surchMustnots = mustnots ? mustnots : this.state.searchmustnots.length > 0 ? this.state.searchmustnots : null;
        let surchShoulds = shoulds ? shoulds : this.state.searchshoulds.length > 0 ? this.state.searchshoulds : null;
        querybody = esb.boolQuery();
        if (surchFilters) {
          console.log("surchFilters: ", surchFilters);
          querybody = querybody.filter(surchFilters);
        }
        if (surchMusts) {
          console.log("surchMusts: ", surchMusts);
          querybody = querybody.must(surchMusts)
        }
        if (surchMustnots) {
          querybody = querybody.mustnot(surchMustnots);
        }
        if (surchShoulds) {
          querybody = querybody.should(surchShoulds);
        }
        if (minShouldMatch) {
          querybody = querybody.minimumShouldMatch(minShouldMatch);
        }
        if (boost) {
          querybody = querybody.boost(boost);
        }
        return querybody;
      }
    }

    createSearchQuery(indexes, size, from, query=this.state.query, highlightfields) {
      let index = indexes ? indexes : this.props.dicts.map( d => d.index);
    
      let requestbody = esb.requestBodySearch();
      
      if (query) {
        requestbody = requestbody.query(query)
      }
      if (highlightfields) {
        requestbody = requestbody.highlight(esb.highlight(highlightfields));
      }
      requestbody = requestbody.toJSON();
      console.log("requestbody: ", requestbody);
      let body = {
        search: {
          index,
          size: size ? size : this.state.searchsize ? this.state.searchsize : 10,
          from: from ? from : this.state.searchfrom ? this.state.searchfrom : 0,
          body: requestbody
        }
      }
      return body;
    }

    async fetchSearchResults(body, callback=this.props.onFetchSearchResults) {
      console.log("body: ", body);
      const {fetchFromApi,handleAlerts} = this.props;
      let result = await fetchFromApi({
        endpoint: "/api/v2/search",
        body
      });
      console.log("result: ", result);
      if(result.status ? result.status === false : false) {
        handleAlerts("", result.status.error, "warning");
        throw new Error(result.status.error);
      }
      
      this.setState({
        searchResults: result,
        lastSearch: body
      });
      if (callback) {
        callback(null,result);
      }
      return result;
    }

    render() {
      console.log("withElasticSearch this.props: ", this.props, "this.state: ", this.state);
      let querystring = queryString.parse(window.location.search);
      return(
        <WrappedComponent
          {...this.props}
          {...this.state}
          stringToEsQuery={this.stringToEsQuery}
          createSearchQuery={this.createSearchQuery}
          fetchSearchResults={this.fetchSearchResults}
          handleElasticSearchState={this.handleElasticSearchState}
          querystring={querystring}
        />
      )
    }

    componentDidUpdate(prevprops) {
      // if the props have changed, let's look at updating state
      console.log("!object_equals(this.props, prevprops): ", !object_equals(this.props, prevprops))
      if(!object_equals(this.props, prevprops)) {
        // if the querystring is different, let's update state
        let newState = {...this.state};
        if (!object_equals(this.props.querystring, prevprops.querystring)) {
          Object.keys(this.props.querystring).forEach( k => {
            newState[k] = this.props.querystring[k];
          })
        }
        if (!object_equals(newState, this.state)) {
          this.setState(newState);
          this.createSearchQuery(null,null,null,this.stringToEsQuery("boolQuery",[esb.multiMatchQuery(["NAME"],newState.searchtext)]));
        }
      } else {
        // Otherwise let's display some new search results
        console.log("componentDidUpdate same prevprops!");
        //this.fetchSearchResults();
      }
    } 
  }
}

withElasticSearch.propTypes = {
  dicts: PropTypes.array.isRequired,
  fetchFromApi: PropTypes.func.isRequired,
  querystring: PropTypes.object
}
  
export default withElasticSearch;