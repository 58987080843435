import React, { Component } from "react";
import { Link } from "react-router-dom";
import ReactMapGL from "react-map-gl";
import {
  Card,
  Row,
  Col,
  Container
} from "react-bootstrap";
import "react-map-gl-geocoder/dist/mapbox-gl-geocoder.css";
import "mapbox-gl/dist/mapbox-gl.css";
import { Articles } from "./supportCenter.jsx";

const TOKEN = process.env.REACT_APP_mapbox;

class Maps extends Component {
  constructor(props) {
    super(props);
    this.state = {
      viewport: {
        latitude: 37.785164,
        longitude: -100,
        zoom: 3.2,
        bearing: 0,
        pitch: 0,
      },
    };
    this.mapRef = React.createRef();
  }
  render() {
    const { viewport } = this.state;
    return (
      <div style={{ height: "100vh", position: "relative" }}>
        <ReactMapGL
          {...viewport}
          onViewportChange={(viewport) => {
            this.setState({
              viewport,
            });
            //this.setMapFilter();
          }}
          //mapStyle="mapbox://styles/mapbox/dark-v9"
          mapboxApiAccessToken={TOKEN}
          ref={this.mapRef}
          width="100%"
          height="100%"
        >
          <Container>
            <Row
              style={{
                marginTop: "30px",
                minHeight: "70vh",
              }}
              className="text-center d-flex align-items-center"
            >
              <Col xs={12}>
                <h3 style={{ marginTop: "60px" }}>
                  What type of leads would you like?
                </h3>
                <div style={{ marginTop: "60px", marginBottom: "80px" }}>
                  <Link
                    to="/search/maps/businesses"
                    className="btn btn-lg btn-primary"
                  >
                    Business leads
                  </Link>
                  &nbsp; &nbsp; &nbsp;
                  <Link to="/search/maps/consumers" className="btn btn-lg btn-primary">
                    Consumer leads
                  </Link>
                </div>
              </Col>
              <Col xs={12}>
                {Articles.map((a) => {
                  if (["search-on-map"].includes(a.id)) {
                    return (
                      <Card
                        key={a.id}
                        style={{
                          width: "18rem",
                          margin: "0 auto",
                          marginBottom: ".75rem",
                        }}
                      >
                        <Card.Body>
                          <Card.Title>{a.name}</Card.Title>
                          <Card.Text className="text-muted">
                            {a.excerpt}
                          </Card.Text>
                          <Link
                            to={`/support/${a.id}`}
                            className="btn btn-info btn-sm"
                          >
                            Learn more
                          </Link>
                        </Card.Body>
                      </Card>
                    );
                  }
                })}
              </Col>
            </Row>
          </Container>
        </ReactMapGL>
      </div>
    );
  }

  componentDidMount() {
    this.props.handleState({
      noDistract: true,
      isApp: true,
      hideSmallTopNav: true,
    });
  }
  componentWillUnmount() {
    this.props.handleState({
      hideSmallTopNav: false,
    });
  }
}
export default Maps;
