import React from "react";
import { Switch, Route, Link, Redirect, useParams } from "react-router-dom";
import {
  Container,
  Row,
  Col,
  Card,
  Tab,
  TabContent,
  TabContainer,
  Nav,
  Button,
  ListGroup,
  ListGroupItem,
  Modal,
  Popover,
  OverlayTrigger
} from "react-bootstrap";
import withSearch from "./withSearch.jsx";
import LeadsCount from "./leadscount.jsx";
import Geographies from "./geographies.jsx";
import Filters from "./filters.jsx";
import Preview from "./previewAgain.jsx";
import {Download} from "./download.jsx";
import SelectedParams from "./selectedParams.jsx";
import { SavedSearchHeader } from "./savedSearch.jsx";
import { IndexTests } from "./searchTests.jsx";
import { db } from "./firebase.jsx";
import MapPreview from "./mapPreview.jsx";
import { saveSearch } from "./savedSearch.jsx";
import { InfoCircle } from "react-bootstrap-icons";
import { SearchLayout } from "./interfaceListShackSupport.jsx";

class Search2 extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showFiltersModal: false,
    };
  }

  render() {
    //console.log("Search2 this.props: ", this.props);
    //let parsedQueryString = queryString.parse(window.location.search);
    //console.log("window.location.search: ", window.location.search, "parsedQueryString: ", parsedQueryString);

    const {
      dataDict,
      leadsCount,
      superAdmin,
      searchParamsCount,
      fetchCount,
      canFetchSearch,
      isFetching
    } = this.props;

    const dictPopover = (<Popover ide="dict-info">
      <Popover.Body>
        <b>Type:</b> {dataDict.type} <br />
        <b>Description:</b> {dataDict.description} <br />
        <small><i>id: {dataDict.dictId}</i></small>
      </Popover.Body>
    </Popover>)

    return (
      <SearchLayout {...this.props}>
      <Container>
        <Row className="g-0 sticky-top d-block d-md-none">
          <Col>
            <ListGroup
              className="list-group-flush text-start"
              style={{
                width: "100%",
              }}
            >
              <ListGroupItem>
                <LeadsCount
                  {...this.props}
                  className="text-center"
                  count={leadsCount}
                  inline={true}
                />
              </ListGroupItem>
              <ListGroupItem className="d-flex flex-row justify-content-between align-items-center">
                <span>Filters ({searchParamsCount})</span>
                <Button
                  variant="outline-primary"
                  size="sm"
                  onClick={() => this.setState({ showFiltersModal: true })}
                  className="pull-right"
                >
                  <small>View</small>
                </Button>
              </ListGroupItem>
            </ListGroup>
          </Col>
          <Modal
            show={this.state.showFiltersModal}
            onHide={() => this.setState({ showFiltersModal: false })}
          >
            <Modal.Body>
              <SearchPreview {...this.props} />
            </Modal.Body>
            <Modal.Footer>
              <Button
                variant="light"
                size="sm"
                onClick={() => this.setState({ showFiltersModal: false })}
              >
                Close
              </Button>
            </Modal.Footer>
          </Modal>
        </Row>
        <Row className="my-4">
          <Col md={8}>
            <Row className="g-0">
              <Col
                xs={12}
                className="d-flex justify-content-between align-items-center"
              >
                <Row className="justify-content-between align-items-center gx-0">
                  <Col className="text-start">
                      <h3 className="text-start">
                        {dataDict.title} 
                        <OverlayTrigger 
                          trigger="click" 
                          placement="right" 
                          overlay={dictPopover}
                          rootClose={true}
                          rootCloseEvent="click"
                        >
                          <InfoCircle className={`text-${dataDict.themeColor}`} style={{marginLeft: "10px"}} />
                        </OverlayTrigger>
                      </h3>
                      {dataDict.subText && (
                        <React.Fragment>{dataDict.subText}</React.Fragment>
                      )}
                  </Col>
                  <Col className="d-none d-lg-flex justify-content-end">
                    <LeadsCount
                      className="text-center"
                      count={leadsCount}
                      refreshCount={fetchCount}
                      isFetching={isFetching}
                      canFetch={canFetchSearch}
                      themeColor={dataDict.themeColor}
                      inline={false}
                    />
                  </Col>
                </Row>
              </Col>
              <Col xs={12} className="mt-5">
                <Switch>
                  <Route exact path={`/:searchNoun/:dictId/:tab`}>
                    <SearchTab {...this.props} />
                  </Route>
                  <Redirect
                    from="/:searchNoun/:dictId"
                    to={`/:searchNoun/:dictId/geography${window.location.search}`}
                  />
                </Switch>
              </Col>
            </Row>
          </Col>
          <Col md={4} className="d-none d-md-block">
            <SearchPreview {...this.props} className="sticky-top" />
          </Col>
        </Row>
        {superAdmin && (
          <Row>
            <Col className="mt-5">
              <IndexTests {...this.props} />
            </Col>
          </Row>
        )}
      </Container>
      </SearchLayout>
    );
  }

  componentDidMount() {
    this.props.handleState({
      isApp: true,
      noDistract: true,
    });
  }
}

export default withSearch(Search2);

const SearchTab = (props) => {
  let { searchNoun, dictId, tab } = useParams();
  const {hasGeo} = props;
  let searchPane = searchPanes.find((p) => p.id === tab);
  let prevPane = searchPane ? searchPanes.find( p => p.id === searchPane.prev ) : null;
  let nextPane = searchPane ? searchPanes.find( p => p.id === searchPane.next ) : null;
  if (!hasGeo && searchPane.id !== "geography") {
    return(
      <Redirect
        to={`/${searchNoun}/${dictId}/geography${window.location.search}`}
      />
    )
  }
  return (
    <TabContainer>
      <Nav
        justify
        variant="tabs"
        className="bg-light"
        defaultActiveKey={searchPane.id}
      >
        {searchPanes.map( p => {
          if (!hasGeo && p.id !== "geography") {
            return (
              <Nav.Item key={p.id}><span className="nav-link">{p.title}</span></Nav.Item>
            )
          }
          return (
            <Nav.Item key={p.id}>
              <Link
                className={`nav-link ${p.id === tab ? "active" : ""}`}
                to={`/${searchNoun}/${dictId}/${p.id}`}
                onClick={(e) => {
                  if (p.onSelect) {
                    p.onSelect(props);
                  }
                }}
              >
                {p.title}
              </Link>
            </Nav.Item>
          )
        })}
        <Nav.Item>
          <Button
            variant="outline-secondary"
            onClick={() => {
              window.location.reload();
            }}
          >
            <small>Start over</small>
          </Button>
        </Nav.Item>
      </Nav>
      <TabContent className="py-4">
        <Row className="g-0">
          <Col xs={12}>
            {searchPane.content({ ...props })}
          </Col>
          <Col xs={12} className="mt-3 d-flex justify-content-between align-items-center">
            {prevPane &&
              <Link
                className="btn btn-link btn-lg pull-left"
                style={{textDecoration: "none"}}
                to={`/${searchNoun}/${dictId}/${prevPane.id}`}
                onClick={ prevPane.onSelect ? () => prevPane.onSelect(props) : null }
              >
                &lt;&nbsp;{prevPane.promptText}
              </Link>
            }
            {!prevPane && <span>&nbsp;</span>}
            {nextPane &&
              <Link
                className="btn btn-link btn-lg pull-right"
                style={{textDecoration: "none"}}
                to={`/${searchNoun}/${dictId}/${nextPane.id}`}
                onClick={ nextPane.onSelect ? () => nextPane.onSelect(props) : null }
              >
                {nextPane.promptText}&nbsp;&gt;
              </Link>
            }
          </Col>
        </Row>

      </TabContent>
    </TabContainer>
  );
};

const searchPanes = [
  {
    id: "geography",
    title: "Geography",
    promptText: "Select geography",
    content: (props) => {
      return (
        <Geographies
          {...props}
          selectSearchBy={props.selectGeoSearchBy}
          addQueryParam={props.addSearchParam}
          searchTypesDict={props.dataDict.geography}
          dictKey="geography"
          themeColor={props.dataDict.themeColor}
        />
      );
    },
    next: "filters"
  },
  {
    id: "filters",
    title: "Filters",
    promptText: "Pick filters",
    content: (props) => {
      return <Filters {...props} />;
    },
    onSelect: (props) => props.fetchCount(),
    next: "preview",
    prev: "geography"
  },
  {
    id: "preview",
    title: "Preview",
    promptText: "Preview leads",
    content: (props) => {
      return (
        <Preview
          {...props}
          previewCols={props.dataDict.previewCols}
          dlCols={props.dataDict.dlCols}
        />
      );
    },
    onSelect: (props) => props.fetchCount(null, false),
    next: "checkout",
    prev: "filters"
  },
  {
    id: "checkout",
    title: "Checkout",
    promptText: "Checkout",
    content: (props) => {
      return <Download {...props} />;
    },
    onSelect: (props) => props.fetchCount(),
    prev: "preview"
  },
];

const SearchPreview = (props) => {
  const {
    hasMap,
    hasGeo,
    canFetchSearch,
    geoBoundingBox,
    dataDict,
    geographyParams,
    searchParams,
    currentSearch,
    handleAlerts,
    savedSearch,
    user,
    leadsCount,
    userDoc,
    handleSearchState,
    fetchCount,
    isFetching,
    resetGeoParams,
    displayOnMap,
    removeSearchParam,
    customerDoc,
    filterParams
  } = props;
  return (
    <div
      className={props.className ? props.className : ""}
      style={props.style ? props.style : null}
    >
      <Card className="shadow" style={{ minHeight: "250px" }}>
        <Card.Header className={`bg-${dataDict.themeColor} text-white`} style={{ minHeight: "89px" }}>
          <SavedSearchHeader {...props} />
        </Card.Header>
        <Card.Body>
          {!hasGeo && (
            <div>Select a geography to start searching for leads!</div>
          )}
          {hasGeo && (
            <div className="text-start">
              {hasMap &&
                <React.Fragment>
                  <h5>Map filter</h5>
                  <MapPreview
                    mapboxApiAccessToken={process.env.REACT_APP_mapbox}
                    width={100}
                    height={100}
                    bb={geoBoundingBox}
                    location={dataDict.map.location}
                  />
                </React.Fragment>
              }
              <SelectedParams
                selectedParams={geographyParams}
                dict={dataDict["geography"]}
                dictKey="geography"
                reset={resetGeoParams}
                removeParam={removeSearchParam}
                paramHeader="Geography"
              />
              <SelectedParams
                selectedParams={searchParams || {}}
                dict={dataDict["cols"]}
                dictKey="cols"
                removeParam={removeSearchParam}
                paramHeader={`${dataDict.type} filters`}
              />
            </div>
          )}
        </Card.Body>
        <ListGroup className="list-group-flush">
          <ListGroupItem>
            <LeadsCount
              count={leadsCount}
              refreshCount={fetchCount}
              isFetching={isFetching}
              canFetch={canFetchSearch}
              themeColor={dataDict.themeColor}
              inline={true}
            />
          </ListGroupItem>
        </ListGroup>
      </Card>
      <div className="d-flex justify-content-between align-items-center">
        <Button
          variant="link"
          size="sm"
          className="text-danger"
          style={{textDecoration: "none"}}
          onClick={async (e) => {
            let timestamp = new Date();
            let reportId = Math.round(timestamp.getTime() / 1000);
            let device = window.navigator.userAgent;
            await db.collection("errors").add({
              uid: userDoc.uid || "",
              id: userDoc.id || "",
              email: userDoc.email || "",
              state: JSON.stringify({
                geographyParams,
                searchParams,
                filterParams,
                savedSearch,
                currentSearch,
                leadsCount
              }),
              location: {
                host: window.location.host,
                href: window.location.href,
                origin: window.location.origin,
                pathname: window.location.pathname,
                port: window.location.port,
                protocol: window.location.protocol,
                search: window.location.search,
              },
              device,
              timestamp,
              reportId,
              type: "reportSearch",
            });
            return await saveSearch(
              e,
              {
                geographyParams,
                searchParams,
                dataDict,
                queryUrl: currentSearch,
                count: leadsCount,
                user,
                reportId,
                device,
                timestamp,
              },
              (error, result) => {
                if (error) {
                  handleAlerts(
                    "",
                    `Uh oh, your search wasn't reported.  Please reach out to Customer Support with a screenshot of the search selected box and your account email address at ${process.env.REACT_APP_contact_email}.`,
                    "warning"
                  );
                }
                if (result) {
                  handleAlerts(
                    "",
                    `Your search has been reported to the ${process.env.REACT_APP_site_name} engineering team. Please reach out to Customer Support with report id ${reportId} and your account email address at ${process.env.REACT_APP_contact_email}.`,
                    "info"
                  );
                }
              }
            );
          }}
        >
          Report search
        </Button>
        <Button
          variant="link"
          onClick={(e) => {
            saveSearch(
              e,
              {
                isDefault: true,
                geographyParams,
                searchParams,
                dataDict,
                queryUrl: currentSearch,
                count: leadsCount,
                user: user,
              },
              (error, result) => {
                if (error) {
                  handleAlerts(
                    "",
                    "Uh oh, the default search wasn't saved.",
                    "warning"
                  );
                }
                if (result) {
                  handleAlerts(
                    "",
                    "Wahoo, the default search was saved!",
                    "success"
                  );
                  handleSearchState({
                    savedSearch: {
                      geographyParams,
                      searchParams,
                      searchName: "",
                      count: leadsCount ? leadsCount : 0,
                      uid: userDoc.uid,
                      queryUrl: currentSearch,
                    },
                  });
                }
              }
            );
          }}
          size="sm"
          className="text-end"
          style={{textDecoration: "none"}}
        >
          Set as default
        </Button>
      </div>
    </div>
  );
};
