import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { Form, ListGroup, Button, InputGroup } from "react-bootstrap";
import {XCircle} from "react-bootstrap-icons";

class AutoComplete extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      searchText: this.props.searchText ? this.props.searchText : "",
      suggestions: []
    };
    //this.items = [
    //  {value: 'D', name: 'David'},
    //  {value: "Da", name: 'Damien'},
    //  {value: "Sa", name: 'Sara'},
    //  {value: "Ja", name: 'Jane'},
    //  //"David", "Damien", "Sara", "Jane"
    //] // For testing
    //console.log(props);
    this.selectSuggestion = this.selectSuggestion.bind(this);
  }

  async onSearchChanged(e) {
    const value = e.target.value;
    let props = this.props;
    //console.log("value: ", value);
    let suggestions = [];
    if (value.length > 1) {
      if (this.props.suggestions) {
        let items = this.props.suggestions;
        console.log("items: ", items);
        let regex = new RegExp(`.*${value}.*`, "i");
        suggestions = items.sort().filter(v => {
          return regex.test(props.searchKey ? v[props.searchKey] : v.name);
        });
      }
      if (this.props.onSearch) {
        let items = await this.props.onSearch(value);
        suggestions = items;
      }
      console.log("suggestions: ", suggestions);
      this.setState(() => ({ suggestions }));
    }
  }

  async onSubmitForm(e) {
    e.preventDefault();
    let props = this.props;
    let {clearSearchTextOnSelect} = props;
    if (this.props.onSubmit) {
      this.props.onSubmit(this.state.searchText);
    }
    // update state
    let so = { searchText: "", suggestions: "" };
    if (!clearSearchTextOnSelect) {
      delete so.searchText;
    }
    this.setState(so);
  }

  selectSuggestion(e, suggestion) {
    e.preventDefault();
    let props = this.props;
    let {clearSearchTextOnSelect} = props;
    if (props.onSelect) {
      props.onSelect(suggestion, this.state.searchText);
    }
    // update state
    let so = { searchText: "", suggestions: "" };
    if (!clearSearchTextOnSelect) {
      delete so.searchText;
    }
    this.setState(so);
  }

  render() {
    let {suggestions, searchText} = this.state;

    let showSuggestions = searchText.length > 0 && suggestions.length > 0;
    let props = this.props;
    let {showSubmit, submitText, submitClassName, submitVariant} = props;
    //console.log("AutoComplete props: ", props);
    return (
      <Form
        style={props.style ? {...props.style, position: "relative"} : {position: "relative"}}
        className={props.className ? props.className : ""}
        onSubmit={e => this.onSubmitForm(e)}
      >
        {this.props.formLabel &&
          <Form.Label className={props.formClassName ? props.formClassName : ""}>{this.props.formLabel}</Form.Label>
        }
        <InputGroup style={props.inputGroupStyle ? {...props.inputGroupStyle, position: "relative"} : {positon: "relative"}}>
          <Form.Control
            type="search"
            placeholder={this.props.placeholder}
            onChange={e => {
              this.onSearchChanged(e);
              this.setState({ searchText: e.target.value });
            }}
            value={this.state.searchText}
            autoComplete="off"
            size={props.size ? props.size : "lg"}
            className={props.inputClassName ? props.inputClassName : "text-center"}
            autoFocus={props.autoFocus ? props.autoFocus : false}
          />
          {searchText && false && 
            <XCircle
              style={props.clearSearchStyle ? props.clearSearchStyle : {
                position: "absolute",
                top: "50%",
                right: "2px",
                transform: "translate(-50%, -50%)",
                zIndex: "1000"
              }} 
              className="text-muted"
              size={20}
              onClick={() => {
                if (this.props.onClearSearch) {
                  this.props.onClearSearch();
                }
                this.setState({
                  searchText: "",
                  suggestions: []
                })
              }}
            />
          }
          {showSubmit &&
            <Button 
              type="submit"
              id="auto_complete_button"
              className={submitClassName ? submitClassName : ""}
              variant={submitVariant ? submitVariant : "primary"}
            >
              {submitText ? submitText : "Submit"}
            </Button>
          }

        </InputGroup>
        {this.props.helpText &&
          <Form.Text className="text-muted ms-2 mt-2">{this.props.helpText}</Form.Text>
        }
        {showSuggestions && (
            <ListGroup
              style={props.listGroupStyle ? props.listGroupStyle : {
                width: "100%",
                zIndex: "1000",
                position: "absolute"
              }}
            >
              {suggestions.map((suggestion, i) => (
                <ListGroup.Item
                  key={i}
                  variant="light"
                  onClick={e => this.selectSuggestion(e, suggestion)}
                  action
                >
                  {props.suggestion && props.suggestion(suggestion)}
                  {!props.suggestion && (
                    <Link to={`${this.props.path}/${suggestion.id}`} style={{textDecoration: "none"}}>
                      {suggestion.name}
                      <p style={{ fontSize: ".8em", margin: "0px" }}>
                        {suggestion.meta ? suggestion.meta : null}
                      </p>
                    </Link>
                  )}
                </ListGroup.Item>
              ))}
            </ListGroup>
          )}
      </Form>
    );
  }
}

AutoComplete.propTypes = {
  suggestions: PropTypes.array,
  placeholder: PropTypes.string,
  helpText: PropTypes.string,
  path: PropTypes.string
};

export default AutoComplete;
