import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Row, Accordion, Card, Button, Form, Col } from "react-bootstrap";
import SearchNSuggest from "./searchNSuggest.jsx";
import { AccordionToggle } from "./interfaceListShackSupport.jsx";

const Filters = props => {
  // Filters related methods. Can we move this to the filters component?
  let {dataDict, removeSearchParam, addSearchParam, rangeSelect, stringRangeSelect, handleSearchState, geoSearchBy, handleAlerts} = props;
  let {cols, themeColor} = props.dataDict;

  const createFilterInputs = (arrayDictKeys) => {
    //console.log("dataDict: ", dataDict);
    let inputsArray = [];
    for (let dictKey of arrayDictKeys) {
      let dictObj = dataDict["cols"][dictKey];
      if (dictObj.inputType === "checkbox") {
        inputsArray.push(
          <Form.Group key={`${dictKey}FormGroup`}  className="my-3">
            {dictObj.displayHeader && <Form.Label>{dictObj.name}</Form.Label>}
            {dictObj.items.map(opt => (
              <div key={`${dictKey}-${opt.value}`} className="mb-1">
                <Form.Check
                  type="checkbox"
                  name={`${dictKey}${opt.index}`}
                  label={opt.name}
                  key={`${dictKey}${opt.index}`}
                  data-param={JSON.stringify(opt)}
                  data-index={opt.index}
                  onChange={() => {
                    if (props[dictKey + opt.index]) {
                      removeSearchParam("cols", dictKey, opt);
                      handleSearchState({
                        [dictKey + opt.index]: false
                      });
                    } else {
                      addSearchParam("cols", dictKey, opt);
                      handleSearchState({
                        [dictKey + opt.index]: true
                      });
                    }
                  }}
                  checked={props[dictKey + opt.index]}
                />
              </div>
            ))}
            {dictObj.formText && <Form.Text>{dictObj.formText}</Form.Text>}
          </Form.Group>
        );
      } else if (dictObj.inputType === "select") {
        inputsArray.push(
          <Form.Group key={`${dictKey}FormGroup`} className="my-3">
            <Form.Label>{dictObj.name}</Form.Label>
            <Form.Control
              as="select"
              value={props[dictKey + "select"]}
              onChange={e => {
                let nValue = JSON.parse(
                  e.target.options[e.target.selectedIndex].dataset.param
                );
                addSearchParam("col", dictKey, nValue, true);
                handleSearchState({
                  [dictKey + "select"]: e.target.options[e.target.selectedIndex]
                    .value
                });
              }}
            >
              {dictObj.items.map(opt => (
                <option
                  value={opt.value}
                  data-param={JSON.stringify(opt)}
                  key={`${dictKey}-${opt.value}`}
                >
                  {opt.name}
                </option>
              ))}
            </Form.Control>
            {dictObj.formText && <Form.Text>{dictObj.formText}</Form.Text>}
          </Form.Group>
        );
      } else if (dictObj.inputType === "range") {
        let itemsMin = dictObj.items;

        let itemsMax =
          dictObj.sortItems === "reverse"
            ? itemsMin.slice().reverse()
            : dictObj.items;

        inputsArray.push(
          <div key={`${dictKey}FormGroup`} className="my-3">
            <Form.Label>{dictObj.name}</Form.Label>
            <Form.Group as={Row}>
              <Form.Label column sm={2}>
                From
              </Form.Label>

              <Col sm={4}>
                <Form.Control
                  as="select"
                  value={props[dictKey + "min"]}
                  onChange={e =>
                    rangeSelect(
                      e,
                      dictKey,
                      e.target.value,
                      props[dictKey + "max"]
                    )
                  }
                >
                  <option value="">Select min</option>
                  {itemsMin.map(opt => (
                    <option
                      value={JSON.stringify(opt)}
                      key={`${dictKey}-selectFrom-${opt.value}`}
                    >
                      {opt.name}
                    </option>
                  ))}
                </Form.Control>
              </Col>

              <Form.Label className="" column sm={2}>
                To
              </Form.Label>

              <Col sm={4}>
                <Form.Control
                  as="select"
                  value={props[dictKey + "max"]}
                  onChange={e =>
                    rangeSelect(
                      e,
                      dictKey,
                      props[dictKey + "min"],
                      e.target.value
                    )
                  }
                >
                  <option value="">Select max</option>
                  {itemsMax.map(opt => (
                    <option
                      value={JSON.stringify(opt)}
                      key={`${dictKey}-selectTo-${opt.value}`}
                    >
                      {opt.name}
                    </option>
                  ))}
                </Form.Control>
              </Col>
            </Form.Group>
          </div>
        );
      } else if (dictObj.inputType === "stringRange") {
        let itemsMin = dictObj.items;

        let itemsMax =
          dictObj.sortItems === "reverse"
            ? itemsMin.slice().reverse()
            : dictObj.items;

        inputsArray.push(
          <div key={`${dictKey}FormGroup`} className="my-3">
            <Form.Label>{dictObj.name}</Form.Label>
            <Form.Group as={Row}>
              <Form.Label column sm={2}>
                From
              </Form.Label>

              <Col sm={4}>
                <Form.Control
                  as="select"
                  value={props[dictKey + "min"]}
                  onChange={e =>
                    stringRangeSelect(
                      e,
                      dictKey,
                      e.target.value,
                      props[dictKey + "max"]
                    )
                  }
                >
                  <option value="">Select min</option>
                  {itemsMin.map(opt => (
                    <option
                      value={JSON.stringify(opt)}
                      key={`${dictKey}-selectFrom-${opt.value}`}
                    >
                      {opt.name}
                    </option>
                  ))}
                </Form.Control>
              </Col>

              <Form.Label className="" column sm={2}>
                To
              </Form.Label>

              <Col sm={4}>
                <Form.Control
                  as="select"
                  value={props[dictKey + "max"]}
                  onChange={e =>
                    stringRangeSelect(
                      e,
                      dictKey,
                      props[dictKey + "min"],
                      e.target.value
                    )
                  }
                >
                  <option value="">Select max</option>
                  {itemsMax.map(opt => (
                    <option
                      value={JSON.stringify(opt)}
                      key={`${dictKey}-selectTo-${opt.value}`}
                    >
                      {opt.name}
                    </option>
                  ))}
                </Form.Control>
              </Col>
            </Form.Group>
          </div>
        );
      } else if (dictObj.inputType === "search") {
        inputsArray.push(
          <React.Fragment key={`sns${dictKey}`}>
            <SearchNSuggest
              {...props}
              searchBy={dictKey}
              addQueryParam={addSearchParam}
              searchTypesDict={dataDict["cols"]}
              dictKey="cols"
              handleAlerts={handleAlerts}
            />
            <hr style={{ marginBottom: "50px" }} />
          </React.Fragment>
        );
      }
    }

    return inputsArray;
  }

  const formatAdvancedFilters = (array_filters) => {
    // just loop through the filters and create a new object shaped like:
    // {
    //     "Mortgage information": ["Col1", "Col2", "Col3"],
    //     "Financial information": ["Col4", "Col5"]
    // }

    //console.log("array_filters: ", array_filters);

    let formattedCols = {};
    for (let col of array_filters) {
      let colCategory = dataDict["cols"][col]["filterCategory"];
      //console.log("colCategory: ", colCategory);
      if (colCategory !== undefined) {
        if (formattedCols[colCategory] === undefined) {
          formattedCols[colCategory] = [col];
        } else {
          formattedCols[colCategory].push(col);
        }
      }
      //console.log("formattedCols: ", formattedCols);
    }
    return formattedCols;
  }

  let basicFilters = [];
  let advancedFilters = [];
  for (let key in cols) {
    if (cols[key]["filterType"] === "basic") {
      basicFilters.push(key);
    } else {
      advancedFilters.push(key);
    }
  }

  const hasAdvancedFilters =
    advancedFilters.length < Object.keys(cols).length;
  const basicFilterCategories = formatAdvancedFilters(basicFilters);
  const advancedFilterCategories = formatAdvancedFilters(
    advancedFilters
  );



  return(
    <div
      className={props.className ? props.className : ""}
      style={props.style ? props.style : null}
    >
      <div className="text-start mb-5">
        <h4>Select filters to target your leads.</h4>
        <small>
          For the best results, only use filters
          important to your search.
        </small>
        {geoSearchBy === "nationwide" && (
          <small>
            &nbsp;For nationwide searches, select at
            least two basic demographic filters to speed
            up results and prevent errors.
          </small>
        )}
      </div>

      <Form className="text-start" id="saForm">
        {!hasAdvancedFilters &&
          createFilterInputs(
            Object.keys(dataDict.cols)
        )}
        {hasAdvancedFilters && (
          <Accordion defaultActiveKey="0">
            <Card className="">
              <AccordionToggle
                as={Card.Header}
                eventKey="0"
                className={`bg-${themeColor} text-white`}
              >
                <Card.Header>Basic filters</Card.Header>
              </AccordionToggle>

              <Accordion.Collapse eventKey="0">
                <Card.Body>
                  {Object.keys(
                    basicFilterCategories
                  ).map((c, i) => {
                    return (
                      <React.Fragment key={`c_${c}`}>
                        <h5>{c}</h5>
                        {createFilterInputs(
                          basicFilterCategories[c]
                        )}
                        {i <
                          Object.keys(
                            basicFilterCategories
                          ).length -
                            1 && <hr />}
                      </React.Fragment>
                    );
                  })}
                </Card.Body>
              </Accordion.Collapse>
            </Card>
            <Card>
              <AccordionToggle
                eventKey="1"
                className={`bg-${themeColor} text-white`}
              >
                <Card.Header>Advanced filters</Card.Header>
              </AccordionToggle>

              <Accordion.Collapse eventKey="1">
                <Card.Body>
                  {Object.keys(
                    advancedFilterCategories
                  ).map((c, i) => {
                    return (
                      <React.Fragment key={`c_${c}`}>
                        <h5>{c}</h5>
                        {createFilterInputs(
                          advancedFilterCategories[c]
                        )}
                        {i <
                          Object.keys(
                            advancedFilterCategories
                          ).length -
                            1 && <hr />}
                      </React.Fragment>
                    );
                  })}
                </Card.Body>
              </Accordion.Collapse>
            </Card>
          </Accordion>
        )}
      </Form>
    </div>

  )
}

export default Filters;

Filters.propTypes = {
  handleAlerts: PropTypes.func.isRequired,
  dataDict: PropTypes.object.isRequired,
  removeSearchParam: PropTypes.func.isRequired,
  addSearchParam: PropTypes.func.isRequired,
  rangeSelect: PropTypes.func.isRequired,
  stringRangeSelect: PropTypes.func.isRequired,
  handleSearchState: PropTypes.func.isRequired,
  geoSearchby: PropTypes.string,
  className: PropTypes.string,
  style: PropTypes.object
};
