import React, { useState } from "react";
import { Redirect } from "react-router-dom";
import { Button, Container, Form, Alert } from "react-bootstrap";
import { CompanyProfile } from "./interfaceListShackPro.jsx";
import { sendText } from "./text.jsx";
import { formatPhoneNumber } from "./interfaceListShackPro.jsx";
import { SubscriptionCard } from "./recurlyCheckoutAgain.jsx";
import { db } from "./firebase.jsx";

const SalesTeamSubscribe = props => {
  const {apiKey, userDoc, selectedPlan, handleAlerts, customerDoc, plans, byPassVerification, handleState} = props;
  const [hasSelectedPlan, setHasSelectedPlan] = useState(false);
  const [callbackTime, setCallbackTime] = useState("");
  const [requested, setRequested] = useState(false);
  let isVerified = byPassVerification ? byPassVerification : false;
  //console.log("SalesTeamSubscribe props: ", props);

  if (userDoc.superVerified) {
    return( <Redirect to={ `_${process.env.REACT_APP_signup_url}` } /> )
  }

  if (!isVerified) {
    return (
      <div style={{ width: "400px" }}>
        <CompanyProfile
          {...props}
          heading={
            <React.Fragment>
              <h6>Verify your account profile </h6>
              <Form.Text>
                Please update and verify your account information prior to
                selecting a plan and speaking with our sales team.
              </Form.Text>
            </React.Fragment>
          }
          size="md"
        />
      </div>
    )
  }

  if (!hasSelectedPlan) {
    return(
      <React.Fragment>
        <SubscriptionCard
          {...props}
          subscriptionCardTitle={
            <h5>Which plan would you like?</h5>
          }
          selectPlanCallback={ () => {
            handleState({
              displayCheckoutModal: false,
              selectPlanCallback: () => handleState({
                displayCheckoutModal: false,
                selectPlanCallback: null
              })
            })
          }}
        />
        <Button
          block
          variant="primary"
          size="lg"
          onClick={ async (e) => {
            e.preventDefault();
            try{
              let freeTrialParams = {
                email: userDoc.email,
                plan_id: process.env.REACT_APP_plan_free_trial.toLowerCase(),
                subscription_status: "canceled",
                sub_checked: Math.round( (new Date() / 1000) + (86400 * 6) ),
                uid: userDoc.uid
              }
              if (customerDoc) {
                await customerDoc._docRef
                  .update(freeTrialParams)
              } else {
                await db.collection("customers").doc(props.user.uid)
                  .set(freeTrialParams, {merge: true})
              }
              await userDoc._docRef.update({
                superVerified: false,
                leads: 0,
                leadsRemaining: 0,
                downloadLimit: 100,
                includesRollover: false
              });
              setHasSelectedPlan(true);
            } catch(err) {
              console.log("Error setting free trial: ", err);
            }
          }}
        >
          Select {plans[selectedPlan].shortname} plan
        </Button>
      </React.Fragment>
    )
  }

  if (!requested) {
    const message = `New signup request \n
      Requested Plan: ${plans[selectedPlan].shortname} \n
      Company name: ${userDoc.companyProfile.name} \n
      Phone: ${formatPhoneNumber(userDoc.companyProfile.phone)} \n
      Email: ${userDoc.companyProfile.email} \n
      Callback Time: ${callbackTime} \n\n
      Verification form: https://docs.google.com/forms/d/e/1FAIpQLScZtFlA-syUQLzwVCrb3N6-fqio_dGJn5h1qu7t15vSkZwzPw/viewform \n
      Current subscribe URL: ${window.location.origin}/_${process.env.REACT_APP_signup_url} \n
      Approve user from: https://www.listshack.support/superadmin/newusers
      `;

    return(
      <React.Fragment>
        <p className="lead mt-5">
          Awesome, our sales team just needs a quick call to finish account setup and collect payment.
        </p>
        <Form.Group controlId="exampleForm.ControlSelect1">
          <Form.Label>When is the best time to reach you?*</Form.Label>
          <Form.Control as="select"
            value={callbackTime}
            onChange={(e) => {
              setCallbackTime(e.target.value);
            }}
          >
            <option key="cb_default" value="">Select callback time</option>
            {
              ["As soon as possible", "Mornings", "Afternoons", "Evenings"].map( (opt,i) => {
                return(
                  <option key={`cb_${i}`} value={opt}>{opt}</option>
                )
              })
            }
          </Form.Control>
        </Form.Group>
        <Button
          block
          variant="success"
          size="lg"
          className="mt-5"
          onClick={ async (e) => {
            e.preventDefault();
            if(!callbackTime) {
              return(
                handleAlerts(
                  null,
                  "Please select the best time for our sales team finish your account setup.",
                  "warning"
                )
              )
            }
            try{
              await sendText({
                apiKey,
                msg: {
                  message,
                  to: process.env.REACT_APP_contact_sms
                }
              });
              setRequested(true);
              handleAlerts(
                null,
                "Awesome, our sales team has been notified and will call you back!",
                "success"
              );
            } catch(err) {
              console.log("Error notifying the sales team: ", err);
              handleAlerts(
                null,
                <React.Fragment>>Uh oh, something went wrong notifiying the sales team, please send a text to <Alert.Link href={`sms:${process.env.REACT_APP_contact_sms}`}>${formatPhoneNumber(process.env.REACT_APP_contact_sms)}</Alert.Link>.</React.Fragment>,
                "warning"
              );
            }
          }}
        >
          Request {plans[selectedPlan].shortname} plan <br />
          ${plans[selectedPlan].price / 100} per Month
        </Button>
      </React.Fragment>
    )
  }

  if (requested) {
    return(
      <Container className="py-5">
        <h4>Awesome, our sales team will call you shortly!</h4>
        <p>
          We're so excited that you've requested an exclusive membership to {process.env.REACT_APP_site_name}.
          <br /> <br />
          While you are waiting, click below to start your 7-day Search & Save free trial.
        </p>
        <p>
          <Button
            block
            className="mt-5"
            variant="primary"
            onClick={ async (e) => {
              console.log("Set 7 day trial.")
              try {
                await customerDoc._docRef
                  .update({
                    subscription_status: "active"
                  });
                handleAlerts(
                  null,
                  "Yay, you're now using the Free Trial!",
                  "success"
                )
              } catch(err) {
                console.log("Error setting free trial: ", err);
                handleAlerts(
                  null,
                  "Uh oh, looks like our Sales Team will need to create your free trial. They will be in touch soon.",
                  "warning"
                );
              }

            }}
          >
            Start 7-day Search &amp; Save Free Trial
          </Button>
        </p>
      </Container>
    )
  }

}

export default SalesTeamSubscribe;
