import React from "react";
import { Helmet } from "react-helmet";
import { Switch, Route, Link, Redirect, useParams } from "react-router-dom";
import { Container, Row, Col } from "react-bootstrap";
import Search2 from "./search.jsx";

const SearchRouter = props => {
  return (
    <Switch>
      <Route exact path={[`/_search/:dictId/:tab`, `/_search/:dictId`]}>
        <SearchPage {...props} />
      </Route>
      <Route exact path="/_search">
        <Redirect to="/search" />
      </Route>
    </Switch>
  );
};

const SearchPage = props => {
  //let { url } = useRouteMatch();
  let { dictId } = useParams();
  let dict = props.dicts.find(dict => dict.dictId === dictId);

  return (
    <React.Fragment>
      <Helmet>
        <title>Shared Search</title>
        <meta name="HandheldFriendly" content="true" />
        <meta name="apple-mobile-web-app-capable" content="YES" />
      </Helmet>
      {dict && <Search2 {...props} dataDict={dict} />}
      {!dict && (
        <Container>
          <Row
            style={{
              minHeight: "70vh",
              textAlign: "center",
              marginTop: "40px"
            }}
          >
            <Col>
              <h3>Uh oh, that search is no longer available :( </h3>
              <p className="lead">
                Click the button below to return to civilization.
              </p>
              <Link to="/search" className="btn btn-large btn-dark">
                Back to civilization
              </Link>
            </Col>
          </Row>
        </Container>
      )}
    </React.Fragment>
  );
};

export default SearchRouter;
