import React from "react";
import { Route } from "react-router-dom";
import { Helmet } from "react-helmet";
import { HomeLss } from "./interfaceListShackSupport.jsx";

const RoutesLss = props => {
  let { user } = props;
  return (
    <React.Fragment>
      <Route exact path="/">
        <Helmet>
          <title>
            {process.env.REACT_APP_site_name}
          </title>
          <meta name="HandheldFriendly" content="true" />
          <meta name="apple-mobile-web-app-capable" content="YES" />
        </Helmet>
        <HomeLss user={user} />
      </Route>
      <Route exact path="/home">
        <Helmet>
          <title>
            {process.env.REACT_APP_site_name}
          </title>
          <meta name="HandheldFriendly" content="true" />
          <meta name="apple-mobile-web-app-capable" content="YES" />
        </Helmet>
        <HomeLss user={null} />
      </Route>
    </React.Fragment>
  );
};

export default RoutesLss;
