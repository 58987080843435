import React from 'react';
import {formatPhoneNumber} from "./interfaceListShackPro.jsx";
import { Button } from "react-bootstrap";
import {addToCrm, withCrm} from "./crm.jsx";

function LeadInfo(props) {
  console.log("LeadInfo props: ", props);
  const {info, display, queryUrl, user, userDoc, formatContactMethod, dataDict} = props;
  return (
    <div className="p-2">
      <table>
        <tbody>
          {display.map( ( col, i) => {
            return(
              <tr key={`entry_${i}`}>
                <td><b>{col.label}:</b></td>
                <td>
                  <span className="text-muted ms-2">
                    { col.label.toLowerCase() === "phone" ? formatPhoneNumber(info[ col.content ]) : info[ col.content ]}
                  </span>
                </td>
              </tr>
            )
          })}
        </tbody>
      </table>
      {/*<Button
        variant="light"
        size="sm"
        onClick={ () => {
          console.log({display,info})
          let cm = ["PHONE", "EMAIL", "ADDR"];
          let n = ["FN", "LN"];
          Object.keys(info).map( k => {
            if ( cm.includes(k) ) {
              info[k] = [ formatContactMethod(k, info[k]) ]
            }
            if ( n.includes(k) ) {
              info[k] = `${info[k][0].toUpperCase()}${info[k].slice(1).toLowerCase()}`
            }
          })
          addToCrm({
            data: info,
            queryUrl,
            uid: user.uid,
            aid: userDoc.id,
            source: {
              type: "listshack maps",
              dictId: dataDict.dictId
            }
          });
        }}
      >
        Add to CRM
      </Button>*/}
    </div>
  );
}

export default React.memo( withCrm(LeadInfo) );
