import React from "react";
import { Form } from "react-bootstrap";
import withInlineEdit from "./withInlineEdit";

const InlineFormSelect = props => {
  console.log("InlineFormControl props: ", props);
  return(
    <Form.Select
      {...props}
    />
  )
}

export default withInlineEdit(InlineFormSelect);