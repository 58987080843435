import React, {useState, useEffect} from 'react';
import MDEditor from '@uiw/react-md-editor';
import {Container, Row, Col, Button, Modal} from "react-bootstrap";
import {db} from "./firebase";
import {X} from "react-bootstrap-icons";

const Note = props => {

  const {noteid, user, noteTitle} = props;

  const [showEdit, setShowEdit] = useState(null);
  const [saved, setSaved] = useState(false);
  const [listenerAdded, setListenerAdded ] = useState(false);
  const [contentType, setContentType] = useState("md");
  const [content, setContent] = useState("");
  const noteRef = db.collection("notes").doc(noteid);
  const saveNote = ref => {
    return ref.update({
      _lastChanged: Math.round( new Date().getTime() / 1000),
      "data.content": content
    });
  }
  
  useEffect( () => {
    if (!listenerAdded) {
      noteRef.onSnapshot( doc => {
        if (doc.exists) {
          let d = doc.data();
          //console.log("d: ", d);
          setListenerAdded(true);
          setContentType(d.data.contentType);
          setContent(d.data.content);
          setSaved( true );
        } else {
          noteRef.set({
            _lastChanged: Math.round( new Date().getTime() / 1000),
            _created: Math.round( new Date().getTime() / 1000),
            createdBy: user.uid,
            data: {
              content,
              contentType
            }
          })
        }
      });
    }

    if (listenerAdded && showEdit) {
      let timeOutId = setTimeout(() => {
        console.log("Done typing.");
        saveNote(noteRef);
      }, 2000);
      return () => {
        console.log("Clearing timeout");
        clearTimeout(timeOutId);
        setSaved(false);
      };
    }

  }, [content]);

  return(
    <Container>
      <Row>
        <Col>
          <h5>{noteTitle}</h5>
          {showEdit &&
            <div className="bg-light">
              <MDEditor
                value={content}
                onChange={setContent}
                preview="edit"
              />
              <div className="mt-1">
                <Button variant="primary" size="sm"
                  onClick={() => {
                    saveNote(noteRef);
                    setShowEdit(false);
                  }}
                >
                  Save
                </Button>
                <Button
                  size="sm"
                  variant="link"
                  onClick={() => {
                    setShowEdit(!showEdit);
                  }}
                  className="text-dark"
                >
                  <X size={30} />
                </Button>
                <small 
                  className="text-muted pull-right mr-2"
                >
                  {saved && "Saved"}
                  {!saved && "Saving"}
                  {saved === null && ""}
                </small>
              </div>
              
            </div>
          }
          {!showEdit &&
            <div 
             style={{
               minHeight: "200px"
             }}
              onClick={() => {
                setShowEdit(!showEdit);
              }}
            >
              <small className="m-2 text-muted">Click to edit</small>
              <MDEditor.Markdown source={content} />
            </div>
          }
        </Col>
      </Row>
    </Container>
  )
}

const NoteInModal = props => {
  const [showModal, setShowModal] = useState(false);

  return(
    <React.Fragment>
      <Button 
        variant={props.variant ? props.variant : "primary"}
        onClick={() => setShowModal(true)}
        size={props.size ? props.size : "md"}
        className={props.className ? props.className : ""}
        style={props.style ? props.style : null}
      >
        {props.children ? props.children : "View Note"}
      </Button>
      <Modal 
        show={showModal} 
        onHide={() => setShowModal(false)} 
        size={props.modalSize ? props.modalSize : "md"}
      >
        <Modal.Body>
          <Button size="sm" variant="link" 
            className="text-dark" 
            style={{
              position: "absolute",
              right: "10px",
              top: "10px",
              zIndex: "1050"
            }}
            onClick={() => setShowModal(false)}>
            <X size={30} />
          </Button>
          <Note {...props} />
        </Modal.Body>
      </Modal>
    </React.Fragment>
  )
}

const ElasticNote = props => {

  const {index, docId, field, user, noteTitle, fetchFromApi, onError, noteObject, onSave} = props;

  const [showEdit, setShowEdit] = useState(null);
  const [saved, setSaved] = useState(false);
  const [listenerAdded, setListenerAdded ] = useState(false);
  const [contentType, setContentType] = useState("md");
  const [content, setContent] = useState(noteObject ? noteObject.content : "");
  
  const saveNote = () => {
    let newNote = {
      content,
      contentType: "md",
      updated: new Date().toISOString(),
      updatedBy: user.uid
    };

    return fetchFromApi({
      endpoint: "/api/elastic/update",
      body: JSON.stringify({
        id: docId,
        index,
        body: {
          doc: {
            [field]: newNote
          },
          detect_noop: false,
        }
      })
    }, (error, result) => {
      if (error) {
        console.log("Error saving note: ", error);
        if (onError) {
          onError(error)
        }
      }
      if (result) {
        if (onSave) {
          onSave(newNote)
        }
        console.log("Note saved.")
        setSaved(true);
      }
      
    })
    
  }
  
  useEffect( () => {
    if (!noteObject) {
      // Get the noteContent
      fetchFromApi({
        endpoint: "/api/v2/elastic/get",
        body: JSON.stringify({
          getBody: {
            index,
            id: docId
          }
        })
      }, (error, result) => {
        if (error) {
          if (onError) {
            onError(error);
          }
          if (result) {
            console.log("result: ", result);
            // If the note doesn't exist in the document
            if (!result._source.NOTE) {

            } else {
              setContent(result._source.NOTE.content);
            }
            //setListenerAdded(true);
          }
        }
      })
    }

    if (showEdit) {
      let timeOutId = setTimeout(() => {
        console.log("Done typing.");
        saveNote();
      }, 2000);
      return () => {
        console.log("Clearing timeout");
        clearTimeout(timeOutId);
        setSaved(false);
      };
    }

  }, [content]);

  return(
    <Container className="gx-0">
      <Row className="gx-0">
        <Col>
          <h5>{noteTitle}</h5>
          {showEdit &&
            <div className="bg-light">
              <MDEditor
                value={content}
                onChange={setContent}
                preview="edit"
                style={{position: "relative"}}
                autoFocus={true}
              />
              <div className="mt-1">
                <Button variant="primary" size="sm"
                  onClick={() => {
                    saveNote();
                    setShowEdit(false);
                  }}
                >
                  Save
                </Button>
                <Button
                  size="sm"
                  variant="link"
                  onClick={() => {
                    setShowEdit(!showEdit);
                  }}
                  className="text-dark"
                >
                  <X size={30} />
                </Button>
                <small 
                  className="text-muted pull-right mr-2"
                >
                  {saved && "Saved"}
                  {!saved && "Saving"}
                  {saved === null && ""}
                </small>
              </div>
              
            </div>
          }
          {!showEdit &&
            <div 
             style={{
               minHeight: "200px"
             }}
              onClick={() => {
                setShowEdit(!showEdit);
              }}
            >
              <small className="m-2 text-muted">Click to edit</small>
              <MDEditor.Markdown source={content} />
            </div>
          }
        </Col>
      </Row>
    </Container>
  )
}

export { Note, NoteInModal, ElasticNote };