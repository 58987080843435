import React from "react";
import { BrowserRouter as Router, withRouter } from "react-router-dom";
import withApp from "./withApp.jsx";
import ScrollToTop from "./scrollToTop.jsx";
import { Container, Row, Col, Form } from "react-bootstrap";
import Routes from "./routes";
import RoutesLss from "./routes_lss.jsx";
import SharedRoutes from "./sharedRoutes.jsx";
import { CompanyProfile } from "./interfaceListShackPro.jsx";
import { TopnavLss, SideBar, settingsPanes, SecondnavLss, Footer, FooterSmall, TopNavWithTabs } from "./interfaceListShackSupport.jsx";
import AppAlerts from "./appAlerts.jsx";
import SalesTeamSubscribe from "./salesTeamSubscribe";
import { SubscribeModal } from "./recurlyCheckoutAgain";

const App = props => {
  const {noDistract, isApp, hideSmallTopNav, userDoc, plans, selectedPlan, user, handleState} = props;
  //console.log("App props: ", props);
  return(
    <Router>
      <ScrollToTop {...props}>
        {!noDistract && !isApp &&
          <TopnavLss {...props} />
        }
        {!noDistract && !user && !isApp &&
          <SecondnavLss />
        }
        <div id="appListshack">
          <Row style={{minHeight: "100vh"}}>
            <Col>
              {isApp && 
								<TopNavWithTabs {...props} className="bg-light p-0" />
							}
              <AppAlerts {...props} />
              <RoutesLss {...props} />
              <Routes {...props} />
              <SharedRoutes
                panes={settingsPanes}
                {...props}
                plan={plans[selectedPlan]}
                manualSubscribe={true}
                manualSubscribeComponent={
                  <SalesTeamSubscribe {...props} 
										isSubscribe={true}
										byPassVerification={userDoc ? userDoc.verified : false}
										plan={plans[selectedPlan]}
                    onChangeSubscription={() => {
                      handleState({
                        displayCheckoutModal: true,
                        selectPlanCallback: () => handleState({
                          displayCheckoutModal: false,
                          showComparePlans: true
                        }),
                        showComparePlans: true
                      })
                    }}
									/>
                }
              />
            </Col>
          </Row>
        </div>
        {!noDistract && !isApp && <Footer {...props} />}
        {!noDistract && isApp && <FooterSmall />}
				<SubscribeModal {...props} 
					plan={plans[selectedPlan]}
					byPassVerification={userDoc ? userDoc.verified : false}
          onChangeSubscription={() => handleState({showComparePlans: true})}
          onboardingComponent={<CompanyProfile
            {...props}
            heading={
              <React.Fragment>
                <h6>Verify your account profile </h6>
                <Form.Text>
                  Please update and verify your account information prior to continue subscribing.
                </Form.Text>
              </React.Fragment>
            }
            size="md"
          />}
				/>
      </ScrollToTop>
    </Router>
  )
}

export default withApp( App );
