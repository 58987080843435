import React from "react";
import PropTypes from "prop-types";
import { Card, Button } from "react-bootstrap";
import Spinner from "react-bootstrap/Spinner";
class LeadsCount extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const {
      isFetching,
      canFetch,
      inline,
      count,
      themeColor,
      readOnly,
      refreshCount,
      refreshButtonText
    } = this.props;
    let hasFetched = this.props.count > 0;
    //console.log("leadsCount props: ", this.props);
    if (inline) {
      return (
        <div className="d-flex flex-row justify-content-between align-items-center text-muted">
          <div className="d-flex flex-row align-items-center">
            <small>Leads Count: &nbsp;</small>{" "}
            <strong className="ms-2" style={{ fontSize: "1.2em" }}>
              {count.toLocaleString()}
            </strong>
          </div>
          {!readOnly && refreshCount && (
            <Button
              variant={`outline-${themeColor}`}
              size="sm"
              onClick={e => refreshCount(e, true)}
              disabled={!canFetch}
            >
              <i className="fa fa-refresh" aria-hidden="true"></i>
            </Button>
          )}
          {this.props.children &&
            this.props.children
          }
        </div>
      );
    }

    return (
      <Card
        className={ this.props.className ? this.props.className : "" }
        style={ this.props.style ? this.props.style : { width: "200px", height: "200px" } }
      >
        <Card.Body>
          <Card.Title>Leads count</Card.Title>

          {!isFetching && (
            <Card.Text>
              <span style={{ fontSize: "1.5em" }}>
                {count.toLocaleString()}
              </span>
              <br />
              {canFetch && (
                <Button
                  variant={themeColor}
                  size="sm"
                  onClick={e => this.props.refreshCount(e, true)}
                  disabled={!canFetch}
                  style={{ marginTop: "10px" }}
                >
                  {!refreshButtonText &&
                    <React.Fragment>
                      {hasFetched && <span>Refresh count</span>}
                      {!hasFetched && <span>Get count</span>}
                    </React.Fragment>
                  }
                  {refreshButtonText &&
                    <span>{refreshButtonText}</span>
                  }
                </Button>
              )}
              {!canFetch && !hasFetched && (
                <small className="text-muted">
                  Select search parameters below to see how many leads are
                  available.
                </small>
              )}
              {!canFetch && hasFetched && (
                <small className="text-muted">
                  Change your search to refresh the count.
                </small>
              )}
            </Card.Text>
          )}
          {isFetching && (
            <React.Fragment>
              <Spinner animation="grow" variant="primary" />
              <Card.Text>
                <small className="text-muted">Getting your count!</small>
                <br />
                <small className="text-muted">
                  Some searches may take up to 3 minutes
                </small>
              </Card.Text>
            </React.Fragment>
          )}
        </Card.Body>
        {this.props.children &&
          this.props.children
        }
      </Card>
    );
  }
}

LeadsCount.propTypes = {
  user: PropTypes.object,
  count: PropTypes.number,
  refreshCount: PropTypes.func,
  isFetching: PropTypes.bool,
  canFetch: PropTypes.bool
};

export default LeadsCount;
