import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import { Switch, Route, Redirect } from "react-router-dom";
import { ViewElasticList, ViewAllLists } from "./elasticlists.jsx";
import { ListCrm } from "./interfaceListShackSupport";
import withTeam from "./withTeam.jsx";

const ElasticListsRouter = props => {
  return(
    <Switch>
      <Route exact path="/listcrm/:listid">
        <ViewElasticList {...props} />
      </Route>
      <Route exact path={["/elastic-lists", "/listcrm"]}>
        <ListCrm {...props}>
          <ViewAllLists {...props} />
        </ListCrm>
      </Route>
    </Switch>
  )
}

export default withTeam( ElasticListsRouter );
