import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { useParams, useLocation, Link, useHistory } from "react-router-dom";
import {
  TrelloBoardHeader,
  TrelloBoardList,
  createBoard,
} from "./likeTrello.jsx";
import { Collection, CollectionContext } from "./firebaseView.jsx";
import { db, listenDoc, functions, firebase } from "./firebase.jsx";
import { NotFound } from "./interfaceListShackPro.jsx";
import {
  Table,
  Button,
  Modal,
  Form,
  Spinner,
  InputGroup,
  FloatingLabel,
  Offcanvas,
  ButtonGroup,
  DropdownButton,
  Dropdown,
  Nav,
  Card,
  ListGroup,
  ListGroupItem,
  Container,
  Row,
  Col,
  Badge,
  ProgressBar
} from "react-bootstrap";
import {
  PlusCircleDotted,
  Download,
  GripVertical,
  ChevronExpand,
  ChevronRight,
  ChevronDoubleRight,
  ChevronLeft,
  ChevronDoubleLeft,
  SortAlphaUpAlt,
  SortAlphaDown,
  Journal,
  JournalText,
  AlignStart,
  AlignEnd,
  AlignTop,
  AlignBottom,
  Table as TableIcon,
  Kanban,
  SegmentedNav,
  Plus,
  X,
  ThreeDots,
  CheckSquareFill,
  DashSquareFill,
  CloudUpload,
  Search
} from "react-bootstrap-icons";
import DownloadPane from "./downloadPane";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import {
  reorder,
  santizeFileName,
} from "./interfaceListShackPro";
import AutoComplete from "./autoComplete.jsx";
import { ElasticNote } from "./notes.jsx";
import ElasticDoc from "./elasticDoc.jsx";
import { Email } from "./displayEmail.jsx";
import { Phone } from "./displayPhone.jsx";
import { Addr } from "./displayAddr";
import { Age } from "./displayAge";
import { HhIncome } from "./displayHhIncome.jsx";
import StatusSelect from "./statusSelect.jsx";
import AppAlerts from "./appAlerts";
import {AddRecord} from "./addRecord.jsx";
import { UploadToCrmButton } from "./uploadToCrm.jsx";
import { ShowMe, useShowMe } from "./showMeTutorial.jsx";

const ViewAllLists = (props) => {
  console.log("ViewAllLists props: ", props);
  const { userDoc, user } = props;
  const [sortBy, setSortBy] = useState("Newest to Oldest");
  const sortByOptions = [
    { field: "_created", order: "desc", name: "Newest to Oldest" },
    { field: "_created", order: "asc", name: "Oldest to Newest" },
    { field: "lastUpdated", order: "desc", name: "Most recently modified" },
    { field: "lastUpdated", order: "asc", name: "Least recently modified" },
    { field: "name", order: "desc", name: "Name Z - A" },
    { field: "name", order: "asc", name: "Name A - Z" },
  ];
  console.log("sortBy: ", sortBy);
  const history = useHistory();

  useEffect(() => {
    if (!props.isApp && props.handleState) {
      props.handleState({ isApp: true });
    }
  }, []);

  return (
    <Collection
      title={props.title ? props.title : ""}
      colRef={props.colRef ? props.colRef : db}
      collection={"elasticlists"}
      permissions={["read", "write", "delete"]}
      listen={true}
      where={[
        { field: "aid", operand: "==", term: userDoc.id },
        { field: "archived", operand: "==", term: false },
      ]}
      orderBy={[sortByOptions.find((o) => o.name === sortBy)]}
      endAt={undefined}
      startAfter={undefined}
      limit={undefined}
      callback={null}
    >
      <Helmet>
        <title>View All Lists</title>
        <meta name="HandheldFriendly" content="true" />
        <meta name="apple-mobile-web-app-capable" content="YES" />
      </Helmet>
      <CollectionContext.Consumer>
        {({ docs, firestoreRef, snapShot }) => {
          console.log("docs: ", docs);
          return (
            <TrelloBoardList
              {...props}
              boardCollectionRef={db.collection("elasticlists")}
              docs={docs}
              cardPath="/listcrm"
              hideNew={false}
              displayDate={(lastUpdated) =>
                new Date(lastUpdated * 1000).toLocaleDateString()
              }
              config={{
                fields: ["NAME", "ADDRESSES", "PHONES", "EMAILS"],
                sort: "NAME",
                sortOrder: "asc",
                sources: [],
                showNote: true,
                showStatus: true,
                statuses: []
              }}
              onCreate={(elasticlistDoc) => {
                createListWithoutRecords({
                  elasticlistDoc,
                  aid: userDoc.id,
                  uid: user.uid
                })
              }}
              cardTheme="topBorder"
            >
              <div className="mt-3 d-flex flex-row justify-content-between align-items-middle mb-3">
                <Form.Group>
                  <Form.Label className="mb-0">
                    <small>Sort by</small>
                  </Form.Label>
                  <Form.Control
                    size="sm"
                    as="select"
                    onChange={(e) => {
                      let nsortBy = e.target.value;
                      console.log("sortBy: ", nsortBy);
                      setSortBy(nsortBy);
                    }}
                    value={sortBy}
                  >
                    {sortByOptions.map((opt) => {
                      return <option key={opt.name} value={opt.name}>{opt.name}</option>;
                    })}
                  </Form.Control>
                </Form.Group>
                <AutoComplete
                  suggestions={docs}
                  searchKey={"name"}
                  size="sm"
                  style={{ width: "300px" }}
                  placeholder="Search all lists"
                  formLabel={<small>Search</small>}
                  formClassName="mb-0"
                  inputClassName="text-start"
                  clearSearchStyle={{
                    position: "absolute",
                    top: ".3rem",
                    right: "5px",
                    zIndex: "1000",
                  }}
                  listGroupStyle={{
                    zIndex: "1000",
                    position: "absolute",
                    width: "100%"
                  }}
                  suggestion={(suggestion) => suggestion.name}
                  onSelect={(suggestion, searchText) => {
                    history.push(`/listcrm/${suggestion._docId}`);
                  }}
                />
              </div>
            </TrelloBoardList>
          );
        }}
      </CollectionContext.Consumer>
    </Collection>
  );
};

const ViewElasticList = (props) => {
  let { userDoc, fetchFromApi, handleAlerts, user } = props;
  let { listid } = useParams();
  let { config } = useLocation();
  const [listener, setListener] = useState(false);
  const [elasticlistConfig, setElasticlistConfig] = useState(config);
  const [elasticIndexMapping, setElasticIndexMapping] = useState(null);
  const [startPage, setStartPage] = useState(1);
  const [pageSize, setPageSize] = useState(24);
  const [elasticDocs, setElasticDocs] = useState(null);
  const [selectedRecordId, setSelectedRecordId] = useState(null);
  const [displayDoc, setDisplayDoc] = useState(false);
  const [displayNote, setDisplayNote] = useState(false);
  const [show, setShow] = useState(false);
  const [placement, setPlacement] = useState("start");
  const [layout, setLayout] = useState({icon: <SegmentedNav />, name: "tabs"})
  const [tab, setTab] = useState("all");
  const [indexCount, setIndexCount] = useState(null);
  const handleShow = () => setShow(true);
  const handleClose = () => setShow(false);

  const [showMe, setShowMe] = useShowMe("crmIntro", {
    0: false,
    1: false,
    2: false,
    3: false,
    4: false,
    5: false,
    totalSteps: 5,
    onDismiss: "nextStep",
    allowReplay: true
  });
  
  let sort, setSort, sortOrder, setSortOrder, selectedRecord, searchTimeout;
  if (selectedRecordId && elasticDocs) {
    selectedRecord = elasticDocs.hits.hits.find( d => d._id === selectedRecordId);
  }

  const updateDocInList = (nd,d=selectedRecord) => {
    let newDoc = { ...d, ...nd };
    let nDocs = { ...elasticDocs };
    let indexOfDoc = elasticDocs.hits.hits.findIndex(
      (doc) => d._id === doc._id
    );
    if (indexOfDoc !== -1) {
      nDocs.hits.hits[indexOfDoc] = newDoc;
      console.log("Updated the record in TableBody state!");
      setElasticDocs(nDocs);
    }
  }

  const addDocInList = (nd, index = elasticDocs.hits.hits.length) => {
    let nDocs = {...elasticDocs};
    if (index <= elasticDocs.hits.hits.length) {
      nDocs.hits.hits[index] = nd;
      console.log("Added the record in TableBody state!");
      setElasticDocs(nDocs);
    }
  }

  const removeDocInList = (doc) => {
    let nDocs = {...elasticDocs};
    nDocs.hits.hits = nDocs.hits.hits.filter( d => d._id !== doc._id);
    console.log("Removed the record in TableBody statye!");
    setElasticDocs(nDocs);
  }

  const ElasticTabs = props => {
    const {tabs, onSelect} = props;
    let [newTab, setNewTab] = useState("");
    let [edit,setEdit] = useState(false);

    const getTabStyle = (isDragging, draggableStyle) => ({
      // some basic styles to make the items look a bit nicer
      userSelect: "none",
      // change background colour if dragging
      background: isDragging ? "lightgray" : "inherit",
      // styles we need to apply on draggables
      ...draggableStyle,
    });

    const createTab = async () => {
      try {
        setEdit(false);
        if (newTab) {
          await db
          .collection("elasticlists")
          .doc(listid)
          .update({
            statuses:
              firebase.firestore.FieldValue.arrayUnion(newTab),
          });
        }
      } catch(error) {
        console.log("Error adding new status to list: ", error);
      }
    }

    return(
        <DragDropContext
          onDragEnd={(result) => {
            console.log("onDragEnd: ", result);
            if (!result.destination) {
              return;
            }
            try {
              elasticlistConfig._docRef.update({
                statuses: reorder(
                  tabs,
                  result.source.index,
                  result.destination.index
                ).filter(s => s !== "all"),
              });
            } catch (err) {
              console.log("Error updating order after drop and drop: ", err);
            }
          }}
        >
          <Droppable droppableId="tabs" direction="horizontal">
            {(provided) => (
              <Nav 
                ref={provided.innerRef} 
                {...provided.droppableProps} 
                variant="tabs" 
                defaultActiveKey={tab === "all" && tabs.length ? tabs[0] : tab} 
                className={props.className ? props.className : ""} 
                style={props.style ? props.style : null}
              >
                {!tabs.length && 
                  <Nav.Item className="ms-3"
                    key={`tab_all`}
                  >
                    <Nav.Link
                      eventKey={"all"}
                      onClick={() => {
                        if (onSelect) {
                          onSelect("all")
                        }
                        setTab("all");
                      }}
                    >
                      View all records
                    </Nav.Link>
                  </Nav.Item>
                }
                {tabs.map((t,i) => {
                  return(
                    <Draggable
                      key={`tab_${i}`}
                      draggableId={i.toString()}
                      index={i}
                    >
                      {(provided, snapshot) => (
                        <Nav.Item className="ms-3"
                          key={`tab_${t}_${i}`}
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                          style={getTabStyle(
                            snapshot.isDragging,
                            provided.draggableProps.style
                          )}
                          className={`${(tabs.length && i === 0) ? "ms-3" : ""} showOnHover`}
                        >
                          <Nav.Link
                            eventKey={t}
                            onClick={() => {
                              if (onSelect) {
                                onSelect(t)
                              }
                              setTab(t);
                            }}
                          >
                            <span style={{ whiteSpace: "nowrap" }}>
                              <GripVertical className="text-muted viz" />
                              {t}
                              <X className="text-muted ms-4 viz" onClick={e => {
                                e.preventDefault();
                                e.stopPropagation();
                                if (window.confirm("Are you sure you want to delete this tab?") ) {
                                  try {
                                    db.collection("elasticlists").doc(listid).update({
                                      statuses: tabs.filter( tab => tab !== t)
                                    })
                                    if (onSelect) {
                                      onSelect("all");
                                    }
                                  } catch(err) {
                                    console.log("Error deleting tab: ", err);
                                  }
                                }
                              }}
                              />
                            </span>
                          </Nav.Link>
                        </Nav.Item>
                      )}
                    </Draggable>
                  );
                })}
                <Nav.Item>
                  <Nav.Link
                    onClick={() => {
                      if (!edit) {
                        setEdit(true)
                      }
                    }}
                    className="text-dark"
                  >
                    {!edit && 
                      <ShowMe
                        trigger="hover"
                        stepNo={2}
                        showMe={showMe}
                        setShowMe={setShowMe}
                        tutorialId="crmIntro"
                        placement="left"
                        popoverContent={
                          <>
                            <b>Tabs.</b> Organize your list with tabs.  Add a new tab, then change the status of a record to add it to the tab.
                          </>
                        }
                      >
                        <Plus /> 
                      </ShowMe>
                    }
                    {edit && 
                      <Form onSubmit={e => {
                        e.preventDefault();
                        e.stopPropagation();
                        createTab()
                      }}>
                            <Form.Control
                              className="m-0 py-0 px-1"
                              size="sm"
                              type="text"
                              value={newTab}
                              onKeyDown={ e => e.stopPropagation()}
                              onChange={ e => {
                                e.preventDefault();
                                e.stopPropagation();
                                setNewTab(e.target.value)
                              }}
                              autoFocus={true}
                              onClick={ e => e.stopPropagation()}
                              onBlur={e => {
                                e.stopPropagation();
                                createTab()
                              }}
                            />
                      </Form>
                    }
                  </Nav.Link>
                </Nav.Item>
              </Nav>
            )}
          </Droppable>
        </DragDropContext>
    )
  }

  const LayoutDropDown = props => {
    return(
      <DropdownButton 
        id="vel_dd_layout" 
        variant={props.variant ? props.variant : "outline-info"} 
        title={`View as ${layout.name}`}
        size={props.size ? props.size : "md"}
        className={props.className ? props.className : ""}
        style={props.style ? props.style : null}
      >
        {[
          {name: "tabs", icon: <SegmentedNav />},
          {name: "table", icon: <TableIcon />},
          //{name: "kanban", icon: <Kanban />}
        ].map(l => {
          return(
            <Dropdown.Item onClick={() => setLayout(l)}>
              {l.name}
            </Dropdown.Item>
          )
        })}
      </DropdownButton>
    )
  }

  const ManageColumnsView = (props) => {
    const { fields, setFields, allFields, listConfig } = props;
    const [showModal, setShowModal] = useState(false);

    return (
      <React.Fragment>
        <ShowMe
          trigger="hover"
          stepNo={1}
          showMe={showMe}
          setShowMe={setShowMe}
          tutorialId="crmIntro"
          placement="left"
          popoverContent={
            <><b>Manage Columns.</b> Pick which columns you want to display.</>
          }
        >
        <Button
          variant={props.variant ? props.variant : "link"}
          size={props.size ? props.size : "sm"}
          className={props.className ? props.className : ""}
          style={props.style ? props.style : null}
          onClick={() => {
            setShowModal(!showModal);
          }}
        >
          {props.children ? props.children : "View More Columns"}
        </Button>
        </ShowMe>
        <Modal
          show={showModal}
          onHide={() => setShowModal(false)}
          size={props.modalSize ? props.modalSize : "sm"}
        >
          <Modal.Body>
            <Modal.Title>
              {props.modalTitle ? props.modalTitle : "View More Columns"}
            </Modal.Title>
            <Form>
              <Form.Text>
                Select which columns you'd like to be displayed
              </Form.Text>
              {allFields.map((f, i) => {
                return (
                  <Form.Check
                    checked={fields.includes(f)}
                    onChange={() => {
                      let newFields;
                      if (fields.includes(f)) {
                        setFields([...fields.filter((field) => f !== field)]);
                      } else {
                        setFields([...fields, f]);
                      }
                    }}
                    label={f}
                    key={`viewColumns_${f}_${i}`}
                  />
                );
              })}
              <hr />
              <Form.Check
                checked={listConfig.showNote ? listConfig.showNote : false}
                onChange={() =>
                  listConfig._docRef.update({
                    showNote:
                      typeof listConfig.showStatus !== "undefined"
                        ? !listConfig.showNote
                        : true,
                  })
                }
                label="Note"
                key="viewShowStatusColumn"
              />
              <Form.Check
                checked={listConfig.showStatus ? listConfig.showStatus : false}
                onChange={() =>
                  listConfig._docRef.update({
                    showStatus:
                      typeof listConfig.showStatus !== "undefined"
                        ? !listConfig.showStatus
                        : true,
                  })
                }
                label="Status"
                key="viewShowStatusColumn"
              />
            </Form>
          </Modal.Body>
        </Modal>
      </React.Fragment>
    );
  };

  const ListTableHeader = (props) => {
    const {
      fields,
      setFields,
      allFields,
      listConfig,
      sort,
      setSort,
      sortOrder,
      setSortOrder,
    } = props;
    //console.log("ListTableHeader fields: ", fields);
    if (!fields) {
      return null;
    }
    let headers = fields.map((f, i) => {
      return { f };
    });
    const getItemStyle = (isDragging, draggableStyle) => ({
      // some basic styles to make the items look a bit nicer
      userSelect: "none",
      // change background colour if dragging
      background: isDragging ? "lightgray" : "inherit",
      // styles we need to apply on draggables
      ...draggableStyle,
    });

    const sortTable = (f) => {
      console.log("clicked to sort: ", f);
      if (sort === f) {
        if (sortOrder === "asc") {
          setSort(sort, "desc");
        } else {
          setSort("", "asc");
        }
      } else {
        setSort(f, "asc");
      }
    };

    const SortButton = (props) => {
      const { field } = props;
      return (
        <Button
          variant="link"
          className={`ms-2 ${sort === field ? "text-primary" : "text-muted"} ${props.className ? props.className : ""}`}
          onClick={() => {
            sortTable(field);
          }}
          style={props.style ? {cursor: "pointer", ...props.style } : {cursor: "pointer"}}
        >
          {sort !== field && <ChevronExpand />}
          {sort === field && sortOrder === "desc" && <SortAlphaUpAlt />}
          {sort === field && sortOrder === "asc" && <SortAlphaDown />}
        </Button>
      );
    };

    return (
      <DragDropContext
        onDragEnd={(result) => {
          console.log("onDragEnd: ", result);
          if (!result.destination) {
            return;
          }
          try {
            listConfig._docRef.update({
              fields: reorder(
                fields,
                result.source.index,
                result.destination.index
              ),
            });
          } catch (err) {
            console.log("Error updating order after drop and drop: ", err);
          }
        }}
      >
        <Droppable droppableId="listheaders" direction="horizontal">
          {(provided) => (
            <thead ref={provided.innerRef} {...provided.droppableProps}>
              <ShowMe
                trigger="hover"
                stepNo={0}
                showMe={showMe}
                setShowMe={setShowMe}
                tutorialId="crmIntro"
                placement="top"
                popoverContent={
                  <><b>Your Data is Here.</b> Now you can manage it here. 
                    <ul>
                      <li>Drag and drop columns to Reorder, Sort, and Hide.</li>
                      <li>Click to call phone numbers using your computer.</li>
                      <li>Hover over ages and addresses to see more info.</li>
                      <li>Click to email from your your computer.</li>
                      <li>Click a name to view and edit the information.</li>
                      <li>Add notes for later.</li>
                      <li>Change the status to manage your workflow.</li>
                    </ul>
                    <i>Coming Soon!</i> Assign team members to a list or record.
                  </>
                }
              >
              <tr>
                <th key={`row_number_th`} className="row_number_th">
                  &nbsp;
                </th>
                {fields.map((f, i) => {
                  return (
                    <Draggable
                      key={`d_${i}`}
                      draggableId={i.toString()}
                      index={i}
                    >
                      {(provided, snapshot) => (
                        <th
                          key={`th_${f}_${i}`}
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                          style={getItemStyle(
                            snapshot.isDragging,
                            provided.draggableProps.style
                          )}
                          className="showOnHover"
                        >
                          <span style={{ whiteSpace: "nowrap" }}>
                            <GripVertical className="text-muted viz" />
                            {f}
                            <SortButton field={f} className="viz" />
                            <X className="text-muted viz"
                              style={{cursor: "pointer"}}
                              onClick={async e => {
                                e.preventDefault();
                                e.stopPropagation();
                                if (window.confirm("Are you sure you want to hide this column?") ) {
                                  try {
                                    await db.collection("elasticlists").doc(listid).update({
                                      fields: firebase.firestore.FieldValue.arrayRemove(f)
                                    });
                                  } catch(err) {
                                    console.log("Error hiding field: ", err);
                                  }
                                }
                              }}
                            />
                          </span>
                        </th>
                      )}
                    </Draggable>
                  );
                })}
                {provided.placeholder}
                {listConfig.showNote && (
                  <th
                    key={`note_column_th`}
                    className="note_column_th"
                    style={{ whiteSpace: "nowrap" }}
                  >
                    Notes{" "}
                    <Button
                      variant="link"
                      style={{ textDecoration: "none" }}
                      className="text-dark"
                    >
                      &nbsp;
                    </Button>
                  </th>
                )}
                {listConfig.showStatus && (
                  <th key={`status_column_th`} className="status_column_th showOnHover">
                    Status <SortButton field={`LISTMAP.${listid}`} className="viz" />
                  </th>
                )}
                <th key={`add_column_th`} className="add_column_th">
                  <ManageColumnsView
                    fields={fields}
                    setFields={setFields}
                    allFields={allFields}
                    className="p-0"
                    listConfig={listConfig}
                  >
                    <PlusCircleDotted className="text-dark" size={20} />
                  </ManageColumnsView>
                </th>
              </tr>
              </ShowMe>
            </thead>
          )}
        </Droppable>
      </DragDropContext>
    );
  };

  const ListTableBody = (props) => {
    let {
      docs,
      fields,
      listConfig,
      setOffCanvasContent,
      handleShow,
      handleClose,
      show,
      setPlacement,
    } = props;

    if (!docs || !fields) {
      return null;
    }
    
    return (
      <tbody>
        {docs.map((d, i) => {
          //console.log("d: ", d);
          let fieldsPresentInDoc = fields.filter( value => Object.keys(d._source).includes(value) );
          return (
            <tr key={`tb_tr_${d._id}_${i}`}
            onClick={async () => {
              setSelectedRecordId(d._id);
              setDisplayNote(true);
              setDisplayDoc(true);
              if (show) {
                //handleClose();
                await setOffCanvasContent(null);
              }
              handleShow();
            }}
            >
              <td
                key={`blank_col_for_row_number_${i}`}
                className="row_number_td text-muted align-middle"
              >
                <small>{ ( docs.length * (startPage -1) ) + i + 1}</small>
              </td>
              {fields.map((f, index) => {
                //console.log("f: ", f);
                if (!fieldsPresentInDoc.includes(f)) {
                  return(
                    <td className="align-middle" key={`tb_td_${d._id}_${index}_${f}`}>&nbsp;</td>
                  )
                }
                if (["PHONES"].includes(f)) {
                  return(
                    <td key={`tb_td_${d._id}_${index}_${f}`}>
                      {d._source[f].map((c, ind) => {
                        let keyId = `phone_td_${index}_${f}_${ind}`;
                        return (
                          <Phone key={keyId} keyId={keyId} phoneObject={c} />
                        );
                      })}
                    </td>
                  )
                }
                if (["EMAILS"].includes(f)) {
                  return(
                    <td key={`tb_td_${d._id}_${index}_${f}`} className="align-middle">
                      {d._source[f].map( (c,ind) => {
                        let keyId = `email_td_${index}_${f}_${ind}`;
                        return(
                          <React.Fragment key={keyId}>
                          <Email emailObject={c} keyId={keyId} className="d-xs-block" />
                          <br />
                          </React.Fragment>
                        )
                      })}
                    </td>
                  );
                }
                if (["ADDRESSES"].includes(f)) {
                  return(
                    <td key={`tb_td_${d._id}_${index}_${f}`} className="align-middle">
                      {d._source[f].map( (c,ind) => {
                        let keyId = `addr_td_${d._id}_${ind}`;
                        return(
                          <Addr addrObject={c} key={keyId} keyId={keyId} />
                        );
                      })}
                    </td>
                  );
                }
                if (["AGE"].includes(f)) {
                  return(
                    <td key={`tb_td_${d._id}_AGE`} className="align-middle"> 
                      <Age ageObject={d._source[f]} keyId={`${d._id}_AGE`} />
                    </td>
                  )
                }
                if (["CREATEDBY", "ASSIGNEDTO"].includes(f)) {
                  return (
                    <td key={`tb_td_${d._id}_${index}_${f}`} className="text-center align-middle">
                      <div
                        style={{
                          borderRadius: "50%",
                          width: "25px",
                          height: "25px",
                          fontSize: "20",
                          margin: "0 auto"
                        }}
                        className="bg-secondary text-white text-center"
                      >
                        {props.teamMembers
                          .find((t) => t.uid === d._source[f])
                          ["email"][0].toUpperCase()}
                      </div>
                    </td>
                  );
                }
                if (["HH_INCOME"].includes(f)) {
                  
                  return (
                    <td key={`tb_td_${d._id}_${index}_${f}`} className="align-middle">
                      <HhIncome code={d._source[f]} />
                    </td>
                  );
                }
                return (
                  <td key={`tb_td_${d._id}_${index}_${f}`} className="align-middle">
                    {d._source[f]}
                  </td>
                );
              })}
              {listConfig.showNote && (
                <td key={`note_column_td${d._id}`} className="align-middle">
                  <Button
                    variant="link"
                    style={{ textDecoration: "none" }}
                    className="text-muted"
                    onClick={async e => {
                      e.preventDefault();
                      e.stopPropagation();
                      setSelectedRecordId(d._id);
                      setDisplayNote(true);
                      setDisplayDoc(false);
                      handleShow();
                    }}
                  >
                    {typeof d._source.NOTE !== "undefined" ? (
                      <JournalText />
                    ) : (
                      <Journal />
                    )}
                  </Button>
                </td>
              )}
              {listConfig.showStatus && (
                <td key={`status_column_td_${d._id}`} style={{minWidth: "120px"}} className="align-middle">
                  <StatusSelect
                    {...props}
                    doc={d}
                    list={listid}
                    statuses={elasticlistConfig.statuses || []}
                    updateElasticDoc={updateElasticDoc}
                    size="sm"
                    onSuccess={(newDoc) => {
                      let nDocs = { ...elasticDocs };
                      let indexOfDoc = elasticDocs.hits.hits.findIndex(
                        (doc) => d._id === doc._id
                      );
                      if (indexOfDoc !== -1) {
                        nDocs.hits.hits[indexOfDoc] = newDoc;
                        setElasticDocs(nDocs);
                      }
                    }}
                  />
                </td>
              )}

              <td key={`blank_col_for_view_columns`} className="add_column_td align-middle">
                &nbsp;
              </td>
            </tr>
          );
        })}
        {listid !== "archived" &&
          <tr id="newRecordTr">
            <td id="newRecordTd" colSpan={fields.length + 2 + (elasticlistConfig.showNote ? 1 : 0) + (elasticlistConfig.showStatus ? 1 : 0)} className="align-middle">
              <AddRecord 
                {...props}
                listid={listid}
                fields={fields} 
                onAdd={async (nd) => {
                  try {
                    let doc = await addElasticDoc({
                      index: `crm_${userDoc.id}`.toLowerCase(),
                      refresh: true,
                      newDoc: nd
                    });
                    console.log("doc: ", doc);
                    let fd = {
                      _id: doc.body._id,
                      _index: doc.body._index,
                      _source: nd
                    }
                    addDocInList(fd);
                    return fd
                  } catch(err) {
                    console.log("Error adding record: ", err);
                  }
                }} 
              />
            </td>
          </tr>
        }
      </tbody>
    );
  };

  const ListTablePagination = (props) => {
    let { totalDocs, docs, addMoreSize } = props;
    return (
      <div
        className={`${props.className ? props.className : ""}`}
        style={props.style ? props.style : null}
      >
        {docs.length < totalDocs && false && (
          <div className="text-center">
            <Button
              variant="outline-dark"
              className="mb-3 mt-0"
              size="sm"
              onClick={() => setPageSize(docs.length + addMoreSize)}
            >
              Load More
            </Button>
          </div>
        )}
        <Row className="text-secondary text-start justify-content-start align-items-center">
          <Col xs="auto">
            <small>
              Viewing records {(docs.length * (startPage -1) + 1).toLocaleString()} - {((docs.length * (startPage-1)) + docs.length).toLocaleString()} of {totalDocs === 10000 ? `${totalDocs.toLocaleString()}+` : totalDocs.toLocaleString()}
            </small>
          </Col>

          <Col xs="auto">
            <InputGroup size="sm">
              <InputGroup.Text id="pageSize">Page Size</InputGroup.Text>
              <Form.Select size="sm" value={pageSize +1} 
                onChange={ e => {
                  setPageSize( parseInt(e.target.value) -1 );
                  setStartPage( 1 );
                }}
                aria-label="Page Size"
                aria-describedby="pageSize"
              >
                {[25, 50, 100, 500].map( v => {
                  return(
                    <option key={v} value={v}>{v}</option>
                  )
                })}
              </Form.Select>
            </InputGroup>
            
          </Col>

          <Col xs="auto">
            <Row className="align-items-center justify-content-end">
              <Col xs="auto">
                <Button variant="link" style={{ textDecoration: "none"}} className="ms-2 text-dark"
                  onClick={ e => {
                    e.preventDefault();
                    e.stopPropagation();
                    setStartPage( 1 );
                  }}
                  disabled={ pageSize === 1 }
                >
                  <ChevronDoubleLeft />
                </Button>
                <Button variant="link" style={{ textDecoration: "none"}} className="text-dark"
                  onClick={ e => {
                    e.preventDefault();
                    e.stopPropagation();
                    setStartPage( Math.max( 1, startPage - 1 ) );
                  }}
                >
                  <ChevronLeft />
                </Button>
              </Col>
              <Col xs="auto">
                Page {startPage.toLocaleString()} of { Math.floor( totalDocs / (pageSize + 1) ).toLocaleString()}
              </Col>
              <Col xs="auto">
                <Button variant="link" style={{ textDecoration: "none"}} className="text-dark"
                  onClick={ e => {
                    e.preventDefault();
                    e.stopPropagation();
                    setStartPage( Math.min(startPage + 1, Math.floor( totalDocs / (pageSize + 1) ) )) ;
                  }}
                  disabled={ (pageSize +1) * startPage >= 10000 }
                >
                  <ChevronRight />
                </Button>
                <Button variant="link" style={{ textDecoration: "none"}} className="ms-2 text-dark"
                  onClick={ e => {
                    e.preventDefault();
                    e.stopPropagation();
                    setStartPage( Math.floor( totalDocs / (pageSize +1) ) );
                  }}
                  disabled={ (pageSize +1) * startPage >= 10000 }
                >
                  <ChevronDoubleRight />
                </Button>
              </Col>
            </Row>
          </Col>
        </Row>
      </div>
    );
  };

  const ListTableHeaderInfo = (props) => {
    let { totalDocs, docs } = props;
    return (
      <div
        className={`d-flex flex-row align-items-center ${
          props.className ? props.className : ""
        }`}
        style={props.style ? props.style : null}
      >
        <div className="text-secondary text-start ms-2">
          <small>
            Viewing records {(docs.length * (startPage -1) + 1).toLocaleString()} - {((docs.length * (startPage-1)) + docs.length).toLocaleString()} of {totalDocs === 10000 ? `${totalDocs.toLocaleString()}+` : totalDocs.toLocaleString()}
          </small>
        </div>
      </div>
    );
  };

  const TableView = props => {
    return(
      <>
        {layout.name === "tabs" && 
          <ElasticTabs 
            tabs={elasticlistConfig.statuses ? elasticlistConfig.statuses.filter( s => s !== "all") : []} 
            className="mb-3"
          />
        }

        <div className="px-4">
          <ListTableHeaderInfo
            totalDocs={indexCount ? indexCount : elasticDocs ? elasticDocs.hits.total.value : 0}
            docs={elasticDocs ? elasticDocs.hits.hits : []}
            fields={elasticlistConfig.fields ? elasticlistConfig.fields : []}
          />
          <Table className="pb-0 mb-2" responsive hover striped size="sm">
            <ListTableHeader
              fields={elasticlistConfig.fields ? elasticlistConfig.fields : []}
              setFields={(newFields) => {
                setElasticDocs(null);
                elasticlistConfig._docRef.update({
                  fields: newFields,
                  "dslQuery.body._source": newFields,
                });
              }}
              allFields={
                elasticIndexMapping
                  ? Object.keys(
                      elasticIndexMapping.body[
                        elasticlistConfig.dslQuery
                          ? elasticlistConfig.dslQuery.index
                          : elasticlistConfig.queryUrl
                      ].mappings.properties
                    ).filter(
                      (p) =>
                        ![
                          "LISTMAP",
                          "ACCOUNTID",
                          "DELETED",
                          "SOURCEID",
                          "SOURCEINDEX",
                          "SOURCEDOCID",
                          "AREA_CODE",
                          "ASSIGNEDTO",
                          "UPDATEDBY",
                          "SOURCE",
                          "CREATEDBY",
                          "NOTE"
                        ].includes(p)
                    )
                  : elasticlistConfig.fields
                  ? elasticlistConfig.fields
                  : []
              }
              listConfig={elasticlistConfig}
              sort={sort}
              setSort={setSort}
              sortOrder={sortOrder}
              setSortOrder={setSort}
            />
            <ListTableBody
              {...props}
              docs={elasticDocs ? elasticDocs.hits.hits : []}
              fields={elasticlistConfig.fields ? elasticlistConfig.fields : []}
              listConfig={elasticlistConfig}
              setPlacement={setPlacement}
              handleShow={handleShow}
              handleClose={handleClose}
              show={show}
            />
          </Table>
          <ListTablePagination
            totalDocs={indexCount ? indexCount : elasticDocs ? elasticDocs.hits.total.value : 0}
            docs={elasticDocs ? elasticDocs.hits.hits : []}
            fields={elasticlistConfig.fields ? elasticlistConfig.fields : []}
            addMoreSize={25}
            className="mb-5 pb-5"
          />
        </div>
      </>
    )
  }

  const KanbanView = props => {

    let [newTab, setNewTab] = useState("");
    let [edit,setEdit] = useState(false);

    if (!elasticlistConfig) {
      return null
    }

    let cardsByStatus = {};
    if(elasticlistConfig) {
      elasticlistConfig.statuses.map( (s,i) => {
        if (!cardsByStatus[s]) {
          cardsByStatus[s] = [];
        }
      });
    }

    const getColStyle = (isDragging, draggableStyle) => ({
      // some basic styles to make the items look a bit nicer
      maxWidth: "300px",
      userSelect: "none",
      // change background colour if dragging
      background: isDragging ? "lightgray" : "inherit",
      // styles we need to apply on draggables
      ...draggableStyle,
    });

    const createStatus = async () => {
      try {
        if (newTab) {
          await db
          .collection("elasticlists")
          .doc(listid)
          .update({
            statuses:
              firebase.firestore.FieldValue.arrayUnion(newTab),
          });
        }
        setEdit(false);
      } catch(error) {
        console.log("Error adding new status to list: ", error);
      }
    }

    if (elasticDocs) {
      elasticDocs.hits.hits.map( d => {
        let {CO_NAME, FN, LN, NAME, ADDRESSES, PHONES, EMAILS, LISTMAP} = d._source;
        console.log("EMAILS: ", EMAILS);
        cardsByStatus[LISTMAP[listid]].push(
          <Card key={`card_${d._id}`} className="m-2">
            <Card.Body>
              <Card.Title>{CO_NAME ? CO_NAME : `${FN ? FN : ""} ${LN ? LN : ""}`}</Card.Title>
            </Card.Body>
            {PHONES &&
              <ListGroup className="list-group-flush">
                {PHONES.map( (p,i) => {
                  let keyId=`PHONE_${i}${d._id}`;
                  return(
                    <ListGroupItem key={keyId} className="p-1"><Phone phoneObject={p} keyId={keyId} /></ListGroupItem>
                  )
                })}
              </ListGroup>
            }
            {EMAILS.DATA &&
              <ListGroup className="list-group-flush">
                {EMAILS.map( (p,i) => {
                  let keyId=`EMAIL_${i}${d._id}`;
                  return(
                    <ListGroupItem key={keyId} className="p-0"><Email emailObject={p} keyId={keyId} /></ListGroupItem>
                  )
                })}
              </ListGroup>
            }
            {ADDRESSES &&
              <ListGroup className="list-group-flush p-1">
                {ADDRESSES.map( (p,i) => {
                  let keyId=`ADDR_${i}${d._id}`;
                  return(
                    <ListGroupItem key={keyId} className="p-0"><Addr addrObject={p} keyId={keyId} /></ListGroupItem>
                  )
                })}
              </ListGroup>
            }

          </Card>
        )
      })
    }
    return(
      <DragDropContext
        onDragEnd={(result) => {
          console.log("onDragEnd: ", result);
          if (!result.destination) {
            return;
          }
          try {
            elasticlistConfig._docRef.update({
              statuses: reorder(
                elasticlistConfig.statuses,
                result.source.index,
                result.destination.index
              ).filter(s => s !== "all"),
            });
          } catch (err) {
            console.log("Error updating order after drop and drop: ", err);
          }
        }}
      >
        <Droppable droppableId="tabs" direction="horizontal">
          {(provided) => (
            <Container ref={provided.innerRef} {...provided.droppableProps} fluid>
              <Row>
                {elasticlistConfig.statuses.map( (s,i) => {
                  return(
                    <Draggable
                      key={`col_${s}_${i}`}
                      draggableId={i.toString()}
                      index={i}
                      
                    >
                      {(provided, snapshot) => (
                        <Col key={`kanban_col_${i}`} className="p-2 pt-3 bg-light m-2"
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                          style={getColStyle(
                            snapshot.isDragging,
                            provided.draggableProps.style
                          )}
                        >
                          <div className="d-flex flex-row justify-content-between">
                            <h6 className="ps-2">{s}</h6>
                            <X className="text-muted ms-4" 
                              onClick={() => {
                                if (window.confirm("Are you sure you want to delete this status?") ) {
                                  try {
                                    db.collection("elasticlists").doc(listid).update({
                                      statuses: elasticlistConfig.statuses.filter( status => status !== s)
                                    })
                                  } catch(err) {
                                    console.log("Error deleting tab: ", err);
                                  }
                                }
                              }}
                              style={{cursor: "default"}}
                            />
                          </div>
                          
                          {cardsByStatus[s]}
                        </Col>
                      )}
                      
                    </Draggable>
                  )
                })}
                <Col key={`add_kanban_status`} style={getColStyle(false,{height: "45px"})}
                  className="bg-light p-2 m-2"
                >
                  <div style={{whiteSpace: "nowrap"}}
                    onClick={() => {
                      if (!edit) {
                        setEdit(true)
                      }
                    }}
                  >
                    {!edit && (<span><Plus /> Add another status</span>)}
                    {edit && 
                      <Form onSubmit={(e) => {
                        e.preventDefault();
                        createStatus()
                      }}
                      >
                        <Form.Group>
                        <Form.Control
                          type="text"
                          value={newTab}
                          onChange={ e => setNewTab(e.target.value)}
                          autoFocus={true}
                          onBlur={() => createStatus()}
                        />
                      </Form.Group>
                      <Button type="submit">Add status</Button>
                    </Form>
                    }
                  </div>
                </Col>
              </Row>
            </Container>
          )}
        </Droppable>
      </DragDropContext>
    )
  }

  const RightButtons = props => {
    const [showSearch, setShowSearch] = useState(false);
    
    return(
      <Row className="flex-grow-1 justify-content-end align-items-center gx-0">
        <Col className="pe-0 flex-grow-1 ms-2 d-flex flex-row justify-content-end">
          {showSearch &&
            <AutoComplete 
              className="elasticSearchInput me-2 text-start"
              inputClassName="text-start"
              style={{width: "100%"}}
              showSubmit={true}
              placeholder="Search for records"
              submitText={<Search size={18} />}
              submitVariant={`outline-${elasticlistConfig.themeColor}`}
              onSearch={ searchCrm }
              onSubmit={ searchCrm }
              suggestion={ h => {
                console.log("h: ", h);
                let name = ["CO_NAME", "FULL_NAME", "FN", "LN"].map( f => {
                  if (h.highlight) {
                    if ( h.highlight[f] ) {
                      return h.highlight[f];
                    }
                  }
                  
                  if (h._source[f]) {
                    return h._source[f];
                  }
                  return "";
                }).join(" ");
                let description = h.highlight ?  Object.keys( h.highlight ).filter( f => !["CO_NAME", "FULL_NAME", "FN", "LN", "NAME"].includes(f) ).map( f => h.highlight[f] ).join(" ") : "";
                console.log("name: ", name, "description: ", description);
                return(
                  <div className="d-flex flex-row justify-content-between align-items-center">
                    <div>
                      <b
                        dangerouslySetInnerHTML={{
                          __html: name.toLowerCase()
                        }}
                        className="text-capitalize"
                      />
                      <p 
                        className="p-0 m-0"
                        dangerouslySetInnerHTML={{
                          __html: description
                        }}
                      />
                    </div>
                    <Badge pill bg="light" text="dark">
                      <small className="text-muted">view</small>
                    </Badge>
                  </div>
                );
              }}
              onSelect={ (suggestion, searchtext) => {
                let ndocs = {...elasticDocs};
                ndocs.hits.hits.push(suggestion);
                setElasticDocs(ndocs);
                setSelectedRecordId(suggestion._id);
                setDisplayDoc(true);
                setDisplayNote(true);
                setShow(true);
              }}
              size="lg"
              autoFocus={true}
              onBlur={ e => {
                setShowSearch(false);
              }}
            />
            
          }
          {!showSearch &&
            <Button size="lg" className="me-2" variant={`outline-${elasticlistConfig.themeColor}`} onClick={() => setShowSearch(true)}>
              <Search size={18} />
            </Button>
          }
        </Col>
        <Col xs="auto" className="pe-2">
          <ShowMe
            trigger="hover"
            stepNo={4}
            showMe={showMe}
            setShowMe={setShowMe}
            tutorialId="crmIntro"
            placement="bottom"
            popoverContent={
              <><b>Upload more records.</b> Combine and manage your lists by uploading more records from other CSVs. Easily spot and remove duplicates. And more.</>
            }
          >
            <div>
          <UploadToCrmButton 
            key="uploadToCrmButton"
            {...props} 
            list={listid}
            listConfig={elasticlistConfig}
            variant={`outline-${elasticlistConfig.themeColor}`}
            bulkAddElasticDoc={bulkAddElasticDoc}
            onUpload={() => fetchElasticDocs(elasticlistConfig)}
            size="lg"
          >
            <CloudUpload size={24} /> Upload
          </UploadToCrmButton>
          </div>
          </ShowMe>
        </Col>
        <Col xs="auto">
          <ShowMe
            trigger="hover"
            stepNo={3}
            showMe={showMe}
            setShowMe={setShowMe}
            tutorialId="crmIntro"
            placement="bottom"
            popoverContent={
              <><b>Download to CSV.</b> Prefer a spreadsheet? Or need your data to import into other software? One click to download your data directly to a CSV file.  Open with Excel, Google Sheets, or any standard spreadsheet software.</>
            }
          >
            <div>
          <DownloadIndexToCsv
            {...props}
            listConfig={elasticlistConfig}
            indexCount={indexCount}
            variant={elasticlistConfig.themeColor}
            size="lg"
            key="DownloadIndexToCsvButton"
          >
            <Download size={24} /> Download
          </DownloadIndexToCsv>
          </div>
          </ShowMe>
        </Col>
    </Row>
    )
  }

  const searchCrm = async (query) => {
    return new Promise( async (resolve, reject) => {
      const runSearch = async () => {
        let fuzzyQuery = `*${query}*`
        let body = {
          query: {
            bool: {
              filter: [
                {
                  term: {
                    "DELETED": listid === "archived" ? true : false
                  }
                }
              ],
              should: [
                {
                  simple_query_string : {
                    query: query + "*"
                  }
                },
                {
                  match: {
                    "NOTE.content": {
                      query: fuzzyQuery
                    }
                  }
                },
                {
                  nested: {
                    path: "EMAILS",
                    query: {
                      match: {
                        "EMAILS.DATA": {
                          query
                        }
                      }
                    }
                  }
                },
                {
                  nested: {
                    path: "PHONES",
                    query: {
                      match: {
                        "PHONES.DATA": {
                          query
                        }
                      }
                    }
                  }
                },
                {
                  query_string: {
                    query
                  }
                },
                {
                  nested: {
                    path: "PHONES", 
                    query: {
                      query_string: {
                        query: query+"*",
                        default_field: "PHONES.DATA"
                      }
                    }
                  }
                },
                {
                  nested: {
                    path: "ADDRESSES",
                    query: {
                      simple_query_string: {
                        query: query + "*",
                        fields: ["ADDRESSES.DATA.ADDR", "ADDRESSES.DATA.APT", "ADDRESSES.DATA.CITY", "ADDRESSES.DATA.ST", "ADDRESSES.DATA.ZIP", "ADDRESSES.DATA.Z5", "ADDRESSES.DATA.Z4", "ADDRESSES.DATA.STRING"]
                      }
                    }
                  }
                }
              ]
            }
          }
        };

        let textFields = Object.entries( elasticIndexMapping.body[elasticlistConfig.dslQuery.index].mappings.properties ).map( ([key, value]) => {
          if (value.type === 'text') {
            return key
          }
        }).filter( v => v !== undefined);
        //console.log("textFields: ", textFields);
        if ( !["all", "archived"].includes(listid) ) {
          body.query.bool.filter.push({
            exists: {
              field: `LISTMAP.${listid}`
            }
          })
        }
        if (textFields.length) {
          let fields = {
            "NOTE.content": {},
            "EMAILS.DATA": {},
            "PHONES.DATA": {},
            "ADDRESSES.DATA.STRING": {}
          };
          textFields.forEach( field => fields[field] = {});
          //console.log("fields: ", fields);
          body.highlight = {fields};
          body.query.bool.should[0].simple_query_string.fields = [...textFields]
        }
        //console.log("body: ", body);
        let hits = await fetchFromApi({
          endpoint: "/api/v2/search",
          body: JSON.stringify({
            search: {
              index: elasticlistConfig.dslQuery.index,
              size: 10,
              body
            }
          })
        }) 
        //console.log("hits: ", hits, hits.hits.hits.length);
        if (hits.hits.hits.length > 0) {
          let suggestions = hits.hits.hits;
          resolve(suggestions);
        } 
        resolve( [] );
      }

      if (searchTimeout) {
        clearTimeout(searchTimeout);
      }
      searchTimeout = await setTimeout(runSearch, 200);
    })
    
  }

  const fetchElasticIndexMapping = async (index) => {
    if (!index) {
      return console.log("No index to get a mapping for.");
    }
    let mapping = await fetchFromApi({
      endpoint: "/api/elastic/getMapping",
      body: JSON.stringify({
        index,
      }),
    });
    console.log("mapping: ", mapping);
    setElasticIndexMapping(mapping);
  };

  const fetchElasticListConfig = async (listid) => {
    let listConfig = await listenDoc(
      {
        ref: db.collection("elasticlists").doc(listid),
      },
      (error, listConfig) => {
        if (error) {
          console.log("Error fetching ElasticListConfig: ", error);
        }
        if (!listConfig.dslQuery.index) {
          listConfig.dslQuery.index = `crm_${userDoc.id.toLowerCase()}`
        }
        console.log("listConfig fetched! ", listConfig);
        if (!listener) {
          setListener(true);
        }
        setElasticlistConfig(listConfig);
      }
    );

    return listConfig;
  };

  const addElasticDoc = async (params, callback) => {
    let { newDoc, index} = params;
    return await fetchFromApi(
      {
        endpoint: "/api/v2/index",
        body: JSON.stringify({
          payload: {
            index,
            body: newDoc
          }
        })
      },
      callback
    )
  };

  const bulkAddElasticDoc = async (params, callback) => {
    let { docs, index } = params;
    let body = docs.flatMap( doc => [{ index: {_index: index} }, doc]);
    console.log("body: ", body);
    return await fetchFromApi(
      {
        endpoint: "/api/v2/bulk_index",
        body: JSON.stringify({
          index,
          refresh: true,
          body
        })
      },
      callback
    )
  }; 

  const updateElasticDoc = async (params, callback) => {
    let { body } = params;
    return await fetchFromApi(
      {
        endpoint: "/api/elastic/update",
        body: JSON.stringify(body),
      },
      callback
    );
  };

  const getSortObject = (sort, sortOrder) => {
    let field = sort ? sort.split(".")[0] : "default";
    console.log("sort: ", sort);
    let nestedSortDict = {
      EMAILS: {
        "EMAILS.DATA.keyword": {
          order: sortOrder,
          nested: {
            path: "EMAILS",
          },
        },
      },
      PHONES: {
        "PHONES.DATA.keyword": {
          order: sortOrder,
          nested: {
            path: "PHONES",
          },
        },
      },
      ADDRESSES: {
        "ADDRESSES.DATA.ADDR.keyword": {
          order: sortOrder,
          nested: {
            path: "ADDRESSES",
          },
        },
      },
      AGE: {
        "AGE.DATA.keyword": {
          order: sortOrder,
          nested: {
            path: "AGE",
          },
        },
      },
      default: {
        _id: {
          order: "asc",
        },
      },
      LISTMAP: {
        [`LISTMAP.${listid}.keyword`]: {
          order: sortOrder
        }
      }
    };
    let fieldMapping = elasticIndexMapping.body[
        elasticlistConfig.dslQuery
          ? elasticlistConfig.dslQuery.index
          : elasticlistConfig.queryUrl
      ].mappings.properties;

    let sortArray = [];
    let sortObject = nestedSortDict[field]
      ? nestedSortDict[field]
      : ["text", "keyword"].includes(fieldMapping[field].type) 
        ? {
            [`${field}.keyword`]: {
              order: sortOrder,
            },
          }
        : {
            [`${field}`]: {
              order: sortOrder,
            },
          }

    sortArray.push(sortObject);
    return sortArray;
  };

  const fetchElasticDocs = async (config) => {
    // fetch from elasticsearch
    // View the lists predefined dslQuery
    //console.log("fetchElasticDocs config: ", config);
    if (config.dslQuery) {
      let dslQuery = { ...config.dslQuery };
      // if there isn't an index in the dslquery, use the accounts default index
      let defaultIndex = elasticlistConfig.dslQuery
        ? elasticlistConfig.dslQuery.index
          ? elasticlistConfig.dslQuery.index
          : `crm_${userDoc.id.toLowerCase()}`
        : elasticlistConfig.queryUrl
        ? elasticlistConfig.queryUrl.index
        : `crm_${userDoc.id.toLowerCase()}`

      if (!elasticlistConfig.dslQuery.index) {
        dslQuery.index = defaultIndex;
      }

      dslQuery.size = pageSize + 1;
      
      if (startPage) {
        dslQuery.from = (startPage -1) * pageSize;
      }
      if (config.fields) {
        dslQuery.body._source = config.fields;
      }
      if (elasticlistConfig.fields.length > 0) {
        dslQuery.body._source = [...elasticlistConfig.fields, "LISTMAP", "NOTE"];
      }
      if (config.sort) {
        if ( config.sort[user.uid] ) {
          dslQuery.body.sort = getSortObject(sort, sortOrder);
        }
      }
      if (layout.name === "tabs") {
        let filter = dslQuery.body.query.bool.filter.filter( f => {
          if ( typeof f.term !== "undefined" ) {
            return !f.term[`LISTMAP.${listid}.keyword`]
          } else {
            return true
          }
        });
        if ( tab !== "all" ) {
          filter.push({
            term: { [`LISTMAP.${listid}.keyword`]: tab}
          });
        }
        dslQuery.body.query.bool.filter = filter;
      }
      let docs = await fetchFromApi({
        endpoint: "/api/v2/search",
        body: JSON.stringify({ search: dslQuery }),
      });
      console.log("fetchElasticdocs config: ", config, "dslQuery: ", dslQuery, "docs: ", docs);
      return setElasticDocs(docs);
    }

    // Just view the data using the existing download document criteria
    // Convert to a SQL statement
    if (config.queryUrl) {
      let queryUrl = { ...config.queryUrl };
      if (config.offset) {
        queryUrl.filter = `${queryUrl.filter} and (id > ${config.offset})`;
      }
      queryUrl.fields = `${queryUrl.fields},id`;
      queryUrl.limit = pageSize + 1;
      // Translate the SQL statement into dslQuery
      let docs = await fetchFromApi({
        endpoint: `/api/v2/data/${queryUrl.index}`,
        body: JSON.stringify(queryUrl),
      });
      // Fetch the dsl query results
      //console.log("docs: ", docs);
      // Display the results
    }
  };

  const fetchIndexCount  = async (config) => {
    if (config.dslQuery) {
      let dslQuery = { ...config.dslQuery };
      // if there isn't an index in the dslquery, use the accounts default index
      let defaultIndex = elasticlistConfig.dslQuery
        ? elasticlistConfig.dslQuery.index
          ? elasticlistConfig.dslQuery.index
          : `crm_${userDoc.id.toLowerCase()}`
        : elasticlistConfig.queryUrl
        ? elasticlistConfig.queryUrl.index
        : `crm_${userDoc.id.toLowerCase()}`

      if (!elasticlistConfig.dslQuery.index) {
        dslQuery.index = defaultIndex;
      }
      let {count} = await fetchFromApi({
        endpoint: "/api/v2/count",
        body: JSON.stringify({ search: dslQuery })
      });
      return setIndexCount(count);
    }
  }

  //console.log(
  //  "ViewElasticList config: ",
  //  config,
  //  "elasticListconfig: ",
  //  elasticlistConfig,
  //  "props: ",
  //  props
  //);
  useEffect(() => {
    if (!listener) {
      // fetch config from firestore on cold page load
      fetchElasticListConfig(listid);
    }
    if (elasticlistConfig) {
      if (!elasticIndexMapping) {
        fetchElasticIndexMapping(
          elasticlistConfig.dslQuery
            ? elasticlistConfig.dslQuery.index
            : elasticlistConfig.queryUrl
            ? elasticlistConfig.queryUrl.index
            : ""
        );
      } else {
        // After we get the mapping, go get the docs
        fetchElasticDocs(elasticlistConfig);
        // If we don't have the index count, go get it
        if (!indexCount) {
          fetchIndexCount(elasticlistConfig);
        }
      }
    }
  }, [listid, startPage, pageSize, elasticlistConfig, elasticIndexMapping, tab, config]);

  if (typeof listid !== "undefined" && elasticlistConfig) {
    sort = elasticlistConfig.sort ? elasticlistConfig.sort[user.uid] ? elasticlistConfig.sort[user.uid] : "" : "";
    sortOrder = elasticlistConfig.sortOrder
      ? elasticlistConfig.sortOrder[user.uid] ? elasticlistConfig.sortOrder[user.uid]
      : "asc" : "asc";
    setSort = (sort, sortOrder) => {
      elasticlistConfig._docRef
        .update({
          [`sort.${user.uid}`]: sort,
          [`sortOrder.${user.uid}`]: sortOrder
        })
        .catch((err) => console.error("Error setSort: ", err));
    };

    return (
      <React.Fragment>
        <Helmet>
          <title>List: {elasticlistConfig.name}</title>
          <meta name="HandheldFriendly" content="true" />
          <meta name="apple-mobile-web-app-capable" content="YES" />
        </Helmet>
        <ShowMe
          trigger="hover"
          align="start"
          stepNo={5}
          showMe={showMe}
          setShowMe={setShowMe}
          tutorialId="crmIntro"
          placement="bottom"
          popoverContent={
            <><b>Manage Your List.</b> Change the name, color, or description of your list.</>
          }
        >
          <div>
        <TrelloBoardHeader
          {...props}
          config={elasticlistConfig}
          backPath="/listcrm"
          hideFavorite={["all", "archived"].includes(listid) ? true : false}
          hideDropMenuDefaults={["all", "archived"].includes(listid) ? true : false}
          dropMenuItems={[
            <LayoutDropDown key="layoutDropDown" size="sm" variant="outline-info" className="ms-3" />
          ]}
          rightNav={
            <RightButtons {...props} />
          }
        />
        </div>
        </ShowMe>
        {["tabs", "table"].includes(layout.name) && 
          <TableView {...props} />
        }
        {["kanban"].includes(layout.name) &&
          <KanbanView {...props} />
        }
        {(selectedRecord && show) &&
          <Offcanvas
            show={show}
            onHide={handleClose}
            backdrop={false}
            placement={placement}
            scroll={true}
          >
            <Offcanvas.Header closeButton>
              <Offcanvas.Title>
                View{" "}
                {selectedRecord._source.NAME
                  ? selectedRecord._source.NAME
                  : selectedRecord._source.CO_NAME
                  ? selectedRecord._source.CO_NAME
                  : selectedRecord._source.FN
                  ? selectedRecord._source.FN
                  : null}
              </Offcanvas.Title>
            </Offcanvas.Header>
            <Offcanvas.Body>
              <AppAlerts {...props} />
              {displayDoc &&
                <ElasticDoc
                  {...props}
                  doc={selectedRecord}
                  docId={selectedRecord._id}
                  index={selectedRecord._index}
                  size="sm"
                  listConfig={elasticlistConfig}
                  indexMap={elasticIndexMapping}
                  onSave={updateDocInList}
                  displayStatus={true}
                  updateElasticDoc={updateElasticDoc}
                  onDelete={(doc) => {
                    removeDocInList(doc);
                    if (selectedRecordId === doc._id) {
                      setSelectedRecordId(null);
                    }
                    setShow(false);
                  }}
                />
              }
              {displayNote &&
                <>
                  <h6 className="mt-5">Notes</h6>
                  <ElasticNote
                    {...props}
                    index={selectedRecord._index}
                    docId={selectedRecord._id}
                    field={"NOTE"}
                    size="sm"
                    noteObject={
                      selectedRecord._source.NOTE
                        ? selectedRecord._source.NOTE
                        : { content: "" }
                    }
                    onSave={(newNote) => {
                      let newDoc = { ...selectedRecord };
                      newDoc._source.NOTE = newNote;
                      updateDocInList(newDoc, selectedRecord);
                    }}
                  />
                </>
              }
            </Offcanvas.Body>
            <ButtonGroup>
              {[
                {
                  placement: "start",
                  icon: <AlignStart />,
                },
                {
                  placement: "end",
                  icon: <AlignEnd />,
                },
                {
                  placement: "top",
                  icon: <AlignTop />,
                },
                {
                  placement: "bottom",
                  icon: <AlignBottom />,
                },
              ].map((b) => {
                return (
                  <Button
                    variant="link"
                    style={{ textDecoration: "none" }}
                    onClick={() => setPlacement(b.placement)}
                    disabled={placement === b.placement}
                    className="text-dark"
                  >
                    {b.icon}
                  </Button>
                );
              })}
            </ButtonGroup>
          </Offcanvas>
        }
      </React.Fragment>
    );
  }

  return <NotFound />;
};

const DownloadIndexToCsv = (props) => {
  //console.log("DownloadIndexToCsv props: ", props);
  const {
    listConfig,
    docIds,
    userDoc,
    user,
    handleAlerts,
    fetchFromApi,
    leadsMultiplier,
    indexCount
  } = props;
  const [downloadDoc, setDownloadDoc] = useState(undefined);
  const [showModal, setShowModal] = useState(false);
  const createCsvFromIndex = async ({
    index,
    docIds, // If this is not defined it will download all Ids
    fields, // If this is not defined it will include all fields
    aid, // Account Id
    email,
    uid, // User Id that initiated the download
    leadsRemaining = 0,
    leadsMultiplier = 0,
    queryUrl,
    dslQuery,
    doc_id = undefined, // if this is not defined, it will create a document automatically
  }) => {
    console.log("Clicked createCsvFromIndeX! aid: ", aid);
    try {
      let newDocId;
      if (!doc_id) {
        // Create a download document
        let downloadParams = {
          searchName: `Download created from ${listConfig.name}`,
          timestamp: new Date(),
          //downloadCount: undefined, // We'll add this automatically in the cloud function
          leadsRemaining,
          leadsMultiplier,
          queryUrl: queryUrl ? queryUrl : "",
          dslQuery: dslQuery ? dslQuery : "",
          email,
          uid,
        };
        let newDoc = await db
          .collection("downloads")
          .doc(aid)
          .collection("files")
          .add(downloadParams);

        newDocId = await newDoc.id;
      }
      listenDoc(
        {
          ref: db
            .collection("downloads")
            .doc(userDoc.id)
            .collection("files")
            .doc(doc_id ? doc_id : newDocId),
        },
        (error, nd) => {
          if (error) {
            handleAlerts(
              "",
              "Uh, oh, something went wrong: ",
              error.message ? error.message : "Unkown error."
            );
          }
          if (nd) {
            setDownloadDoc(nd);
            setShowModal(true);
          }
        }
      );
      let csvParams = {
        index,
        docIds, // If this is not defined it will download all Ids
        fields, // If this is not defined it will include all fields
        aid, // Account Id
        email,
        uid, // User Id that initiated the download
        leadsRemaining,
        leadsMultiplier,
        queryUrl,
        dslQuery,
        doc_id: doc_id ? doc_id : newDocId,
        fileName: `list_shack/${aid}/${santizeFileName(listConfig.name)}`,
        downloadCount: indexCount
      };
      let csvLinkResponse = await fetchFromApi({
        endpoint: "/api/elastic/create_csv_from_index",
        body: JSON.stringify(csvParams),
      });
      console.log("csvLinkResponse: ", csvLinkResponse);
    } catch (err) {
      console.error("Error creating csv from index: ", err);
      handleAlerts(
        "",
        `Uh oh, something went wrong creating a CSV: ${err.message}`,
        "warning"
      );
    }
  };

  return (
    <React.Fragment>
      <Button
        variant={props.variant ? props.variant : "primary"}
        size={props.size ? props.size : "md"}
        className={props.className ? props.className : ""}
        style={props.style ? props.style : null}
        disabled={
          typeof downloadDoc !== "undefined" && downloadDoc
            ? typeof downloadDoc.csvFile === "undefined"
            : false
        }
        onClick={() => {
          createCsvFromIndex({
            index: listConfig.dslQuery
              ? listConfig.dslQuery.index
              : listConfig.queryUrl.index,
            docIds: docIds,
            fields: listConfig.fields,
            aid: userDoc.id,
            email: user.email,
            uid: user.uid,
            leadsRemaining: userDoc.leadsRemaining ? userDoc.leadsRemaining : 0,
            leadsMultiplier,
            queryUrl: listConfig.queryUrl,
            dslQuery: listConfig.dslQuery,
          });
        }}
      >
        {(downloadDoc ? typeof downloadDoc.csvFile === "undefined" : false) && (
          <>
            <Spinner
              as="span"
              animation="border"
              size="sm"
              role="status"
              aria-hidden="true"
            />
            <span className="sr-only">Loading...</span>
          </>
        )}
        {props.children ? props.children : "Download CSV"}
      </Button>
      {typeof downloadDoc !== "undefined" && (
        <Modal show={showModal} onHide={() => setShowModal(false)}>
          {typeof downloadDoc.csvFile !== "undefined" && (
            <Modal.Body>
              <Modal.Title className="text-center">Your CSV Export is Ready!</Modal.Title>
              <DownloadPane
                download={downloadDoc}
                disallowReportAndArchive={true}
              />
            </Modal.Body>
          )}
          {typeof downloadDoc.csvFile == "undefined" && (
            <Modal.Body>
              <ProgressBar 
                variant={listConfig.themeColor ? listConfig.themeColor : "primary"}
                now={ downloadDoc.fetchProgress }
                label={downloadDoc.fetchProgress ? `${downloadDoc.fetchProgress}%` : ""}
              />
              <Modal.Title className="text-center mt-2">
                Preparing Your CSV Export
              </Modal.Title>
              <p className="text-muted">
                Don't want to wait? Close the modal and check your <Link to="/downloads">downloads</Link> later.
              </p>
            </Modal.Body>
          )}
        </Modal>
      )}
    </React.Fragment>
  );
};

const createList = async (params, callback) => {
  let {
    fetchFromApi,
    wait_for_completion,
    refresh,
    reindexQuery,
    reindexFields,
    createJobId,
    aid,
    uid,
    name,
    themeColor,
    sources,
  } = params;
  console.log("createList params: ", params);
  try {
    // Create List Document
    // handle translation of of contact methods
    let fields = reindexFields.map( f => {
      if ( f === "EMAIL") {
        return "EMAILS"
      }
      if ( f === "ADDR") {
        return "ADDRESSES"
      }
      if ( f === "PHONE") {
        return "PHONES"
      }
      return f;
    });
    let ref = db.collection("elasticlists");
    let config = {
      name,
      themeColor,
      dslQuery: {
        index: reindexQuery.dest.index,
        body: {
          _source: fields,
          query: {
            bool: {
              filter: [
                {
                  term: {
                    CREATEJOBID: createJobId,
                  },
                },
                {
                  term: {
                    DELETED: false,
                  },
                },
              ],
            },
          },
          sort: [
            {
              "NAME.keyword": {
                order: "asc",
              },
            },
          ],
        },
      },
      fields,
      sort: "NAME",
      sortOrder: "asc",
      sources,
      showNote: true,
      showStatus: true,
      statuses: ["new"],
    };

    let elasticlistDoc = await createBoard({
      ref,
      config,
      aid,
      uid,
    });
    // Make sure all the docs have a status for the list
    reindexQuery.script.params.LISTMAP = {
      all: "new",
      [elasticlistDoc._docId]: "new",
    };
    // Send reindex job to the API
    let {task} = await fetchFromApi({
      endpoint: "/api/elastic/reindex",
      body: JSON.stringify({
        wait_for_completion,
        refresh,
        requests_per_second: -1,
        body: reindexQuery,
      }),
    });
    if (reindexQuery.max_docs > 10000) {
      // To Do: Set ElasticSearch Watcher on the Task Id to send webhook to update the downloadDoc every 10 seconds
    }
    if (callback) {
      callback(null, { elasticlistDoc, task });
    }
    return({elasticlistDoc, task});
  } catch (error) {
    console.log("Error createList: ", error);
    if (callback) {
      callback(error);
    }
    throw error;
  }
};

const queryUrlToDslQuery = async ({ queryUrl, fetchFromApi }) => {
  let dslQuery = await fetchFromApi({
    endpoint: "/api/v2/translate_sql",
    body: JSON.stringify({ queryUrl }),
  });
  return dslQuery;
};

const addDownloadedByToIndex = (params, callback) => {
  let { index, createJobId, accountId, fetchFromApi, reindexTaskId } = params;
  // Update the original index with downloadedBy field for leadSuppressions
  // Query the crm index for all docs with CREATEJOBID
  let sourceQuery = {
    index,
    body: {
      _source: ["SOURCEINDEX", "SOURCEDOCID", "CREATEJOBID"],
      query: {
        term: {
          CREATEJOBID: createJobId,
        },
      },
    },
  };
  // Add the accountID to the document fetched from the SOURCEINDEX
  let script = {
    source:
      "if (ctx._source.DOWNLOADEDBY == null) { ctx._source.DOWNLOADEDBY = new ArrayList(); } ctx._source.DOWNLOADEDBY.add(params.accountId);",
    params: {
      createJobId,
      accountId,
    },
  };

  fetchFromApi(
    {
      endpoint: "/api/elastic/update_with_sourceQuery_and_script",
      body: JSON.stringify({ sourceQuery, script, reindexTaskId }),
    },
    (error, result) => {
      if (error) {
        if (callback) {
          callback(error);
        }
      }
      if (result) {
        console.log(
          "Successfully updated source index with DOWNLOADEDBY fields: ",
          result
        );
        if (callback) {
          callback(null, result);
        }
      }
    }
  );

  // TO DO:
  // Add a filter to the reindex query of accounts paying for leads suppression
  // To exclude documents where downloadedBy has their account id
};

const createListWithoutRecords = async (params, callback) => {
  let {name, description, themeColor, displayFields, aid, uid, elasticlistDoc} = params;

  try {
    // Create List Document
    if (!elasticlistDoc) {
      let ref = db.collection("elasticlists");
      let config = {
        name,
        description,
        themeColor,
        fields: displayFields ? displayFields : ["NAME", "ADDRESSES", "PHONES", "EMAILS"],
        sort: "NAME",
        sortOrder: "asc",
        sources: [],
        showNote: true,
        showStatus: true,
        statuses: ["new"],
      };

      elasticlistDoc = await createBoard({
        ref,
        config,
        aid,
        uid,
      });
    }

    let dslQuery = {
      index: `crm_${aid}`.toLowerCase(),
      body: {
        _source: displayFields ? displayFields : ["NAME", "ADDRESSES", "PHONES", "EMAILS"],
        query: {
          bool: {
            filter: [
              {
                exists: {
                  field: `LISTMAP.${elasticlistDoc._docId}`
                }
              },
              {
                term: {
                  DELETED: false,
                },
              },
            ],
          },
        },
        sort: [
          {
            "NAME.keyword": {
              order: "asc",
            },
          },
        ],
      },
    }
    await elasticlistDoc._docRef.update({
      dslQuery
    });

    elasticlistDoc.dslQuery = dslQuery;
    if (callback) {
      callback(null, elasticlistDoc);
    }
    return elasticlistDoc;
  } catch(err) {
    console.log("Error creating list without records: ", err);
    if (callback) {
      callback(err);
    }
  }
}

const CreateNewListButton = props => {
  let { userDoc, user } = props;
  console.log("CreateNewListButton props: ", props);
  let history = useHistory();

  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [themeColor, setThemeColor] = useState("info");
  const [showModal, setShowModal] = useState(false);
  const [displayFields, setDisplayFields] = useState({
    NAME: true,
    CO_NAME: false,
    FN: true,
    LN: true,
    PHONES: true,
    EMAILS: true,
    ADDRESSES: true
  });
  const [newField, setNewField] = useState("");

  const createList = async () => {
    let newList = await createListWithoutRecords({
      name,
      description,
      displayFields: Object.keys(displayFields).map( f => {
        if (displayFields[f]) {
          return( f );
        }
      }).filter( f => f !== undefined),
      themeColor,
      uid: user.uid,
      aid: userDoc.id
    });
    return history.push(`/listcrm/${newList._docId}`);
  }

  return(
    <>
      <Button 
        variant="info"
        onClick={ () => {
          console.log("clicked create new list.");
          setShowModal(true);
        }}
      >
        Create New List
      </Button>
      <Modal show={showModal} onHide={() => setShowModal(false)}>
        <Modal.Body>
          <Modal.Title>Create New List</Modal.Title>
          <Form 
            onSubmit={e => {
              e.preventDefault();
              createList();
            }}
          >
            <FloatingLabel className="mb-2" label="Name" controlId="createNewListName">
              <Form.Control
                type="text"
                value={name}
                onChange={ e => {
                  e.preventDefault();
                  setName(e.target.value)
                }}
                placeholder="My List"
                required
              />
            </FloatingLabel>
            <FloatingLabel className="mb-2" controlId="createNewListDescription" label="Description" >
              <Form.Control 
                as="textarea"
                value={description}
                onChange={ e => {
                  e.preventDefault();
                  setDescription(e.target.value);
                }}
                rows={3}
                placeholder="A list to keep track of my customers"
              />
            </FloatingLabel>
            <Form.Group className="bg-white p-2 rounded mb-2">
              <Form.Label>Select color</Form.Label>
              <div className="d-flex flex-row bg-white">
                {[
                  "light",
                  "primary",
                  "secondary",
                  "success",
                  "info",
                  "danger",
                  "warning",
                  "dark",
                ].map((t) => {
                  let cColor = t === "light" ? "dark" : "white";
                  return (
                    <div
                      key={`color-${t}`}
                      onClick={() => setThemeColor(t)}
                      className={`bg-white`}
                    >
                      {themeColor === t && (
                        <CheckSquareFill
                          className={`text-${t} m-1 bg-${cColor}`}
                          size={30}
                        />
                      )}
                      {themeColor !== t && (
                        <DashSquareFill
                          className={`text-${t} m-1 bg-${cColor}`}
                          size={30}
                        />
                      )}
                    </div>
                  );
                })}
              </div>
            </Form.Group>
            <Form.Group key={`default-displayFields`} className="mb-3">
              <Form.Label>Which fields would you like to include in your list?</Form.Label>
              {Object.entries(displayFields).map(([fieldName, bool]) => {
                
                return(
                  <Form.Check 
                    type="checkbox"
                    key={`checkbox-${fieldName}`}
                    id={`checkbox-${fieldName}`}
                    label={fieldName}
                    checked={bool}
                    onChange={ e => {
                      let ndf = {...displayFields};
                      ndf[fieldName] = !bool;
                      setDisplayFields(ndf);
                    }}
                    disabled={ fieldName === "NAME"}
                  />
                );
              })}
              <InputGroup>
                <Form.Control
                  type="text"
                  size="sm"
                  value={newField}
                  onChange={e => {
                    setNewField( e.target.value.toLowerCase() );
                  }}
                  placeholder="Type a new field name"
                />
                <Button 
                  variant="outline-dark"
                  size="sm"
                  onClick={() => {
                    let ndf = {...displayFields};
                    ndf[newField] = true;
                    setDisplayFields(ndf);
                    setNewField("");
                  }}
                >
                  Add
                </Button>
              </InputGroup>
              

            </Form.Group>
            <Button 
              variant="info"
              type="submit"
              className="pull-right"
            >
              Create List
            </Button>
          </Form>
        </Modal.Body>
      </Modal>
    </>
  );
}

export {
  ViewElasticList,
  ViewAllLists,
  createList,
  queryUrlToDslQuery,
  addDownloadedByToIndex,
  CreateNewListButton
};
