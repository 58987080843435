import React, { useState, useEffect } from "react";
import { Container, Row, Col, Button, Form, FloatingLabel, Card } from "react-bootstrap";
import { X, Trash } from "react-bootstrap-icons";
import InlineFormControl from "./inlineFormControl";
import { AddEmailButton, blankEmail, Email } from "./displayEmail";
import { AddPhoneButton, blankPhone, Phone } from "./displayPhone";
import { AddAddrButton, Addr, AddrInput, blankAddr } from "./displayAddr";
import { age, AgeInput } from "./displayAge";
import { HhIncome, HhIncomeInput } from "./displayHhIncome";
import StatusSelect from "./statusSelect";
import { object_equals } from "./interfaceListShackPro";
import { user } from "firebase-functions/lib/providers/auth";

let sortPriority = {
  NAME: 10,
  CO_NAME: 100,
  FULL_NAME: 150,
  FN: 200,
  MI: 300,
  LN: 400,
  AGE: 450,
  ADDRESSES: 500,
  COUNTY: 550,
  PHONES: 600,
  EMAILS: 700,
  HH_INCOME: 800,
};

const ElasticDoc = (props) => {
  const { doc, docId, index, onError, onSave, onDelete, fetchFromApi, listConfig, displayStatus, handleAlerts, indexMap } = props;

  const [showEdit, setShowEdit] = useState(null);
  const [fetched, setFetched] = useState(false);
  const [saved, setSaved] = useState(null);
  const [newDoc, setNewDoc] = useState(doc);

  const createFormInput = (entry) => {
    let field = entry[0];
    let value = entry[1];
    let input = [];
    //console.log("entry: ", entry);
    // Handle special object Formats
    if (field === "HH_INCOME") {
      let keyId = `${docId}_hhIncome`;
      return (
        <HhIncomeInput
          key={keyId}
          code={value}
          onChange={(nc) => {
            let nd = { ...newDoc };
            nd._source[field] = nc;
            setNewDoc(nd);
          }}
        />
      );
    }

    if (field === "AGE") {
      let keyId = `${docId}_ageObject`;
      return (
        <AgeInput
          keyId={keyId}
          key={keyId}
          ageObject={value}
          onChange={(newObject) => {
            let nd = { ...newDoc };
            nd._source[field] = newObject;
            setNewDoc(nd);
          }}
        />
      );
    }

    if (field === "ADDRESSES") {
      let addressInputs = value.map((addrObject, i) => {
        let keyId = `${docId}_addrObject_${i}`;
        return (
          <Form.Group className="">
            <div className="d-flex flex-row justify-content-between align-items-start">
              <Form.Label className="text-muted ps-2"><small>Address</small></Form.Label>
              <Button 
                className="text-danger"
                variant="link"
                style={{
                  textDecoration: "none",
                }}
                onClick={ e => {
                  e.preventDefault();
                  e.stopPropagation();
                  if (window.confirm(`Are you sure you want to remove this address? ${addrObject.DATA.ADDR}`)) {
                    let nd = {...newDoc};
                    nd._source.ADDRESSES = newDoc._source.ADDRESSES.filter( p => !object_equals(p, addrObject));
                    setNewDoc(nd);
                  }
                }}
              >
                <Trash />
              </Button>
            </div>
            
            <AddrInput
              id="floatingInputAddress"
              key={keyId}
              addrObject={addrObject}
              onChange={(newAddress) => {
                let nd = { ...newDoc };
                nd._source[field][i] = newAddress;
                setNewDoc(nd);
              }}
            />
          </Form.Group>
        );
      });
      return(
        <>
          {addressInputs}
          <div className="d-grid gap-2">
            <AddAddrButton 
              className="mt-1 mb-3" 
              variant="outline-dark" 
              size="sm" 
              btnText={<small className="text-muted">Add address</small>}
              onAdd={(blankAddr) => {
                let nd = {...newDoc};
                if (!nd._source.ADDRESSES) {
                  nd._source.ADDRESSES = [blankAddr]
                } else {
                  if (!Array.isArray(nd._source.ADDRESSES)) {
                    nd._source.ADDRESSES = [blankAddr]
                  } else {
                    nd._source.ADDRESSES.push(blankAddr);
                  }
                }
                console.log("nd: ", nd);
                setNewDoc(nd);
              }}
            />
          </div>
        </>
      );
    }

    if (field === "PHONES") {
      let phoneInputs = value.map((phoneObject, i) => {
        let keyId = `${docId}_phone_${i}`;
        return (
          <div style={{position: "relative"}} className="mb-1">
            <FloatingLabel controlId={`${newDoc._id}_${field}_formgroup`} label="Phone" >
              <InlineFormControl
                key={keyId}
                type="text"
                value={phoneObject.DATA}
                onChange={(e) => {
                  let nd = { ...newDoc };
                  nd._source[field][i]["DATA"] = e.target.value;
                  setNewDoc(nd);
                }}
              />
            </FloatingLabel>
            <Button 
              className="text-danger"
              variant="link"
              style={{
                textDecoration: "none",
                top: "0",
                right: "0",
                position: "absolute"
              }}
              onClick={ e => {
                e.preventDefault();
                e.stopPropagation();
                if (window.confirm(`Are you sure you want to remove this phone number? ${phoneObject.DATA}`)) {
                  let nd = {...newDoc};
                  nd._source.PHONES = newDoc._source.PHONES.filter( p => !object_equals(p, phoneObject));
                  setNewDoc(nd);
                }
              }}
            >
              <Trash />
            </Button>
          </div>
        )
      });
      return(
        <>
          {phoneInputs}
          <div className="d-grid gap-2">
            <AddPhoneButton 
              className="mt-1 mb-3" 
              variant="outline-dark" 
              size="sm" 
              btnText={<small className="text-muted">Add phone</small>}
              onAdd={(blankPhone) => {
                let nd = {...newDoc};
                if (!nd._source.PHONES) {
                  nd._source.PHONES = [blankPhone]
                } else {
                  if (!Array.isArray(nd._source.PHONES)) {
                    nd._source.PHONES = [blankPhone]
                  } else {
                    nd._source.PHONES.push(blankPhone);
                  }
                }
                console.log("nd: ", nd);
                setNewDoc(nd);
              }}
            />
          </div>
        </>
      );
    }

    if (field === "EMAILS") {
      let emailInputs = value.map((emailObject, i) => {
        let keyId = `${docId}_email_${i}`;
        return (
          <div style={{position: "relative"}} className="mb-1">
            <FloatingLabel controlId={`${newDoc._id}_${field}_formgroup`} label="Email" >
              <InlineFormControl
                type="text"
                value={emailObject.DATA ? emailObject.DATA : ""}
                onChange={(e) => {
                  let nd = { ...newDoc };
                  nd._source[field][i]["DATA"] = e.target.value;
                  setNewDoc(nd);
                }}
              />
            </FloatingLabel>
            <Button 
              className="text-danger"
              variant="link"
              style={{
                textDecoration: "none",
                top: "0",
                right: "0",
                position: "absolute"
              }}
              onClick={ e => {
                e.preventDefault();
                e.stopPropagation();
                if (window.confirm(`Are you sure you want to remove this email address? ${emailObject.DATA}`)) {
                  let nd = {...newDoc};
                  nd._source.EMAILS = newDoc._source.EMAILS.filter( p => !object_equals(p, emailObject));
                  setNewDoc(nd);
                }
              }}
            >
              <Trash />
            </Button>
          </div>
        );
      });
      return(
        <>
          {emailInputs}
          <div className="d-grid gap-2">
            <AddEmailButton 
              className="mt-1 mb-3" 
              variant="outline-dark" 
              size="sm" 
              btnText={<small className="text-muted">Add email</small>}
              onAdd={(blankEmail) => {
                let nd = {...newDoc};
                if (!nd._source.EMAILS) {
                  nd._source.EMAILS = [blankEmail]
                } else {
                  if (!Array.isArray(nd._source.EMAILS)) {
                    nd._source.EMAILS = [blankEmail]
                  } else {
                    nd._source.EMAILS.push(blankEmail);
                  }
                }
                console.log("nd: ", nd);
                setNewDoc(nd);
              }}
            />
          </div>
        </>
      )
    }
    console.log("field: ", field);
    // Check if it's a date
    if ( ( !isNaN(Date.parse(value)) && indexMap.body[index].mappings.properties[field].type ? indexMap.body[index].mappings.properties[field].type === "date" : true ) || indexMap.body[index].mappings.properties[field].type ? indexMap.body[index].mappings.properties[field].type === "date" : false) {
      // Give folks a date picker
      let dateString = value.split("T")[0];
      console.log("dateString: ", dateString);
      return (
        <FloatingLabel controlId={`${newDoc._id}_${field}_formgroup`} label={field} >
          <InlineFormControl
            type="date"
            value={dateString}
            onChange={(e) => {
              console.log("new date: ", e.target.value);
              let nd = { ...newDoc };
              nd._source[field] = e.target.value;
              setNewDoc(nd);
            }}
          />
        </FloatingLabel>
      );
    }

    if (typeof value === "string") {
      return (
        <FloatingLabel controlId={`${newDoc._id}_${field}_formgroup`} label={field} >
          <InlineFormControl
            type="text"
            value={value}
            onChange={(e) => {
              let nd = { ...newDoc };
              nd._source[field] = e.target.value;
              setNewDoc(nd);
            }}
          />
        </FloatingLabel>
      );
    }
  };

  const deleteDoc = (bool=true) => {
    return fetchFromApi(
      {
        endpoint: "/api/elastic/update",
        body: JSON.stringify({
          id: newDoc._id,
          index: newDoc._index,
          refresh: true,
          body: {
            doc: {DELETED: bool, UPDATEDBY: user.uid, UPDATEDAT: new Date().toISOString()},
            detect_noop: false,
          },
        }),
      },
      (error, result) => {
        if (error) {
          console.log("Error deleting elastic doc: ", error);
          handleAlerts("", "Uh oh, we couldn't delete your document. Please refresh your page and try again.", "warning");
          if (onError) {
            onError(error);
          }
        }
        if (result) {
          if (onDelete) {
            onDelete(doc);
          }
          console.log("Elastic doc deleted.");
          //setSaved(true);
        }
      }
    );
  }

  const saveDoc = () => {
    return fetchFromApi(
      {
        endpoint: "/api/elastic/update",
        body: JSON.stringify({
          id: newDoc._id,
          index: newDoc._index,
          refresh: true,
          body: {
            doc: {...newDoc._source, UPDATEDBY: user.uid, UPDATEDAT: new Date().toISOString()},
            detect_noop: false,
          },
        }),
      },
      (error, result) => {
        if (error) {
          console.log("Error saving elastic doc: ", error);
          handleAlerts("", "Uh oh, we couldn't save your document. Please refresh your page and try again.", "warning");
          if (onError) {
            onError(error);
          }
        }
        if (result) {
          if (onSave) {
            onSave(newDoc, doc);
          }
          console.log("Elastic doc saved.");
          setSaved(true);
        }
      }
    );
  };

  useEffect(() => {
    if (!doc || !fetched) {
      // Get the document
      fetchFromApi(
        {
          endpoint: "/api/v2/elastic/get",
          body: JSON.stringify({
            getBody: {
              index,
              id: docId,
            },
          }),
        },
        (error, result) => {
          if (error) {
            console.log("Error fetching elasticDoc: ", error, index, docId);
            if (onError) {
              onError(error);
            }
          }
          if (result) {
            console.log("result: ", result);
            // Placeholder for future check
            if (false) {
            } else {
              setNewDoc(result);
            }
            setFetched(true);
          }
        }
      );
    }
    // If we want to do autosave enable this code.
    if (fetched) {
      let timeOutId = setTimeout(() => {
        console.log("Done typing.");
        saveDoc();
      }, 2000);
      return () => {
        console.log("Clearing timeout");
        clearTimeout(timeOutId);
        setSaved(false);
      };
    }
  }, [docId, index, newDoc]);

  if (!newDoc) {
    return null;
  }
  let docSource = {
    ADDRESSES: [],
    PHONES: [],
    EMAILS: [],
    ...newDoc._source    
  };
  console.log("docSource: ", docSource);
  let fieldEntries = Object.entries(docSource)
    .filter(
      (f) =>
        ![
          "DELETED",
          "SOURCEINDEX",
          "SOURCEDOCID",
          "CREATEDAT",
          "CREATEJOBID",
          "CREATEDBY",
          "ACCOUNTID",
          "LISTMAP",
          "SOURCEID",
          "UPDATEDAT",
          "UPDATEDBY",
          "ASSIGNEDTO"
        ].includes(f[0])
    )
    .sort((a, b) => {
      return ( sortPriority[a[0]] ? sortPriority[a[0]] : 10000 ) - ( sortPriority[b[0]] ? sortPriority[b[0]] : 10000 );
    });

  return (
    <Container>
      <Row>
        {displayStatus && // This is buggy right now ... the list behind the offCanvas component isn't updating until OffCanvas is closed.
          <Col xs={12} className="mb-5">
            <h6>Status</h6>
            <StatusSelect
              {...props}
              doc={newDoc}
              list={listConfig._docId}
              statuses={listConfig.statuses}
              onSucess={ nd => {
                //setNewDoc(nd);
                onSave(nd);
              }}
            />
          </Col>
        }
        <Col>
          <div className="d-flex flex-row justify-content-between align-items-center">
            <h6>Details</h6> <small className="text-muted">{saved === true ? "saved" : saved === false ? "saving" : ""}</small>
          </div>
          
          {false &&
            Object.entries(newDoc._source).map((entry) => {
              let field = entry[0];
              let value = entry[1];
              return <p>{`${field} : ${value} (${typeof value})`}</p>;
            })}
          {true && (
            <>
            <Form
              onSubmit={(e) => {
                e.preventDefault();
                saveDoc();
              }}
            >
              <Row xs={1}>
              {fieldEntries
                .map((entry) => {
                  let field = entry[0];
                  return (
                    <Col className="p-1">
                      {createFormInput(entry)}
                    </Col>
                  );
              })}
                <Col xs={12}>
                  <Button type="submit" disabled={saved}
                    className="mt-2 pull-right"
                  >
                    Save
                  </Button>
                </Col>
              </Row>
              
            </Form>
            <div className="d-grid g-2 mt-5">
              {!newDoc._source.DELETED &&
                <Button variant="outline-danger"
                  size="sm"
                  onClick={() => {
                    if (window.confirm("Are you sure you want to archive this record? This will remove the record from all lists.")) {
                      deleteDoc(true);
                    }
                  }}
                >
                  Archive Record
                </Button>
              }
              {newDoc._source.DELETED &&
                <Button variant="outline-success"
                 size="sm"
                  onClick={() => {
                    if (window.confirm("Are you sure you want to restore this record?")) {
                      deleteDoc(false);
                    }
                  }}
                >
                  Restore Record
                </Button>
              }
            </div>
            </>
          )}
        </Col>
        <Col xs={12}>
          {props.children}
        </Col>
      </Row>
    </Container>
  );
};

export default ElasticDoc;
