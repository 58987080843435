import React from "react";
import PropTypes from "prop-types";
import { Row, Col, Form, ListGroup, Dropdown } from "react-bootstrap";

class SearchNSuggest extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      searchText: "",
      suggestions: []
    };
    //console.log("SearchNSuggest this.props: ", this.props);
    this.timer = null;
    this.apiString = `${process.env.REACT_APP_api_url}/api/v2/data`;
  }

  searchZip = async (searchText, searchBy, stateCol) => {
    let url = `${this.apiString}/fipsdata_zips?filter=ZIP%20LIKE%20"${searchText}%"&order=COUNT%20DESC`;
    let body = {
      fields: "ZIP,ST,COUNT",
      filter: `ZIP LIKE '${searchText}%'`,
      order: "COUNT DESC"
    };
    let data = await this.fetchSuggestions(searchText, url, body);
    let zipItems = [];
    let fieldsArray = body.fields.split(",");
    for (let r of data) {
      let zip = r[fieldsArray.indexOf("ZIP")];
      let state = r[fieldsArray.indexOf("ST")];
      let count = r[fieldsArray.indexOf("COUNT")];
      zipItems.push({
        value: zip,
        name: `${zip} - ${state}`,
        meta: `${count} records`
        //query: [`(${searchBy}="${zip}")`]
      });
    }
    return zipItems;
  }

  searchCounty = async (searchText, searchBy, stateCol) => {
    let url = `${this.apiString}/fipsdata_cities?fields=COUNTY,ST,COUNT,FIPS_ST&filter=COUNTY%20LIKE%20"${searchText}%"&order=COUNT%20DESC&group=COUNT,COUNTY,ST`;
    let body = {
      fields: "COUNTY,ST,FIPS_ST,SUM(COUNT)",
      filter: `COUNTY LIKE '${searchText}%'`,
      order: "SUM(COUNT) DESC",
      group: "COUNTY,ST,FIPS_ST"
    };
    let data = await this.fetchSuggestions(searchText, url, body);
    let countyItems = [];
    let fieldsArray = body.fields.split(",");
    for (let r of data) {
      let county = this.capitalize(r[fieldsArray.indexOf("COUNTY")]);
      let state = r[fieldsArray.indexOf("ST")].toUpperCase();
      //console.log("county: ", county);
      if (county === "Miami-dade") {
        county = "Miami-Dade";
      } // temporary hack so we don't have to reindex
      countyItems.push({
        value: `${county}`,
        agg: { [stateCol]: state },
        name: `${county}, ${state}`
      });
    }
    return countyItems;
  }

  searchCity = async (searchText, searchBy, stateCol) => {
    let url = `${this.apiString}/fipsdata_cities?filter=CITY%20LIKE%20"${searchText}%"&order=COUNT%20DESC`;
    let body = {
      fields: "CITY,FIPS_COMPOSITE,ST,COUNT",
      filter: `CITY LIKE '${searchText}%'`,
      order: "COUNT DESC"
    };
    let data = await this.fetchSuggestions(searchText, url, body);
    let cityItems = [];
    let fieldsArray = body.fields.split(",");
    for (let r of data) {
      let state = r[fieldsArray.indexOf("ST")].toUpperCase();
      let city = r[fieldsArray.indexOf("CITY")];
      //let fips_comp = r[fieldsArray.indexOf("FIPS_COMPOSITE")];

      if (searchBy === "vinCity") {
        cityItems.push({
          value: city,
          agg: { State: state },
          name: `${city}, ${state}`
        });
      } else if (searchBy === "city") {
        cityItems.push({
          value: city,
          agg: { state },
          name: `${city}, ${state}`
        });
      } else {
        cityItems.push({
          value: city,
          agg: { [stateCol]: state },
          name: `${city}, ${state}`
        });
      }
    }
    return cityItems;
  }

  searchSIC = async (searchText) => {
    let url = `${this.apiString}/business_siclist_2?filter=(SIC_4%20LIKE%20"${searchText}%")%20OR%20(SIC_NAME1%20LIKE%20"%${searchText}%")`;
    let body = {
      fields: "SIC_4,SIC_NAME1",
      filter: `((SIC_4 LIKE '${searchText}%') OR (SIC_NAME1 LIKE '%${searchText}%'))`,
      group: "SIC_4,SIC_NAME1"
    };
    let data = await this.fetchSuggestions(searchText, url, body);
    console.log("data: ", data);
    let sicItems = [];
    let fieldsArray = body.fields.split(",");
    for (let r of data) {
      let sic4 = r[fieldsArray.indexOf("SIC_4")];
      let sicName = r[fieldsArray.indexOf("SIC_NAME1")];
      sicItems.push({
        value: `${sic4}`,
        name: `${sic4} | ${sicName}`,
        meta: "SIC 4 - All records",
        query: [`(SIC_4 = ${sic4})`]
      });
    }

    let surl = `${this.apiString}/business_sicsearch?fields=SIC_4,SIC_CODE1,SIC_NAME1,Record_Count&filter=(SIC_4%20LIKE%20"${searchText}%")%20OR%20(SIC_NAME1%20LIKE%20"%${searchText}%")&order=RECORD_COUNT DESC`;
    let body2 = {
      fields: "SIC_4,SIC_CODE1,SIC_NAME1,RECORD_COUNT",
      filter: `((SIC_4 LIKE '${searchText}%') OR (SIC_NAME1 LIKE '%${searchText}%'))`,
      order: "RECORD_COUNT DESC"
    };

    let data2 = await this.fetchSuggestions(searchText, surl, body2);
    console.log("data2: ", data2);
    let sicItems2 = [];
    let fieldsArray2 = body2.fields.split(",");
    for (let r of data2) {
      let sic1 = r[fieldsArray2.indexOf("SIC_CODE1")];
      let sicName = r[fieldsArray2.indexOf("SIC_NAME1")];
      let count = r[
        fieldsArray2.indexOf("RECORD_COUNT")
      ].toLocaleString();

      sicItems2.push({
        value: sic1,
        name: `${sic1} | ${sicName}`,
        meta: `SIC 6`,
        query: [`((SIC_6 = "${sic1}") or (SIC_DESCRIPTION = "${sicName}"))`],
        count: count
      });
    }

    sicItems = sicItems.concat(
      sicItems2.sort((a, b) => {
        return b.count - a.count;
      })
    );
    console.log("sicItems: ", sicItems);
    return sicItems;
  }

  searchNAICS = async (searchText) => {
    let url = `${this.apiString}/naicsindex`;
    let body = {
      fields: "CODE,DESCRIPTION",
      filter: `((CODE LIKE '${searchText}%') OR (DESCRIPTION LIKE '%${searchText}%'))`
    };
    let data = await this.fetchSuggestions(searchText, url, body);

    let naicsItems = [];
    //let naicsItems2 = [];
    let fieldsArray = body.fields.split(",");
    // might need to sort these here so that we only get one of each value
    for (let r of data) {
      let naics = r[fieldsArray.indexOf("CODE")];
      let naicsName = r[fieldsArray.indexOf("DESCRIPTION")];
      //let count = r[
      //  fieldsArray.indexOf("COUNT")
      //].toLocaleString();
      // Broad category search query
      naicsItems.push({
        value: `${naics}`,
        name: `${naics} | ${naicsName}`,
        meta: "NAICS Code Category",
        query: [`(NAICS = ${naics})`]
      });
      // Specific code search query
      //naicsItems2.push({
      //  value: naics,
      //  name: `${naics} | ${naicsName}`,
      //  meta: `NAICS 6`,
      //  query: [`(NAICS = "${naics}") or (NAICS_DESCRIPTION = ${naicsName})`],
      //  //count: count
      //});
    }
    //console.log("naicsItems: ", naicsItems, "naicsItems2: ", naicsItems2);
    //naicsItems = naicsItems.concat(
    //  naicsItems2.sort((a, b) => {
    //    return parseInt(b.value) - parseInt(a.value);
    //  })
    //);
    return naicsItems;
  }

  searchSICNAICS = async (searchText) => {
    let sicItems = this.searchSIC(searchText);
    let naicsItems = this.searchNAICS(searchText);
    let allItems = await Promise.allSettled([sicItems, naicsItems]);
    console.log("allItems: ", allItems);
    return [...allItems[0].value, ...allItems[1].value].sort( (a,b) => (a.value - b.value) )
  }

  searchModel = async (searchText, searchBy) => {
    // This needs to be rewritten for the new dataset
    //let url = `https://listshack.apps.dreamfactory.com/api/v2/geo/vin2015/_table/makeModel?fields=MAKE,MODEL&filters=MODEL%20like%20"${searchText}%"&orderby=MODEL`;
    //let data = await this.fetchSuggestions(searchText, url);
    //let makes = [];
    //for (let r of data) {
    //  let make = r.MAKE;
    //  let model = r.MODEL;
    //  makes.push({
    //    value: model,
    //    name: `${make}, ${model}`,
    //    query: [`(MAKE="${make}") and (MODEL="${model}")`]
    //  });
    //}
    //return makes;
    return [];
  }

  searchTitle = async (searchText, searchBy, searchTypesDict) => {
    let items = searchTypesDict[searchBy]["items"];
    let plusOne = {
      value: searchText,
      query: [`(${searchBy} like %${searchText}%)`],
      name: searchText,
      meta: `All titles with the text ${searchText}`
    };
    items.push(plusOne);
    return items;
  }

  fetchSuggestions = async (searchText, url, postBody) => {
    //console.log("url: ", url);
    const init = {
      method: "POST",
      headers: {
        authorization: `Bearer ${this.props.apiKey}`,
        "Content-Type": "application/json"
      },
      "Transfer-Encoding": "chunked",
      cache: "default",
      accept: "application/json",
      body: JSON.stringify(postBody)
    };

    try {
      //console.log("url: ", url);
      let response = await fetch(url, init);
      // only proceed once promise is resolved
      let data = await response.json();
      return await data.resource;
    } catch (error) {
      await console.error(error);
      // Tell the user that something went wrong.
      await this.props.handleAlerts(
        "",
        `Uh oh, something went wrong fetching suggestions. ${error.message}. Try changing your search.  If the problem persists send an email to ${process.env.REACT_APP_contact_email}`,
        "warning"
      );
    }
  };

  findItemsBySearchby = async (searchText, searchBy, searchTypesDict) => {
    // Look up which columm is the State column
    let stateCol = Object.entries(searchTypesDict).find(col => {
      return col[1]["name"] === "States";
    })[0];
    if (searchBy === "AREA_CODE") {
      return searchTypesDict.AREA_CODE.items;
    } else if (searchBy === "COUNTY" || searchBy === "county") {
      return await this.searchCounty(searchText, searchBy, stateCol);
    } else if ( ["CITY", "MAIL_CITY", "vinCity", "city"].includes(searchBy) ) {
      return await this.searchCity(searchText, searchBy, stateCol);
    } else if ( ["ST", "MAIL_ST"].includes(searchBy) ) {
      return searchTypesDict[searchBy]["items"];
    } else if (searchBy === "SIC") {
      return await this.searchSIC(searchText)
    } else if (searchBy === "NAICS") {
      return await this.searchNAICS(searchText);
    } else if (searchBy === "SICNAICS") {
      return await this.searchSICNAICS(searchText);
    } else if ( ["ZIP", "MAIL_ZIP", "vinZip", "zip", "MAIL_ZIP5"].includes(searchBy) ) {
      return await this.searchZip(searchText,searchBy, stateCol);
    } else if ( ["Model", "MODEL"].includes(searchBy) ) {
      return await this.searchModel(searchText, searchBy);
    } else if ( ["title", "TITLE"].includes(searchBy) ) {
      return await this.searchTitle(searchText, searchBy, searchTypesDict);
    } else {
      return searchTypesDict[searchBy]["items"];
    }
  };

  findRegexBySearchBy = (searchBy, searchText) => {
    if (
      ["SIC_4", "sic", "SIC", "NAICS", "SICNAICS", "ST", "MAIL_STATE", "Model"].includes(searchBy)
    ) {
      return new RegExp(`.*${searchText}.*`, "i");
    } else {
      return new RegExp(`^${searchText}.*`, "i");
    }
  };
  
  proposeSuggestions = async (searchText, searchBy, searchTypesDict, regEx=null) => {
    // functions
    let items = await this.findItemsBySearchby(searchText, searchBy, searchTypesDict);
    let regex = !regEx ? await this.findRegexBySearchBy(searchBy, searchText) : regEx;
    let suggestions = await items.sort().filter(v => {
      return regex.test(v.name);
    });
    //console.log("items: ", items, "regex: ", regex, "suggestions: ", suggestions);
    this.setState({ suggestions });
  };

  capitalize(string) {
    return string.replace(/(?:^|\s)\S/g, function(a) {
      return a.toUpperCase();
    });
  }

  onSearchChanged(searchText) {
    let searchBy = this.props.searchBy;
    if (searchText.length > 1) {
      if (this.timer) clearTimeout(this.timer);
      return this.timer = setTimeout(
        this.proposeSuggestions,
        1000,
        searchText,
        searchBy,
        this.props.searchTypesDict
      );
    } else {
      if (this.timer) return clearTimeout(this.timer);
    }
    return;
  }

  selectSuggestion(e, suggestion) {
    e.preventDefault();
    this.props.addQueryParam(this.props.dictKey, this.props.searchBy, [
      suggestion
    ]);
    this.setState({
      suggestions: [],
      searchText: ""
    });
  }

  render() {
    const { searchBy, searchTypesDict, selectSearchBy } = this.props;
    const { suggestions, searchText } = this.state;
    const searchTypes = Object.keys(searchTypesDict);
    let showSuggestions = searchText.length > 0 && suggestions.length > 0;
    return (
      <React.Fragment>
        <h5 style={{ marginTop: "30px", marginBottom: "20px" }}>
          Search by&nbsp;
          {this.props.dictKey === "geography" && (
            <Dropdown style={{ display: "inline-block" }}>
              <Dropdown.Toggle
                variant="link"
                style={{
                  fontSize: "1.3rem",
                  textDecoration: "underline",
                  display: "inline-block"
                }}
                id="dropdown-basic"
              >
                {searchTypesDict[searchBy]["name"].toLowerCase()}:
              </Dropdown.Toggle>
              <Dropdown.Menu>
                {searchTypes.map((type, index) => (
                  <Dropdown.Item
                    onClick={e => selectSearchBy(e, type)}
                    as="button"
                    key={index}
                  >
                    {searchTypesDict[type]["name"]}
                  </Dropdown.Item>
                ))}
              </Dropdown.Menu>
            </Dropdown>
          )}
          {this.props.dictKey !== "geography" && (
            <span>{searchTypesDict[searchBy]["name"].toLowerCase()}:</span>
          )}
        </h5>
        <div>
          <Form.Group as={Row} controlId={`searchByForm_${searchBy}`}>
            <Form.Label column sm="2">
              {searchTypesDict[searchBy]["name"]}
            </Form.Label>
            <Col sm="10">
              <Form.Control
                type="text"
                placeholder={"Type " + searchTypesDict[searchBy]["name"]}
                onChange={e => {
                  let searchText = e.target.value;
                  this.onSearchChanged(searchText);
                  this.setState({ searchText });
                }}
                value={this.state.searchText}
                autoComplete="off"
              />
              <Form.Text className="text-muted text-start">
                {`${searchTypesDict[searchBy]["formText"] ? searchTypesDict[searchBy]["formText"] : ""} Start typing to see possible options. Click your selection.`}
              </Form.Text>
              {showSuggestions && (
                <ListGroup
                  style={{
                    width: "94.5%",
                    zIndex: "1000",
                    position: "absolute"
                  }}
                >
                  {suggestions.map((suggestion, i) => (
                    <ListGroup.Item
                      key={suggestion.value + i}
                      variant="light"
                      onClick={e => this.selectSuggestion(e, suggestion)}
                      action
                    >
                      {suggestion.name}{" "}
                      <span
                        style={{
                          fontSize: ".8em",
                          fontStyle: "italic",
                          float: "right",
                          marginRight: "10px"
                        }}
                      >
                        {suggestion.meta ? suggestion.meta : null}
                      </span>
                    </ListGroup.Item>
                  ))}
                </ListGroup>
              )}
            </Col>
          </Form.Group>
        </div>
      </React.Fragment>
    );
  }
}

SearchNSuggest.propTypes = {
  user: PropTypes.object,
  searchType: PropTypes.string,
  searchBy: PropTypes.string,
  addQueryParam: PropTypes.func,
  searchTypesDict: PropTypes.object,
  dictKey: PropTypes.string
};

export default SearchNSuggest;
