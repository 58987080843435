import React from 'react';
import ReactMapGL, { WebMercatorViewport } from 'react-map-gl';

const MapPreview = props => {
  //console.log("props: ", props);
  //geo_bounding_box shaped like:
  //{
  //  geo_bounding_box: {
  //    location: {
  //      top_left: {
  //        lat: "something",
  //        lon: "something"
  //      },
  //      bottom_right: {
  //        lat: "something",
  //        lon: "something"
  //      }
  //    }
  //  }
  //}

  const { mapboxApiAccessToken, width, height, bb, location} = props;
  let viewportPreview = { viewport: {
    latitude: 37.785164,
    longitude: -100,
    zoom: 3.2,
    bearing: 0,
    pitch: 0,
  }};

  if (bb && location) {
    const {geo_bounding_box} = bb;
    const {top_left, bottom_right} = geo_bounding_box[location];
    viewportPreview = new WebMercatorViewport({width, height})
      .fitBounds( [ [ top_left.lon, top_left.lat ], [ bottom_right.lon, bottom_right.lat ] ]);
  }
  
  return(
    <div style={{position: "relative"}}>
      <ReactMapGL
        { ...viewportPreview }
        mapboxApiAccessToken={mapboxApiAccessToken ? mapboxApiAccessToken: process.env.REACT_APP_mapbox}
        width={`${width}px`}
        height={`${height}px`}
      >
        <div className="d-flex flex-column justify-content-center align-items-center"
          style={{
            position: "absolute",
            top: "50%",
            right: "50%",
            transform: "translate(50%, -50%)"
          }}
        >
          {props.children}
        </div>
      </ReactMapGL>
    </div>
  )
}

export default MapPreview;
