import React from "react";
import { FloatingLabel, Form } from "react-bootstrap";
import InlineFormSelect from "./inlineFormSelect";

const incomeDict = {
  A: "Under $10K",
  B: "$10K-$14K",
  C: "$15K-$19K",
  D: "$20K-$24K",
  E: "$25K-$29K",
  F: "$30K-$34K",
  G: "$35K-$39K",
  H: "$40K-$44K",
  I: "$45K-$49K",
  J: "$50K-$54K",
  K: "$55K-$59K",
  L: "$60K-$64K",
  M: "$65K-$74K",
  N: "$75K-$99K",
  O: "$100K-$149K",
  P: "$150K-$174K",
  Q: "$175K-$199K",
  R: "$200K-$249K",
  S: "$250K+",
  U: "Unknown",
};

const HhIncome = props => {
  const {code} = props;
  return(
    <div 
      className={props.className ? props.className : ""} 
      style={ props.style ? props.style : null}
    >
      {
        incomeDict[code] ? incomeDict[code] : "Unknown"
      }
    </div>
  )
}

const HhIncomeInput = props => {
  const {code, keyId, onChange} = props;
  return(
    <FloatingLabel controlId={`hhincomeinput_${keyId}`} label="Household Income">
      <InlineFormSelect
        value={code}
        onChange={ e => {
          let nc = e.target.value;
          onChange(nc);
        }}
      >
        <option value="">Select income</option>
        {Object.entries(incomeDict).map( ([c, d],i) => {
          return(
            <option key={`${c}_${i}_hhincome_opt_${keyId}`} value={c}>{d}</option>
          )
        })}
      </InlineFormSelect>
    </FloatingLabel>
  );
}

const SimpleHhIncomeInput = props => {
  const {code, keyId, onChange} = props;
  return(
    <Form.Select
      size={props.size ? props.size : "md"}
      value={code}
      onChange={ e => {
        let nc = e.target.value;
        onChange(nc);
      }}
    >
      <option value="">Select income</option>
      {Object.entries(incomeDict).map( ([c, d],i) => {
        return(
          <option key={`${c}_${i}_hhincome_opt_${keyId}`} value={c}>{d}</option>
        )
      })}
    </Form.Select>
  )
}

export {HhIncome, HhIncomeInput, SimpleHhIncomeInput};