import React from "react";
import PropTypes from "prop-types";
import {
  Row,
  Col,
  Table,
  Pagination,
  OverlayTrigger,
  Popover
} from "react-bootstrap";
import Spinner from "react-bootstrap/Spinner";
import { formatPhoneNumber } from "./interfaceListShackPro";

class Preview extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      pageStart: 0,
      pageEnd: 19,
      activePage: 1
    };
    //console.log(props);
  }

  render() {
    // fetch data from the database server. The function will need
    // to live on search.jsx and be passed via props to this component
    //console.log("Preview this.props: ", this.props);
    const {isFetching, leads, previewCols, dlCols} = this.props;
    const {pageStart, pageEnd, activePage} = this.state;
    const arrayOfArrays = leads;
    let array, i;

    let tHeaders = [];
    previewCols.display.map(col => {
      return tHeaders.push(<th key={`th${col.label}`}>{col.label}</th>);
    });

    let tRows = [];
    for ([i, array] of arrayOfArrays.slice(pageStart, pageEnd).entries()) {
      //console.log("array, i: ", array, i);
      let previewCells = [];
      previewCols.display.map((col, index) => {
        // eslint-disable-line
        let dlIndex = dlCols.findIndex(object => {
          return object.value === col.content;
        });
        //console.log("array, col, index: ", array, col, index, "dlIndex: ", dlIndex, "array[dlIndex]: ", array[dlIndex]);
        return previewCells.push(
          <td key={`td${index}${col.content}`}>{col.content === "PHONE" ? formatPhoneNumber(array[dlIndex]) : array[dlIndex]}</td>
        );
      });

      let popoverCells = [];
      previewCols.popover.map((col, index) => {
        // eslint-disable-line
        let dlIndex = dlCols.findIndex(object => {
          return object.value === col.content;
        });
        return popoverCells.push(
          <React.Fragment key={`span${index}${col.content}`}>
            <span>
              <b>{col.label}:</b>&nbsp;{array[dlIndex]}
            </span>
            <br />
          </React.Fragment>
        );
      });

      tRows.push(
        <OverlayTrigger
          trigger={["hover", "focus", "click"]}
          key={`td${i}OV`}
          placement="bottom"
          overlay={
            <Popover>
              <Popover.Body>{popoverCells}</Popover.Body>
            </Popover>
          }
        >
          <tr key={`td${i}TR`}>{previewCells}</tr>
        </OverlayTrigger>
      );
    }

    let pageItems = [];
    let totalPages = parseInt(arrayOfArrays.length / 20);
    for (let number = 1; number <= totalPages; number++) {
      let newPageStart = number * 20 - 20;
      let newPageEnd = number * 20 - 1;
      pageItems.push(
        <Pagination.Item
          key={number}
          active={number === activePage}
          onClick={e =>
            this.setState({
              pageStart: newPageStart,
              pageEnd: newPageEnd,
              activePage: number
            })
          }
        >
          {number}
        </Pagination.Item>
      );
    }

    return (
      <Row>
        <Col xs={12} className="text-start">
          <h3>Preview sales leads.</h3>
          <p>
            Based on the criteria you selected, your sales leads will look
            similar to the following:
          </p>
        </Col>
        <Col xs={12}>
          <Pagination>{pageItems}</Pagination>
        </Col>
        <Col xs={12}>
          {!isFetching && (
            <Table striped bordered hover responsive size="sm">
              <thead>
                <tr>{tHeaders}</tr>
              </thead>
              <tbody>{tRows}</tbody>
            </Table>
          )}
          {isFetching && (
            <div className="text-center">
              <Spinner animation="grow" variant="primary" />
              <h4>We're preparing a preview. Hold tight!</h4>
            </div>
          )}
        </Col>
      </Row>
    );
  }
}

Preview.propTypes = {
  user: PropTypes.object,
  searchType: PropTypes.string,
  isFetching: PropTypes.bool,
  leads: PropTypes.array
};

export default Preview;
