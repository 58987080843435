import React from "react";
import {getUserData} from "./superadmin.jsx";
import {object_equals} from "./interfaceListShackPro";
import { object } from "firebase-functions/lib/providers/storage";
function withTeam(WrappedComponent, props) {
  return class extends React.Component {
    constructor(props) {
      super(props);
      this.state = {
        teamMembers: null,
        handleWithTeamState: obj => this.setState(obj)
      }
      console.log("Mounted withTeam!")
      this.fetchUsers();
    }
    async fetchUsers() {
      let {userDoc, apiKey, user} = this.props;
      let users = [user];
      if (userDoc.companyUsers) {
        for (let u of userDoc.companyUsers ) {
          let user = await getUserData({ email: u.email, apiKey });
          console.log("user: ", user);
          if (user) { users.push(user) }
        }
      }
      this.setState({teamMembers: users});
    }
    render() {
      return <WrappedComponent {...this.props} {...this.state} />;
    }
  }
}

export default withTeam;
