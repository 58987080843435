import React, {useState, useEffect} from "react";
import { InputGroup, Form, Button } from "react-bootstrap";
import { db, firebase } from "./firebase";

const StatusSelect = (props) => {
  let { doc, list, statuses, size, onSuccess, updateElasticDoc, handleAlerts } = props;
  const [status, setStatus] = useState(
    doc._source.LISTMAP[list] ? doc._source.LISTMAP[list] : ""
  );
  const [error, setError] = useState(null);
  const [typeValue, setTypeValue] = useState(false);
  //console.log("StatusSelect props: ", props, "status: ", status, "error: ", error);
  useEffect(() => {
    if (typeof doc._source.LISTMAP[list] !== "undefined") {
      setStatus(doc._source.LISTMAP[list]);
    }
  }, [doc]);

  const updateList = (newStatus) => {
    let LISTMAP = { ...doc._source.LISTMAP, [list]: newStatus };
    let newDoc = { ...doc };
    newDoc._source.LISTMAP = LISTMAP;

    updateElasticDoc(
      {
        body: {
          id: doc._id,
          index: doc._index,
          _source: Object.keys(doc._source),
          body: {
            doc: {
              LISTMAP,
            },
            detect_noop: false,
          },
        },
      },
      (error, result) => {
        let throwError = (err) => {
          console.log("Error updating list: ", err);
          setError(err.error);
          //handleAlerts("", `Uh oh, we couldn't add that list status: ${err.error}`, "warning");
        };
        if (error) {
          throwError(error);
        }
        if (result) {
          console.log("List updated: ", result);
          if (result.status === false) {
            return throwError(result);
          }
          setStatus(newStatus);
          if (onSuccess) {
            onSuccess(newDoc);
          }
        }
      }
    );
  };

  const addStatus = async e => {
    console.log("Submitted");
    e.preventDefault();
    e.stopPropagation();
    try {
      let addStatusToConfig = await db
        .collection("elasticlists")
        .doc(list)
        .update({
          statuses:
            firebase.firestore.FieldValue.arrayUnion(status),
        });
      let updateDocStatus = await updateList(status);
      setTypeValue(false);
    } catch (err) {
      console.log("Error adding list status: ", err);
      handleAlerts(
        "",
        `Uh oh, we couldn't add that list status: ${err.message}`,
        "warning"
      );
    }
  }

  return (
    <>
      {!typeValue && (
        <Form noValidate validated={error ? false : null} 
          onClick={e => {
            e.preventDefault();
            e.stopPropagation();
          }}
        >
          <Form.Select
            value={status}
            onChange={(e) => {
              e.preventDefault();
              e.stopPropagation();
              let nv = e.target.value;
              console.log("nv: ", nv);
              if (nv === "anv") {
                setStatus("");
                return setTypeValue(true);
              }
              updateList(nv);
            }}
            size={size ? size : "md"}
            isInvalid={error ? true : false}
          >
            <option key="statusOptionBlank">Select a status</option>
            {!statuses.find( s => s === status) &&
              <option key={`status_without_status_match_${doc._id}`} disabled>{status}</option>
            }
            {statuses.map((s, i) => {
              return <option key={`statusOption_${i}`}>{s}</option>;
            })}
            <option key="statusOptionAddNewValue" value="anv">
              Add new status
            </option>
          </Form.Select>
          {typeof error !== "null" && (
            <Form.Control.Feedback type="invalid">
              {error}
            </Form.Control.Feedback>
          )}
        </Form>
      )}
      {typeValue && (
        <Form noValidate validated={error ? false : null} 
          onClick={e => {
            e.preventDefault();
            e.stopPropagation();
          }}
          onSubmit={(e) => addStatus(e)}
        >
          <InputGroup
            size={size ? size : "md"}
            hasValidation={error ? true : false}
          >
            <Form.Control
              value={status}
              onChange={(e) => {
                e.preventDefault();
                e.stopPropagation();
                let nv = e.target.value;
                setStatus(nv);
              }}
              autoFocus={true}
            />
            <Button
              variant="info"
              type="submit"
              onClick={e => addStatus(e)}
            >
              Add
            </Button>
            {typeof error !== "null" && (
              <Form.Control.Feedback type="invalid">
                {error}
              </Form.Control.Feedback>
            )}
          </InputGroup>
        </Form>
      )}
    </>
  );
};

export default StatusSelect;