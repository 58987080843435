export default {
  database: "consumerMar2019",
  type: "Consumer",
  path: "/search/consumerMar2019",
  apiUrl: "https://listshack.apps.dreamfactory.com/api/v2/consumermar2019/_table/redNatTrimTest?",
  title: "Archive - Consumers March 2019 Database",
  description: "Historical consumer database last updated in March 2019.",
  themeColor: "dark",
  geography: {
    AREA_CODE: {
      name: 'Area codes',
      displayHeader: true,
      items: [
        {value: 201, name: "201", meta:" - NJ"},
        {value: 202, name: "202", meta:" - DC"},
        {value: 203, name: "203", meta:" - CT"},
        {value: 204, name: "204", meta:" - MANITOBA"},
        {value: 205, name: "205", meta:" - AL"},
        {value: 206, name: "206", meta:" - WA"},
        {value: 207, name: "207", meta:" - ME"},
        {value: 208, name: "208", meta:" - ID"},
        {value: 209, name: "209", meta:" - CA"},
        {value: 210, name: "210", meta:" - TX"},
        {value: 212, name: "212", meta:" - NY"},
        {value: 213, name: "213", meta:" - CA"},
        {value: 214, name: "214", meta:" - TX"},
        {value: 215, name: "215", meta:" - PA"},
        {value: 216, name: "216", meta:" - OH"},
        {value: 217, name: "217", meta:" - IL"},
        {value: 218, name: "218", meta:" - MN"},
        {value: 219, name: "219", meta:" - IN"},
        {value: 220, name: "220", meta:" - OH"},
        {value: 223, name: "223", meta:" - PA"},
        {value: 224, name: "224", meta:" - IL"},
        {value: 225, name: "225", meta:" - LA"},
        {value: 226, name: "226", meta:" - ONTARIO"},
        {value: 228, name: "228", meta:" - MS"},
        {value: 229, name: "229", meta:" - GA"},
        {value: 231, name: "231", meta:" - MI"},
        {value: 234, name: "234", meta:" - OH"},
        {value: 236, name: "236", meta:" - BRITISH COLUMBIA"},
        {value: 239, name: "239", meta:" - FL"},
        {value: 240, name: "240", meta:" - MD"},
        {value: 242, name: "242", meta:" - BAHAMAS"},
        {value: 246, name: "246", meta:" - BARBADOS"},
        {value: 248, name: "248", meta:" - MI"},
        {value: 249, name: "249", meta:" - ONTARIO"},
        {value: 250, name: "250", meta:" - BRITISH COLUMBIA"},
        {value: 251, name: "251", meta:" - AL"},
        {value: 252, name: "252", meta:" - NC"},
        {value: 253, name: "253", meta:" - WA"},
        {value: 254, name: "254", meta:" - TX"},
        {value: 256, name: "256", meta:" - AL"},
        {value: 260, name: "260", meta:" - IN"},
        {value: 262, name: "262", meta:" - WI"},
        {value: 264, name: "264", meta:" - ANGUILLA"},
        {value: 267, name: "267", meta:" - PA"},
        {value: 268, name: "268", meta:" - ANTIGUA/BARBUDA"},
        {value: 269, name: "269", meta:" - MI"},
        {value: 270, name: "270", meta:" - KY"},
        {value: 272, name: "272", meta:" - PA"},
        {value: 276, name: "276", meta:" - VA"},
        {value: 279, name: "279", meta:" - CA"},
        {value: 281, name: "281", meta:" - TX"},
        {value: 284, name: "284", meta:" - BRITISH VIRGIN ISLANDS"},
        {value: 289, name: "289", meta:" - ONTARIO"},
        {value: 301, name: "301", meta:" - MD"},
        {value: 302, name: "302", meta:" - DE"},
        {value: 303, name: "303", meta:" - CO"},
        {value: 304, name: "304", meta:" - WV"},
        {value: 305, name: "305", meta:" - FL"},
        {value: 306, name: "306", meta:" - SASKATCHEWAN"},
        {value: 307, name: "307", meta:" - WY"},
        {value: 308, name: "308", meta:" - NE"},
        {value: 309, name: "309", meta:" - IL"},
        {value: 310, name: "310", meta:" - CA"},
        {value: 312, name: "312", meta:" - IL"},
        {value: 313, name: "313", meta:" - MI"},
        {value: 314, name: "314", meta:" - MO"},
        {value: 315, name: "315", meta:" - NY"},
        {value: 316, name: "316", meta:" - KS"},
        {value: 317, name: "317", meta:" - IN"},
        {value: 318, name: "318", meta:" - LA"},
        {value: 319, name: "319", meta:" - IA"},
        {value: 320, name: "320", meta:" - MN"},
        {value: 321, name: "321", meta:" - FL"},
        {value: 323, name: "323", meta:" - CA"},
        {value: 325, name: "325", meta:" - TX"},
        {value: 330, name: "330", meta:" - OH"},
        {value: 331, name: "331", meta:" - IL"},
        {value: 332, name: "332", meta:" - NY"},
        {value: 334, name: "334", meta:" - AL"},
        {value: 336, name: "336", meta:" - NC"},
        {value: 337, name: "337", meta:" - LA"},
        {value: 339, name: "339", meta:" - MA"},
        {value: 340, name: "340", meta:" - USVI"},
        {value: 341, name: "341", meta:" - CA"},
        {value: 343, name: "343", meta:" - ONTARIO"},
        {value: 345, name: "345", meta:" - CAYMAN ISLANDS"},
        {value: 346, name: "346", meta:" - TX"},
        {value: 347, name: "347", meta:" - NY"},
        {value: 351, name: "351", meta:" - MA"},
        {value: 352, name: "352", meta:" - FL"},
        {value: 360, name: "360", meta:" - WA"},
        {value: 361, name: "361", meta:" - TX"},
        {value: 364, name: "364", meta:" - KY"},
        {value: 365, name: "365", meta:" - ONTARIO"},
        {value: 367, name: "367", meta:" - QUEBEC"},
        {value: 380, name: "380", meta:" - OH"},
        {value: 385, name: "385", meta:" - UT"},
        {value: 386, name: "386", meta:" - FL"},
        {value: 401, name: "401", meta:" - RI"},
        {value: 402, name: "402", meta:" - NE"},
        {value: 403, name: "403", meta:" - ALBERTA"},
        {value: 404, name: "404", meta:" - GA"},
        {value: 405, name: "405", meta:" - OK"},
        {value: 406, name: "406", meta:" - MT"},
        {value: 407, name: "407", meta:" - FL"},
        {value: 408, name: "408", meta:" - CA"},
        {value: 409, name: "409", meta:" - TX"},
        {value: 410, name: "410", meta:" - MD"},
        {value: 412, name: "412", meta:" - PA"},
        {value: 413, name: "413", meta:" - MA"},
        {value: 414, name: "414", meta:" - WI"},
        {value: 415, name: "415", meta:" - CA"},
        {value: 416, name: "416", meta:" - ONTARIO"},
        {value: 417, name: "417", meta:" - MO"},
        {value: 418, name: "418", meta:" - QUEBEC"},
        {value: 419, name: "419", meta:" - OH"},
        {value: 423, name: "423", meta:" - TN"},
        {value: 424, name: "424", meta:" - CA"},
        {value: 425, name: "425", meta:" - WA"},
        {value: 430, name: "430", meta:" - TX"},
        {value: 431, name: "431", meta:" - MANITOBA"},
        {value: 432, name: "432", meta:" - TX"},
        {value: 434, name: "434", meta:" - VA"},
        {value: 435, name: "435", meta:" - UT"},
        {value: 437, name: "437", meta:" - ONTARIO"},
        {value: 438, name: "438", meta:" - QUEBEC"},
        {value: 440, name: "440", meta:" - OH"},
        {value: 441, name: "441", meta:" - BERMUDA"},
        {value: 442, name: "442", meta:" - CA"},
        {value: 443, name: "443", meta:" - MD"},
        {value: 445, name: "445", meta:" - PA"},
        {value: 450, name: "450", meta:" - QUEBEC"},
        {value: 458, name: "458", meta:" - OR"},
        {value: 463, name: "463", meta:" - IN"},
        {value: 469, name: "469", meta:" - TX"},
        {value: 470, name: "470", meta:" - GA"},
        {value: 473, name: "473", meta:" - GRENADA"},
        {value: 475, name: "475", meta:" - CT"},
        {value: 478, name: "478", meta:" - GA"},
        {value: 479, name: "479", meta:" - AR"},
        {value: 480, name: "480", meta:" - AZ"},
        {value: 484, name: "484", meta:" - PA"},
        {value: 501, name: "501", meta:" - AR"},
        {value: 502, name: "502", meta:" - KY"},
        {value: 503, name: "503", meta:" - OR"},
        {value: 504, name: "504", meta:" - LA"},
        {value: 505, name: "505", meta:" - NM"},
        {value: 506, name: "506", meta:" - NEW BRUNSWICK"},
        {value: 507, name: "507", meta:" - MN"},
        {value: 508, name: "508", meta:" - MA"},
        {value: 509, name: "509", meta:" - WA"},
        {value: 510, name: "510", meta:" - CA"},
        {value: 512, name: "512", meta:" - TX"},
        {value: 513, name: "513", meta:" - OH"},
        {value: 514, name: "514", meta:" - QUEBEC"},
        {value: 515, name: "515", meta:" - IA"},
        {value: 516, name: "516", meta:" - NY"},
        {value: 517, name: "517", meta:" - MI"},
        {value: 518, name: "518", meta:" - NY"},
        {value: 519, name: "519", meta:" - ONTARIO"},
        {value: 520, name: "520", meta:" - AZ"},
        {value: 530, name: "530", meta:" - CA"},
        {value: 531, name: "531", meta:" - NE"},
        {value: 534, name: "534", meta:" - WI"},
        {value: 539, name: "539", meta:" - OK"},
        {value: 540, name: "540", meta:" - VA"},
        {value: 541, name: "541", meta:" - OR"},
        {value: 548, name: "548", meta:" - ONTARIO"},
        {value: 551, name: "551", meta:" - NJ"},
        {value: 559, name: "559", meta:" - CA"},
        {value: 561, name: "561", meta:" - FL"},
        {value: 562, name: "562", meta:" - CA"},
        {value: 563, name: "563", meta:" - IA"},
        {value: 564, name: "564", meta:" - WA"},
        {value: 567, name: "567", meta:" - OH"},
        {value: 570, name: "570", meta:" - PA"},
        {value: 571, name: "571", meta:" - VA"},
        {value: 573, name: "573", meta:" - MO"},
        {value: 574, name: "574", meta:" - IN"},
        {value: 575, name: "575", meta:" - NM"},
        {value: 579, name: "579", meta:" - QUEBEC"},
        {value: 580, name: "580", meta:" - OK"},
        {value: 581, name: "581", meta:" - QUEBEC"},
        {value: 585, name: "585", meta:" - NY"},
        {value: 586, name: "586", meta:" - MI"},
        {value: 587, name: "587", meta:" - ALBERTA"},
        {value: 601, name: "601", meta:" - MS"},
        {value: 602, name: "602", meta:" - AZ"},
        {value: 603, name: "603", meta:" - NH"},
        {value: 604, name: "604", meta:" - BRITISH COLUMBIA"},
        {value: 605, name: "605", meta:" - SD"},
        {value: 606, name: "606", meta:" - KY"},
        {value: 607, name: "607", meta:" - NY"},
        {value: 608, name: "608", meta:" - WI"},
        {value: 609, name: "609", meta:" - NJ"},
        {value: 610, name: "610", meta:" - PA"},
        {value: 612, name: "612", meta:" - MN"},
        {value: 613, name: "613", meta:" - ONTARIO"},
        {value: 614, name: "614", meta:" - OH"},
        {value: 615, name: "615", meta:" - TN"},
        {value: 616, name: "616", meta:" - MI"},
        {value: 617, name: "617", meta:" - MA"},
        {value: 618, name: "618", meta:" - IL"},
        {value: 619, name: "619", meta:" - CA"},
        {value: 620, name: "620", meta:" - KS"},
        {value: 623, name: "623", meta:" - AZ"},
        {value: 626, name: "626", meta:" - CA"},
        {value: 628, name: "628", meta:" - CA"},
        {value: 629, name: "629", meta:" - TN"},
        {value: 630, name: "630", meta:" - IL"},
        {value: 631, name: "631", meta:" - NY"},
        {value: 636, name: "636", meta:" - MO"},
        {value: 639, name: "639", meta:" - SASKATCHEWAN"},
        {value: 640, name: "640", meta:" - NJ"},
        {value: 641, name: "641", meta:" - IA"},
        {value: 646, name: "646", meta:" - NY"},
        {value: 647, name: "647", meta:" - ONTARIO"},
        {value: 649, name: "649", meta:" - TURKS & CAICOS ISLANDS"},
        {value: 650, name: "650", meta:" - CA"},
        {value: 651, name: "651", meta:" - MN"},
        {value: 657, name: "657", meta:" - CA"},
        {value: 658, name: "658", meta:" - JAMAICA"},
        {value: 660, name: "660", meta:" - MO"},
        {value: 661, name: "661", meta:" - CA"},
        {value: 662, name: "662", meta:" - MS"},
        {value: 664, name: "664", meta:" - MONTSERRAT"},
        {value: 667, name: "667", meta:" - MD"},
        {value: 669, name: "669", meta:" - CA"},
        {value: 670, name: "670", meta:" - CNMI"},
        {value: 671, name: "671", meta:" - GU"},
        {value: 672, name: "672", meta:" - BRITISH COLUMBIA"},
        {value: 678, name: "678", meta:" - GA"},
        {value: 680, name: "680", meta:" - NY"},
        {value: 681, name: "681", meta:" - WV"},
        {value: 682, name: "682", meta:" - TX"},
        {value: 684, name: "684", meta:" - AS"},
        {value: 689, name: "689", meta:" - FL"},
        {value: 701, name: "701", meta:" - ND"},
        {value: 702, name: "702", meta:" - NV"},
        {value: 703, name: "703", meta:" - VA"},
        {value: 704, name: "704", meta:" - NC"},
        {value: 705, name: "705", meta:" - ONTARIO"},
        {value: 706, name: "706", meta:" - GA"},
        {value: 707, name: "707", meta:" - CA"},
        {value: 708, name: "708", meta:" - IL"},
        {value: 709, name: "709", meta:" - NEWFOUNDLAND AND LABRADOR"},
        {value: 712, name: "712", meta:" - IA"},
        {value: 713, name: "713", meta:" - TX"},
        {value: 714, name: "714", meta:" - CA"},
        {value: 715, name: "715", meta:" - WI"},
        {value: 716, name: "716", meta:" - NY"},
        {value: 717, name: "717", meta:" - PA"},
        {value: 718, name: "718", meta:" - NY"},
        {value: 719, name: "719", meta:" - CO"},
        {value: 720, name: "720", meta:" - CO"},
        {value: 721, name: "721", meta:" - SINT MAARTEN"},
        {value: 724, name: "724", meta:" - PA"},
        {value: 725, name: "725", meta:" - NV"},
        {value: 726, name: "726", meta:" - TX"},
        {value: 727, name: "727", meta:" - FL"},
        {value: 731, name: "731", meta:" - TN"},
        {value: 732, name: "732", meta:" - NJ"},
        {value: 734, name: "734", meta:" - MI"},
        {value: 737, name: "737", meta:" - TX"},
        {value: 740, name: "740", meta:" - OH"},
        {value: 743, name: "743", meta:" - NC"},
        {value: 747, name: "747", meta:" - CA"},
        {value: 754, name: "754", meta:" - FL"},
        {value: 757, name: "757", meta:" - VA"},
        {value: 758, name: "758", meta:" - ST. LUCIA"},
        {value: 760, name: "760", meta:" - CA"},
        {value: 762, name: "762", meta:" - GA"},
        {value: 763, name: "763", meta:" - MN"},
        {value: 765, name: "765", meta:" - IN"},
        {value: 767, name: "767", meta:" - DOMINICA"},
        {value: 769, name: "769", meta:" - MS"},
        {value: 770, name: "770", meta:" - GA"},
        {value: 772, name: "772", meta:" - FL"},
        {value: 773, name: "773", meta:" - IL"},
        {value: 774, name: "774", meta:" - MA"},
        {value: 775, name: "775", meta:" - NV"},
        {value: 778, name: "778", meta:" - BRITISH COLUMBIA"},
        {value: 779, name: "779", meta:" - IL"},
        {value: 780, name: "780", meta:" - ALBERTA"},
        {value: 781, name: "781", meta:" - MA"},
        {value: 782, name: "782", meta:" - NOVA SCOTIA - PRINCE EDWARD ISLAND"},
        {value: 784, name: "784", meta:" - ST. VINCENT & GRENADINES"},
        {value: 785, name: "785", meta:" - KS"},
        {value: 786, name: "786", meta:" - FL"},
        {value: 787, name: "787", meta:" - PUERTO RICO"},
        {value: 801, name: "801", meta:" - UT"},
        {value: 802, name: "802", meta:" - VT"},
        {value: 803, name: "803", meta:" - SC"},
        {value: 804, name: "804", meta:" - VA"},
        {value: 805, name: "805", meta:" - CA"},
        {value: 806, name: "806", meta:" - TX"},
        {value: 807, name: "807", meta:" - ONTARIO"},
        {value: 808, name: "808", meta:" - HI"},
        {value: 809, name: "809", meta:" - DOMINICAN REPUBLIC"},
        {value: 810, name: "810", meta:" - MI"},
        {value: 812, name: "812", meta:" - IN"},
        {value: 813, name: "813", meta:" - FL"},
        {value: 814, name: "814", meta:" - PA"},
        {value: 815, name: "815", meta:" - IL"},
        {value: 816, name: "816", meta:" - MO"},
        {value: 817, name: "817", meta:" - TX"},
        {value: 818, name: "818", meta:" - CA"},
        {value: 819, name: "819", meta:" - QUEBEC"},
        {value: 820, name: "820", meta:" - CA"},
        {value: 825, name: "825", meta:" - ALBERTA"},
        {value: 828, name: "828", meta:" - NC"},
        {value: 829, name: "829", meta:" - DOMINICAN REPUBLIC"},
        {value: 830, name: "830", meta:" - TX"},
        {value: 831, name: "831", meta:" - CA"},
        {value: 832, name: "832", meta:" - TX"},
        {value: 838, name: "838", meta:" - NY"},
        {value: 843, name: "843", meta:" - SC"},
        {value: 845, name: "845", meta:" - NY"},
        {value: 847, name: "847", meta:" - IL"},
        {value: 848, name: "848", meta:" - NJ"},
        {value: 849, name: "849", meta:" - DOMINICAN REPUBLIC"},
        {value: 850, name: "850", meta:" - FL"},
        {value: 854, name: "854", meta:" - SC"},
        {value: 856, name: "856", meta:" - NJ"},
        {value: 857, name: "857", meta:" - MA"},
        {value: 858, name: "858", meta:" - CA"},
        {value: 859, name: "859", meta:" - KY"},
        {value: 860, name: "860", meta:" - CT"},
        {value: 862, name: "862", meta:" - NJ"},
        {value: 863, name: "863", meta:" - FL"},
        {value: 864, name: "864", meta:" - SC"},
        {value: 865, name: "865", meta:" - TN"},
        {value: 867, name: "867", meta:" - YUKON-NW TERR. - NUNAVUT"},
        {value: 868, name: "868", meta:" - TRINIDAD & TOBAGO"},
        {value: 869, name: "869", meta:" - ST. KITTS & NEVIS"},
        {value: 870, name: "870", meta:" - AR"},
        {value: 872, name: "872", meta:" - IL"},
        {value: 873, name: "873", meta:" - QUEBEC"},
        {value: 876, name: "876", meta:" - JAMAICA"},
        {value: 878, name: "878", meta:" - PA"},
        {value: 901, name: "901", meta:" - TN"},
        {value: 902, name: "902", meta:" - NOVA SCOTIA - PRINCE EDWARD ISLAND"},
        {value: 903, name: "903", meta:" - TX"},
        {value: 904, name: "904", meta:" - FL"},
        {value: 905, name: "905", meta:" - ONTARIO"},
        {value: 906, name: "906", meta:" - MI"},
        {value: 907, name: "907", meta:" - AK"},
        {value: 908, name: "908", meta:" - NJ"},
        {value: 909, name: "909", meta:" - CA"},
        {value: 910, name: "910", meta:" - NC"},
        {value: 912, name: "912", meta:" - GA"},
        {value: 913, name: "913", meta:" - KS"},
        {value: 914, name: "914", meta:" - NY"},
        {value: 915, name: "915", meta:" - TX"},
        {value: 916, name: "916", meta:" - CA"},
        {value: 917, name: "917", meta:" - NY"},
        {value: 918, name: "918", meta:" - OK"},
        {value: 919, name: "919", meta:" - NC"},
        {value: 920, name: "920", meta:" - WI"},
        {value: 925, name: "925", meta:" - CA"},
        {value: 928, name: "928", meta:" - AZ"},
        {value: 929, name: "929", meta:" - NY"},
        {value: 930, name: "930", meta:" - IN"},
        {value: 931, name: "931", meta:" - TN"},
        {value: 934, name: "934", meta:" - NY"},
        {value: 936, name: "936", meta:" - TX"},
        {value: 937, name: "937", meta:" - OH"},
        {value: 938, name: "938", meta:" - AL"},
        {value: 939, name: "939", meta:" - PUERTO RICO"},
        {value: 940, name: "940", meta:" - TX"},
        {value: 941, name: "941", meta:" - FL"},
        {value: 947, name: "947", meta:" - MI"},
        {value: 949, name: "949", meta:" - CA"},
        {value: 951, name: "951", meta:" - CA"},
        {value: 952, name: "952", meta:" - MN"},
        {value: 954, name: "954", meta:" - FL"},
        {value: 956, name: "956", meta:" - TX"},
        {value: 959, name: "959", meta:" - CT"},
        {value: 970, name: "970", meta:" - CO"},
        {value: 971, name: "971", meta:" - OR"},
        {value: 972, name: "972", meta:" - TX"},
        {value: 973, name: "973", meta:" - NJ"},
        {value: 978, name: "978", meta:" - MA"},
        {value: 979, name: "979", meta:" - TX"},
        {value: 980, name: "980", meta:" - NC"},
        {value: 984, name: "984", meta:" - NC"},
        {value: 985, name: "985", meta:" - LA"},
        {value: 986, name: "986", meta:" - ID"},
        {value: 989, name: "989", meta:" - MI"},
      ]
    },
    COUNTY: {
      name: 'Counties',
      displayHeader: true,
      items: []
    }, 
    CITY: {
      name: 'Cities',
      displayHeader: true,
      items: []
    }, 
    ZIP: {
      name: 'Zip codes',
      displayHeader: true,
      items: []
    }, 
    ST: {
      name: 'States',
      displayHeader: true,
      items: [
        {value: "AK", name: "Alaska", query: [`(FIPS_ST='2')`] },
        {value: "AL", name: "Alabama", query: [`(FIPS_ST='1')`] },
        {value: "AR", name: "Arkansas", query: [`(FIPS_ST='5')`] },
        {value: "AZ", name: "Arizona", query: [`(FIPS_ST='4')`] },
        {value: "CA", name: "California", query: [`(FIPS_ST='6')`] },
        {value: "CO", name: "Colorado", query: [`(FIPS_ST='8')`] },
        {value: "CT", name: "Connecticut", query: [`(FIPS_ST='9')`] },
        {value: "DC", name: "District", query: [`(FIPS_ST='11')`] },
        {value: "DE", name: "Delaware", query: [`(FIPS_ST='10')`] },
        {value: "FL", name: "Florida", query: [`(FIPS_ST='12')`] },
        {value: "GA", name: "Georgia", query: [`(FIPS_ST='13')`] },
        {value: "HI", name: "Hawaii", query: [`(FIPS_ST='15')`] },
        {value: "IA", name: "Iowa", query: [`(FIPS_ST='19')`] },
        {value: "ID", name: "Idaho", query: [`(FIPS_ST='16')`] },
        {value: "IL", name: "Illinois", query: [`(FIPS_ST='17')`] },
        {value: "IN", name: "Indiana", query: [`(FIPS_ST='18')`] },
        {value: "KS", name: "Kansas", query: [`(FIPS_ST='20')`] },
        {value: "KY", name: "Kentucky", query: [`(FIPS_ST='21')`] },
        {value: "LA", name: "Louisiana", query: [`(FIPS_ST='22')`] },
        {value: "MA", name: "Massachusetts", query: [`(FIPS_ST='25')`] },
        {value: "MD", name: "Maryland", query: [`(FIPS_ST='24')`] },
        {value: "ME", name: "Maine", query: [`(FIPS_ST='23')`] },
        {value: "MI", name: "Michigan", query: [`(FIPS_ST='26')`] },
        {value: "MN", name: "Minnesota", query: [`(FIPS_ST='27')`] },
        {value: "MO", name: "Missouri", query: [`(FIPS_ST='29')`] },
        {value: "MS", name: "Mississippi", query: [`(FIPS_ST='28')`] },
        {value: "MT", name: "Montana", query: [`(FIPS_ST='30')`] },
        {value: "NC", name: "North Carolina", query: [`(FIPS_ST='37')`] },
        {value: "ND", name: "North Dakota", query: [`(FIPS_ST='38')`] },
        {value: "NE", name: "Nebraska", query: [`(FIPS_ST='31')`] },
        {value: "NH", name: "New Hampshire", query: [`(FIPS_ST='33')`] },
        {value: "NJ", name: "New Jersey", query: [`(FIPS_ST='34')`] },
        {value: "NM", name: "New Mexico", query: [`(FIPS_ST='35')`] },
        {value: "NV", name: "Nevada", query: [`(FIPS_ST='32')`] },
        {value: "NY", name: "New York", query: [`(FIPS_ST='36')`] },
        {value: "OH", name: "Ohio", query: [`(FIPS_ST='39')`] },
        {value: "OK", name: "Oklahoma", query: [`(FIPS_ST='40')`] },
        {value: "OR", name: "Oregon", query: [`(FIPS_ST='41')`] },
        {value: "PA", name: "Pennsylvania", query: [`(FIPS_ST='42')`] },
        {value: "RI", name: "Rhode Island", query: [`(FIPS_ST='44')`] },
        {value: "SC", name: "South Carolina", query: [`(FIPS_ST='45')`] },
        {value: "SD", name: "South Dakota", query: [`(FIPS_ST='46')`] },
        {value: "TN", name: "Tennessee", query: [`(FIPS_ST='47')`] },
        {value: "TX", name: "Texas", query: [`(FIPS_ST='48')`] },
        {value: "UT", name: "Utah", query: [`(FIPS_ST='49')`] },
        {value: "VA", name: "Virginia", query: [`(FIPS_ST='51')`] },
        {value: "VT", name: "Vermont", query: [`(FIPS_ST='50')`] },
        {value: "WA", name: "Washington", query: [`(FIPS_ST='53')`] },
        {value: "WI", name: "Wisconsin", query: [`(FIPS_ST='55')`] },
        {value: "WV", name: "West Virginia", query: [`(FIPS_ST='54')`] },
        {value: "WY", name: "Wyoming", query: [`(FIPS_ST='56')`] },
      ]
    },
    nationwide: {
      name: 'Nationwide',
      displayHeader: false,
      items: [],
    }
  },
  cols: {
    AREA_CODE: {
      name: 'Area codes',
      displayHeader: true,
      items: [
        {value: 201, name: "201", meta:" - NJ"},
        {value: 202, name: "202", meta:" - DC"},
        {value: 203, name: "203", meta:" - CT"},
        {value: 204, name: "204", meta:" - MANITOBA"},
        {value: 205, name: "205", meta:" - AL"},
        {value: 206, name: "206", meta:" - WA"},
        {value: 207, name: "207", meta:" - ME"},
        {value: 208, name: "208", meta:" - ID"},
        {value: 209, name: "209", meta:" - CA"},
        {value: 210, name: "210", meta:" - TX"},
        {value: 212, name: "212", meta:" - NY"},
        {value: 213, name: "213", meta:" - CA"},
        {value: 214, name: "214", meta:" - TX"},
        {value: 215, name: "215", meta:" - PA"},
        {value: 216, name: "216", meta:" - OH"},
        {value: 217, name: "217", meta:" - IL"},
        {value: 218, name: "218", meta:" - MN"},
        {value: 219, name: "219", meta:" - IN"},
        {value: 220, name: "220", meta:" - OH"},
        {value: 223, name: "223", meta:" - PA"},
        {value: 224, name: "224", meta:" - IL"},
        {value: 225, name: "225", meta:" - LA"},
        {value: 226, name: "226", meta:" - ONTARIO"},
        {value: 228, name: "228", meta:" - MS"},
        {value: 229, name: "229", meta:" - GA"},
        {value: 231, name: "231", meta:" - MI"},
        {value: 234, name: "234", meta:" - OH"},
        {value: 236, name: "236", meta:" - BRITISH COLUMBIA"},
        {value: 239, name: "239", meta:" - FL"},
        {value: 240, name: "240", meta:" - MD"},
        {value: 242, name: "242", meta:" - BAHAMAS"},
        {value: 246, name: "246", meta:" - BARBADOS"},
        {value: 248, name: "248", meta:" - MI"},
        {value: 249, name: "249", meta:" - ONTARIO"},
        {value: 250, name: "250", meta:" - BRITISH COLUMBIA"},
        {value: 251, name: "251", meta:" - AL"},
        {value: 252, name: "252", meta:" - NC"},
        {value: 253, name: "253", meta:" - WA"},
        {value: 254, name: "254", meta:" - TX"},
        {value: 256, name: "256", meta:" - AL"},
        {value: 260, name: "260", meta:" - IN"},
        {value: 262, name: "262", meta:" - WI"},
        {value: 264, name: "264", meta:" - ANGUILLA"},
        {value: 267, name: "267", meta:" - PA"},
        {value: 268, name: "268", meta:" - ANTIGUA/BARBUDA"},
        {value: 269, name: "269", meta:" - MI"},
        {value: 270, name: "270", meta:" - KY"},
        {value: 272, name: "272", meta:" - PA"},
        {value: 276, name: "276", meta:" - VA"},
        {value: 279, name: "279", meta:" - CA"},
        {value: 281, name: "281", meta:" - TX"},
        {value: 284, name: "284", meta:" - BRITISH VIRGIN ISLANDS"},
        {value: 289, name: "289", meta:" - ONTARIO"},
        {value: 301, name: "301", meta:" - MD"},
        {value: 302, name: "302", meta:" - DE"},
        {value: 303, name: "303", meta:" - CO"},
        {value: 304, name: "304", meta:" - WV"},
        {value: 305, name: "305", meta:" - FL"},
        {value: 306, name: "306", meta:" - SASKATCHEWAN"},
        {value: 307, name: "307", meta:" - WY"},
        {value: 308, name: "308", meta:" - NE"},
        {value: 309, name: "309", meta:" - IL"},
        {value: 310, name: "310", meta:" - CA"},
        {value: 312, name: "312", meta:" - IL"},
        {value: 313, name: "313", meta:" - MI"},
        {value: 314, name: "314", meta:" - MO"},
        {value: 315, name: "315", meta:" - NY"},
        {value: 316, name: "316", meta:" - KS"},
        {value: 317, name: "317", meta:" - IN"},
        {value: 318, name: "318", meta:" - LA"},
        {value: 319, name: "319", meta:" - IA"},
        {value: 320, name: "320", meta:" - MN"},
        {value: 321, name: "321", meta:" - FL"},
        {value: 323, name: "323", meta:" - CA"},
        {value: 325, name: "325", meta:" - TX"},
        {value: 330, name: "330", meta:" - OH"},
        {value: 331, name: "331", meta:" - IL"},
        {value: 332, name: "332", meta:" - NY"},
        {value: 334, name: "334", meta:" - AL"},
        {value: 336, name: "336", meta:" - NC"},
        {value: 337, name: "337", meta:" - LA"},
        {value: 339, name: "339", meta:" - MA"},
        {value: 340, name: "340", meta:" - USVI"},
        {value: 341, name: "341", meta:" - CA"},
        {value: 343, name: "343", meta:" - ONTARIO"},
        {value: 345, name: "345", meta:" - CAYMAN ISLANDS"},
        {value: 346, name: "346", meta:" - TX"},
        {value: 347, name: "347", meta:" - NY"},
        {value: 351, name: "351", meta:" - MA"},
        {value: 352, name: "352", meta:" - FL"},
        {value: 360, name: "360", meta:" - WA"},
        {value: 361, name: "361", meta:" - TX"},
        {value: 364, name: "364", meta:" - KY"},
        {value: 365, name: "365", meta:" - ONTARIO"},
        {value: 367, name: "367", meta:" - QUEBEC"},
        {value: 380, name: "380", meta:" - OH"},
        {value: 385, name: "385", meta:" - UT"},
        {value: 386, name: "386", meta:" - FL"},
        {value: 401, name: "401", meta:" - RI"},
        {value: 402, name: "402", meta:" - NE"},
        {value: 403, name: "403", meta:" - ALBERTA"},
        {value: 404, name: "404", meta:" - GA"},
        {value: 405, name: "405", meta:" - OK"},
        {value: 406, name: "406", meta:" - MT"},
        {value: 407, name: "407", meta:" - FL"},
        {value: 408, name: "408", meta:" - CA"},
        {value: 409, name: "409", meta:" - TX"},
        {value: 410, name: "410", meta:" - MD"},
        {value: 412, name: "412", meta:" - PA"},
        {value: 413, name: "413", meta:" - MA"},
        {value: 414, name: "414", meta:" - WI"},
        {value: 415, name: "415", meta:" - CA"},
        {value: 416, name: "416", meta:" - ONTARIO"},
        {value: 417, name: "417", meta:" - MO"},
        {value: 418, name: "418", meta:" - QUEBEC"},
        {value: 419, name: "419", meta:" - OH"},
        {value: 423, name: "423", meta:" - TN"},
        {value: 424, name: "424", meta:" - CA"},
        {value: 425, name: "425", meta:" - WA"},
        {value: 430, name: "430", meta:" - TX"},
        {value: 431, name: "431", meta:" - MANITOBA"},
        {value: 432, name: "432", meta:" - TX"},
        {value: 434, name: "434", meta:" - VA"},
        {value: 435, name: "435", meta:" - UT"},
        {value: 437, name: "437", meta:" - ONTARIO"},
        {value: 438, name: "438", meta:" - QUEBEC"},
        {value: 440, name: "440", meta:" - OH"},
        {value: 441, name: "441", meta:" - BERMUDA"},
        {value: 442, name: "442", meta:" - CA"},
        {value: 443, name: "443", meta:" - MD"},
        {value: 445, name: "445", meta:" - PA"},
        {value: 450, name: "450", meta:" - QUEBEC"},
        {value: 458, name: "458", meta:" - OR"},
        {value: 463, name: "463", meta:" - IN"},
        {value: 469, name: "469", meta:" - TX"},
        {value: 470, name: "470", meta:" - GA"},
        {value: 473, name: "473", meta:" - GRENADA"},
        {value: 475, name: "475", meta:" - CT"},
        {value: 478, name: "478", meta:" - GA"},
        {value: 479, name: "479", meta:" - AR"},
        {value: 480, name: "480", meta:" - AZ"},
        {value: 484, name: "484", meta:" - PA"},
        {value: 501, name: "501", meta:" - AR"},
        {value: 502, name: "502", meta:" - KY"},
        {value: 503, name: "503", meta:" - OR"},
        {value: 504, name: "504", meta:" - LA"},
        {value: 505, name: "505", meta:" - NM"},
        {value: 506, name: "506", meta:" - NEW BRUNSWICK"},
        {value: 507, name: "507", meta:" - MN"},
        {value: 508, name: "508", meta:" - MA"},
        {value: 509, name: "509", meta:" - WA"},
        {value: 510, name: "510", meta:" - CA"},
        {value: 512, name: "512", meta:" - TX"},
        {value: 513, name: "513", meta:" - OH"},
        {value: 514, name: "514", meta:" - QUEBEC"},
        {value: 515, name: "515", meta:" - IA"},
        {value: 516, name: "516", meta:" - NY"},
        {value: 517, name: "517", meta:" - MI"},
        {value: 518, name: "518", meta:" - NY"},
        {value: 519, name: "519", meta:" - ONTARIO"},
        {value: 520, name: "520", meta:" - AZ"},
        {value: 530, name: "530", meta:" - CA"},
        {value: 531, name: "531", meta:" - NE"},
        {value: 534, name: "534", meta:" - WI"},
        {value: 539, name: "539", meta:" - OK"},
        {value: 540, name: "540", meta:" - VA"},
        {value: 541, name: "541", meta:" - OR"},
        {value: 548, name: "548", meta:" - ONTARIO"},
        {value: 551, name: "551", meta:" - NJ"},
        {value: 559, name: "559", meta:" - CA"},
        {value: 561, name: "561", meta:" - FL"},
        {value: 562, name: "562", meta:" - CA"},
        {value: 563, name: "563", meta:" - IA"},
        {value: 564, name: "564", meta:" - WA"},
        {value: 567, name: "567", meta:" - OH"},
        {value: 570, name: "570", meta:" - PA"},
        {value: 571, name: "571", meta:" - VA"},
        {value: 573, name: "573", meta:" - MO"},
        {value: 574, name: "574", meta:" - IN"},
        {value: 575, name: "575", meta:" - NM"},
        {value: 579, name: "579", meta:" - QUEBEC"},
        {value: 580, name: "580", meta:" - OK"},
        {value: 581, name: "581", meta:" - QUEBEC"},
        {value: 585, name: "585", meta:" - NY"},
        {value: 586, name: "586", meta:" - MI"},
        {value: 587, name: "587", meta:" - ALBERTA"},
        {value: 601, name: "601", meta:" - MS"},
        {value: 602, name: "602", meta:" - AZ"},
        {value: 603, name: "603", meta:" - NH"},
        {value: 604, name: "604", meta:" - BRITISH COLUMBIA"},
        {value: 605, name: "605", meta:" - SD"},
        {value: 606, name: "606", meta:" - KY"},
        {value: 607, name: "607", meta:" - NY"},
        {value: 608, name: "608", meta:" - WI"},
        {value: 609, name: "609", meta:" - NJ"},
        {value: 610, name: "610", meta:" - PA"},
        {value: 612, name: "612", meta:" - MN"},
        {value: 613, name: "613", meta:" - ONTARIO"},
        {value: 614, name: "614", meta:" - OH"},
        {value: 615, name: "615", meta:" - TN"},
        {value: 616, name: "616", meta:" - MI"},
        {value: 617, name: "617", meta:" - MA"},
        {value: 618, name: "618", meta:" - IL"},
        {value: 619, name: "619", meta:" - CA"},
        {value: 620, name: "620", meta:" - KS"},
        {value: 623, name: "623", meta:" - AZ"},
        {value: 626, name: "626", meta:" - CA"},
        {value: 628, name: "628", meta:" - CA"},
        {value: 629, name: "629", meta:" - TN"},
        {value: 630, name: "630", meta:" - IL"},
        {value: 631, name: "631", meta:" - NY"},
        {value: 636, name: "636", meta:" - MO"},
        {value: 639, name: "639", meta:" - SASKATCHEWAN"},
        {value: 640, name: "640", meta:" - NJ"},
        {value: 641, name: "641", meta:" - IA"},
        {value: 646, name: "646", meta:" - NY"},
        {value: 647, name: "647", meta:" - ONTARIO"},
        {value: 649, name: "649", meta:" - TURKS & CAICOS ISLANDS"},
        {value: 650, name: "650", meta:" - CA"},
        {value: 651, name: "651", meta:" - MN"},
        {value: 657, name: "657", meta:" - CA"},
        {value: 658, name: "658", meta:" - JAMAICA"},
        {value: 660, name: "660", meta:" - MO"},
        {value: 661, name: "661", meta:" - CA"},
        {value: 662, name: "662", meta:" - MS"},
        {value: 664, name: "664", meta:" - MONTSERRAT"},
        {value: 667, name: "667", meta:" - MD"},
        {value: 669, name: "669", meta:" - CA"},
        {value: 670, name: "670", meta:" - CNMI"},
        {value: 671, name: "671", meta:" - GU"},
        {value: 672, name: "672", meta:" - BRITISH COLUMBIA"},
        {value: 678, name: "678", meta:" - GA"},
        {value: 680, name: "680", meta:" - NY"},
        {value: 681, name: "681", meta:" - WV"},
        {value: 682, name: "682", meta:" - TX"},
        {value: 684, name: "684", meta:" - AS"},
        {value: 689, name: "689", meta:" - FL"},
        {value: 701, name: "701", meta:" - ND"},
        {value: 702, name: "702", meta:" - NV"},
        {value: 703, name: "703", meta:" - VA"},
        {value: 704, name: "704", meta:" - NC"},
        {value: 705, name: "705", meta:" - ONTARIO"},
        {value: 706, name: "706", meta:" - GA"},
        {value: 707, name: "707", meta:" - CA"},
        {value: 708, name: "708", meta:" - IL"},
        {value: 709, name: "709", meta:" - NEWFOUNDLAND AND LABRADOR"},
        {value: 712, name: "712", meta:" - IA"},
        {value: 713, name: "713", meta:" - TX"},
        {value: 714, name: "714", meta:" - CA"},
        {value: 715, name: "715", meta:" - WI"},
        {value: 716, name: "716", meta:" - NY"},
        {value: 717, name: "717", meta:" - PA"},
        {value: 718, name: "718", meta:" - NY"},
        {value: 719, name: "719", meta:" - CO"},
        {value: 720, name: "720", meta:" - CO"},
        {value: 721, name: "721", meta:" - SINT MAARTEN"},
        {value: 724, name: "724", meta:" - PA"},
        {value: 725, name: "725", meta:" - NV"},
        {value: 726, name: "726", meta:" - TX"},
        {value: 727, name: "727", meta:" - FL"},
        {value: 731, name: "731", meta:" - TN"},
        {value: 732, name: "732", meta:" - NJ"},
        {value: 734, name: "734", meta:" - MI"},
        {value: 737, name: "737", meta:" - TX"},
        {value: 740, name: "740", meta:" - OH"},
        {value: 743, name: "743", meta:" - NC"},
        {value: 747, name: "747", meta:" - CA"},
        {value: 754, name: "754", meta:" - FL"},
        {value: 757, name: "757", meta:" - VA"},
        {value: 758, name: "758", meta:" - ST. LUCIA"},
        {value: 760, name: "760", meta:" - CA"},
        {value: 762, name: "762", meta:" - GA"},
        {value: 763, name: "763", meta:" - MN"},
        {value: 765, name: "765", meta:" - IN"},
        {value: 767, name: "767", meta:" - DOMINICA"},
        {value: 769, name: "769", meta:" - MS"},
        {value: 770, name: "770", meta:" - GA"},
        {value: 772, name: "772", meta:" - FL"},
        {value: 773, name: "773", meta:" - IL"},
        {value: 774, name: "774", meta:" - MA"},
        {value: 775, name: "775", meta:" - NV"},
        {value: 778, name: "778", meta:" - BRITISH COLUMBIA"},
        {value: 779, name: "779", meta:" - IL"},
        {value: 780, name: "780", meta:" - ALBERTA"},
        {value: 781, name: "781", meta:" - MA"},
        {value: 782, name: "782", meta:" - NOVA SCOTIA - PRINCE EDWARD ISLAND"},
        {value: 784, name: "784", meta:" - ST. VINCENT & GRENADINES"},
        {value: 785, name: "785", meta:" - KS"},
        {value: 786, name: "786", meta:" - FL"},
        {value: 787, name: "787", meta:" - PUERTO RICO"},
        {value: 801, name: "801", meta:" - UT"},
        {value: 802, name: "802", meta:" - VT"},
        {value: 803, name: "803", meta:" - SC"},
        {value: 804, name: "804", meta:" - VA"},
        {value: 805, name: "805", meta:" - CA"},
        {value: 806, name: "806", meta:" - TX"},
        {value: 807, name: "807", meta:" - ONTARIO"},
        {value: 808, name: "808", meta:" - HI"},
        {value: 809, name: "809", meta:" - DOMINICAN REPUBLIC"},
        {value: 810, name: "810", meta:" - MI"},
        {value: 812, name: "812", meta:" - IN"},
        {value: 813, name: "813", meta:" - FL"},
        {value: 814, name: "814", meta:" - PA"},
        {value: 815, name: "815", meta:" - IL"},
        {value: 816, name: "816", meta:" - MO"},
        {value: 817, name: "817", meta:" - TX"},
        {value: 818, name: "818", meta:" - CA"},
        {value: 819, name: "819", meta:" - QUEBEC"},
        {value: 820, name: "820", meta:" - CA"},
        {value: 825, name: "825", meta:" - ALBERTA"},
        {value: 828, name: "828", meta:" - NC"},
        {value: 829, name: "829", meta:" - DOMINICAN REPUBLIC"},
        {value: 830, name: "830", meta:" - TX"},
        {value: 831, name: "831", meta:" - CA"},
        {value: 832, name: "832", meta:" - TX"},
        {value: 838, name: "838", meta:" - NY"},
        {value: 843, name: "843", meta:" - SC"},
        {value: 845, name: "845", meta:" - NY"},
        {value: 847, name: "847", meta:" - IL"},
        {value: 848, name: "848", meta:" - NJ"},
        {value: 849, name: "849", meta:" - DOMINICAN REPUBLIC"},
        {value: 850, name: "850", meta:" - FL"},
        {value: 854, name: "854", meta:" - SC"},
        {value: 856, name: "856", meta:" - NJ"},
        {value: 857, name: "857", meta:" - MA"},
        {value: 858, name: "858", meta:" - CA"},
        {value: 859, name: "859", meta:" - KY"},
        {value: 860, name: "860", meta:" - CT"},
        {value: 862, name: "862", meta:" - NJ"},
        {value: 863, name: "863", meta:" - FL"},
        {value: 864, name: "864", meta:" - SC"},
        {value: 865, name: "865", meta:" - TN"},
        {value: 867, name: "867", meta:" - YUKON-NW TERR. - NUNAVUT"},
        {value: 868, name: "868", meta:" - TRINIDAD & TOBAGO"},
        {value: 869, name: "869", meta:" - ST. KITTS & NEVIS"},
        {value: 870, name: "870", meta:" - AR"},
        {value: 872, name: "872", meta:" - IL"},
        {value: 873, name: "873", meta:" - QUEBEC"},
        {value: 876, name: "876", meta:" - JAMAICA"},
        {value: 878, name: "878", meta:" - PA"},
        {value: 901, name: "901", meta:" - TN"},
        {value: 902, name: "902", meta:" - NOVA SCOTIA - PRINCE EDWARD ISLAND"},
        {value: 903, name: "903", meta:" - TX"},
        {value: 904, name: "904", meta:" - FL"},
        {value: 905, name: "905", meta:" - ONTARIO"},
        {value: 906, name: "906", meta:" - MI"},
        {value: 907, name: "907", meta:" - AK"},
        {value: 908, name: "908", meta:" - NJ"},
        {value: 909, name: "909", meta:" - CA"},
        {value: 910, name: "910", meta:" - NC"},
        {value: 912, name: "912", meta:" - GA"},
        {value: 913, name: "913", meta:" - KS"},
        {value: 914, name: "914", meta:" - NY"},
        {value: 915, name: "915", meta:" - TX"},
        {value: 916, name: "916", meta:" - CA"},
        {value: 917, name: "917", meta:" - NY"},
        {value: 918, name: "918", meta:" - OK"},
        {value: 919, name: "919", meta:" - NC"},
        {value: 920, name: "920", meta:" - WI"},
        {value: 925, name: "925", meta:" - CA"},
        {value: 928, name: "928", meta:" - AZ"},
        {value: 929, name: "929", meta:" - NY"},
        {value: 930, name: "930", meta:" - IN"},
        {value: 931, name: "931", meta:" - TN"},
        {value: 934, name: "934", meta:" - NY"},
        {value: 936, name: "936", meta:" - TX"},
        {value: 937, name: "937", meta:" - OH"},
        {value: 938, name: "938", meta:" - AL"},
        {value: 939, name: "939", meta:" - PUERTO RICO"},
        {value: 940, name: "940", meta:" - TX"},
        {value: 941, name: "941", meta:" - FL"},
        {value: 947, name: "947", meta:" - MI"},
        {value: 949, name: "949", meta:" - CA"},
        {value: 951, name: "951", meta:" - CA"},
        {value: 952, name: "952", meta:" - MN"},
        {value: 954, name: "954", meta:" - FL"},
        {value: 956, name: "956", meta:" - TX"},
        {value: 959, name: "959", meta:" - CT"},
        {value: 970, name: "970", meta:" - CO"},
        {value: 971, name: "971", meta:" - OR"},
        {value: 972, name: "972", meta:" - TX"},
        {value: 973, name: "973", meta:" - NJ"},
        {value: 978, name: "978", meta:" - MA"},
        {value: 979, name: "979", meta:" - TX"},
        {value: 980, name: "980", meta:" - NC"},
        {value: 984, name: "984", meta:" - NC"},
        {value: 985, name: "985", meta:" - LA"},
        {value: 986, name: "986", meta:" - ID"},
        {value: 989, name: "989", meta:" - MI"},
      ]
    },
    COUNTY: {
      name: 'Counties',
      displayHeader: true,
      items: []
    }, 
    CITY: {
      name: 'Cities',
      displayHeader: true,
      items: []
    }, 
    ZIP: {
      name: 'Zip codes',
      displayHeader: true,
      items: []
    }, 
    ST: {
      name: 'States',
      displayHeader: true,
      items: [
        {value: "AK", name: "Alaska", query: [`(FIPS_ST='2')`] },
        {value: "AL", name: "Alabama", query: [`(FIPS_ST='1')`] },
        {value: "AR", name: "Arkansas", query: [`(FIPS_ST='5')`] },
        {value: "AZ", name: "Arizona", query: [`(FIPS_ST='4')`] },
        {value: "CA", name: "California", query: [`(FIPS_ST='6')`] },
        {value: "CO", name: "Colorado", query: [`(FIPS_ST='8')`] },
        {value: "CT", name: "Connecticut", query: [`(FIPS_ST='9')`] },
        {value: "DC", name: "District", query: [`(FIPS_ST='11')`] },
        {value: "DE", name: "Delaware", query: [`(FIPS_ST='10')`] },
        {value: "FL", name: "Florida", query: [`(FIPS_ST='12')`] },
        {value: "GA", name: "Georgia", query: [`(FIPS_ST='13')`] },
        {value: "HI", name: "Hawaii", query: [`(FIPS_ST='15')`] },
        {value: "IA", name: "Iowa", query: [`(FIPS_ST='19')`] },
        {value: "ID", name: "Idaho", query: [`(FIPS_ST='16')`] },
        {value: "IL", name: "Illinois", query: [`(FIPS_ST='17')`] },
        {value: "IN", name: "Indiana", query: [`(FIPS_ST='18')`] },
        {value: "KS", name: "Kansas", query: [`(FIPS_ST='20')`] },
        {value: "KY", name: "Kentucky", query: [`(FIPS_ST='21')`] },
        {value: "LA", name: "Louisiana", query: [`(FIPS_ST='22')`] },
        {value: "MA", name: "Massachusetts", query: [`(FIPS_ST='25')`] },
        {value: "MD", name: "Maryland", query: [`(FIPS_ST='24')`] },
        {value: "ME", name: "Maine", query: [`(FIPS_ST='23')`] },
        {value: "MI", name: "Michigan", query: [`(FIPS_ST='26')`] },
        {value: "MN", name: "Minnesota", query: [`(FIPS_ST='27')`] },
        {value: "MO", name: "Missouri", query: [`(FIPS_ST='29')`] },
        {value: "MS", name: "Mississippi", query: [`(FIPS_ST='28')`] },
        {value: "MT", name: "Montana", query: [`(FIPS_ST='30')`] },
        {value: "NC", name: "North Carolina", query: [`(FIPS_ST='37')`] },
        {value: "ND", name: "North Dakota", query: [`(FIPS_ST='38')`] },
        {value: "NE", name: "Nebraska", query: [`(FIPS_ST='31')`] },
        {value: "NH", name: "New Hampshire", query: [`(FIPS_ST='33')`] },
        {value: "NJ", name: "New Jersey", query: [`(FIPS_ST='34')`] },
        {value: "NM", name: "New Mexico", query: [`(FIPS_ST='35')`] },
        {value: "NV", name: "Nevada", query: [`(FIPS_ST='32')`] },
        {value: "NY", name: "New York", query: [`(FIPS_ST='36')`] },
        {value: "OH", name: "Ohio", query: [`(FIPS_ST='39')`] },
        {value: "OK", name: "Oklahoma", query: [`(FIPS_ST='40')`] },
        {value: "OR", name: "Oregon", query: [`(FIPS_ST='41')`] },
        {value: "PA", name: "Pennsylvania", query: [`(FIPS_ST='42')`] },
        {value: "RI", name: "Rhode Island", query: [`(FIPS_ST='44')`] },
        {value: "SC", name: "South Carolina", query: [`(FIPS_ST='45')`] },
        {value: "SD", name: "South Dakota", query: [`(FIPS_ST='46')`] },
        {value: "TN", name: "Tennessee", query: [`(FIPS_ST='47')`] },
        {value: "TX", name: "Texas", query: [`(FIPS_ST='48')`] },
        {value: "UT", name: "Utah", query: [`(FIPS_ST='49')`] },
        {value: "VA", name: "Virginia", query: [`(FIPS_ST='51')`] },
        {value: "VT", name: "Vermont", query: [`(FIPS_ST='50')`] },
        {value: "WA", name: "Washington", query: [`(FIPS_ST='53')`] },
        {value: "WI", name: "Wisconsin", query: [`(FIPS_ST='55')`] },
        {value: "WV", name: "West Virginia", query: [`(FIPS_ST='54')`] },
        {value: "WY", name: "Wyoming", query: [`(FIPS_ST='56')`] },
      ]
    },
    nationwide: {
      name: 'Nationwide',
      displayHeader: false,
      items: [],
    },
    PHONE_OCCURRENCE: {
      name: "One phone record per household",
      displayHeader: false,
      inputType: "checkbox",
      defaultValue: true,
      items: [
        {index: 0, value: 1, name: "One phone record per household"}
      ]
    },
    AGE: {
      name: "Ages",
      displayHeader: true,
      inputType: "range",
      items: [
        {value: 18, order: 18, name: 18},
        {value: 19, order: 19, name: 19},
        {value: 20, order: 20, name: 20},
        {value: 21, order: 21, name: 21},
        {value: 22, order: 22, name: 22},
        {value: 23, order: 23, name: 23},
        {value: 24, order: 24, name: 24},
        {value: 25, order: 25, name: 25},
        {value: 26, order: 26, name: 26},
        {value: 27, order: 27, name: 27},
        {value: 28, order: 28, name: 28},
        {value: 29, order: 29, name: 29},
        {value: 30, order: 30, name: 30},
        {value: 31, order: 31, name: 31},
        {value: 32, order: 32, name: 32},
        {value: 33, order: 33, name: 33},
        {value: 34, order: 34, name: 34},
        {value: 35, order: 35, name: 35},
        {value: 36, order: 36, name: 36},
        {value: 37, order: 37, name: 37},
        {value: 38, order: 38, name: 38},
        {value: 39, order: 39, name: 39},
        {value: 40, order: 40, name: 40},
        {value: 41, order: 41, name: 41},
        {value: 42, order: 42, name: 42},
        {value: 43, order: 43, name: 43},
        {value: 44, order: 44, name: 44},
        {value: 45, order: 45, name: 45},
        {value: 46, order: 46, name: 46},
        {value: 47, order: 47, name: 47},
        {value: 48, order: 48, name: 48},
        {value: 49, order: 49, name: 49},
        {value: 50, order: 50, name: 50},
        {value: 51, order: 51, name: 51},
        {value: 52, order: 52, name: 52},
        {value: 53, order: 53, name: 53},
        {value: 54, order: 54, name: 54},
        {value: 55, order: 55, name: 55},
        {value: 56, order: 56, name: 56},
        {value: 57, order: 57, name: 57},
        {value: 58, order: 58, name: 58},
        {value: 59, order: 59, name: 59},
        {value: 60, order: 60, name: 60},
        {value: 61, order: 61, name: 61},
        {value: 62, order: 62, name: 62},
        {value: 63, order: 63, name: 63},
        {value: 64, order: 64, name: 64},
        {value: 65, order: 65, name: 65},
        {value: 66, order: 66, name: 66},
        {value: 67, order: 67, name: 67},
        {value: 68, order: 68, name: 68},
        {value: 69, order: 69, name: 69},
        {value: 70, order: 70, name: 70},
        {value: 71, order: 71, name: 71},
        {value: 72, order: 72, name: 72},
        {value: 73, order: 73, name: 73},
        {value: 74, order: 74, name: 74},
        {value: 75, order: 75, name: 75},
        {value: 76, order: 76, name: 76},
        {value: 77, order: 77, name: 77},
        {value: 78, order: 78, name: 78},
        {value: 79, order: 79, name: 79},
        {value: 80, order: 80, name: 80},
        {value: 81, order: 81, name: 81},
        {value: 82, order: 82, name: 82},
        {value: 83, order: 83, name: 83},
        {value: 84, order: 84, name: 84},
        {value: 85, order: 85, name: 85},
        {value: 86, order: 86, name: 86},
        {value: 87, order: 87, name: 87},
        {value: 88, order: 88, name: 88},
        {value: 89, order: 89, name: 89},
        {value: 90, order: 90, name: 90},
        {value: 91, order: 91, name: 91},
        {value: 92, order: 92, name: 92},
        {value: 93, order: 93, name: 93},
        {value: 94, order: 94, name: 94},
        {value: 95, order: 95, name: 95},
        {value: 96, order: 96, name: 96},
        {value: 97, order: 97, name: 97},
        {value: 98, order: 98, name: 98},
        {value: 99, order: 99, name: 99},
      ],
    },
    HH_INCOME: {
      name: "Estimated income range",
      displayHeader: true,
      inputType: "range",
      items: [
        {value: "A", order: 1, name: "Under $10K"},
        {value: "B", order: 2, name: "$10K - $14K"},
        {value: "C", order: 3, name: "$15K - $19K"},
        {value: "D", order: 4, name: "$20K - $24K"},
        {value: "E", order: 5, name: "$25K - $29K"},
        {value: "F", order: 6, name: "$30K - $34K"},
        {value: "G", order: 7, name: "$35K - $39K"},
        {value: "H", order: 8, name: "$40K - $44K"},
        {value: "I", order: 9, name: "$45K - $49K"},
        {value: "J", order: 10, name: "$50K - $54K"},
        {value: "K", order: 11, name: "$55K - $59K"},
        {value: "L", order: 12, name: "$60K - $64K"},
        {value: "M", order: 13, name: "$65K - $74K"},
        {value: "N", order: 13, name: "$75K - $99K"},
        {value: "O", order: 14, name: "$100K - $149K"},
        {value: "P", order: 15, name: "$150K - $174K"},
        {value: "Q", order: 16, name: "$175K - $199K"},
        {value: "R", order: 17, name: "$200K - $249K"},
      ]
    },
    HOMEOWNER_STATUS_ENHANCED: {
      name: "Home owner status",
      displayHeader: true,
      inputType: "checkbox",
      items: [
        {index: 0, value: "H", name: "Home owner"},
        {index: 1, value: "R", name: "Renter"},
        {index: 2, value: "LR", name: "Likely renter"},
        {index: 3, value: "LH", name: "Likely home owner"}
      ]
    },
    HH_MARITAL_STAT: {
      name: "Marital status",
      displayHeader: true,
      inputType: "checkbox",
      colname: "personmaritalstatus",
      items: [
        {index: 0, value: "M", name: "Married"},
        {index: 1, value: "A", name: "Likely married"},
        {index: 2, value: "S", name: "Single"},
        {index: 3, value: "B", name: "Likely single"}
      ]
    },
    GENDER: {
      name: "Gender",
      displayHeader: true,
      inputType: "select",
      items: [
        {value: "", name: "No preference"},
        {value: "F", name: "Female"},
        {value: "M", name: "Male"},
      ]
    },
    HOME_MKT_VALUE: {
      name: "Estimated property value",
      displayHeader: true,
      inputType: "range",
      items: [
        {value: "A", order: 10, name: "Less than $25K"},
        {value: "B", order: 20, name: "$25K - $49K"},
        {value: "C", order: 40, name: "$50K - $74K"},
        {value: "D", order: 50, name: "$75K - $99K"},
        {value: "E", order: 70, name: "$100K - $124K"},
        {value: "F", order: 90, name: "$125K - $149K"},
        {value: "G", order: 110, name: "$150K - $174K"},
        {value: "H", order: 130, name: "$175K - $199K"},
        {value: "I", order: 150, name: "$200K - $224K"},
        {value: "J", order: 170, name: "$225K - $249K"},
        {value: "K", order: 190, name: "$250K - $274K"},
        {value: "L", order: 210, name: "$275K - $299K"},
        {value: "M", order: 230, name: "$300K - $349K"},
        {value: "N", order: 250, name: "$350K - $399K"},
        {value: "O", order: 270, name: "$400K - $449K"},
        {value: "P", order: 290, name: "$450K - $499K"},
        {value: "Q", order: 310, name: "$500K - $749K"},
        {value: "R", order: 330, name: "$750K - $999K"},
        {value: "S", order: 360, name: "$1M+"}
      ]
    },
    PROP_TYPE: {
      name: "Property type",
      displayHeader: true,
      inputType: "checkbox",
      items: [
        {index: 0, value: "S", name: "Single family dwelling"},
        {index: 1, value: "M", name: "Multiple family dwelling"}
      ]
    },
    PROP_BLD_YR: {
      name: "Year built",
      displayHeader: true,
      inputType: "range",
      sortItems: "reverse",
      items: [
        {value: 1949, order: 1949, name: 1949},
        {value: 1950, order: 1950, name: 1950},
        {value: 1951, order: 1951, name: 1951},
        {value: 1952, order: 1952, name: 1952},
        {value: 1953, order: 1953, name: 1953},
        {value: 1954, order: 1954, name: 1954},
        {value: 1955, order: 1955, name: 1955},
        {value: 1956, order: 1956, name: 1956},
        {value: 1957, order: 1957, name: 1957},
        {value: 1958, order: 1958, name: 1958},
        {value: 1959, order: 1959, name: 1959},
        {value: 1960, order: 1960, name: 1960},
        {value: 1961, order: 1961, name: 1961},
        {value: 1962, order: 1962, name: 1962},
        {value: 1963, order: 1963, name: 1963},
        {value: 1964, order: 1964, name: 1964},
        {value: 1965, order: 1965, name: 1965},
        {value: 1966, order: 1966, name: 1966},
        {value: 1967, order: 1967, name: 1967},
        {value: 1968, order: 1968, name: 1968},
        {value: 1969, order: 1969, name: 1969},
        {value: 1970, order: 1970, name: 1970},
        {value: 1971, order: 1971, name: 1971},
        {value: 1972, order: 1972, name: 1972},
        {value: 1973, order: 1973, name: 1973},
        {value: 1974, order: 1974, name: 1974},
        {value: 1975, order: 1975, name: 1975},
        {value: 1976, order: 1976, name: 1976},
        {value: 1977, order: 1977, name: 1977},
        {value: 1978, order: 1978, name: 1978},
        {value: 1979, order: 1979, name: 1979},
        {value: 1980, order: 1980, name: 1980},
        {value: 1981, order: 1981, name: 1981},
        {value: 1982, order: 1982, name: 1982},
        {value: 1983, order: 1983, name: 1983},
        {value: 1984, order: 1984, name: 1984},
        {value: 1985, order: 1985, name: 1985},
        {value: 1986, order: 1986, name: 1986},
        {value: 1987, order: 1987, name: 1987},
        {value: 1988, order: 1988, name: 1988},
        {value: 1989, order: 1989, name: 1989},
        {value: 1990, order: 1990, name: 1990},
        {value: 1991, order: 1991, name: 1991},
        {value: 1992, order: 1992, name: 1992},
        {value: 1993, order: 1993, name: 1993},
        {value: 1994, order: 1994, name: 1994},
        {value: 1995, order: 1995, name: 1995},
        {value: 1996, order: 1996, name: 1996},
        {value: 1997, order: 1997, name: 1997},
        {value: 1998, order: 1998, name: 1998},
        {value: 1999, order: 1999, name: 1999},
        {value: 2000, order: 2000, name: 2000},
        {value: 2001, order: 2001, name: 2001},
        {value: 2002, order: 2002, name: 2002},
        {value: 2003, order: 2003, name: 2003},
        {value: 2004, order: 2004, name: 2004},
        {value: 2005, order: 2005, name: 2005},
        {value: 2006, order: 2006, name: 2006},
        {value: 2007, order: 2007, name: 2007},
        {value: 2008, order: 2008, name: 2008},
        {value: 2009, order: 2009, name: 2009},
        {value: 2010, order: 2010, name: 2010},
        {value: 2011, order: 2011, name: 2011},
        {value: 2012, order: 2012, name: 2012},
        {value: 2013, order: 2013, name: 2013},
        {value: 2014, order: 2014, name: 2014},
        {value: 2015, order: 2015, name: 2015},
        {value: 2016, order: 2016, name: 2016},
        {value: 2017, order: 2017, name: 2017},
        {value: 2018, order: 2018, name: 2018},
      ]
    },
    PURCH_MONTH: {
      name: "Purchase month range",
      displayHeader: true,
      inputType: "range",
      items: [
        {value: "1", order: 1, name: "January"},
        {value: "2", order: 2, name: "February"},
        {value: "3", order: 3, name: "March"},
        {value: "4", order: 4, name: "April"},
        {value: "5", order: 5, name: "May"},
        {value: "6", order: 6, name: "June"},
        {value: "7", order: 7, name: "July"},
        {value: "8", order: 8, name: "August"},
        {value: "9", order: 9, name: "September"},
        {value: "10", order: 10, name: "October"},
        {value: "11", order: 11, name: "November"},
        {value: "12", order: 12, name: "December"},
      ]
    },
    PURCH_YEAR: {
      name: "Purchase year",
      displayHeader: true,
      inputType: "range",
      sortItems: "reverse",
      items: [
        {value: 1900, order: 1900, name: 1900},
        {value: 1901, order: 1901, name: 1901},
        {value: 1902, order: 1902, name: 1902},
        {value: 1903, order: 1903, name: 1903},
        {value: 1904, order: 1904, name: 1904},
        {value: 1905, order: 1905, name: 1905},
        {value: 1906, order: 1906, name: 1906},
        {value: 1907, order: 1907, name: 1907},
        {value: 1908, order: 1908, name: 1908},
        {value: 1909, order: 1909, name: 1909},
        {value: 1910, order: 1910, name: 1910},
        {value: 1911, order: 1911, name: 1911},
        {value: 1912, order: 1912, name: 1912},
        {value: 1913, order: 1913, name: 1913},
        {value: 1914, order: 1914, name: 1914},
        {value: 1915, order: 1915, name: 1915},
        {value: 1916, order: 1916, name: 1916},
        {value: 1917, order: 1917, name: 1917},
        {value: 1918, order: 1918, name: 1918},
        {value: 1919, order: 1919, name: 1919},
        {value: 1920, order: 1920, name: 1920},
        {value: 1921, order: 1921, name: 1921},
        {value: 1922, order: 1922, name: 1922},
        {value: 1923, order: 1923, name: 1923},
        {value: 1924, order: 1924, name: 1924},
        {value: 1925, order: 1925, name: 1925},
        {value: 1926, order: 1926, name: 1926},
        {value: 1927, order: 1927, name: 1927},
        {value: 1928, order: 1928, name: 1928},
        {value: 1929, order: 1929, name: 1929},
        {value: 1930, order: 1930, name: 1930},
        {value: 1931, order: 1931, name: 1931},
        {value: 1932, order: 1932, name: 1932},
        {value: 1933, order: 1933, name: 1933},
        {value: 1934, order: 1934, name: 1934},
        {value: 1935, order: 1935, name: 1935},
        {value: 1936, order: 1936, name: 1936},
        {value: 1937, order: 1937, name: 1937},
        {value: 1938, order: 1938, name: 1938},
        {value: 1939, order: 1939, name: 1939},
        {value: 1940, order: 1940, name: 1940},
        {value: 1941, order: 1941, name: 1941},
        {value: 1942, order: 1942, name: 1942},
        {value: 1943, order: 1943, name: 1943},
        {value: 1944, order: 1944, name: 1944},
        {value: 1945, order: 1945, name: 1945},
        {value: 1946, order: 1946, name: 1946},
        {value: 1947, order: 1947, name: 1947},
        {value: 1948, order: 1948, name: 1948},
        {value: 1949, order: 1949, name: 1949},
        {value: 1950, order: 1950, name: 1950},
        {value: 1951, order: 1951, name: 1951},
        {value: 1952, order: 1952, name: 1952},
        {value: 1953, order: 1953, name: 1953},
        {value: 1954, order: 1954, name: 1954},
        {value: 1955, order: 1955, name: 1955},
        {value: 1956, order: 1956, name: 1956},
        {value: 1957, order: 1957, name: 1957},
        {value: 1958, order: 1958, name: 1958},
        {value: 1959, order: 1959, name: 1959},
        {value: 1960, order: 1960, name: 1960},
        {value: 1961, order: 1961, name: 1961},
        {value: 1962, order: 1962, name: 1962},
        {value: 1963, order: 1963, name: 1963},
        {value: 1964, order: 1964, name: 1964},
        {value: 1965, order: 1965, name: 1965},
        {value: 1966, order: 1966, name: 1966},
        {value: 1967, order: 1967, name: 1967},
        {value: 1968, order: 1968, name: 1968},
        {value: 1969, order: 1969, name: 1969},
        {value: 1970, order: 1970, name: 1970},
        {value: 1971, order: 1971, name: 1971},
        {value: 1972, order: 1972, name: 1972},
        {value: 1973, order: 1973, name: 1973},
        {value: 1974, order: 1974, name: 1974},
        {value: 1975, order: 1975, name: 1975},
        {value: 1976, order: 1976, name: 1976},
        {value: 1977, order: 1977, name: 1977},
        {value: 1978, order: 1978, name: 1978},
        {value: 1979, order: 1979, name: 1979},
        {value: 1980, order: 1980, name: 1980},
        {value: 1981, order: 1981, name: 1981},
        {value: 1982, order: 1982, name: 1982},
        {value: 1983, order: 1983, name: 1983},
        {value: 1984, order: 1984, name: 1984},
        {value: 1985, order: 1985, name: 1985},
        {value: 1986, order: 1986, name: 1986},
        {value: 1987, order: 1987, name: 1987},
        {value: 1988, order: 1988, name: 1988},
        {value: 1989, order: 1989, name: 1989},
        {value: 1990, order: 1990, name: 1990},
        {value: 1991, order: 1991, name: 1991},
        {value: 1992, order: 1992, name: 1992},
        {value: 1993, order: 1993, name: 1993},
        {value: 1994, order: 1994, name: 1994},
        {value: 1995, order: 1995, name: 1995},
        {value: 1996, order: 1996, name: 1996},
        {value: 1997, order: 1997, name: 1997},
        {value: 1998, order: 1998, name: 1998},
        {value: 1999, order: 1999, name: 1999},
        {value: 2000, order: 2000, name: 2000},
        {value: 2001, order: 2001, name: 2001},
        {value: 2002, order: 2002, name: 2002},
        {value: 2003, order: 2003, name: 2003},
        {value: 2004, order: 2004, name: 2004},
        {value: 2005, order: 2005, name: 2005},
        {value: 2006, order: 2006, name: 2006},
        {value: 2007, order: 2007, name: 2007},
        {value: 2008, order: 2008, name: 2008},
        {value: 2009, order: 2009, name: 2009},
        {value: 2010, order: 2010, name: 2010},
        {value: 2011, order: 2011, name: 2011},
        {value: 2012, order: 2012, name: 2012},
        {value: 2013, order: 2013, name: 2013},
        {value: 2014, order: 2014, name: 2014},
        {value: 2015, order: 2015, name: 2015},
        {value: 2016, order: 2016, name: 2016},
        {value: 2017, order: 2017, name: 2017},
        {value: 2018, order: 2018, name: 2018},
      ]
    },
    PROP_AC: {
      name: "A/C",
      displayHeader: true,
      inputType: "checkbox",
      items: [
        {index: 0, value: "A", name: "AC CENTRAL"},
        {index: 1, value: "B", name: "AC CENTRAL AND UNIT"},
        {index: 2, value: "C", name: "AC DUAL UNIT"},
        {index: 3, value: "D", name: "AC EVAPORATIVE"},
        {index: 4, value: "E", name: "AC HEAT PUMP"},
        {index: 5, value: "F", name: "AC REFRIGERATION"},
        {index: 6, value: "G", name: "AC PACKAGE"},
        {index: 7, value: "H", name: "AC SEPARATE SYSTEM"},
        {index: 8, value: "I", name: "AC SPLIT SYSTEM"},
        {index: 9, value: "J", name: "AC WALL UNIT"},
        {index: 10, value: "K", name: "AC WINDOW UNIT"},
        {index: 11, value: "L", name: "AC WALL/WINDOW UNIT"},
      ]
    },
    PROP_POOL: {
      name: "Pool",
      displayHeader: true,
      inputType: "select",
      items: [
        {value: "", name: "No preference"},
        {value: "Y", name: "Yes"},
      ]
    },
    PROP_FUEL: {
      name: "Fuel",
      displayHeader: true,
      inputType: "checkbox",
      items: [
        {index: 0, value: "E", name: "ELECTRIC"},
        {index: 1, value: "G", name: "GAS"},
        {index: 2, value: "P", name: "GAS PUBLIC PIPED"},
        {index: 3, value: "O", name: "OIL"}, 
      ]
    },
    MR_AMT: {
      name: "Has mortgage",
      displayHeader: true,
      inputType: "select",
      items: [
        {value: "", name: "No preference"},
        {query: ['(MR_AMT > "0")'], value: "1", name: "Yes"}, 
        {value: "0", name: "No"}
      ]
    },
    MR_LOAN_TYP: {
      name: "Mortgage loan type",
      displayHeader: true,
      inputType: "checkbox",
      items: [
        {index: 0, value: "5", name: "COMMUNITY DEVELOPMENT AUTHORITY"},
        {index: 1, value: "C", name: "CONVENTIONAL"},
        {index: 2, value: "F", name: "FHA"},
        {index: 3, value: "P", name: "PRIVATE PARTY LENDER"},
        {index: 4, value: "S", name: "SMALL BUSINESS ADMINISTRATION"},
        {index: 5, value: "V", name: "VA"},
        {index: 6, value: "W", name: "WRAP-AROUND MORTGAGE"},
      ]
    },
    MR_RATE: {
      name: "Interest rate",
      displayHeader: true,
      inputType: "range",
      sortItems: "reverse",
      items: [
        {value: "0200", order: 1, name: "2.00"},
        {value: "0225", order: 2, name: "2.25"},
        {value: "0250", order: 3, name: "2.50"},
        {value: "0275", order: 4, name: "2.75"},
        {value: "0300", order: 5, name: "3.00"},
        {value: "0325", order: 6, name: "3.25"},
        {value: "0350", order: 7, name: "3.50"},
        {value: "0375", order: 8, name: "3.75"},
        {value: "0400", order: 8, name: "4.00"},
        {value: "0425", order: 10, name: "4.25"},
        {value: "0450", order: 11, name: "4.50"},
        {value: "0475", order: 12, name: "4.75"},
        {value: "0500", order: 13, name: "5.00"},
        {value: "0525", order: 14, name: "5.25"},
        {value: "0550", order: 15, name: "5.50"},
        {value: "0575", order: 16, name: "5.75"},
        {value: "0600", order: 17, name: "6.00"},
        {value: "0625", order: 18, name: "6.25"},
        {value: "0650", order: 19, name: "6.50"},
        {value: "0675", order: 20, name: "6.75"},
        {value: "0700", order: 21, name: "7.00"},
        {value: "0725", order: 22, name: "7.25"},
        {value: "0750", order: 23, name: "7.50"},
        {value: "0775", order: 24, name: "7.75"},
        {value: "0800", order: 25, name: "8.00"},
      ]
    },
    NET_WORTH: {
      name: "Net worth",
      displayHeader: true,
      inputType: "range",
      sortItems: "reverse",
      items: [
        {value: "A", order: 1, name: "Less than $1"},
        {value: "B", order: 2, name: "$1 - $4K"},
        {value: "C", order: 3, name: "$5K - $9K"},
        {value: "D", order: 4, name: "$10K - $24K"},
        {value: "E", order: 5, name: "$25K - $49K"},
        {value: "F", order: 6, name: "$50K - $99K"},
        {value: "G", order: 7, name: "$100K - $249K"},
        {value: "H", order: 8, name: "$250K - $499K"},
        {value: "I", order: 9, name: "$499K +"},
      ]
    },
    CREDIT_RATING: {
      name: "Credit rating",
      displayHeader: true,
      inputType: "range",
      sortItems: "reverse",
      items: [
        {value: "A", order: 1, name: "800+"},
        {value: "B", order: 2, name: "750 - 799"},
        {value: "C", order: 3, name: "700 - 749"},
        {value: "D", order: 4, name: "650 - 699"},
        {value: "E", order: 5, name: "600 - 649"},
        {value: "F", order: 6, name: "550 - 599"},
        {value: "G", order: 7, name: "500 - 549"},
        {value: "H", order: 8, name: "under 499"},
      ]
    },
    ETHNIC_LANG: {
      name: "Language",
      displayHeader: true,
      inputType: "checkbox",
      items: [
        {index: 0, value: "E1", name: "English"},
        {index: 1, value: "S8", name: "Spanish"},
      ]
    },
    ETHNIC_RELIG: {
      name: "Religion",
      displayHeader: true,
      inputType: "checkbox",
      items: [
        {index: 0, value: "B", name: "Buddhist"},
        {index: 1, value: "C", name: "Catholic"},
        {index: 2, value: "G", name: "Greek Orthodox"},
        {index: 3, value: "H", name: "Hindu"},
        {index: 4, value: "I", name: "Islamic"},
        {index: 5, value: "J", name: "Jewish"},
        {index: 6, value: "K", name: "Siku"},
        {index: 7, value: "L", name: "Lutheran"},
        {index: 8, value: "M", name: "Mormon"},
        {index: 9, value: "O", name: "Eastern Orthodox"},
        {index: 10, value: "P", name: "Protestant"},
        {index: 11, value: "S", name: "Shinto"},
        {index: 12, value: "X", name: "Unknown"},
      ]
    },
  },
  defaultSearchParams: {
    PHONE_OCCURRENCE: [],
    AGE: [],
    HH_INCOME: [],
    HOMEOWNER_STATUS_ENHANCED: [],
    HH_MARITAL_STAT: [],
    GENDER: [],
    HOME_MKT_VALUE: [],
    PROP_TYPE: [],
    PROP_BLD_YR: [],
    PURCH_MONTH: [],
    PURCH_YEAR: [],
    PROP_AC: [],
    PROP_POOL: [],
    PROP_FUEL: [],
    MR_AMT: [],
    MR_LOAN_TYP: [],
    MR_RATE: [],
    NET_WORTH: [],
    CREDIT_RATING: [],
    ETHNIC_LANG: [],
    ETHNIC_RELIG: []
  },
  previewCols: {
    display: [
      {label: "First name", content: "FN"},
      {label: "Last name", content: "LN"},
      {label: "Address", content: "ADDR"},
      {label: "City", content: "CITY"},
      {label: "Zip", content: "ZIP"},
      {label: "Phone", content: "PHONE"}],
    popover: [
      {label: "First name", content: "FN"},
      {label: "Last name", content: "LN"},
      {label: "Address", content: "ADDR"},
      {label: "City", content: "CITY"},
      {label: "State", content: "ST"},
      {label: "Zip", content: "ZIP"},
      {label: "Phone", content: "PHONE"},
    ]
  },
  dlCols: [
    {value: "FN", name: "First name", default: true},
    {value: "LN", name: "Last name", default: true},
    {value: "ADDR", name: "Street address", default: true},
    {value: "APT", name: "Apartment number", default: true},
    {value: "CITY", name: "City", default: true},
    {value: "ST", name: "State", default: true},
    {value: "ZIP", name: "Zip code", default: true},
    {value: "Z4", name: "Zip 4", default: true},
    {value: "COUNTY", name: "County", default: true},
    {value: "AREA_CODE", name: "Area code", default: true},
    {value: "PHONE", name: "Phone", default: true},
    {value: "HH_INCOME", name: "Est income", default: true},
    {value: "DOB_YR", name: "Birth Year", default: false},
  ],
  selectFilterValidation: ["HOMEOWNER_STATUS_ENHANCED", "HH_MARITAL_STAT", "PROP_TYPE", "PROP_AC", "PROP_FUEL", "MR_LOAN_TYP", "ETHNIC_LANG", "ETHNIC_RELIG"],
  leadsMultiplier: 0,
  access: "Any subscription"
}