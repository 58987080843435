import React, {useState, useEffect} from "react";
import { Link, useHistory, useLocation, useParams } from "react-router-dom";
import { Helmet } from "react-helmet";
import { Container, Row, Col, Card, Form } from "react-bootstrap";
import { Collection, CollectionContext } from "./firebaseView";
import { db, getDoc } from "./firebase"; 
import { TrelloBoardList } from "./likeTrello";
import AutoComplete from "./autoComplete";
import { EnvelopeFill, Mailbox2, TelephoneFill} from "react-bootstrap-icons";
//import { DatabaseDescription } from "./interfaceLsStatic";

const DatabaseMetaInfo = ({database}) => {
  let {use, type, description, _docId} = database;

  return(
    <React.Fragment>
      <Card.Text>
        {description}
      </Card.Text>
      <div className="d-flex flex-row justify-content-between">
        <div>
          {use.map( u => {
            if (u === "email") {
              return (<EnvelopeFill className="ms-2" />)
            }
            if (u === "mail") {
              return(<Mailbox2 className="ms-2" />)
            }
            if (u === "telemarketing") {
              return(<TelephoneFill className="ms-2" />)
            }
          })}
        </div>
        <Link to={`/marketplace/${_docId}`} className="btn btn-sm btn-round btn-light pull-right">
          <small>View</small>
        </Link>
      </div>
    </React.Fragment>
    
  )
}

const ViewAllDatabases = props => {
  const [sortBy, setSortBy] = useState("");
  const sortByOptions = [
    { field: "_created", order: "desc", value: "", name: "Select sort" },
    { field: "_created", order: "desc", name: "Newest to Oldest", name: "Newest to Oldest" },
    { field: "_created", order: "asc", name: "Oldest to Newest", name: "Oldest to Newest" },
    { field: "lastUpdated", order: "desc", value: "Most recently modified", name: "Most recently modified" },
    { field: "lastUpdated", order: "asc", value: "Least recently modified", name: "Least recently modified" },
    { field: "name", order: "desc", name: "Name Z - A", name: "Name Z - A" },
    { field: "name", order: "asc", value: "Name A - Z", name: "Name A - Z" },
  ];
  const history = useHistory();

  useEffect(() => {
    if (!props.isApp && props.handleState) {
      props.handleState({ isApp: true });
    }
  }, []);

  return(
    <Collection
      title={props.title ? props.title : ""}
      colRef={props.colRef ? props.colRef : db}
      collection={"databases"}
      permissions={["read", "write"]}
      listen={true}
      where={[
        { field: "approved", operand: "==", term: true }
      ]}
      orderBy={ sortBy ? [sortByOptions.find((o) => o.name === sortBy)] : null}
      endAt={undefined}
      startAfter={undefined}
      limit={undefined}
      callback={null}
    >
      <CollectionContext.Consumer>
        {({ docs, firestoreRef, snapShot }) => {
          console.log("docs: ", docs);
          return (
            <TrelloBoardList
              {...props}
              boardCollectionRef={db.collection("elasticlists")}
              docs={docs}
              cardPath="/listmarket"
              hideNew={true}
              hideFavorites={true}
              displayDate={(lastUpdated) =>
                new Date(lastUpdated * 1000).toLocaleDateString()
              }
              config={{
                fields: ["NAME", "ADDRESSES", "PHONES", "EMAILS"],
                sort: "NAME",
                sortOrder: "asc",
                sources: [],
                showNote: true,
                showStatus: true,
                statuses: []
              }}
              cardTheme="topBorder"
              cardDecorator={ doc => <DatabaseMetaInfo database={doc} /> }
            >
              <div className="mt-3 d-flex flex-row justify-content-between align-items-middle mb-3">
                <Form.Group>
                  <Form.Label className="mb-0">
                    <small>Sort by</small>
                  </Form.Label>
                  <Form.Control
                    size="sm"
                    as="select"
                    onChange={(e) => {
                      let nsortBy = e.target.value;
                      console.log("sortBy: ", nsortBy);
                      setSortBy(nsortBy);
                    }}
                    value={sortBy}
                  >
                    {sortByOptions.map((opt) => {
                      return <option key={opt.name} value={opt.value}>{opt.name}</option>;
                    })}
                  </Form.Control>
                </Form.Group>
                <AutoComplete
                  suggestions={docs}
                  searchKey={"name"}
                  size="sm"
                  style={{ width: "300px" }}
                  placeholder="Search all lists"
                  formLabel={<small>Search</small>}
                  formClassName="mb-0"
                  inputClassName="text-start"
                  clearSearchStyle={{
                    position: "absolute",
                    top: ".3rem",
                    right: "5px",
                    zIndex: "1000",
                  }}
                  listGroupStyle={{
                    zIndex: "1000",
                    position: "absolute",
                    width: "100%"
                  }}
                  suggestion={(suggestion) => suggestion.name}
                  onSelect={(suggestion, searchText) => {
                    history.push(`/listcrm/${suggestion._docId}`);
                  }}
                />
              </div>
            </TrelloBoardList>
          );
        }}
      </CollectionContext.Consumer>
    </Collection>
  )
}

const createNewDatabase = () => {

}

const CreateNewDatabaseButton = props => {
  return null;
}

const ViewDatabase = props => {
  console.log("ViewDatabase props: ", props);
  let {dicts} = props;
  let {databaseid} = useParams();
  let {doc} = useLocation(); 

  let [database, setDatabase] = useState(doc);

  let matchingDicts = dicts.find(d => {
    if (d.path && database) {
      return d.path === database.path
    }
  });
  console.log("matchingDicts: ", matchingDicts, "doc: ", doc);
  useEffect( () => {
    if (!doc && !database) {
      getDoc({
        ref: db.collection("databases").doc(databaseid)
      }, (error, data) => {
        if (data) {
          setDatabase(data);
        }
        
      })
    }
  })
  if (matchingDicts) {
    return(
      <DatabaseDescription dataDict={matchingDicts} />
    )
  }
  
  return(null);
}

const DatabaseDescription = props => {
  let {dataDict} = props;
  console.log("DatabaseDescription props: ", props);
  return (
    <React.Fragment>
      <Helmet>
        <title>{dataDict.title}</title>
        <meta name="HandheldFriendly" content="true" />
        <meta name="apple-mobile-web-app-capable" content="YES" />
      </Helmet>
      <Container>
        <Row>
          <Col xs={12} className="mt-4">
            <p>
              <Link to="/marketplace">&lt;&nbsp;View all databases</Link>
            </p>
          </Col>
          <Col xs={12} className="mb-5">
            <Container className="py-5 bg-light">
              <h1>{dataDict.title}</h1>
              <p className="lead">
                {dataDict.description}
              </p>
              <hr />
              <p>{dataDict.overview}</p>
              <a href={`https://www.listshack.support${dataDict.path}`} className={`btn btn-${dataDict.themeColor} btn-lg`}>
                Search now
              </a>
            </Container>
          </Col>
          <Col xs={12} md={8} className="mb-5 pb-5">
            <h3>Filters available</h3>
            <ul>
              {Object.values(dataDict.cols).map(col => {
                return <li key={col.name}>{col.name}</li>;
              })}
            </ul>
            <h3>Columns available for download</h3>
            <ul>
              {dataDict.dlCols.map(col => {
                return <li key={col.value}>{col.name}</li>;
              })}
            </ul>
            {/*
            <h3>Availablilty</h3>
            <p>{dataDict.access}</p>
            <h3>Price per lead</h3>
            <p>${(50 / 50000) * dataDict.leadsMultiplier}</p>
            */}
          </Col>
          <Col xs={12} md={4}>
            <Card bg={dataDict.themeColor} text="white" className="sticky-top">
              <Card.Body>
                <Card.Title>{dataDict.title}</Card.Title>
                <Card.Text>{dataDict.description}</Card.Text>
                <a href={`https://www.listshack.support${dataDict.path}`} className="btn btn-light">
                  Search now
                </a>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    </React.Fragment>
  );
};

export {ViewAllDatabases, ViewDatabase, createNewDatabase, CreateNewDatabaseButton}