import React from "react";
import { OverlayTrigger, Button, Tooltip, InputGroup, FloatingLabel, Form, Row, Col } from "react-bootstrap";
import InlineFormControl from "./inlineFormControl";
function getAge(dateString) {
  try {
    var today = new Date();
    var birthDate = new Date(dateString);
    var age = today.getFullYear() - birthDate.getFullYear();
    var m = today.getMonth() - birthDate.getMonth();
    if (
      m < 0 ||
      (m === 0 && today.getDate() < birthDate.getDate())
    ) {
      age--;
    }
    //console.log("age: ", age);
    if (isNaN(age)) {
      throw new Error("Age calculation returns NaN.");
    }
    return age;
  } catch (err) {
    return "Unknown";
  }
}

function dateString(yr,mo,dy) {
  if (mo.length === 1) {
    mo = `0${mo}`
  }
  if (dy.length === 1) {
    dy = `0${mo}`;
  }
  return `${yr}-${mo}-${dy}T00:00:00`;
}

function getNewAgeObject(yr,mo,dy,data) {
  let na = {};
  na["DOB_DAY"] = dy ? dy : "";
  na["DOB_MON"] = mo ? mo : "";
  na["DOB_YR"] = yr ? yr : "";
  na["DATA"] = data ? data : "";
  let calcAge = getAge( dateString(yr, mo, dy) );
  if (calcAge !== "Unknown") {
    na["DATA"] = calcAge;
  }
  return na;
}

const Age = props => {
  let {ageObject, keyId} = props;
  
  let yr = ageObject["DOB_YR"];
  let mo = ageObject["DOB_MON"]
    ? ageObject["DOB_MON"].length === 2
      ? ageObject["DOB_MON"]
      : "0" + ageObject["DOB_MON"]
    : "01";
  let dy = ageObject["DOB_DAY"]
    ? ageObject["DOB_DAY"].length === 2
      ? ageObject["DOB_DAY"]
      : "0" + ageObject["DOB_DAY"]
    : "01";
  let date = dateString(yr,mo,dy);
  return (
      <OverlayTrigger
        overlay={
          <Tooltip id={`tooltip-${keyId}`}>
            <div className="text-start">
              {["DOB_MON", "DOB_YR"].map((sf, i) => {
                return (
                  <React.Fragment
                    key={`${keyId}_${sf}_${i}`}
                  >
                    <span>
                      {sf}: <b>{ageObject[sf]}</b>
                    </span>
                    <br />
                  </React.Fragment>
                );
              })}
              <span>
                AGE: <b>{ageObject["DATA"]}</b>
              </span>
              <br />
              {/*<span>Birthday: <b>{date}</b></span><br />*/}
              <span>
                Calculated Age: <b>{getAge(date)}</b>
              </span>
            </div>
          </Tooltip>
        }
      >
        <Button
          variant="link"
          style={{ textDecoration: "none" }}
          className="text-info"
        >
          {ageObject["DATA"]}
        </Button>
      </OverlayTrigger>
  );
}

const blankAge = {
  DATA: "",
  DOB_DAY: "",
  DOB_MON: "",
  DOB_YR: ""
};

const AgeInput = props => {
  let {ageObject, keyId, onChange} = props;
  let {DOB_MON, DOB_DAY, DOB_YR, DATA} = ageObject;
  
  return(
    <>
      <FloatingLabel controlId={`${keyId}_ageData`} label="Age">
      <InlineFormControl
        type="text"
        key={`${keyId}_age_data`}
        value={DATA}
        onChange={e => {
          let na = {...ageObject};
          na["DATA"] = e.target.value;
          onChange(na);
        }}
      />
      </FloatingLabel>
      <Form.Group className="pt-2">
        <Form.Label className="text-muted ps-2"><small>Date of Birth</small></Form.Label>
        <Row className="align-items-center">
          <Col>
            <InlineFormControl
              type="text"
              keyId={`${keyId}_dob_mon`}
              value={DOB_MON}
              onChange={e => {
                let nm = e.target.value;
                onChange( getNewAgeObject(DOB_YR, nm, DOB_DAY, DATA) );
              }}
              placeholder="mm"
            />
          </Col>
          <Col className="text-muted" xs={1}>
            /
          </Col>
          <Col>
            <InlineFormControl
              type="text"
              keyId={`${keyId}_dob_day`}
              value={DOB_DAY}
              onChange={e => {
                let nd = e.target.value;
                onChange( getNewAgeObject(DOB_YR, DOB_MON, nd, DATA) );
              }}
              placeholder="dd"
            />
          </Col>
          <Col className="text-muted" xs={1}>
            /
          </Col>
          <Col>
            <InlineFormControl
              type="text"
              keyId={`${keyId}_dob_yr`}
              value={DOB_YR}
              onChange={e => {
                let ny = e.target.value;
                onChange( getNewAgeObject(ny, DOB_MON, DOB_DAY, DATA) );
              }}
              placeholder="dd"
            />
          </Col>
        </Row>
      </Form.Group>
    </>
  );
}

export { Age, AgeInput, blankAge}