import React, { useRef, useState } from "react"; 
import { Container, Row, Col, Button, Modal, Form, ButtonGroup, Card, Table } from "react-bootstrap";
import { FileDrop } from "react-file-drop";
import {
  previewCsv,
  parseCsv,
  unparseToCsv,
  formatState,
} from "./appendCsv.jsx";
import { storeMedia, firebase, db, batchWrite } from "./firebase.jsx";

import { transformHeader, mapImport, CsvPreview } from "./crm.jsx";
import { formatPhoneNumber, indexOfArray } from "./interfaceListShackPro.jsx";
import { DashCircleFill, PlusCircleFill } from "react-bootstrap-icons";
import { formatElasticCrmDoc } from "./addRecord";

const UploadToCrmButton = props => {
  const [showModal, setShowModal] = useState(false);
  return(
    <React.Fragment>
      <Button
        variant={props.variant ? props.variant : ""}
        size={props.size ? props.size : "md"}
        className={props.className ? props.className : ""}
        style={props.style ? props.style : null}
        onClick={() => {
          setShowModal(true);
        }}
      >
        {props.children}
      </Button>
      {showModal &&
        <Modal show={showModal} onHide={() => setShowModal(false)} size="xl">
          <Modal.Header>
            <Modal.Title>Upload records to List</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <UploadRecordsToCrm {...props} uploadToCrmCallback={() => setShowModal(false)} />
          </Modal.Body>
          <Modal.Footer className="d-flex flex-row justify-content-between">
            <Button size="sm" onClick={() => setShowModal(false)} variant="light">
              Close
            </Button>
            <div>&nbsp;</div>
          </Modal.Footer>
        </Modal>
      }
    </React.Fragment>
  )
}

const contactMethods = {
  ADDRESSES: "Address",
  PHONES: "Phone number",
  EMAILS: "Email",
};

const reservedFields = [
  { value: "CO_NAME", name: "Company name", group: "Name" },
  { value: "FN", name: "First name", group: "Name" },
  { value: "LN", name: "Last name", group: "Name" },
  { value: "FULL_NAME", name: "Full name", group: "Name" },
  { value: "TITLE", name: "Title", group: "Name" },
  { value: "ADDR", name: "Street address", group: "ADDRESSES" },
  { value: "APT", name: "Apartment number", group: "ADDRESSES" },
  { value: "CITY", name: "City", group: "ADDRESSES" },
  { value: "ST", name: "State", group: "ADDRESSES" },
  { value: "ZIP", name: "Postal code", group: "ADDRESSES" },
  { value: "Z4", name: "Zip 4", group: "ADDRESSES" },
  { value: "COUNTRY", name: "Country", group: "ADDRESSES" },
  { value: "latitude", name: "Latitude", group: "ADDRESSES" },
  { value: "longitude", name: "Longitude", group: "ADDRESSES" },
  { value: "confirmed", name: "Address confirmed", group: "ADDRESSES" },
  { value: "confirmedAt", name: "Address confirmed timestamp", group: "ADDRESSES" },
  { value: "data", name: "Phone", group: "PHONES" },
  { value: "dncFlag", name: "DNC", group: "PHONES" },
  { value: "confirmed", name: "Phone confirmed", group: "PHONES" },
  { value: "confirmedAt", name: "Phone confirmed timestamp", group: "PHONES" },
  { value: "data", name: "Email", group: "EMAILS" },
  { value: "optIn", name: "Opt in", group: "EMAILS" },
  { value: "optOut", name: "Opt out", group: "EMAILS" },
  { value: "confirmed", name: "Email confirmed", group: "EMAILS" },
  { value: "confirmedAt", name: "Email confirmed timestamp", group: "EMAILS" }
];

const UploadRecordsToCrm = (props) => {
  const fileInputRef = useRef(null);
  const dropAreaRef = useRef(window.document.getElementById("file_drop"));
  const storeInputFile = async (file) => {
    // upload the file to storage
    let fileOfInputs = await storeMedia(
      `uploads/${props.userDoc.id}/${file.name}`,
      file,
      // progressCallBack
      (progress) => {
        console.log("progress: ", progress);
      },
      // downloadCallBack
      (downloadUrl) => {
        console.log("dowloadUrl: ", downloadUrl);
        setUploadedFile(downloadUrl);
      },
      // metadataCallBack
      (metadata) => {
        console.log("metadata: ", metadata);
      }
    );
  };
  const onFileInputChange = (event) => {
    const { files } = event.target;
    // do something with your files...
    selectFile(files);
  };
  const onTargetClick = () => {
    fileInputRef.current.click();
  };
  const selectFile = (files) => {
    let newFile = files[0];
    setFile(newFile);
    storeInputFile(newFile);
    previewCsv(
      {
        file: files[0],
        transformHeader,
      },
      (error, results) => {
        if (error) {
          console.log("Error previewing csv upload: ", error);
        }
        if (results) {
          console.log("preview results: ", results);
          setPreview(results);
        }
      }
    );
  };
  //props variables
  const { userDoc, user, list, listConfig, teamMembers, uploadToCrmCallback, bulkAddElasticDoc, onUpload } = props;
  // state variables
  // upload workflow state variables
  const [uploadedFile, setUploadedFile] = useState(null);
  const [file, setFile] = useState(false);
  const [preview, setPreview] = useState(false);
  const [confirmedFile, setConfirmedFile] = useState(false);
  const [headerIndex, setHeaderIndex] = useState(0);
  const [uploadMapping, setUploadMapping] = useState({});
  const [mappingComplete, setMappingComplete] = useState(false);
  const [previewConfirmed, setPreviewConfirmed] = useState(false);
  const [uploadProgress, setUploadProgress] = useState(0);
  // Mapping state variables
  const [nameCount, setNameCount] = useState(0);
  const [addressCount, setAddressCount] = useState(0);
  const [phoneCount, setPhoneCount] = useState(0);
  const [emailCount, setEmailCount] = useState(0);
  const [dataIndex, setDataIndex] = useState(0);

  // Upload configuration state variables
  const [checkForDuplicates, setCheckForDuplicates] = useState(false);
  const [assignRecordStatus, setAssignRecordStatus] = useState(false);
  const [predefinedRecordStatus, setPredefinedRecordStatus] = useState("new");
  const [assignTeamMember, setAssignTeamMember] = useState(false);
  const [assignedUid, setAssignedUid] = useState(userDoc.uid);
  // uploadPreview
  let headerValue, addresses, phones;
  let groups = {};
  let mappedObject = {};
  let names = [];
  let mappedColumns = Object.keys(uploadMapping);
  if (preview) {
    headerValue = preview.meta.fields[headerIndex];
    reservedFields.map((f) => {
      if (typeof f.group === "undefined") {
        f = { ...f, group: "Other" };
      }
      //console.log("f: ", f);
      if (typeof groups[f.group] === "undefined") {
        groups[f.group] = [];
      }
      groups[f.group].push(f);
    });
    //console.log("groups: ", groups);
    mappedObject = mapImport(preview.data[dataIndex], uploadMapping);
  }
  console.log(
    "UploadRecordsToCrm props: ",
    props,
    "uploadMapping: ",
    uploadMapping,
    "groups: ",
    groups
  );
  return (
    <Container>
      {!file && (
        <React.Fragment>
          <h6 className="mt-3">
            <i>Upload records from a csv file.</i>
          </h6>
          <div className="border rounded file-drop-container">
            <FileDrop
              id="file_drop"
              onFrameDragEnter={(event) => {
                console.log("onFrameDragEnter", event);
                event.preventDefault();
              }}
              onFrameDragLeave={(event) => {
                console.log("onFrameDragLeave", event);
                event.preventDefault();
              }}
              onFrameDrop={(event) => {
                console.log("onFrameDrop", event);
                event.preventDefault();
              }}
              onDragOver={(event) => {
                console.log("onDragOver", event);
                event.preventDefault();
              }}
              onDragLeave={(event) => console.log("onDragLeave", event)}
              onDrop={(files, event) => {
                console.log("onDrop!", files, event);
                event.preventDefault();
                selectFile(files);
              }}
              onTargetClick={onTargetClick}
              frame={document}
            >
              Drag a CSV file here.
            </FileDrop>
            <input
              onChange={onFileInputChange}
              ref={fileInputRef}
              type="file"
              className="d-none"
              accept="text/csv, .csv"
            />
          </div>
        </React.Fragment>
      )}
      {preview && !confirmedFile && !mappingComplete && (
        <Row>
          <Col>
            <h5>Is this the correct file?</h5>
            <CsvPreview preview={preview} mapping={uploadMapping} />
          </Col>
          <Col xs={12} className="d-flex flex-row justify-content-between align-items-center">
            <Button
              onClick={ (e) => {
                e.preventDefault();
                setFile(false);
                setPreview(false);
                setUploadedFile(false);
              }}
            >
              &lt; Back
            </Button>
            <Button
              onClick={(e) => {
                e.preventDefault();
                setConfirmedFile(true);
              }}
            >
              Yes, keep going &gt;
            </Button>
          </Col>
        </Row>
      )}
      {preview && confirmedFile && !mappingComplete && !previewConfirmed && (
        <Row>
          <Col>
            <h5>Does your list contain names, addresses, phone numbers, or emails?</h5>
            <Form>
              <MapContactForm
                className=""
                size="sm"
                attribute={"group"}
                group={"Name"}
                title={"Name"}
                counter={nameCount}
                setCounter={setNameCount}
                allowMultiple={false}
                fields={preview.meta.fields}
                reservedFields={reservedFields}
                uploadMapping={uploadMapping}
                setUploadMapping={setUploadMapping}
              />
              <MapContactForm
                className="mt-3"
                size="sm"
                attribute={"group"}
                group={"ADDRESSES"}
                title={"Address"}
                counter={addressCount}
                setCounter={setAddressCount}
                allowMultiple={true}
                fields={preview.meta.fields}
                reservedFields={reservedFields}
                uploadMapping={uploadMapping}
                setUploadMapping={setUploadMapping}
              />
              <MapContactForm
                className="mt-3"
                size="sm"
                attribute={"group"}
                group={"PHONES"}
                title={"Phone"}
                counter={phoneCount}
                setCounter={setPhoneCount}
                allowMultiple={true}
                fields={preview.meta.fields}
                reservedFields={reservedFields}
                uploadMapping={uploadMapping}
                setUploadMapping={setUploadMapping}
              />
              <MapContactForm
                className="mt-3"
                size="sm"
                attribute={"group"}
                group={"EMAILS"}
                title={"Email"}
                counter={emailCount}
                setCounter={setEmailCount}
                allowMultiple={true}
                fields={preview.meta.fields}
                reservedFields={reservedFields}
                uploadMapping={uploadMapping}
                setUploadMapping={setUploadMapping}
              />
            </Form>
          </Col>
          {(nameCount + addressCount + emailCount + phoneCount) > 0 &&
              <Col>
                <h6>Record preview</h6>
                <ButtonGroup className="text-center mb-2">
                  <Button size="sm" variant="secondary"
                    onClick={() => setDataIndex(dataIndex - 1)}
                    disabled={dataIndex === 0}
                  >
                    &lt;
                  </Button>
                  <Button className="text-dark" size="sm" variant="link" disabled>
                    Previewing record {dataIndex +1} of {preview.data.length}
                  </Button>
                  <Button size="sm" variant="secondary"
                    onClick={() => setDataIndex(dataIndex +1)}
                    disabled={dataIndex +1 === preview.data.length}
                  >
                    &gt;
                  </Button>
                </ButtonGroup>
                <Card bg="light">
                  <Card.Body>
                    {["Name","ADDRESSES", "PHONES", "EMAILS"].map((method) => {
                      return(
                        <MapPreviewTable
                          method={method}
                          groups={groups}
                          mappedObject={mappedObject}
                          uploadMapping={uploadMapping}
                          setUploadMapping={setUploadMapping}
                          mappedColumns={mappedColumns}
                          tableDataArray={mappedObject[method] ? mappedObject[method] : [mappedObject]}
                          title={contactMethods[method] ? contactMethods[method] : "Name"}
                        />
                      )
                    })}
                  </Card.Body>
                </Card>
              </Col>
          }
          <Col xs={12} className="d-flex flex-row justify-content-between align-items-center">
            <Button
              onClick={ (e) => {
                e.preventDefault();
                setConfirmedFile(false);
              }}
            >
              &lt; Back
            </Button>
            <Button
              onClick={(e) => {
                e.preventDefault();
                //let mappedValues = Object.values(uploadMapping);
                //let mappedNames = mappedValues.filter( v => reservedNameFields.includes(v) );
                //if(mappedNames.length === 0) {
                //  console.log("User must map at least one name field.");
                //}
                setPreviewConfirmed(true);
              }}
            >
              Yes, keep going &gt;
            </Button>
          </Col>
        </Row>
      )}
      {previewConfirmed && (
        <Row>
          <Col>
            <h5>Confirm your upload</h5>
            <p><i>Green headers indicate the column is mapped to a name, address, phone, or email.</i></p>
            <CsvPreview preview={preview} mapping={uploadMapping} />
            {/*<Form.Group>
              <Form.Check
                id="dup_check_tog"
                type="switch"
                label="Check for duplicates"
                checked={checkForDuplicates}
                onChange={() => setCheckForDuplicates(!checkForDuplicates)}
              />
            </Form.Group>*/}
            <Form.Group>
              <Form.Check
                id="status_asg_tog"
                type="switch"
                label="Assign record status"
                checked={assignRecordStatus}
                onChange={() => setAssignRecordStatus(!assignRecordStatus)}
              />
              {assignRecordStatus &&
                <Card bg="light" className="mt-3">
                  <Card.Body>
                    <fieldset>
                      <Form.Label>
                        Select record status
                      </Form.Label>
                    {["new", ...listConfig.statuses.filter( s => s !== "new")].map( (status,i) => {
                        return(
                          <Form.Check
                            type="radio"
                            label={ status }
                            value={ status }
                            onChange={ () => setPredefinedRecordStatus(status) }
                            checked={ predefinedRecordStatus === status }
                            id={ `status_${i}` }
                            key={ `status_${i}` }
                          />
                        )
                      })}
                    </fieldset>
                    <AddListStatus
                      size="sm"
                      listConfig={listConfig}
                      inline={true}
                      variant="secondary"
                    />
                  </Card.Body>
                </Card>
              }
            </Form.Group>
            <Form.Group>
              <Form.Check
                id="tm_mbr_tog"
                type="switch"
                label="Assign to team member"
                checked={assignTeamMember}
                onChange={() => setAssignTeamMember(!assignTeamMember)}
              />
              {assignTeamMember &&
                <Card bg="light" className="mt-3">
                  <Card.Body>
                    <fieldset>
                      <Form.Label>
                        Select team member
                      </Form.Label>
                      {[{name: "Account owner", email: userDoc.email},...userDoc.companyUsers].map( (u,i) => {
                        let member = [{uid: userDoc.uid, email: userDoc.email}, ...teamMembers].find( m => u.email === m.email);
                        let uid, email;
                        if (member) {
                          uid = member.uid;
                          email = member.email;
                        }
                        return(
                          <Form.Check
                            type="radio"
                            label={ `${u.name} (${email ? email : "pending"})` }
                            name={ u.name }
                            onChange={ () => setAssignedUid(uid) }
                            checked={ assignedUid === uid }
                            id={ `tm_${i}` }
                            key={ `tm_${i}` }
                            disabled={ !member }
                          />
                        )
                      })}
                    </fieldset>
                  </Card.Body>
                </Card>
              }
            </Form.Group>
          </Col>
          <Col xs={12} className="d-flex flex-row justify-content-between align-items-center">
            <Button
              onClick={(e) => {
                e.preventDefault()
                setPreviewConfirmed(false)
              }}
            >
              &lt; Back
            </Button>
            <Button
              className=""
              onClick={(e) => {
                e.preventDefault();
                console.log("CSV import started!");
                //handleCrmState({ crmLoaded: false });
                parseCsv(
                  {
                    file,
                    transformHeader,
                  },
                  (error, results) => {
                    if (error) {
                      console.log("Error parsing upload to Crm: ", error);
                    }
                    if (results) {
                      addCsvData(
                        {
                          results,
                          fieldMapping: uploadMapping,
                          list,
                          user,
                          userDoc,
                          listConfig,
                          file,
                          fileLink: uploadedFile,
                          assignedUid,
                          listStatus: {[list]: predefinedRecordStatus},
                          bulkAddElasticDoc
                        },
                        (error, writeSummary) => {
                          if (error) {
                            console.log(
                              "Error adding documents to CRM: ",
                              error
                            );
                          }
                          if (writeSummary) {
                            console.log(
                              "Successfully added documents to CRM: ",
                              writeSummary
                            );
                            if (onUpload) {
                              onUpload(writeSummary);
                            }
                          }
                        }
                      );
                    }
                  }
                );
                if(uploadToCrmCallback) {
                  uploadToCrmCallback(null, true);
                }
              }}
            >
              Begin CSV Import
            </Button>
          </Col>
      </Row>
      )}
    </Container>
  );
};

const MapContactForm = (props) => {
  let {
    attribute,
    group,
    title,
    allowMultiple,
    counter,
    setCounter,
    reservedFields,
    fields,
    uploadMapping,
    setUploadMapping,
    size,
  } = props;
  if(typeof counter === "undefined") {
    counter = 1;
  }
  let items = [];
  let mappedColumns = Object.keys(uploadMapping);
  for (let count = 0; count < counter; count++) {
    items.push(
      <Form.Group
        key={`fg_${title}_${count}`}
        className={props.className ? props.className : ""}
        style={props.style ? props.style : null}
      >
        <div className="d-flex flex-row justify-content-between align-items-center">
          <Form.Label>
            {title}
            {count > 0 ? ` ${count + 1}` : ""}
          </Form.Label>
          {count > 0 ? (
            <Button
              onClick={() => {
                if (
                  window.confirm(
                    `Are you sure you'd like to remove this ${title.toLowerCase()} from your import?`
                  )
                ) {
                  setCounter(counter - 1);
                }
              }}
              size="sm"
              variant="link"
              className="text-danger"
            >
              <DashCircleFill size={24} />
            </Button>
          ) : (
            ""
          )}
        </div>

        {reservedFields.map((f) => {
          if (f[attribute] === group) {
            return (
              <Form.Control
                size={size}
                key={`control_${f.value}`}
                as="select"
                name={f.value}
                group={f.group}
                // value needs to be added and this needs to be restructured so that when you make a mistake you can change the value of the input
                onChange={(e) => {
                  let selVal = e.target.value;
                  let v = JSON.parse(selVal);
                  let selField  = v.field;
                  let selGroup = v.group;
                  let nm = {...uploadMapping};
                  console.log("nm: ", nm, "selVal: ", selVal, "selField: ", selField, "selGroup: ", selGroup, "name: ", e.target.name);
                  if(mappedColumns.includes(selField)) {
                    delete nm[selField];
                    console.log("nm: ", nm);
                    //return setUploadMapping(nm);
                  }
                  if (![""].includes(selVal)) {

                    setUploadMapping({
                      ...uploadMapping,
                      [selField]: [
                        "ADDRESSES",
                        "PHONES",
                        "EMAILS",
                      ].includes(selGroup)
                        ? [f.group, count, e.target.name]
                        : e.target.name,
                    });
                  }
                }}
              >
                <option>Add {f.name.toLowerCase()}</option>
                {fields.map((field) => {
                  return (
                    <React.Fragment>
                      <option
                        key={`option_${field}`}
                        value={JSON.stringify({ field, group: f.group })}
                        disabled={mappedColumns.includes(field)}
                      >
                        {field}
                      </option>
                    </React.Fragment>
                  );
                })}
              </Form.Control>
            );
          }
        })}
      </Form.Group>
    );
  }
  return (
    <React.Fragment>
      {items}
      {(allowMultiple || counter === 0) &&
        <Button
          onClick={() => setCounter(counter + 1)}
          variant="link"
          className="text-muted"
        >
          <PlusCircleFill size={20} />
          <span className="ms-1 text-capitalize">{title}</span>
        </Button>
      }
    </React.Fragment>
  );
};

const MapPreviewTable = props => {
  const {method, mappedObject, groups, uploadMapping, setUploadMapping, mappedColumns, tableDataArray, title} = props;

  let contactMethodDetails = mappedObject[method];
  if (typeof contactMethodDetails === "undefined") {
    //return null;
  }
  let cms = [];
  tableDataArray.map((a, i) => {
    //console.log(method, a, i);
    //console.log(groups[method]);
    let bodyRows = [];
    groups[method].map((f, index) => {
      let cmvalue = a[f.value];
      let rmap = [method,i, f.value];
      //console.log("rmap: ", rmap);

      if (typeof cmvalue !== "undefined") {

        if (
          f.value === "data" &&
          method === "PHONES"
        ) {
          cmvalue = formatPhoneNumber(cmvalue);
        }
        bodyRows.push(
          <tr
            key={`cmtr_${method}_${i}_${f.value}_${index}`}
          >
            <td className="text-muted">{f.name}</td>
            <td>{cmvalue}</td>
            <td>
              <Button
                className="text-muted"
                variant="link"
                onClick={(e) => {
                  e.preventDefault();
                  let nm = {...uploadMapping};
                  let mappings = Object.values(uploadMapping);
                  let rmindex = indexOfArray( mappings, rmap );
                  let mappedColumn;
                  if (rmindex !== -1) {
                    mappedColumn = mappedColumns[ rmindex ];
                  } else {
                    rmap = f.value;
                    mappedColumn = mappedColumns[ mappings.indexOf(rmap) ];
                  }
                  console.log("mappings: ", mappings, "rmap: ", rmap, "rmindex: ", rmindex, "mappedColumn: ", mappedColumn);
                  delete nm[mappedColumn];
                  setUploadMapping(nm);
                }}
              >
                &times;
              </Button>
            </td>
          </tr>
        );
      }
    })

    cms.push(
      <Table
        key={`cmtable_${method}_${i}`}
        style={{width: "100%"}}
        borderless
        size="sm"
      >
        <thead>
          <tr>
            <th colSpan="3">
              {title} {i > 0 ? i + 1 : ""}
            </th>
          </tr>
        </thead>
        <tbody>
          {bodyRows.length > 0 && bodyRows}
          {!bodyRows.length &&
            <tr>
              <td colSpan="3" className="text-muted">
                Select a column header from your CSV file under
                the {title.toLowerCase()} category.
              </td>
            </tr>
          }
        </tbody>
      </Table>
    );
  });
  return cms;
}

const AddListStatus = props => {
  const {inline, modal, listConfig} = props;
  const [newStatus, setNewStatus] = useState("");
  const [showModal, setShowModal] = useState(false);

  if(inline) {
    return(
      <Form.Group
        className="d-flex flex-start"
      >
        {props.label &&
          <Form.Label>{props.label}</Form.Label>
        }
        <Form.Control
          size={props.size ? props.size : "sm"}
          style={{width: "200px"}}
          type="text"
          value={newStatus}
          onChange={(e) => {
            setNewStatus(e.target.value);
          }}
        />
        <Button
          variant={props.variant ? props.variant : "light"}
          size={props.size ? props.size : "sm"}
          onClick={() => {
            console.log("newStatus: ", newStatus);
            listConfig._docRef.update({
              statuses: firebase.firestore.FieldValue.arrayUnion(
                newStatus
              ),
            });
            setNewStatus("");
          }}
        >
          <small>add</small>
        </Button>
      </Form.Group>
    )
  }
  if(modal) {
    return(
      <React.Fragment>
        <Button
          variant={props.variant ? props.variant : ""}
          size={props.size ? props.size : "sm"}
          onClick={() => setShowModal(true)}
        >
          Add New Status
        </Button>
        {showModal &&
          <Modal size="sm" show={showModal} onHide={() => setShowModal(false)}>
            <Modal.Header>
              <Modal.Title>Add List Status</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Form.Group
                className={props.className ? props.className : ""}
                style={props.style ? props.style : null}
              >
                {props.label &&
                  <Form.Label>{props.label}</Form.Label>
                }
                <Form.Control
                  size={props.size ? props.size : "sm"}
                  style={{width: "200px"}}
                  type="text"
                  value={newStatus}
                  onChange={(e) => {
                    setNewStatus(e.target.value);
                  }}
                />

              </Form.Group>
              <Button
                className="pull-right"
                variant={props.variant ? props.variant : "light"}
                size={props.size ? props.size : "sm"}
                onClick={() => {
                  console.log("newStatus: ", newStatus);
                  listConfig._docRef.update({
                    statuses: firebase.firestore.FieldValue.arrayUnion(
                      newStatus
                    ),
                  });
                  setNewStatus("");
                  setShowModal(false);
                }}
              >
                <small>add</small>
              </Button>
            </Modal.Body>
          </Modal>
        }
      </React.Fragment>
    )
  }
  return null;
}

const formatName = (mappedDoc) => {
  // columns could be FN, LN, MI, TITLE, CO_NAME
  //{
  //  CO_NAME: "Acme Inc",
  //  FN: "John",
  //  MI: "A",
  //  LN: "Doe",
  //  TITLE: "CEO",
  //  FULL_NAME: "John A Doe"
  //}
  let {CO_NAME, FN, LN, MI, TITLE, FULL_NAME } = mappedDoc;
  if (CO_NAME){
    return CO_NAME
  }
  if (FULL_NAME) {
    return `${FULL_NAME}${TITLE ? `, ${TITLE}` : ""}`
  }
  return `${FN ? FN : ""}${MI ? ` ${MI}` : ""}${LN ? ` ${LN}` : ""}${TITLE ? `, ${TITLE}` : ""}`.trim()
}

const formatContactMethod = (method, data, options) => {
  if (!method) {
    return console.log(
      "Please include a method of phone, email, or address when configuring a contact method."
    );
  }
  let type, confirmed, confirmedAt;
  if (options) {
    type = options.type ? options.type : null;
    confirmed = options.confirmed ? options.confirmed : null;
    confirmedAt = options.confirmedAt ? options.confirmedAt : null;
  }

  let formatted = {
    TYPE: method,
    DATA: data ? data : "",
    //type: type ? type : null,
    CONFIRMED: { value: confirmed ? confirmed : null, timestamp: confirmedAt ? confirmedAt : null }, // Need to decide if I want to keep this nested
  };

  if (method === "EMAILS") {
    if (options) {
      formatted.OPT_IN = options.optIn ? options.optIn : "";
      formatted.OPT_OUT = options.optOut ? options.optOut : ""; // To Do: Add to the crm indexing template on elastic
    }
  }

  if (method === "PHONES") {
    if (options) {
      formatted.DNC_FLAG = options.dncFlag ? options.dncFlag : "";
    }
  }

  if (method === "ADDRESSES") {
    formatted.DATA = {};
    let newopts = {};
    if (options) {
      newopts = { ...options };
      let { ZIP, Z4, ST } = options;
      if (ZIP) {
        ZIP = formatZip(ZIP);
        newopts = { ...newopts, ...ZIP };
      }
      if (Z4) {
        Z4 = formatZ4(Z4);
        newopts = { ...newopts, ...Z4 };
      }
      if (ST) {
        let ns = formatState(ST);
        ST = ns ? ns : ST;
        newopts["ST"] = ST;
      }
    }
    let s1 = `${newopts.ADDR ? `${newopts.ADDR}, ` : ""}`;
    let s2 = `${newopts.APT ? `${newopts.APT}, ` : ""}`;
    let c = `${newopts.CITY ? `${newopts.CITY} ` : ""}`;
    let st = `${newopts.ST ? `${newopts.ST} ` : ""}`;
    let zip = `${newopts.ZIP ? newopts.ZIP : ""}`;
    let z4 = `${newopts.Z4 ? `-${newopts.Z4}` : ""}`;
    let addrData = `${s1}${s2}${c}${st}${zip}${z4}`;// This is the better approach to handling addresses.
    console.log("addrData: ", addrData);
    formatted.DATA.ADDR = newopts.ADDR ? newopts.ADDR : "";
    formatted.DATA.APT = newopts.APT ? newopts.APT : "";
    formatted.DATA.CITY = newopts.CITY ? newopts.CITY : "";
    formatted.DATA.ST = newopts.ST ? newopts.ST : "";
    formatted.DATA.ZIP = newopts.ZIP ? newopts.ZIP : "";
    formatted.DATA.Z4 = newopts.Z4 ? newopts.Z4 : "";
    formatted.DATA.STRING = addrData;
  }
  console.log(
    "formatContactMethod method: ",
    method,
    "data: ",
    data,
    "options: ",
    options,
    "formatted: ",
    formatted
  );
  return formatted;
};

const formatZip = (zipstring) => {
  let ziparray = zipstring.split("-");
  let ZIP = ziparray[0].toString();
  let Z4 = ziparray[1];
  if (ZIP.length > 5) {
    ZIP = ZIP.substring(0, 5);
  }
  if (Z4) {
    Z4 = formatZ4(Z4);
  }

  return { ZIP, ...Z4 };
};

const formatZ4 = (z4string) => {
  let Z4 = z4string.toString();
  if (Z4.length > 4) {
    Z4 = Z4.substring(0, 4);
  }
  return { Z4 };
};

const fieldTransformations = {
  PHONES: (arr) => {
    // input shaped like:
    //{
    //  DATA: "5555678890",
    //  DNC_FLAG: true,
    //  CONFIRMED: false,
    //  CONFIRMEDAT: "2020-01-13T14:22:33Z",
    //  TYPE: "PHONE"
    //}
    let farr = [];
    arr.map((obj) => {
      let transformed = formatContactMethod("PHONES", obj.data, obj);
      // Don't add contact methods that are empty strings
      if (transformed.data !== "") {
        farr.push(transformed);
      }
    });
    return farr;
  },
  ADDRESSES: (arr) => {
    // input shaped like:
    //{
    //  ADDR: "123 main",
    //  APT: "UNIT 3",
    //  CITY: "San Mateo",
    //  ST: "CA",
    //  ZIP: "zipString",
    //  Z4: "z4string",
    //  COUNTRY: "Some country",
    //  LAT: "-41",
    //  LONG: "97",
    //  confirmed: "true",
    //  confirmedAt: "sometimestamp",
    //}
    let farr = [];
    arr.map((obj) => {
      let transformed = formatContactMethod("ADDRESSES", null, obj);
      if (transformed.data !== "") {
        farr.push(transformed);
      }
    });
    return farr;
  },
  EMAILS: (arr) => {
    // input shaped like:
    //{
    //  data: "5555678890",
    //  confirmed: "true",
    //  optIn: "true",
    //  optOut: "true",
    //  confirmedAt: "false",
    //}
    let farr = [];
    arr.map((obj) => {
      let transformed = formatContactMethod("EMAILS", obj.data, obj);
      if (transformed.data !== "") {
        farr.push(transformed);
      }
    });
    return farr;
  },
};

const formatImport = (imp) => {
  let fimp = {
    NAME: formatName(imp)
  };
  Object.keys(imp).map((k) => {
    if (fieldTransformations[k]) {
      return (fimp[k] = fieldTransformations[k](imp[k]));
    }
    return (fimp[k] = imp[k]);
  });
  console.log("fimp: ", fimp);
  return fimp;
};

const addCsvData = async (
  { results, fieldMapping, list, user, userDoc, listConfig, file, fileLink, assignedUid, listStatus, bulkAddElasticDoc },
  callback
) => {
  console.log("Csv successfully parsed: ", results);
  let mapped = [];
  let formatted = [];

  // Map the CSV Data to reserved fieldnames and downcasing
  results.data.map((r) => {
    mapped.push(mapImport(r, fieldMapping));
  });

  // Format the CRM data to proper objects for reserved fieldnames
  mapped.map((r) => {
    formatted.push(formatImport(r));
  });

  console.log("formatted results: ", formatted);
  // format for EMAILS, PHONES, ADDRESSES is incorrect. 

  let importDoc = {
    archived: false,
    csvFile: fileLink,
    fileName: file.name,
    timestamp: new Date(),
    listName: listConfig.name,
    list,
  };
  console.log("importDoc: ", importDoc); // This is returning something undefined
  let impRef = await db
    .collection("crm")
    .doc(userDoc.id)
    .collection("imports")
    .add(importDoc);
  let importId = await impRef.id;
  
  // Format the data for bulk indexing to elasticsearch
  formatted = formatted.map( d => {
    // Add the record assignment if it exists
    if (assignedUid) {
      d.ASSIGNEDTO = assignedUid;
    }
    // Format the object properly before indexing
    let doc = formatElasticCrmDoc({
      // Add a source in case we need to find the records
      // created from this csv import
      // To Do: ensure that we add the SOURCE field as a 
      // nested property type in the elasticsearch indexing templated
      docData: {
        ...d, 
        SOURCE: [ // Document where the record came from
          {
            type: "csv upload",
            importId,
          }
        ],
        CREATEJOBID: importId // Add a unique createjobId
      }, 
      aid: userDoc.id,
      uid: user.uid,
      listid: listConfig._docId,
      listStatus
    });
    return doc;
  })
  console.log("formatted: ", formatted);

  bulkAddElasticDoc(
    {
      docs: formatted,
      index: `crm_${userDoc.id.toLowerCase()}`
    },
    (error, writeSummary) => {
      // let the user know that something happened
      if (error) {
        console.log("Something went adding to CRM: ", error);
        if (callback) {
          callback(error);
        }
      }
      if (writeSummary) {
        console.log("Documents added to CRM: ", writeSummary);
        console.log("results.meta.fields: ", results.meta.fields);
        // Add header to the fields in the list configuration
        let data = results.meta.fields.map( f => {
          return({
            ref: listConfig._docRef,
            data: {
              fields: firebase.firestore.FieldValue.arrayUnion(f)
            },
            op: "update"
          })
        });
        //listConfig._docRef.update({
        //  fields: firebase.firestore.FieldValue.arrayUnion(
        //    ...results.meta.fields
        //  ),
        //});
        batchWrite({data});

        if (callback) {
          callback(null, writeSummary);
        }
      }
    }
  );
};

export {UploadToCrmButton};