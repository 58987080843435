import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import { Switch, Route, Redirect } from "react-router-dom";
import { ViewDatabase, ViewAllDatabases } from "./listMarket.jsx";
import { ListMarketLayout } from "./interfaceListShackSupport";
import withTeam from "./withTeam.jsx";

const ListMarketRouter = props => {
  return(
    <Switch>
      <Route exact path="/marketplace/:databaseid">
        <ViewDatabase {...props} />
      </Route>
      <Route exact path={["/listmarket", "/marketplace"]}>
        <ListMarketLayout {...props}>
          <ViewAllDatabases {...props} />
        </ListMarketLayout>
      </Route>
    </Switch>
  )
}

export default ListMarketRouter;
