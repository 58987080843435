import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import { Switch, Route, Link, useParams } from "react-router-dom";
import { Container, Row, Col, Button, Form, Card } from "react-bootstrap";
import Search2 from "./search.jsx";
import { SearchUsers } from "./superadmin.jsx";
import CopyToClipboard from "react-copy-to-clipboard";

const CustomSearchRouter = props => {
  let {handleState} = props;
  useEffect( () => {
    handleState({isApp: true});
  }, [handleState]);

  return (
    <Switch>
      <Route exact path={[`/_custom_search/:dictId/:tab`, `/_custom_search/:dictId`]}>
        <SearchPage {...props} />
      </Route>
      <Route exact path="/_custom_search">
        <SearchPage {...props} />
      </Route>
    </Switch>
  );
};

const SearchPage = props => {
  //let { url } = useRouteMatch();
  const {dicts, handleAlerts} = props;
  let steps = ["selectIndex", "addFilters", "addDownloadColumns", "selectUser", "search"];
  let { dictId } = useParams();
  
  let [dict, setDict] = useState(null);
  let [step, setStep] = useState("selectIndex");
  let [queryItems, setQueryItems] = useState([]);
  let [query, setQuery] = useState("");
  let [name, setName] = useState("");
  let [customDownloadColumns, setCustomDownloadColumns] = useState([]);
  let [field, setField] = useState("");
  let [onBehalf, setOnBehalf] = useState(null);

  useEffect(() => {
    if (dictId ? !dict ? true : dict.dictId !== dictId : false) {
      setDict( props.dicts.find( d => d.dictId === dictId) )
    }
  }, [dictId, dict, props.dicts]);

  return (
    <React.Fragment>
      <Helmet>
        <title>Custom Search</title>
        <meta name="HandheldFriendly" content="true" />
        <meta name="apple-mobile-web-app-capable" content="YES" />
      </Helmet>
      
      <Container fluid>
        <Row>
          <Col xs={12} className="p-2 d-flex flex-row flex-wrap justify-content-start align-items-center bg-light">
            <p className="lead mb-0">
              <i
                className="fa fa-eye-slash me-3"
                style={{ fontSize: "24px" }}
              ></i>
              Custom Search
            </p>
            {
              steps.map( (s,i) => {
                return(
                  <Button 
                    key={`step_button_${i}`}
                    variant={ s === step ? "secondary" : "outline-secondary" }
                    size="sm"
                    className="ms-2"
                    disabled={s === step}
                    onClick={() => {
                      if (s === "search") {
                        if (!dict) {
                          return handleAlerts("","You must select an index before you can search.", "warning")
                        }
                        if (!queryItems.length) {
                          return handleAlerts("","You must add a custom filter before you can search.", "warning")
                        }
                        if (!customDownloadColumns.length) {
                          return handleAlerts("", "You must add custom download columns before you can search.", "warning");
                        }
                      }
                      setStep(s)
                    }}
                  >
                    {i + 1} - {s} ({s === "selectIndex" ? (dict ? dict.index : "" ) : s === "addFilters" ? (queryItems.length) : s === "addDownloadColumns" ? customDownloadColumns.length : (onBehalf ? onBehalf.email : "none") })
                  </Button>
                )
              })
            }
          </Col>
        </Row>
        <Row
          style={{
            minHeight: "70vh",
            textAlign: "center",
            marginTop: "40px"
          }}
        >
          {(step === "selectIndex") && (
            <Col xs={12} className="text-start">
              <h3>Select an index for your custom search</h3>
              {dicts.map( (d,i) => {
                return(
                  <Link key={`index_button_${i}`} to={`/_custom_search/${d.dictId}`} className="btn btn-large btn-outline-primary m-2">
                    {d.index}
                  </Link>
                )
              })}
            </Col>
          )}
          {(step === "addFilters")  && (
            <Col xs={12} className="text-start">
              <h3>Add Custom Filters</h3>
              <Form 
                onSubmit={ e => {
                  e.preventDefault();
                  console.log("dict: ", dict, "query: ", query, "name: ", name);
                  let newQueryItems = [...queryItems, {query, name}];
                  let items = newQueryItems.map( (o,i) => ({...o, index: i, value: i}) );
                  let newDict = {...dict};
                  newDict.cols.custom = {
                    name: "Custom Search Filters",
                    filterType: "basic",
                    inputType: "checkbox",
                    displayHeader: true,
                    items
                  };
                  newDict.keyOrder.custom = 0;
                  newDict.defaultSearchParams.custom = items;
                  setQueryItems(newQueryItems);
                  setDict(newDict);
                  handleAlerts("", "Added custom filter", "success");
                }}
              >
                <Form.Group>
                  <Form.Label>Display Name</Form.Label>
                  <Form.Control 
                    as="textarea"
                    type="text"
                    value={name}
                    onChange={ e => setName(e.target.value)}
                    rows="1"
                  />
                </Form.Group>
                <Form.Group>
                  <Form.Label>Sql Query Where Clause</Form.Label>
                  <Form.Control 
                    as="textarea"
                    type="text"
                    value={query}
                    onChange={ e => setQuery(e.target.value)}
                    rows="3"
                  />
                </Form.Group>
                <Button 
                  type="submit"
                >
                  Add Filter
                </Button>
              </Form>
            </Col>
          )}
          {(step === "addDownloadColumns") &&
            <Col xs={12} className="text-start">
              <h3>Add Additional Download Columns</h3>
              <h5>Added fields:</h5>
              {customDownloadColumns.map( (c,i) => {
                return(
                  <Button
                    key={`added-fiels_${i}`}
                    variant="outline-primary"
                    size="sm"
                    className="ms-1"
                    onClick={() => {
                      let ncdlcols = customDownloadColumns.filter( s => s !== c);
                      let newDict = {...dict};
                      newDict.dlCols = [ ...dict.dlCols.filter(d => d.value !==c) ];
                      newDict.previewCols.display = [...dict.previewCols.display.filter(d => d.content !== c) ];
                      newDict.displayCol = newDict.dlCols.length;
                      console.log("newDict.dlCols: ", newDict.dlCols);
                      setCustomDownloadColumns(ncdlcols);
                      setDict(newDict);
                    }}
                  >
                    <span className="text-dark me-3">{c}</span>
                    &times;
                  </Button>
                )
              })}
              <Form 
                className="mt-4"
                onSubmit={ e => {
                  e.preventDefault();
                  let ncdlcols = [...customDownloadColumns, field];
                  let newDlCols = ncdlcols.map( (f,i) => ({ value: f, name: f, default: true}) );
                  let newDisplayCols = ncdlcols.map( (f,i) => ({content: f, label: f}) );
                  console.log("newDlCols: ", newDlCols);
                  let newDict = {...dict};
                  newDict.dlCols = [...dict.dlCols, ...newDlCols.filter(d => !dict.dlCols.find( col => col.value === d.value) )];
                  newDict.previewCols.display = [...dict.previewCols.display, ...newDisplayCols.filter(d => !dict.previewCols.display.find( col => col.content === d.content)) ];
                  newDict.displayCol = newDict.dlCols.length;
                  console.log("newDict.dlCols: ", newDict.dlCols);
                  setCustomDownloadColumns(ncdlcols);
                  handleAlerts("", `Made ${field} available for download.`);
                  setField("");
                  setDict(newDict);
                }}
              >
                <Form.Group>
                  <Form.Label>Field</Form.Label>
                  <Form.Control
                    type="text"
                    onChange={ e => setField(e.target.value) }
                    value={field}
                  />
                </Form.Group>
                <Button type="submit"
                  variant="primary"
                  size="md"
                >
                  Add Field
                </Button>
              </Form>
            </Col>
          }
          {(step === "selectUser") && 
            <Col xs={12} className="text-start">
              <h3>Select User</h3>
              <p className="lead">Select the user that you're performing this search on behalf of.</p>
              {onBehalf && 
                <Card className="bg-light">
                  <Card.Body>
                    <h5>Company information</h5>
                    {onBehalf.companyProfile && (
                      <React.Fragment>
                        Company name: {onBehalf.companyProfile.name}
                        <br />
                        Phone:{" "}
                        <a
                          href={`tel:${onBehalf.companyProfile.phone.replace(
                            /\D/g,
                            ""
                          )}`}
                        >
                          {onBehalf.companyProfile.phone}
                        </a>
                        <br />
                        Company email:{" "}
                        <a href={`mailto:${onBehalf.companyProfile.email}`}>
                          {onBehalf.companyProfile.email}
                        </a>
                        <br />
                      </React.Fragment>
                    )}
                    Email:{" "}
                    <CopyToClipboard
                      text={onBehalf.email}
                      onCopy={() =>
                        handleAlerts(
                          "",
                          `Copied email ${onBehalf.email}`,
                          "success"
                        )
                      }
                    >
                      <code>{onBehalf.email}</code>
                    </CopyToClipboard>{" "}
                    <br />
                    Leads: {onBehalf.leads}<br />
                    Leads Remaining: {onBehalf.leadsRemaining}<br />
                    Download limit: {onBehalf.downloadLimit}<br />
                    Includes rollover: {onBehalf.includesRollover ? "YES" : "NO"}
                  </Card.Body>
                  <Card.Footer>
                    <Button
                      variant="outline-danger"
                      onClick={() => setOnBehalf(null)}
                      size="xl"
                    >
                      Remove &times;
                    </Button>
                  </Card.Footer>
                </Card>
                
              }
              {!onBehalf &&
                <SearchUsers 
                  selectCallback={(userObject) => {
                    console.log("selectCallback userObject: ", userObject);
                    setOnBehalf(userObject);
                  }} 
              />
              }
              
            </Col>
          }
          {(step === "search" && dict) && 
            <Col xs={12}>
              <Search2 
                {...props} 
                dataDict={dict} 
                userDoc={onBehalf ? onBehalf : props.userDoc}
              />
            </Col>
          }
        </Row>
      </Container>
    </React.Fragment>
  );
};

export default CustomSearchRouter;
