import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import { Switch, Route, Redirect } from "react-router-dom";
import { withCrm, ListView, Lists } from "./crm.jsx";
import withTeam from "./withTeam.jsx";

const CrmRouter = props => {
  return(
    <Switch>
      <Route exact path="/crm/:listid">
        <ListView {...props} />
      </Route>
      <Route exact path="/lists">
        <Lists {...props} />
      </Route>
      <Route exact path={["/crm", "/crm/"]}>
        <Redirect to="/crm/leads" />
      </Route>
    </Switch>
  )
}

export default withCrm( withTeam( CrmRouter ) );
