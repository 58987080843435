import React, { Component } from "react";
import ReactMapGL, {
  NavigationControl,
  Popup
} from "react-map-gl";
import Geocoder from "react-map-gl-geocoder";
import withSearch from "./withSearch.jsx";
import SelectedParams from "./selectedParams.jsx";
import Filters from "./filters.jsx";
import Preview from "./previewAgain.jsx";
import {Download} from "./download.jsx";
import { UserNav } from "./interfaceListShackSupport.jsx";
import LeadsCount from "./leadscount.jsx";
import {
  Card,
  Modal,
  Button,
  ListGroup,
  ListGroupItem,
  Accordion,
  Tabs,
  Tab,
  Row,
  Col,
  Form
} from "react-bootstrap";
import { AccordionToggle } from "./interfaceListShackSupport";
import Pins from "./pins.jsx";
import LeadInfo from "./leadInfo.jsx";
import MapPreview from "./mapPreview.jsx";
import "react-map-gl-geocoder/dist/mapbox-gl-geocoder.css";
import "mapbox-gl/dist/mapbox-gl.css";
//import {d3} from 'd3-ease';

const TOKEN = process.env.REACT_APP_mapbox;
const navStyle = {
  position: "absolute",
  bottom: 100,
  right: 30,
  padding: "10px",
};
const filterStyle = {
  position: "absolute",
  top: 10,
  right: 10,
  //padding: "10px",
  maxHeight: "90%",
  width: "225px",
};
const settingsStyle = {
  position: "absolute",
  bottom: 35,
  left: 10,
}

class Map extends Component {
  constructor(props) {
    super(props);
    this.state = {
      viewport: {
        latitude: 37.785164,
        longitude: -100,
        zoom: 3.2,
        bearing: 0,
        pitch: 0,
      },
      prevViewport: null,
      showFiltersModal: false,
      showCheckoutModal: false,
      showSettingsModal: false,
      fetchedUserLocation: false,
      popupInfo: null,
      geoCoderResult: null,
      searchSensitivity: 1,
      darkMode: false
    };
    this.mapRef = React.createRef();
    this.setMapFilter = this.setMapFilter.bind(this);
    this.searchTypes = Object.keys(this.props.dataDict.map.searchTypes);
  }

  getUserLocation() {
    this.setState({fetchedUserLocation: true});
    if (typeof window !== "undefined") {
      window.navigator.geolocation.getCurrentPosition(async (geo) => {
        console.log("geo: ", geo);
        if (this.mapRef.current !== null) {
          await this.setState({
            viewport: {
              latitude: geo.coords.latitude,
              longitude: geo.coords.longitude,
              zoom: 10,
              bearing: 0,
              pitch: 0,
            },
            prevViewport: this.state.viewport
          });
          await this.props.resetGeoParams();
          await this.setMapFilter();
          this.props.fetchCount(null, false);
        }
      });
    }
  }

  setMapFilter() {
    const { handleSearchState, dataDict } = this.props;
    const myMap = this.mapRef.current.getMap();
    const bounds = myMap.getBounds();
    //console.log("myMap: ", myMap, "bounds: ", bounds);
    handleSearchState({
      filterParams: [
        {
          geo_bounding_box: {
            [dataDict.map.location]: {
              top_left: {
                lat: bounds._ne.lat,
                lon: bounds._sw.lng,
              },
              bottom_right: {
                lat: bounds._sw.lat,
                lon: bounds._ne.lng,
              },
            },
          },
        },
      ],
    });
  }

  async setGeoFilterFromMap(result) {
    const {
      dataDict,
      fetchCount,
      resetGeoParams,
      handleSearchState
    } = this.props;
    //console.log("GeoCoder Result: ", result)
    resetGeoParams();
    let type = result.result.place_type.filter((t) =>
      this.searchTypes.includes(t)
    );
    //console.log("type: ", type);
    let searchTypeToFields = dataDict.map.searchTypes;
    let field = searchTypeToFields[type[0]];
    //console.log("field: ", field);
    let rt = result.result.text.toLowerCase().replaceAll(".", "");
    let valueArray = [{ value: rt, name: result.result.place_name }];
    if (type.includes("region")) {
      let stAbbreviation = dataDict.geography.ST.items.find(
        (i) => i.name.toLowerCase() === rt.toLowerCase()
      ).value;
      valueArray = [{ value: stAbbreviation, name: valueArray[0].name }];
    }
    if (type.includes("district")) {
      let countyName = rt.toLowerCase().replace(" county", "").trim();
      valueArray = [{ value: countyName, name: rt }];
    }
    // To Do: Sometimes the cities don't exactly match, like saint george and st. george and salt lake city and salt lake cty
    //if ( type.includes("place") ) {
    //  // lookup the closest city
    //}
    //console.log("valueArray: ", valueArray);
    let nGeoParams = this.props.geographyParams;
    nGeoParams[field] = valueArray;
    await handleSearchState({
      geographyParams: nGeoParams,
      filterParams: [
        {
          geo_bounding_box: {
            [dataDict.map.location]: {
              top_left: {
                lat: result.result.bbox[3],
                lon: result.result.bbox[0],
              },
              bottom_right: {
                lat: result.result.bbox[1],
                lon: result.result.bbox[2],
              },
            },
          },
        },
      ],
    });
    this.setState({ geoCoderResult: result });
    fetchCount(null, false);
  }

  render() {
    const { viewport, popupInfo, geoCoderResult, darkMode } = this.state;
    const {
      lastSearch,
      dataDict,
      leads,
      leadsCount,
      fetchCount,
      searchParams,
      removeParam,
      searchParamsCount
    } = this.props;
    //console.log("viewport: ", viewport, "popupInfo: ", popupInfo);
    console.log("Map: this.props: ", this.props, "this.state: ", this.state);

    let mapsMatch = false;
    if (geoCoderResult !== null) {
      mapsMatch =
        geoCoderResult.result.center[0].toFixed(2) ===
        viewport.longitude.toFixed(2)
          ? geoCoderResult.result.center[1].toFixed(2) ===
            viewport.latitude.toFixed(2)
          : false;
      //console.log("comparing geoCoderResult.");
    } else if (this.state.prevViewport) {
      let spv = 0;
      let cpv = 0;
      Object.values(this.state.prevViewport).map((v) => {
        if (typeof v === "number") {
          spv += parseFloat(v.toFixed(this.state.searchSensitivity));
        }
      });
      Object.values(this.state.viewport).map((v) => {
        if (typeof v === "number") {
          cpv += parseFloat(v.toFixed(this.state.searchSensitivity));
        }
      });
      //console.log("comparing viewports.");
      mapsMatch = spv === cpv;
    }

    //console.log("mapsMatch: ", mapsMatch, "geoCoderResult: ", geoCoderResult);

    let showSearchThisArea = !mapsMatch
      ? true
      : !this.props.canFetchSearch
      ? true
      : false;
    //console.log("showSearchThisArea: ", showSearchThisArea);

    const geo_bounding_box = this.props.filterParams.find((p) =>
      Object.keys(p).includes("geo_bounding_box")
    );
    //console.log("geo_bounding_box: ", geo_bounding_box);

    return (
      <div style={{ height: "100vh", position: "relative" }}>
        <ReactMapGL
          {...viewport}
          onViewportChange={(viewport) => {
            this.setState({
              viewport
            });
          }}
          mapStyle={ darkMode ? "mapbox://styles/mapbox/dark-v9" : undefined }
          mapboxApiAccessToken={TOKEN}
          ref={this.mapRef}
          width="100%"
          height="100%"
        >
          <Geocoder
            mapRef={this.mapRef}
            onViewportChange={(newViewPort) => {
              newViewPort.transitionDuration = 1000;
              this.setState({
                viewport: newViewPort
              });
            }}
            countries="us"
            types={this.searchTypes.join(",")}
            mapboxApiAccessToken={TOKEN}
            position="top-left"
            clearOnBlur={true}
            onResult={(result) => this.setGeoFilterFromMap(result)}
          />
          {leads && (
            <Pins
              data={leads}
              onClick={(info) => this.setState({ popupInfo: info })}
              fields={lastSearch ? lastSearch.fields.split(",") : null}
              latField={dataDict.map.latitude}
              longField={dataDict.map.longitude}
              themeColor={dataDict.themeColor || "#007bff"}
            />
          )}

          {popupInfo && (
            <Popup
              tipSize={5}
              anchor="top"
              longitude={popupInfo[dataDict.map.longitude]}
              latitude={popupInfo[dataDict.map.latitude]}
              closeOnClick={false}
              onClose={() => this.setState({ popupInfo: null })}
            >
              <LeadInfo
                {...this.props}
                info={popupInfo}
                display={dataDict.previewCols.display}
                queryUrl={lastSearch}
              />
            </Popup>
          )}
          <div className="nav" style={navStyle}>
            <NavigationControl />
          </div>
          <div style={settingsStyle}>
            <Button
              size="sm"
              variant="light"
              onClick={ () => this.setState({showSettingsModal: true})}
            >
              <i className="text-dark fa fa-sliders fa-lg" aria-hidden="true"></i>
            </Button>
          </div>
          <div style={filterStyle}>
            {/*
              <UserNav {...this.props}
                className="map-user-nav mb-2 pull-right"
                style={{
                  borderRadius: ".25rem",
                  //width: "100%"
                }}
              />
            */}
            <LeadsCount
              className="text-center"
              style={{
                width: "100%",
              }}
              inline={false}
              count={leadsCount}
              themeColor={dataDict.themeColor}
              canFetch={showSearchThisArea}
              readOnly={false}
              refreshCount={async (e) => {
                await this.props.resetGeoParams();
                await this.setMapFilter();
                fetchCount(e, false);
                this.setState({
                  prevViewport: this.state.viewport,
                  geoCoderResult: null,
                });
              }}
              refreshButtonText={"Search this area"}
            >
              <ListGroup
                className="list-group-flush text-start"
                style={{
                  marginBottom: "-20px",
                  width: "100%",
                }}
              >
                <ListGroupItem>
                  <Accordion flush>
                    <AccordionToggle className="d-flex flex-row justify-content-between align-items-center"
                      eventKey="0"
                    >
                      <span
                        className="text-primary"
                      >
                        Filters ({searchParamsCount})
                      </span>
                      <Button
                        variant="outline-primary"
                        size="sm"
                        onClick={() =>
                          this.setState({ showFiltersModal: true })
                        }
                        className="pull-right"
                      >
                        <small>Edit</small>
                      </Button>
                    </AccordionToggle>
                    <Accordion.Collapse eventKey="0" className="text-start">
                      
                      <SelectedParams
                        selectedParams={searchParams || {}}
                        dict={dataDict.cols}
                        dictKey="cols"
                        removeParam={removeParam}
                        paramHeader={`${dataDict.type} filters`}
                      />
                      
                    </Accordion.Collapse>
                  </Accordion>
                </ListGroupItem>
                {leads.length > 0 && !showSearchThisArea && (
                  <Button
                    className="mt-1"
                    variant="success"
                    onClick={() => {
                      this.setState({ showCheckoutModal: true });
                    }}
                    block
                  >
                    Download
                  </Button>
                )}
              </ListGroup>
            </LeadsCount>
          </div>
        </ReactMapGL>
        {this.state.showFiltersModal && (
          <Modal
            show={this.state.showFiltersModal}
            onHide={() => {
              this.setState({ showFiltersModal: false });
              this.props.fetchCount(null, false);
            }}
            size="lg"
            centered
          >
            <Modal.Body>
              <Filters {...this.props} />
            </Modal.Body>
            <Modal.Footer>
              <Button
                size="sm"
                variant="light"
                onClick={() => this.setState({ showFiltersModal: false })}
              >
                Close
              </Button>
            </Modal.Footer>
          </Modal>
        )}
        {this.state.showCheckoutModal && (
          <Modal
            show={this.state.showCheckoutModal}
            onHide={() => {
              if (this.props.canFetchSearch) {
                this.props.fetchCount(null, false);
              }
              this.setState({ showCheckoutModal: false });
            }}
            size="lg"
            keyboard={true}
            scrollable={true}
          >
            <Modal.Body>
              <Tabs defaultActiveKey="searchSelected">
                <Tab eventKey="geography"></Tab>
                <Tab eventKey="searchSelected" title="Filters">
                  <Card>
                    <Card.Body>
                      <div className="d-flex flex-row justify-content-between">
                        <h3>Confirm filters.</h3>
                        <Button
                          variant="outline-primary"
                          size="sm"
                          onClick={() =>
                            this.setState({ showFiltersModal: true })
                          }
                          className="pull-right"
                        >
                          Edit
                        </Button>
                      </div>

                      <Row className="mt-3">
                        {this.props.hasMap && (
                          <Col>
                            <h5>Map filter</h5>
                            <MapPreview
                              mapboxApiAccessToken={TOKEN}
                              width={100}
                              height={100}
                              bb={geo_bounding_box}
                              location={this.props.dataDict.map.location}
                            />
                          </Col>
                        )}
                        {this.props.hasGeo && (
                          <Col>
                            <SelectedParams
                              selectedParams={this.props.geographyParams}
                              dict={this.props.dataDict.geography}
                              dictKey={"geography"}
                              paramHeader="Geography"
                            />
                          </Col>
                        )}
                        {this.props.hasFilters && (
                          <Col>
                            <SelectedParams
                              selectedParams={this.props.searchParams}
                              dict={this.props.dataDict.cols}
                              dictKey={"cols"}
                              paramHeader={`${this.props.dataDict.type} filters`}
                            />
                          </Col>
                        )}
                      </Row>
                    </Card.Body>
                  </Card>
                </Tab>
                <Tab eventKey="previewCsv" title="Preview" className="p-2 pt-4">
                  <Preview
                    {...this.props}
                    previewCols={this.props.dataDict.previewCols}
                    dlCols={this.props.dataDict.dlCols}
                  />
                </Tab>
                <Tab eventKey="Checkout" title="Checkout" className="p-2 pt-4">
                  <Download {...this.props} />
                </Tab>
              </Tabs>
            </Modal.Body>
            <Modal.Footer>
              <Button
                size="sm"
                variant="light"
                onClick={() => this.setState({ showCheckoutModal: false })}
              >
                Nevermind
              </Button>
            </Modal.Footer>
          </Modal>
        )}
        {this.state.showSettingsModal && (
          <Modal
            show={this.state.showSettingsModal}
            onHide={ () => this.setState({showSettingsModal: false})}
          >
            <Modal.Body>
              <Modal.Title className="text-center">Map Settings</Modal.Title>
              <Form.Group className="mt-3">
                <Form.Check
                  type="switch"
                  id="darkmode-switch"
                  label="Dark Mode"
                  checked={ this.state.darkMode }
                  onChange={ () => {
                    console.log("Tried to change darkMode.")
                    this.setState({darkMode: !this.state.darkMode})
                  }}
                />
              <Form.Text>Use this to toggle your map to a darker theme. Similar to how Google Maps looks after dark.</Form.Text>
              </Form.Group>
              <Form.Group id="mapSensitivityRange">
                <Form.Label>Map Sensitivity</Form.Label>
                <Form.Control type="range"
                  value={this.state.searchSensitivity}
                  min="0"
                  max="5"
                  onChange={ (e) => {
                    this.setState({
                      searchSensitivity: parseInt(e.target.value)
                    })
                  }}
                />
                <Form.Text>Increasing sensitivity allows you to use the "Search This Area" button more frequently.</Form.Text>
              </Form.Group>
            </Modal.Body>
            <Modal.Footer>
              <Button
                variant="light"
                size="sm"
                onClick={ () => this.setState({showSettingsModal: false})}
              >
                Close
              </Button>
            </Modal.Footer>
          </Modal>
        )}
      </div>
    );
  }

  componentDidUpdate() {
    if (!this.state.fetchedUserLocation) {
      this.getUserLocation();
    }
  }
  componentDidMount() {
    this.props.handleState({
      noDistract: true,
      isApp: true,
      hideSmallTopNav: true
    });
    this.setMapFilter();
    this.props.fetchCount(null, false);
    this.setState({ prevViewport: {...this.state.viewPort} })
  }

  componentWillUnmount() {
    this.props.handleState({
      hideSmallTopNav: false
    });
  }
}

export default withSearch(Map);
