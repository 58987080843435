import React, { useState, useEffect } from "react";
import {
  Container,
  Row,
  Col,
  Button,
  Form,
  ButtonGroup
} from "react-bootstrap";
import { shareLink } from "./share.jsx";
import { db } from "./firebase.jsx";

const ComposeEmail = props => {
  //console.log("ComposeEmail props: ", props);
  const { superAdmin } = props.userDoc;
  const { apiKey, handleAlerts, htmlTemplate, onSend } = props;
  const [emails, setEmails] = useState([]);
  const [cc, setCC] = useState([]);
  const [bcc, setBCC] = useState([]);
  const [from, setFrom] = useState({
    email: "help@listshack.support",
    name: "List Shack Support"
  });
  const [html, setHtml] = useState("");
  const [plainText, setPlainText] = useState("");
  const [subject, setSubject] = useState("");
  const [autoHtml, setAutoHtml] = useState(true);
  const [preview, setPreview] = useState(false);
  const [mobile, setMobile] = useState(false);
  const [consolidated, setConsolidated] = useState(false);
  const [hide, setHide] = useState([]);
  //const [template, setTemplate] = useState("");

  const sendEmails = async () => {
    //Send off the email
    console.log("Sending email!");
    let sEmails = [];
    let fEmails = [];
    let msg = {
      to: emails,
      cc: cc ? cc : null,
      bcc: bcc ? bcc : null,
      subject,
      from,
      text: props.appendPlainText
        ? props.appendPlainText(plainText)
        : plainText,
      html: html
    };

    for (let email of emails) {
      msg.to = email;
      try {
        //console.log("msg: ", msg);
        let payload = {
          msg,
          handleAlerts,
          apiKey,
          signed: false
        };
        if (onSend) {
          await onSend({msg});
        } else {
          await sendEmail(payload);
        }
        sEmails.push(email);
      } catch (err) {
        console.log("Couldn't deliver email: ", email);
        fEmails.push(email);
        continue;
      }
    }

    
    if (props.onSent) {
      props.onSent({
        sent: sEmails,
        failed: fEmails,
        msg
      });
    } else {
      handleAlerts("", "Your email was successfully sent!", "success");
    }
    setSubject("");
    setPlainText("");
    setHtml("");
    setEmails([]);
    setCC([]);
    setBCC([]);
    setFrom("");
    setPreview(false);
  };

  useEffect(() => {
    if (props.emails) {
      setEmails(props.emails);
    }
    if (props.cc) {
      setCC(props.cc);
    }
    if (props.bcc) {
      setBCC(props.bcc);
    }
    if (props.from) {
      setFrom(props.from);
    }
    if (props.autoHtml) {
      setAutoHtml(props.autoHtml);
    }
    if (props.plainText) {
      setPlainText(props.plainText);
      if (autoHtml) {
        setHtml(
          props.wrapHtml
            ? props.wrapHtml(props.plainText)
            : `<p>${props.plainText}</p>`
        );
      }
    }
    if (props.html) {
      setHtml(props.html);
    }
    if (props.subject) {
      setSubject(props.subject);
    }
    if (props.preview) {
      setPreview(props.preview);
    }
    if (props.consolidated) {
      setConsolidated(props.consolidated);
    }
    if (props.hide) {
      setHide(props.hide);
    }
  }, [props.emails, props.plainText, props.html, props.subject]);

  const displayPreview = preview
    ? preview
    : consolidated
    ? consolidated
    : false;

  return (
    <div
      className={props.className ? props.className : null}
      style={props.style ? props.style : null}
    >
      <div className="d-flex flex-row justify-content-between align-items-center">
        <div>
          <h3>
            {preview
              ? "Preview email"
              : props.title
              ? props.title
              : "Compose email"}
          </h3>
        </div>
        <div>
          {preview && (
            <ButtonGroup>
              <Button
                variant={mobile ? "light" : "dark"}
                disabled={mobile}
                size="sm"
                onClick={() => setMobile(false)}
              >
                Desktop
              </Button>
              <Button
                variant={!mobile ? "light" : "dark"}
                disabled={!mobile}
                size="sm"
                onClick={() => setMobile(true)}
              >
                Mobile
              </Button>
            </ButtonGroup>
          )}
        </div>
      </div>
      {!preview && (
        <React.Fragment>
          {(!hide.includes("to") || superAdmin) && (
            <Form.Group>
              <Form.Label>
                {props.toLabel ? props.toLabel : "Recipients"}
              </Form.Label>
              <Form.Control
                type="textarea"
                rows={2}
                onChange={e => {
                  let emails = e.target.value.replace(/ /g, "").split(",");
                  setEmails(emails);
                }}
                value={emails.join(", ")}
              />
              {superAdmin && (
                <div
                  style={{
                    padding: "10px",
                    border: "solid 1px darkgray",
                    borderRadius: "4px"
                  }}
                >
                  {emails.join(", ")}
                </div>
              )}
            </Form.Group>
          )}
          {(!hide.includes("from") || superAdmin) && (
            <Form.Group>
              <Form.Label>
                {props.fromLabel ? props.fromLabel : "Send From"}
              </Form.Label>
              <Form.Control
                as="select"
                rows={2}
                onChange={e => {
                  let nfrom = JSON.parse(
                    e.target.options[e.target.selectedIndex].value
                  );
                  console.log("nfrom: ", nfrom);
                  setFrom(nfrom);
                }}
                value={JSON.stringify(from)}
              >
                <option value="">Select From Address</option>
                {!props.fromOptions
                  ? null
                  : props.fromOptions.map(option => {
                      return (
                        <option
                          key={option.email}
                          value={JSON.stringify(option)}
                        >
                          {option.name} {option.email}
                        </option>
                      );
                    })}
              </Form.Control>
            </Form.Group>
          )}
          {(!hide.includes("subject") || superAdmin) && (
            <Form.Group>
              <Form.Label>
                {props.subjectLabel ? props.subjectLabel : "Subject"}
              </Form.Label>
              <Form.Control
                type="text"
                value={subject}
                onChange={e => setSubject(e.target.value)}
              />
            </Form.Group>
          )}
          {(!hide.includes("message") || superAdmin) && (
            <Form.Group>
              <Form.Label>
                {props.messageLabel ? props.messageLabel : "Add message"}
              </Form.Label>
              <Form.Control
                as="textarea"
                rows={3}
                value={plainText}
                onChange={e => {
                  setPlainText(e.target.value);
                  if (autoHtml) {
                    setHtml(
                      props.wrapHtml
                        ? props.wrapHtml(e.target.value)
                        : `<p>${e.target.value}</p>`
                    );
                  }
                }}
              />
            </Form.Group>
          )}
          {superAdmin && (
            <Form.Group>
              <Form.Label>HTML EMAIL (As String)</Form.Label>
              <Form.Check
                type="checkbox"
                checked={autoHtml}
                onChange={e => setAutoHtml(!autoHtml)}
                label="Automatically write HTML"
              />
              <Form.Control
                as="textarea"
                rows={6}
                value={html}
                onChange={e => setHtml(e.target.value)}
              />
            </Form.Group>
          )}
          {!consolidated && (
            <div className="pull-right">
              {/*<Button variant={!preview ? "dark" : "light"} disabled={!preview} size="sm" onClick={ () => setPreview(false)} >Compose</Button>*/}
              <Button
                variant={preview ? "dark" : "outline-primary"}
                disabled={preview}
                size="sm"
                onClick={() => setPreview(true)}
              >
                Preview
              </Button>
            </div>
          )}
        </React.Fragment>
      )}
      {displayPreview && (
        <div className="mt-4">
          {consolidated && <h4>Email preview</h4>}
          <div
            className="p-2 border"
            dangerouslySetInnerHTML={{
              __html: htmlTemplate ? htmlTemplate(html, false, false) : html
            }}
          />
          <div className="mt-2">
            {!consolidated &&
              <Button
                variant="outline-primary"
                className="pull-left"
                onClick={() => setPreview(false)}
              >
                Back
              </Button>
            }
            <Button className="pull-right" 
              onClick={() => {
                sendEmails()
              }}
            >
              Send
            </Button>
          </div>
        </div>
      )}
    </div>
  );
};

const sendEmail = (params, callback) => {
  //console.log("msg: ", msg, "handleAlerts: ", handleAlerts, "apiKey: ", apiKey);
  let { msg, handleAlerts, apiKey, signed } = params;
  if (typeof signed === "undefined") {
    signed = true;
  }
  // msg prop should be formatted as follows:
  // const msg = {
  //   to: "some@email.com" OR ["some@email.com", "someother@email.com", ... ],
  //   cc: "some@email.com" OR ["some@email.com", "someother@email.com", ... ],
  //   bcc: "some@email.com" OR ["some@email.com", "someother@email.com", ... ],
  //   from: "some@email.com" OR {email: "some@email.com", name: "Jane Doe"},
  //   subject: "Sending email is awesome",
  //   text: "Some text version of an email message",
  //   html: '<strong>Some html version of an email</strong>'
  // }

  if (msg.to === undefined || msg.to === "") {
    return handleAlerts(
      "",
      "Please include a 'To' email address for your message.",
      "warning"
    );
  }
  if (msg.from === undefined || msg.from === "") {
    return handleAlerts(
      "",
      "Please include a 'From' email address for your message.",
      "warning"
    );
  }
  if (msg.subject === undefined || msg.subject === "") {
    return handleAlerts(
      "",
      "Please include a 'Subject' for your message.",
      "warning"
    );
  }
  if (msg.text === undefined || msg.text === "") {
    return handleAlerts(
      "",
      "Please include a text version of your email.",
      "warning"
    );
  }
  if (msg.html === undefined || msg.html === "") {
    return handleAlerts(
      "",
      "Please include an html version of your email.",
      "warning"
    );
  } else {
    msg.html = emailTemplate(msg.html, signed);
  }
  try {
    const init = {
      method: "POST",
      headers: {
        authorization: `Bearer ${apiKey}`,
        "Content-Type": "application/json"
      },
      "Transfer-Encoding": "chunked",
      cache: "default",
      accept: "application/json",
      body: JSON.stringify({ msg })
    };
    let url = `${process.env.REACT_APP_api_url}/api/email`;
    //console.log("url: ", url, "init: ", init);
    fetch(url, init);
    if (callback) {
      return callback(null, msg);
    }
    return;
  } catch (err) {
    console.log("There was an error sending email: ", err);
    if (callback) {
      return callback(err);
    }
  }
};

const emailTemplate = (innerHtml, signed = true, preview = false) => {
  return `<html xmlns="http://www.w3.org/1999/xhtml" lang="en" xml:lang="en">
      <head>
        <meta http-equiv="Content-Type" content="text/html; charset=utf-8" />
        <meta name="viewport" content="width=device-width" />
        <title>ListShack - Lists Made Easy</title>
      </head>
      <body style="font-family: arial, sans-serif; font-weight: 300;">
        <table style="width: 100%; height: 100%; background-color: #ffffff">
          <tr>
            <td style="text-align: center" align="center" valign="top">
                ${
                  !preview
                    ? `<table align="center">
                  <tbody>
                    <tr>
                      <td>
                        <table>
                          <tbody>
                            <tr>
                              <td
                                height="24px"
                                style="font-size:24px;line-height:24px;"
                              >
                                &#xA0;
                              </td>
                            </tr>
                          </tbody>
                        </table>

                        <table>
                          <tbody>
                            <tr>
                              <th>
                                &nbsp;
                              </th>
                            </tr>
                          </tbody>
                        </table>
                      </td>
                    </tr>
                  </tbody>
                </table>`
                    : ""
                }
                <table style="text-align: center" align="center">
                  <tbody>
                    <tr>
                      <td>
                        ${
                          !preview
                            ? `<table>
                          <tbody>
                            <tr>
                              <td align="center">
                                <div style="width: 400px; max-width: 400px; padding: 30px 15px;">
                                  <a href="https://www.listshack.support" style="text-decoration: none"><img
                                    src="https://www.listshack.support/static/media/logo_list_shack.3a5bbb3d.png"
                                    align="center"
                                    height="75"
                                    style="border-radius: 16px"
                                  /></a>
                                </div>
                              </td>
                            </tr>
                          </tbody>
                        </table>`
                            : ""
                        }
                        <table>
                          <tbody>
                            <tr>
                              <td align="center">
                                <div style="width: 400px; max-width: 400px; padding: 15px 30px; background-color: #f3f3f3 !important; border-radius: 16px">
                                  <div style="text-align: left">
                                    ${
                                      signed
                                        ? "<p><i>Dear ListShack Member,</i></p>"
                                        : ""
                                    }
                                    ${innerHtml}
                                    ${
                                      signed
                                        ? `
                                    <p>We're here if you have any questions!</p>
                                    <p>Best,</p>
                                    <p><i>Wyatt</i></p>
                                    <p>
                                      <a href="tel:909-703-5114" style="text-decoration: none; color: inherit">909-703-5114</a> (text me)<br />
                                      <a href="mailto:help@listshack.support">help@listshack.support</a>
                                    </p>`
                                        : ""
                                    }
                                  </div>
                                </div>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </td>
                    </tr>
                  </tbody>
                </table>
                ${
                  !preview
                    ? `<table style="text-align: center" align="center">
                  <tbody>
                    <tr>
                      <td>
                        <table>
                          <tbody>
                            <tr>
                              <th>
                                <table>
                                  <tr>
                                    <th>
                                      <table class="spacer">
                                        <tbody>
                                          <tr>
                                            <td
                                              height="20px"
                                              style="font-size:20px;line-height:20px;"
                                            >
                                              &#xA0;
                                            </td>
                                          </tr>
                                        </tbody>
                                      </table>
                                      <p style="text-align: center">
                                        <small>© 2021 ListShack</small>
                                      </p>
                                      <p style="text-align: center">
                                        <small
                                          >1042 Fort Union Blvd #1122, Midvale UT
                                          84067</small
                                        >
                                      </p>
                                    </th>
                                  </tr>
                                </table>
                              </th>
                            </tr>
                          </tbody>
                        </table>
                      </td>
                    </tr>
                  </tbody>
                </table>
            </td>
          </tr>
        </table>`
                    : ""
                }
        <div
          style="display:none; white-space:nowrap; font:15px courier; line-height:0;"
        >
          &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
          &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
          &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
        </div>
      </body>
    </html>`;
};

const searchCardHtml = props => {
  // params shaped like:
  // {
  //   geographyParams: object,
  //   searchParams: object,
  //   dataDict: object,
  //   query: object,
  //   count: number,
  //   searchName: string,
  //   user: object,
  //   isDefault: boolean
  // }
  const { savedSearch, savedSearchId } = props;

  return `<a href="${shareLink({
    type: "search",
    searchId: savedSearchId,
    uid: savedSearch.uid
  })}"
      style="font-family: 'Josefin Sans', sans-serif !important;font-size: 16px;font-weight: 400;line-height: 1.5;color: #212529;text-align: left;box-sizing: border-box;position: relative;display: block;min-width: 300;background-color: #fff;background-clip: border-box;border: 1px solid rgba(0,0,0,.125);border-radius: 4px;border-color: #007bff!important;box-shadow: 0 8px 16px rgba(0,0,0,.15)!important; text-decoration: none;"
    >
      <div
        style="text-align: left;box-sizing: border-box;padding: 12px 20px;margin-bottom: 0;border-bottom: 1px solid rgba(0,0,0,.125);background-color: #007bff!important;display: block; color: #fff!important;border-radius: 3px 3px 0 0;"
      >
        ${savedSearch.searchName ? savedSearch.searchName : "Leads Search"}
      </div>
      <div
        style="text-align: left;box-sizing: border-box;display: block;padding: 20px;"
      >
        <div style="font-size: 18px; text-align: center; padding-top: 30px; padding-bottom: 30px"><i>Click this card to view the details of this search</i></div>
        <div
          style="box-sizing: border-box;display: flex;flex-wrap: wrap;margin-right: 0;margin-left: 0;text-align: left!important;"
        >
          <div
            style="box-sizing: border-box;position: relative;width: 100%;flex: 0 0 100%;max-width: 100%;border-top: 1px solid #dee2e6!important;display: block;margin-bottom: 0!important;margin-top: 8px!important;padding-bottom: 0!important;padding-top: 16px!important;padding-right: 16px!important;padding-left: 16px!important;"
          >
            <span style="font-size: 18px;">Leads count:</span>
            ${
              savedSearch.count
                ? `<span style="font-size: 22px;">${savedSearch.count.toLocaleString()}</span>`
                : `<small><i>Search to find available leads</i></small>`
            }
          </div>
        </div>
      </div>
    </a>`;
};

const EmailEditor = props => {
  const { handleIsApp, handleState } = props;
  useEffect(() => {
    if (handleIsApp) {
      handleState({
        isApp: true,
        noDistract: true
      })
    }
  }, [handleState, handleIsApp]);
  return (
    <Container>
      <Row>
        <Col>
          <ComposeEmail
            {...props}
            className="mt-5 pb-5"
            title="Send an email, Yay!"
            from=""
            fromOptions={[
              {
                email: "wyatt@listshack.com",
                name: "List Shack"
              },
              {
                email: "help@listshack.support",
                name: "List Shack Support"
              }
            ]}
            onSent={emailObj => {
              const emailRef = db
                .collection("emails")
                .doc(props.userDoc._docId);
              try {
                emailRef.add(emailObj);
              } catch (err) {
                console.log("Error saving email to firestore: ", err);
              }
            }}
          />
        </Col>
      </Row>
    </Container>
  );
};

export { ComposeEmail, emailTemplate, sendEmail, searchCardHtml, EmailEditor };
