import React, {useEffect, useState} from "react";
import { Form, Button, Row, Col } from "react-bootstrap";
import { blankAddr } from "./displayAddr";
import { blankPhone } from "./displayPhone";
import { blankEmail } from "./displayEmail";
import { blankAge } from "./displayAge";
import { SimpleHhIncomeInput } from "./displayHhIncome.jsx";

const AddRecord = props => {
  let {fields, onAdd, userDoc, listid, user} = props;
  const [newDoc, setNewDoc] = useState(null);
  const setBlankDoc = () => {
    let nd = {};
    //console.log("blankAddr: ", blankAddr);
    fields.forEach( f => {
      if (f === "PHONES") {
        return nd[f] = [ JSON.parse(JSON.stringify(blankPhone)) ];
      }
      if (f === "EMAILS") {
        return nd[f] = [ JSON.parse(JSON.stringify(blankEmail)) ];
      }
      if (f === "ADDRESSES") {
        return nd[f] = [ JSON.parse(JSON.stringify(blankAddr)) ];
      }
      if (f === "AGE") {
        return nd[f] = JSON.parse(JSON.stringify(blankAge));
      }
      return nd[f] = ""
    });
    setNewDoc(nd);
    //console.log("ran setBlankDoc!", nd);
  }

  useEffect( () => {
    setBlankDoc();
  }, [fields, listid])
  //console.log("newDoc: ", newDoc);
  if (!newDoc || !fields ) { return null }
  return(
    <Form 
      onSubmit={ async e => {
        e.preventDefault();
        let nd = formatElasticCrmDoc({
          docData: {
            ...newDoc,
            SOURCE: [
              {
                type: "user inline",
                timestamp: new Date().toISOString() 
              }
            ]
          },
          aid: userDoc.id,
          uid: user.uid,
          listid
        })
        await onAdd(nd);
        setBlankDoc();
      }}
    >
      <Row className="g-0 align-items-center justify-content-start">
        <Col xs="auto" className="text-muted ms-5 me-2"><small>Add new record</small></Col>
      {fields.map( (f,index) => {
        let key = `tb_td_new_${index}_${f}`;
        if (["PHONES"].includes(f)) {
          return(
            <Col xs="auto" key={key}>
              <Form.Control
                key={key}
                type="text"
                value={newDoc[f][0]["DATA"]}
                onChange={ e => {
                  let nd = {...newDoc};
                  nd[f][0]["DATA"] = e.target.value;
                  setNewDoc(nd);
                }}
                placeholder={"Phone"}
              />
            </Col>
          );
        }
        if (["EMAILS"].includes(f)) {
          return(
            <Col xs="auto" key={key}>
              <Form.Control
                key={key}
                type="text"
                value={newDoc[f][0]["DATA"]}
                onChange={ e => {
                  let nd = {...newDoc};
                  nd[f][0]["DATA"] = e.target.value;
                  setNewDoc(nd);
                }}
                placeholder={"Email"}
              />
            </Col>
          );
        }
        if (["ADDRESSES"].includes(f)) {
          return(
            <React.Fragment key={key}>
              {["ADDR", "APT", "CITY", "ST", "ZIP"].map( sf => {
                let map = {
                  ADDR: "Street 1",
                  APT: "Street 2",
                  CITY: "City",
                  ST: "State",
                  ZIP: "Zip",
                  Z4: "Z4"
                }
                return(
                  <Col xs="auto" key={`${key}_${sf}`}>
                    <Form.Control
                      key={`${key}_${sf}`}
                      type="text"
                      value={newDoc[f][0]["DATA"][sf]}
                      onChange={ e => {
                        let nd = {...newDoc};
                        if (! nd[f] ) {
                          nd[f] = [JSON.parse(JSON.stringify(blankAddr))];
                        }
                        nd[f][0]["DATA"][sf] = e.target.value;
                        setNewDoc(nd);
                      }}
                      placeholder={map[sf]}
                    />
                  </Col>
                );
              })}
            </React.Fragment>
          );
        }
        if (["AGE"].includes(f)) {
          return(
            <Col xs="auto" key={key}>
              <Form.Control
                key={key}
                type="text"
                value={newDoc[f]["DATA"]}
                onChange={ e => {
                  let nd = {...newDoc};
                  nd[f]["DATA"] = e.target.value;
                  setNewDoc(nd);
                }}
                placeholder={f}
              />
            </Col>
          );
        }
        if (["CREATEDBY"].includes(f)) {
          return (
            <Col xs="auto" key={key}>
              tbd
            </Col>
          );
        }
        if (["HH_INCOME"].includes(f)) {
          return (
            <Col xs="auto" key={key}>
                <SimpleHhIncomeInput 
                code={newDoc[f] ? newDoc[f] : ""} 
                keyId={key}
                key={key}
                onChange={(nc) => {
                  let nd = {...newDoc};
                  nd[f] = nc;
                  setNewDoc(nd);
                }}
              />
            </Col>
          );
        }
        return (
          <Col xs="auto" key={key}>
            <Form.Control
              size="md"
              value={newDoc[f]}
              onChange={e => {
                let nd = {...newDoc}
                nd[f] = e.target.value;
                setNewDoc(nd);
              }}
              placeholder={f}
            />
          </Col>

        );
      }
      )}
        <Col xs="auto">
          <Button 
            type="submit"
            variant="info"
          >
            Add
          </Button>
        </Col>
      
      </Row>
    </Form>
  )
}

const formatElasticCrmDoc = ({docData, listid, aid, uid, listStatus}) => {
  let nd = {
    ...docData, 
    LISTMAP: {
      all: "new",
      [listid]: listStatus ? listStatus[listid] : "new"
    },
    DELETED: "false",
    ACCOUNTID: aid,
    CREATEDAT: new Date().toISOString(),
    UPDATEDAT: new Date().toISOString(),
    CREATEDBY: uid,
    UPDATEDBY: uid
  }
  if (!nd["NAME"]) {
    nd["NAME"] = `${nd.CO_NAME} ${nd.FN} ${nd.LN} ${ ( (!nd.CO_NAME && !nd.FN && !nd.LN) && ( nd.PHONES || nd.ADDRESSES || nd.EMAILS) ) ? nd.PHONES[0].DATA ? nd.PHONES[0].DATA : nd.EMAILS[0].DATA ? nd.EMAILS[0].DATA : nd.ADDRESSES[0].DATA.ADDR ? nd.ADDRESSES[0].DATA.ADDR : "" : ""}`.replaceAll("  ", " ").replaceAll("undefined", "").trim();
  }
  return nd;
}

export {AddRecord, formatElasticCrmDoc};