import React, {useEffect} from "react";
import { useParams, Link, Redirect } from "react-router-dom";
import { archiveDoc, db } from "./firebase";
import { Tab, Table, Card, Row, Col, Button, ButtonGroup } from "react-bootstrap";
import SelectedParams from "./selectedParams";
import { ShareButton } from "./share";
import { sendEmail } from "./email.jsx";
import TaskProgress from "./taskProgress";

const DownloadPane = props => {
  //console.log("DownloadPane props: ", props);
  let { downloads, dicts, handleAlerts, handleDlState, userDoc, download, disallowReportAndArchive, apiKey, hideSearchPreview, showEmailButton, fetchFromApi, displayTaskProgress } = props;
  let { id } = useParams();
  if (!download) {
    download = downloads.find(({ _docId }) => _docId === id);
  }
  //console.log("download: ", download);
  useEffect(() => {
    if (handleDlState) {
      handleDlState({
        downloadId: typeof download !== "undefined" ? download._docId : null
      });
    }
  }, []);

  if (typeof download === "undefined") {
    return <Redirect to="/downloads" />;
  }

  let dict;
  if (
    !hideSearchPreview &&
    typeof download.queryUrl === "object"
      ? typeof download.queryUrl.index !== "undefined"
      : false
  ) {
    dict = dicts.find(({ index }) => index === download.queryUrl.index);
  }

  let displaySearchPreview =
    !hideSearchPreview &&
    typeof dict !== "undefined" &&
    typeof download.geographyParams !== "undefined" &&
    typeof download.searchParams !== "undefined" &&
    (typeof download.queryUrl === "object" ? typeof download.queryUrl.index !== "undefined" : false) &&
    typeof dict !== "undefined";

  console.log(
    "download: ",
    download,
    "dict: ",
    dict,
    "displaySearchPreview: ",
    displaySearchPreview
  );

  return (
    <Tab.Pane key={`pane${download._docId}`} className="active show">
      {displayTaskProgress && download.elasticlist && download.reindexTaskId &&
        <TaskProgress 
          taskId={download.reindexTaskId}
          fetchFromApi={fetchFromApi}
          message={
            <p className="text-muted mt-2">
              We're still adding leads to your list, but you can go ahead and start managing it now.  If you need to download a CSV, wait until we're finished.
            </p>
          }
        />
      }
      <Table bordered hover className="text-start">
        <tbody>
          <tr>
            <td>
              <b>Search name:</b>{" "}
            </td>
            <td>{download.searchName}</td>
          </tr>
          <tr>
            <td>
              <b>Leads downloaded:</b>{" "}
            </td>
            <td>{parseInt(download.downloadCount).toLocaleString()}</td>
          </tr>
          <tr>
            <td>
              <b>Download date:</b>{" "}
            </td>
            <td>{new Date(download.timestamp.seconds * 1000).toString()}</td>
          </tr>
          {download.reported &&
            download.metaData === undefined &&
            download.geoMatch === undefined && (
              <tr>
                <td>
                  <b>File reported:</b>{" "}
                </td>
                <td className="text-success">Yes - account credited</td>
              </tr>
            )}
          {download.reported &&
            (download.metaData !== undefined ||
              download.geoMatch !== undefined) && (
              <tr>
                <td>
                  <b>File reported:</b>
                </td>
                <td className="text-danger">Yes - no credits approved</td>
              </tr>
            )}
          {displaySearchPreview && (
            <tr>
              <td>
                <b>Search:</b>
              </td>
              <td>
                <Card bg="light">
                  <Card.Body>
                    <Row>
                      <Col xs={12} md={6}>
                        <SelectedParams
                          selectedParams={download.geographyParams}
                          dict={dict["geography"]}
                          dictKey="geography"
                          paramHeader="Geography"
                          download={download}
                        />
                      </Col>
                      <Col xs={12} md={6}>
                        <SelectedParams
                          selectedParams={download.searchParams}
                          dict={dict["cols"]}
                          dictKeys="cols"
                          paramHeader={`${dict.type} filters`}
                          download={download}
                        />
                      </Col>
                    </Row>
                  </Card.Body>
                  <Card.Footer>
                    <ButtonGroup className="pull-right">
                      <ShareButton
                        type="download"
                        downloadId={download._docId}
                        aid={download.aid}
                        cb={() => {
                          console.log(
                            "Copied the share link to your clipboard."
                          );
                          handleAlerts(
                            "",
                            "Copied the share link to your clipboard",
                            "info"
                          );
                        }}
                      >
                        <Button variant="light" size="sm">
                          Copy Link&nbsp;
                          <i className="fa fa-clone" aria-hidden="true"></i>
                        </Button>
                      </ShareButton>
                      <Link
                        to={`/_search/${download.queryUrl.dictId}?downloadSearch=${download._docId}&aid=${download.aid}`}
                        className="btn btn-outline-dark btn-sm pull-right"
                      >
                        Reuse search
                      </Link>
                    </ButtonGroup>
                  </Card.Footer>
                </Card>
              </td>
            </tr>
          )}
        </tbody>
      </Table>
      {!download.elasticlist &&
        <Button
          variant="success"
          size="lg"
          href={download.csvFile}
          download={download.fileName}
        >
          Download CSV
        </Button>
      }
      {download.elasticlist &&
        <Link to={`/listcrm/${download.elasticlist}`}
          className="btn btn-lg btn-success"
        >
          View List
        </Link>
      }
      {showEmailButton && 
        <Button
          variant="outline-success"
          className="ms-3"
          onClick={() => {
            let emailRecipients = [userDoc.email];
            if (
              typeof userDoc.companyProfile !==
              "undefined"
            ) {
              if (
                userDoc.email !==
                userDoc.companyProfile.email
              ) {
                emailRecipients.push(
                  userDoc.companyProfile.email
                );
              }
            }
            let msg = {
              to: emailRecipients,
              from: "help@listshack.support",
              subject: `Download your file from ${process.env.REACT_APP_site_name}`,
              text: `Download the file for the search <strong>${download.searchName} from ${process.env.REACT_APP_site_name}</strong> by clicking the button below by or copying and pasting the link into your browser. ${download.csvFile}.`,
              html: `<p>Download the file for the search ${
                download.searchName
              } from ${
                process.env.REACT_APP_site_name
              } by clicking the button below or copying and pasting the link into your browser:</p><div style="text-align: center; display: block"><a style="padding: 8px 16px; margin: 30px; font-size: 20px; background-color: #17a2b8; color: #ffffff;border-radius:.3rem;text-decoration: none" href="${
                download.csvFile
              }">${
                download.searchName
              }</a></div><p>${
                download.csvFile
              }</p><p><small>This list contains ${download.downloadCount.toLocaleString()} leads and you have ${userDoc.leadsRemaining.toLocaleString()} left during this billing cycle. If you have any issues with this file, such as a blank file, log into your account and report the file from the <a href="https://www.listshack.support/downloads">Downloads page</a>.</small></p>`
            };

            sendEmail({
              msg,
              handleAlerts,
              apiKey
            });
            handleAlerts(
              "",
              `A link to download your file was sent to the following email addresses: ${emailRecipients.join(
                " and "
              )}`,
              "success",
              "Email sent"
            );
          }}
        >
          Email me this file
        </Button>
      }
      
      {!disallowReportAndArchive &&
        <ButtonGroup className="pull-right">
          <Button
            variant="outline-dark"
            onClick={e => {
              e.preventDefault();
              handleDlState({
                displayModal: true,
                selectedDownload: download
              });
            }}
          >
            Report this file
          </Button>
          <Button
            variant="outline-danger"
            onClick={async () => {
              if (
                window.confirm(
                  `Are you sure that you'd like to archive the download named ${download.searchName}?`
                )
              ) {
                try {
                  await archiveDoc({
                    docRef: db
                      .collection("downloads")
                      .doc(userDoc._docId)
                      .collection("files")
                      .doc(download._docId),
                    unarchive: download.archived ? true : false
                  });
                } catch (err) {
                  handleAlerts(
                    "",
                    `Uh oh, there was an error archiving your download: ${err.message}`,
                    "warning"
                  );
                }
              }
            }}
          >
            {download.archived ? "Unarchive" : "Archive"}
          </Button>
        </ButtonGroup>
      }
    </Tab.Pane>
  );
};

  export default DownloadPane;