import React from "react";
import { OverlayTrigger, Tooltip, Button, InputGroup } from "react-bootstrap";
import InlineFormControl from "./inlineFormControl";


const Addr = props => {
  let {addrObject, keyId} = props;
  return(
    <OverlayTrigger
      overlay={
        <Tooltip id={`tooltip-${keyId}`}>
          <div className="text-start">
            {["COUNTY", "ZIP", "Z4", "LAT", "LONG"].map(
              (sf, i) => {
                return (
                  <React.Fragment
                    key={`frag_addr_${keyId}_${sf}_${i}`}
                  >
                    <span>
                      {sf}: <b>{`${addrObject.DATA[sf]}`}</b>
                    </span>
                    <br />
                  </React.Fragment>
                );
              }
            )}
            {/*<span>{JSON.stringify(c)}</span>*/}
          </div>
        </Tooltip>
      }
    >
      <Button
        variant="link"
        style={{ textDecoration: "none", textAlign: "left" }}
      >
        <div className="text-info">
          {["ADDR", "APT"].map((f, no) => {
            if (addrObject.DATA[f]) {
              return (
                <span
                  style={{whiteSpace: "nowrap"}}
                  key={`span_addr_${keyId}_${no}${f}`}
                >
                  {addrObject.DATA[f]},{" "}
                </span>
              );
            }
          })}
          {["CITY", "ST", "ZIP"].map((f, no) => {
            if (addrObject.DATA[f]) {
              return (
                <span
                  style={{whiteSpace: "nowrap"}}
                  key={`span_addr_${keyId}_${f}_${no}_sz`}
                >
                  {addrObject.DATA[f]}{" "}
                </span>
              );
            }
          })}
        </div>
      </Button>
    </OverlayTrigger>
  );
}

const AddrInput = props => {
  let {addrObject, keyId, onChange} = props;
  return(
    <>
      <InputGroup className="pb-1">
        <InlineFormControl
          type="text"
          keyId={`${keyId}_addr`}
          value={addrObject.DATA.ADDR}
          onChange={e => {
            let na = {...addrObject};
            na["DATA"]["ADDR"] = e.target.value;
            onChange(na);
          }}
          placeholder="Street"
        />
      </InputGroup>
      <InputGroup className="pb-1">
        <InlineFormControl
          type="text"
          keyId={`${keyId}_apt`}
          value={addrObject.DATA.APT}
          onChange={e => {
            let na = {...addrObject};
            na["DATA"]["APT"] = e.target.value;
            onChange(na);
          }}
          placeholder="Street 2"
        />
      </InputGroup>
      <InputGroup className="pb-1">
        <InlineFormControl
          type="text"
          keyId={`${keyId}_city`}
          value={addrObject.DATA.CITY}
          onChange={e => {
            let na = {...addrObject};
            na["DATA"]["CITY"] = e.target.value;
            onChange(na);
          }}
          placeholder="City"
        />
        <InlineFormControl
          type="text"
          keyId={`${keyId}_st`}
          value={addrObject.DATA.ST}
          onChange={e => {
            let na = {...addrObject};
            na["DATA"]["ST"] = e.target.value;
            onChange(na);
          }}
          placeholder="St"
        />
        <InlineFormControl
          type="text"
          keyId={`${keyId}_zip`}
          value={addrObject.DATA.ZIP}
          onChange={e => {
            let na = {...addrObject};
            na["DATA"]["ZIP"] = e.target.value;
            onChange(na);
          }}
          placeholder="12345"
        />
        <InputGroup.Text>-</InputGroup.Text>
        <InlineFormControl
          type="text"
          keyId={`${keyId}_z4`}
          value={addrObject.DATA.Z4}
          onChange={e => {
            let na = {...addrObject};
            na["DATA"]["Z4"] = e.target.value;
            onChange(na);
          }}
          placeholder="6789"
        />
      </InputGroup>
    </>
  );
}

let blankAddr = {
  "DATA": {
      "ZIP": "",
      //"LOCATION": "", // Elastic doesn't like empty values here.
      "ST": "",
      "CITY": "",
      "APT": "",
      "Z4": "",
      "LAT": "",
      "ADDR": "",
      "LONG": "",
      "COUNTY": ""
  },
  "NCOA_DATE": "",
  "TYPE": "ADDR"
}

const AddAddrButton = props => {
  let {onAdd} = props;
  return(
    <Button 
      size={props.size ? props.size : "md"}
      variant={ props.variant ? props.variant : "primary"}
      style={props.style ? props.style : null}
      className={props.className ? props.className : ""}
      onClick={e => {
        //Create blank addr object
        
        // Do something with the object via a prop
        if (onAdd) {
          onAdd(blankAddr);
        }
      }}
    >
      {props.btnText ? props.btnText : "Add Phone"}
    </Button>
  );
}

export { Addr, AddrInput, AddAddrButton, blankAddr };