import React from "react";
import { Form } from "react-bootstrap";
import withInlineEdit from "./withInlineEdit";

const InlineFormControl = props => {
  //console.log("InlineFormControl props: ", props);
  return(
    <Form.Control
      {...props}
    />
  )
}

export default withInlineEdit(InlineFormControl);