import React, { useState, useEffect } from "react";
import { Link, Redirect, NavLink } from "react-router-dom";
import {
  Navbar,
  Nav,
  NavDropdown,
  Container,
  Row,
  Col,
  Button,
  Form,
  Card,
  Badge,
  Tab,
  Offcanvas,
  useAccordionButton,
  FloatingLabel
} from "react-bootstrap";
import { Sliders } from "react-bootstrap-icons";
import logo from "./media/icon_192.png";
import listShackLogo from "./media/logo_list_shack.png";
import listShackProLogo from "./media/logo.png";
import whitelogo from "./media/list_shack_white.svg";
import darklogo from "./media/list_shack_dark.svg";
import listcrmLogoDark from "./media/list_crm_dark.svg";
import listmarketLogoDark from "./media/list_market_dark.svg";
import cards from "./media/card_logos.png";
import Dashboard from "./dashboard.jsx";
import { CompanyProfile, NotAdmin, TeamMembers, UsageTable } from "./interfaceListShackPro";
import Invoices from "./invoices";
import UpdateEmail from "./updateEmail";
import ChangePass from "./changePassword";
import { ManageRecurlyAccount } from "./recurlyCheckoutAgain";
import { CardChecklist, Search as SearchIcon, Shop, GearWideConnected, List, X } from "react-bootstrap-icons";
import MapPreview from "./mapPreview";
import {CreateNewListButton} from "./elasticlists";
import { CreateNewDatabaseButton } from "./listMarket";

// STATIC CONTENT
const TopNavWithTabs = props => {
  let { isApp } = props;
  //console.log("TopNavWithTabs: ", props);
  return(
    <>
      <Navbar
        className={ props.className ? props.className : "p-0"}
        style = { props.style ? props.style : null }
      >
        <Container fluid className="d-flex flex-row justify-content-between">
          <Nav className="justify-content-between bg-light customNavTabs pt-3 d-none d-md-flex" variant="tabs">
            {[
              {
                to: "/search",
                text: <><img src={darklogo} alt="ListShack" height="24" width="auto"/> ListShack</>
              },
              {
                to: "/listcrm",
                text: <><img src={listcrmLogoDark} alt="ListShack" height="24" width="auto"/> ListCrm</>
              },
              {
                to: "/marketplace",
                text: <><img src={listmarketLogoDark} alt="ListShack" height="24" width="auto"/> ListMarket</>
              },
              {
                to: "/downloads",
                className: "text-dark ms-5",
                text: <>Downloads</>
              },
              {
                to: "/settings",
                className: "text-dark",
                text: <><GearWideConnected /> Settings</>
              }
            ].map( (t,i) => {
              return(
                <Nav.Item key={`ni_${i}`}>
                  <NavLink className={`nav-link border text-dark bg-dark bg-opacity-10 mx-1 ${t.className ? t.className : ""}`} to={t.to} activeClassName="active" style={{whiteSpace: "nowrap"}}>{t.text}</NavLink>
                </Nav.Item>
              );
            })}
          </Nav>
          <Navbar.Toggle />
          <Navbar.Collapse className="justify-content-end">
            <UserNav {...props} className="text-dark" />
          </Navbar.Collapse>
        </Container>
      </Navbar>
      {/*<Nav className="justify-content-center bg-light customNavTabs pt-3" variant="tabs">
        {[
          {
            to: "/search",
            text: <><img src={darklogo} alt="ListShack" height="24" width="auto"/> ListShack</>
          },
          {
            to: "/listcrm",
            text: <><img src={listcrmLogoDark} alt="ListShack" height="24" width="auto"/> ListCrm</>
          },
          {
            to: "/marketplace",
            text: <><img src={listmarketLogoDark} alt="ListShack" height="24" width="auto"/> ListMarket</>
          },
          {
            to: "/downloads",
            className: "text-dark ms-5",
            text: <>Downloads</>
          },
          {
            to: "/settings",
            className: "text-dark",
            text: <><GearWideConnected /> Settings</>
          }
        ].map( (t,i) => {
          return(
            <Nav.Item key={`ni_${i}`}>
              <NavLink className={`nav-link border text-dark bg-dark bg-opacity-10 mx-1 ${t.className ? t.className : ""}`} to={t.to} activeClassName="active">{t.text}</NavLink>
            </Nav.Item>
          );
        })}
      </Nav>
      */}
    </>
  )
}

const TopnavLss = props => {
  let { isApp, style, className, hide } = props;
  //console.log("TopnavLss rendered.");
  if (hide) { return null }
  return (
    <Navbar expand="lg"
      className={ className ? className : ""}
      style={ style ? style : null }
    >
      <Container>
        <Navbar.Brand>
          <Link id="lss_nav_text" to="/" className="d-md-none">
            <img src={logo} alt="ListShack" height="75" width="auto"/>
            {isApp && <span className="text-dark ms-2" style={{fontSize: "1.6rem"}} >listshack</span>}
          </Link>
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
        <Navbar.Collapse className={isApp ? "justify-content-end" : "justify-content-between"}>
          <Nav className="">
            {!isApp && (
              <Nav.Item className="navbar-text">
                <Nav.Link href="tel:833-222-7919">
                  <i className="fa fa-phone"></i> 833-222-7919
                </Nav.Link>
              </Nav.Item>
            )}
          </Nav>
          <UserNav {...props} className="">
            <Nav.Item className="d-inline-block">
              <NavLink
                to="/settings"
                className="nav-link"
                activeClassName="activeSidebar"
              >
                <i className="fa fa-cog fa-lg" aria-hidden="true"></i>
              </NavLink>
            </Nav.Item>
            <Nav.Item className="d-inline-block">
              <NavLink
                to="/support"
                className="nav-link"
                activeClassName="activeSidebar"
              >
                <i className="fa fa-question-circle fa-lg"></i>
              </NavLink>
            </Nav.Item>
            <span id="hw" className="d-inline-block"></span>
          </UserNav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
};

const UserNav = props => {
  const {user, loaded, signOut, className, style, superAdmin} = props;
  return(
    <Nav
      className={className ? className : ""}
      style={style ? style : null}
      id="hw"
    >
      {user === null && loaded && (
        <React.Fragment>
          <Nav.Item>
            <Link to="/signup">
              <Button variant="link" style={{textDecoration: "none"}}>Signup</Button>
            </Link>
          </Nav.Item>
          <Nav.Item>
            <Link to="/login">
              <Button variant="primary">Member login</Button>
            </Link>
          </Nav.Item>
        </React.Fragment>

      )}
      {user !== null && user !== "" && loaded && (
        <React.Fragment>
          {props.children &&
            props.children
          }
          <NavDropdown id="usernav_dropdown" menuVariant="light" title={user.email} style={{ zIndex: "1031" }}
            className={"d-inline-block"}
          >
            <Link className="dropdown-item" to="/search">
              ListShack
            </Link>
            <Link className="dropdown-item" to="/listcrm">
              ListCrm
            </Link>
            {/*<Link className="dropdown-item" to="/marketplace">
              ListMarket
            </Link>*/}
            <NavDropdown.Divider />
              <Link className="dropdown-item" to="/downloads">
                Downloads
              </Link>
              <Link className="dropdown-item" to="/settings">
                Settings
              </Link>
              <Link className="dropdown-item" to="/support">
                Help
              </Link>
              {superAdmin &&
                <Link className="dropdown-item text-warning" to="/superadmin">
                  Super Admin
                </Link>
              }
            <NavDropdown.Divider />
            <NavDropdown.Item onClick={e => signOut(e, user)}>
              <i className="fa fa-sign-out" aria-hidden="true"></i>&nbsp;Sign out
            </NavDropdown.Item>
          </NavDropdown>
        </React.Fragment>
      )}
    </Nav>
  )
}

const SideBar = props => {
  let { userDoc, isApp, superAdmin } = props;
  const [sideBarIsOpen, setSideBarIsOpen] = useState(true);

  useEffect( () => {
    if (props.sideBarIsOpen) {
      setSideBarIsOpen(sideBarIsOpen);
    }
  }, [props.sideBarIsOpen]);

  let sideBarWidth = sideBarIsOpen ? "16.66%" : "75px";
  return (
    <div
      className="d-none d-md-flex flex-column justify-content-between bg-light"
      style={{
        width: sideBarWidth,
        maxHeight: "100vh",
        minWidth: sideBarIsOpen ? "280px" : ""
      }}
    >
      <div>
        <div style={{ display: "block" }}>
          <Button
            onClick={e => setSideBarIsOpen(!sideBarIsOpen)}
            variant="link"
            style={{ display: "inline-block" }}
            className="pull-right"
          >
            {!sideBarIsOpen && (
              <i className="fa fa-chevron-right" aria-hidden="true"></i>
            )}
            {sideBarIsOpen && (
              <i
                className="fa fa-chevron-left"
                aria-hidden="true"
                style={{ color: "#00000080" }}
              ></i>
            )}
          </Button>
        </div>
        {sideBarIsOpen && (
          <Nav defaultActiveKey="/search" className="flex-column mt-4 sideBar ms-3">
            <NavLink id="hw" className="lss_nav_text" style={{textDecoration: "none"}} to="/">
              <img src={logo} alt="ListShack" height="65" width="auto"/>
              {isApp && <span className="text-dark ms-2" style={{fontSize: "1.6rem", textDecoration: "none"}} >listshack</span>}
            </NavLink>
            <NavLink
              to="/search"
              className="nav-link"
              activeClassName="activeSidebar"
            >
              <i
                className="fa fa-search fa-flip-horizontal"
                aria-hidden="true"
              ></i>&nbsp;Search
            </NavLink>
            <NavLink
              to="/search/consumers"
              className="nav-link ms-3 sub-nav"
              activeClassName="activeSidebar"
            >
              Search consumers
            </NavLink>
            <NavLink
              to="/search/businesses"
              className="nav-link ms-3 sub-nav"
              activeClassName="activeSidebar"
            >
              Search businesses
            </NavLink>
            <NavLink
              to="/emails"
              className="nav-link ms-3 sub-nav"
              activeClassName="activeSidebar"
            >
              Search emails{" "}
              <Badge variant="light" pill>
                <i>Beta</i>
              </Badge>
            </NavLink>
            <NavLink
              to="/maps"
              className="nav-link"
              activeClassName="activeSidebar"
            >
              <i
                className="fa fa-map-marker"
                aria-hidden="true"
              ></i>{" "}
              Maps
              <Badge variant="success" size="sm" className="ms-1" pill>
                <i>New</i>
              </Badge>
              </NavLink>
            <NavLink
              to="/maps/consumers"
              className="nav-link ms-3 sub-nav"
              activeClassName="activeSidebar"
            >
              Consumer maps
            </NavLink>
            <NavLink
              to="/maps/businesses"
              className="nav-link ms-3 sub-nav"
              activeClassName="activeSidebar"
            >
              Business maps
            </NavLink>
            {/*
            <NavLink
              to="/archives"
              className="nav-link"
              activeClassName="activeSidebar"
            >
              <i className="fa fa-database" aria-hidden="true"></i> Archives <Badge variant="info">New</Badge>
            </NavLink>
            */}
            <NavLink
              to="/searches"
              className="nav-link"
              activeClassName="activeSidebar"
            >
              <i className="fa fa-bookmark-o" aria-hidden="true"></i> Saved
              searches
            </NavLink>
            <NavLink
              to="/downloads"
              className="nav-link"
              activeClassName="activeSidebar"
            >
              <i
                className="fa fa-cloud-download fa-flip-horizontal"
                aria-hidden="true"
              ></i>{" "}
              Downloads
            </NavLink>
            {superAdmin &&
            <React.Fragment>
              <NavLink
                to="/superadmin"
                className="nav-link text-warning"
                activeClassName="activeSidebar"
              >
                <i className="fa fa-eye-slash"></i>&nbsp;Super Admin
              </NavLink>
              <NavLink
                to="/crm"
                className="nav-link text-warning"
                activeClassName="activeSidebar"
              >
                <CardChecklist />&nbsp;CRM
              </NavLink>
              <NavLink
                to="/elastic-lists"
                className="nav-link text-warning"
                activeClassName="activeSidebar"
              >
                <CardChecklist />&nbsp;Elastic Lists
              </NavLink>
            </React.Fragment>
            }
          </Nav>
        )}
        {!sideBarIsOpen && (
          <Nav
            defaultActiveKey="/search"
            className="flex-column justify-content-between align-items-center mt-4 sideBar sideBarClosed"
            style={{minHeight: "400px"}}
          >
            <NavLink className="lss_nav_text text-center mt-3" to="/">
              <img src={logo} alt="ListShack" height="40px" width="auto"/>
            </NavLink>
            <div id="hw" style={{width: "50px", height: "42px", textAlign: "center"}}></div>
            <NavLink
              to="/search"
              className="nav-link"
              activeClassName="activeSidebar"
            >
              <i
                className="fa fa-search fa-flip-horizontal"
                aria-hidden="true"
              ></i>
            </NavLink>
            <NavLink
              to="/maps"
              className="nav-link"
              activeClassName="activeSidebar"
            >
              <i
                className="fa fa-map-marker"
                aria-hidden="true"
              ></i>
              </NavLink>
            <NavLink
              to="/searches"
              className="nav-link"
              activeClassName="activeSidebar"
            >
              <i className="fa fa-bookmark-o" aria-hidden="true"></i>
            </NavLink>
            <NavLink
              to="/downloads"
              className="nav-link"
              activeClassName="activeSidebar"
            >
              <i
                className="fa fa-cloud-download fa-flip-horizontal"
                aria-hidden="true"
              ></i>
            </NavLink>
            {superAdmin &&
            <React.Fragment>
              <NavLink
                to="/superadmin"
                className="nav-link text-warning"
                activeClassName="activeSidebar"
              >
                <i className="fa fa-eye-slash"></i>
              </NavLink>
              <NavLink
                to="/crm"
                className="nav-link text-warning"
                activeClassName="activeSidebar"
              >
                <CardChecklist />
              </NavLink>
              <NavLink
                to="/elastic-lists"
                className="nav-link text-warning"
                activeClassName="activeSidebar"
              >
                <CardChecklist />
              </NavLink>
            </React.Fragment>
              
            }
          </Nav>
        )}
      </div>
      {userDoc && sideBarIsOpen &&
        <Dashboard userDoc={userDoc} />
      }
    </div>
  );
};

const SecondnavLss = props => {
  return (
    <Navbar
      expand="lg"
      variant="dark"
      className="bg-dark"
      style={{ height: "60px" }}
      id="nav2"
    >
      <Container>
        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
        <Navbar.Collapse>
          <Nav.Item>
            <Link className="nav-link" to="/">
              Our Brands
            </Link>
          </Nav.Item>
          <Nav.Item>
            <Link className="nav-link" to="/support">
              Help
            </Link>
          </Nav.Item>
          <Nav.Item>
            <Link className="nav-link" to="/login">
              Login
            </Link>
          </Nav.Item>
          <Nav.Item>
            <Link className="nav-link" to="/signup">
              Signup
            </Link>
          </Nav.Item>
          <Nav.Item>
            <Link className="nav-link" to="/contact">
              Contact Us
            </Link>
          </Nav.Item>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
};

const HomeLss = props => {
  let { user, handleState } = props;
  const setLayout = () => {
    if (handleState) {
      handleState({
        noDistract: false,
        isApp: false
      });
    }
  }
  useEffect( () => {
    setLayout();
    return setLayout
  }, [handleState])

  if (user !== null) {
    return <Redirect to="/search" />;
  } else {
    return (
      <Container>
        <Row xs={1} sm={2} md={3}>
          <Col className="p-3">
            <Card>
              <div className="d-flex flex-row justify-content-center align-items-center p-3">
                <img src={listShackLogo}
                  height="65"
                  width="auto"
                />
              </div>
              <Card.Body>
                <Card.Text>
                  The classic ListShack, $50 per month for more leads that you can dial. The inspiration for the current individual unlimited plan.  Used and loved by thousands.
                </Card.Text>
              </Card.Body>
              <Card.Footer>
                <a
                  href="https://www.listshack.com"
                  className="btn btn-outline-primary pull-right"
                >
                  Learn more
                </a>
              </Card.Footer>
            </Card>
          </Col>
          <Col className="p-3">
            <Card>
              <div className="d-flex flex-row justify-content-center align-items-center bg-dark p-3">
                <img src={logo}
                  height="65"
                  width="65"
                />
                <span
                  style={{
                    fontSize: "1.3rem"
                  }}
                  className="ms-2 text-white"
                >
                  ListShack Desktop
                </span>
              </div>

              <Card.Body>
                <Card.Text>
                  The Desktop app brings all that you love about ListShack right to your desktop computer. Fewer password resets. Less confusion about where to login.
                </Card.Text>
              </Card.Body>
              <Card.Footer>
                <Link
                  to="/listshack_desktop"
                  className="btn btn-outline-primary pull-right"
                >
                  Learn more
                </Link>
              </Card.Footer>
            </Card>
          </Col>
          <Col className="p-3">
            <Card>
              <div className="d-flex flex-row justify-content-center align-items-center p-3">
                <img src={listShackProLogo}
                  height="65"
                  width="auto"
                />
              </div>
              <Card.Body>
                <Card.Text>
                  Everything you need in a list broker without the phone tag. Plans for the smallest companies at $50 per month.  Plans for the largest enterprise with unlimited leads and unlimted team members.
                </Card.Text>
              </Card.Body>
              <Card.Footer>
                <a
                  href="https://www.listshackpro.com"
                  className="btn btn-outline-primary pull-right"
                >
                  Learn more
                </a>
              </Card.Footer>
            </Card>
          </Col>
        </Row>
        <Row
          className="text-center text-white d-flex justify-content-center align-items-middle"
          style={{
            height: "30vh",
          }}
        >
          <Container
            className="d-flex justify-content-center align-items-middle flex-column text-dark p-5 mb-4 rounded-3"
            fluid
          >
            <h4>One subscription</h4>
            <p className="lead">
              Everything that you need
            </p>
            <div className="mb-3">
              <Link className="btn btn-primary btn-lg" to="/signup">
                Signup Now
              </Link>
            </div>
          </Container>
        </Row>
      </Container>
    );
  }
};

const Footer = props => {
  let { appInterface } = props;
  return (
    <footer id="footer" className="bg-dark" style={{ padding: "50px 0px" }}>
      <Container>
        <Row>
          <Col>
            <a
              className="btn btn-link text-white"
              href={`mailto: ${process.env.REACT_APP_contact_email}`}
              style={{ paddingLeft: "0px", textDecoration: "none" }}
            >
              <i className="fa fa-envelope"></i>{" "}
              {process.env.REACT_APP_contact_email}
            </a>
            <br />
            <a
              className="btn btn-link text-white"
              style={{ paddingLeft: "0px", textDecoration: "none" }}
              href="tel: 833-222-7919"
            >
              <i className="fa fa-phone"></i> 833-222-7919
            </a>
            <p className="text-white">
              <Link className="text-white" to="/privacy" style={{textDecoration: "none"}}>
                Privacy policy
              </Link>{" "}
              |{" "}
              <Link className="text-white" to="/terms" style={{textDecoration: "none"}}>
                Terms of service
              </Link>{" "}
              |{" "}
              <Link className="text-white" to="/ccpa" style={{textDecoration: "none"}}>
                CCPA
              </Link>
            </p>
            <a
              className="text-white"
              href="/sitemapLss.txt"
              style={{textDecoration: "none"}}
            >
              Site map
            </a>
          </Col>
          <Col>
            <p className="text-white">All major credit cards accepted</p>
            <img
              alt="Visa, Discover, MasterCard, and American Express"
              src={cards}
              width="300"
              height="auto"
            />
            <hr />
            <Link to="/ccpa_optout" className="text-white" style={{textDecoration: "none"}}>
              <small>Do Not Sell My Personal Information</small>
            </Link>
          </Col>
        </Row>
      </Container>
    </footer>
  );
};

const FooterSmall = () => {
  return (
    <footer className="text-center" style={{ padding: "20px 0px" }}>
      <Container>
        <Row>
          <Col>
            <p
              style={{
                marginBottom: "0px",
                fontSize: ".8em",
                color: "#00000080"
              }}
            >
              <Link to="/privacy" style={{ color: "#00000080" }}>
                Privacy policy
              </Link>
              &nbsp;&#183;&nbsp;
              <Link to="/terms" style={{ color: "#00000080" }}>
                Terms of service
              </Link>
            </p>
          </Col>
        </Row>
      </Container>
    </footer>
  );
};

const settingsPanes = [
  {
    id: "profile",
    name: "Company Profile",
    admin: true,
    activeKey: "sixth",
    content: props => {
      const {
        userDoc,
        apiKey,
        user,
        handleAlerts,
        isCancelled,
        isAdmin,
        handleModalShow
      } = props;
      return (
        <Tab.Pane eventKey="first">
          {!isCancelled && isAdmin && (
            <CompanyProfile
              heading={<h3 className="mt-3">Company profile</h3>}
              userDoc={userDoc}
              handleAlerts={handleAlerts}
              user={user}
              apiKey={apiKey}
            />
          )}
          {isCancelled && isAdmin && (
            <div className="mt-3">
              <h3>Company profile</h3>
              <p className="lead text-danger">
                Your subscription is no longer active. Please update your
                subscription to view and edit your company profile.
              </p>
              <Button onClick={handleModalShow}>Subscribe now</Button>
            </div>
          )}
          {!isAdmin && <NotAdmin {...props} />}
        </Tab.Pane>
      );
    }
  },
  {
    id: "team",
    name: "Team members",
    admin: true,
    activeKey: "seventh",
    content: props => {
      const {
        userDoc,
        apiKey,
        handleAlerts,
        handleModalShow,
        userPlan,
        isCancelled,
        isAdmin
      } = props;
      return (
        <Tab.Pane eventKey="first">
          {!isCancelled && isAdmin && (
            <TeamMembers
              heading={
                <div className="mt-3">
                  <h3>Team members</h3>
                  <p className="lead text-muted">
                    Add colleagues and employees
                  </p>
                </div>
              }
              userDoc={userDoc}
              handleAlerts={handleAlerts}
              plan={userPlan}
              handleModalShow={handleModalShow}
              apiKey={apiKey}
            />
          )}
          {isCancelled && isAdmin && (
            <div className="mt-3">
              <h3>Team members</h3>
              <p className="lead text-muted">Add colleagues and employees</p>
              <p className="lead text-danger">
                Your subscription is no longer active. Please update your
                subscription to view and edit team members.
              </p>
              <Button onClick={handleModalShow}>Subscribe now</Button>
            </div>
          )}
          {!isAdmin && <NotAdmin {...props} />}
        </Tab.Pane>
      );
    }
  },
  {
    id: "billing",
    name: "Billing & Payment",
    admin: true,
    activeKey: "third",
    content: props => {
      //console.log("Billing props: ", props );
      const { isAdmin } = props;
      return (
        <Tab.Pane eventKey="first">
          {isAdmin && (
            <React.Fragment>
              <h3>Billing &amp; payment</h3>
              <ManageRecurlyAccount {...props} />
            </React.Fragment>
          )}
          {!isAdmin && <NotAdmin {...props} />}
        </Tab.Pane>
      );
    }
  },
  {
    id: "invoices",
    name: "Invoices",
    activeKey: "fifth",
    admin: true,
    content: props => {
      return (
        <Tab.Pane eventKey="first">
          {props.isAdmin && <Invoices {...props} />}
          {!props.isAdmin && <NotAdmin {...props} />}
        </Tab.Pane>
      );
    }
  },
  {
    id: "usage",
    name: "Usage",
    activeKey: "second",
    content: props => {
      const { isCancelled, handleModalShow } = props;
      return (
        <Tab.Pane eventKey="first">
          <h3>Usage</h3>
          {!isCancelled && <UsageTable {...props} />}
          {isCancelled && (
            <React.Fragment>
              <p className="lead text-danger">
                Your subscription is no longer active. Update your susbscription
                to view account usage information.
              </p>
              <Button onClick={handleModalShow}>Subscribe now</Button>
            </React.Fragment>
          )}
        </Tab.Pane>
      );
    }
  },
  {
    id: "update_email",
    name: "Update email",
    activeKey: "fourth",
    content: props => {
      return (
        <Tab.Pane eventKey="first">
          <UpdateEmail {...props} />
        </Tab.Pane>
      );
    }
  },
  {
    id: "change_password",
    name: "Change password",
    activeKey: "first",
    content: props => {
      const { user, handleAlerts } = props;
      return (
        <Tab.Pane eventKey="first">
          <ChangePass user={user} handleAlerts={handleAlerts} />
        </Tab.Pane>
      );
    }
  }
];

const OffCanvasSideBar = props => {
  const [show, setShow] = useState(true);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <>
      <Button variant="link" onClick={handleShow} className="pull-left text-muted">
        {!show &&
          <List size={30} />
        }
        {show &&
          <X />
        }
      </Button>

      <Offcanvas show={show} onHide={handleClose}>
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>Offcanvas</Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          Some text as placeholder. In real life you can have the elements you
          have chosen. Like, text, images, lists, etc.
        </Offcanvas.Body>
      </Offcanvas>
    </>
  );
}

const SearchNavbar = props => {
  return(
    <Nav className="flex-column mt-4 sideBar d-none d-md-block border-end">
      <NavLink
        to="/search"
        className="nav-link"
        activeClassName="activeSidebar"
      >
        <i
          className="fa fa-search fa-flip-horizontal"
          aria-hidden="true"
        ></i>&nbsp;Search
      </NavLink>
      <NavLink
        to="/search/consumers"
        className="nav-link ms-3 sub-nav"
        activeClassName="activeSidebar"
      >
        Search consumers
      </NavLink>
      <NavLink
        to="/search/businesses"
        className="nav-link ms-3 sub-nav"
        activeClassName="activeSidebar"
      >
        Search businesses
      </NavLink>
      <NavLink
        to="/search/emails"
        className="nav-link ms-3 sub-nav"
        activeClassName="activeSidebar"
      >
        Search emails{" "}
        <Badge bg="light" pill>
          <i className="text-dark">Beta</i>
        </Badge>
      </NavLink>
      <NavLink
        to="/search/maps"
        className="nav-link"
        activeClassName="activeSidebar"
      >
        <i
          className="fa fa-map-marker"
          aria-hidden="true"
        ></i>{" "}
        Maps
        <Badge bg="success" size="sm" className="ms-1" pill>
          <i>New</i>
        </Badge>
        </NavLink>
      <NavLink
        to="/search/maps/consumers"
        className="nav-link ms-3 sub-nav"
        activeClassName="activeSidebar"
      >
        Consumer maps
      </NavLink>
      <NavLink
        to="/search/maps/businesses"
        className="nav-link ms-3 sub-nav"
        activeClassName="activeSidebar"
      >
        Business maps
      </NavLink>
      <NavLink
        to="/searches"
        className="nav-link"
        activeClassName="activeSidebar"
      >
        <i className="fa fa-bookmark-o" aria-hidden="true"></i> Saved
        searches
      </NavLink>
      <NavLink
        to="/settings/usage"
        className="nav-link mt-5"
        activeClassName="activeSidebar"
      >
        <Sliders /> Usage & Limits
      </NavLink>
      <Dashboard {...props} 
        type="horizontalBar" 
        hidePie={true} 
        showAccountStats={true} 
        className="ps-4 pe-5" 
        borderless={true} 
        hover={true}
      />
    </Nav>
  );
}

const Search = (props) => {
  return (
    <SearchLayout {...props}>
      <Row
        style={{
          marginTop: "30px",
          minHeight: "70vh"
        }}
        className="text-center d-flex align-items-center justify-content-start"
        sm={1}
        md={2}
      >
        <Col md={12} className={`d-flex flex-column justify-content-between align-items-middle`} style={{minHeight: "300px"}}>
          <h6 className="text-uppercase text-muted">Geography & Filters</h6>
          <div>
            <h3>
              What type of leads would you like?
            </h3>
            <div style={{ marginTop: "40px", marginBottom: "80px" }}>
              <Link to="/search/businesses" className="btn btn-lg btn-primary d-sm-block d-md-inline-block">
                Business leads
              </Link>
              &nbsp; &nbsp; &nbsp;
              <Link to="/search/consumers" className="btn btn-lg btn-primary d-sm-block d-md-inline-block">
                Consumer leads
              </Link>
            </div>
          </div>
          <div className="text-muted"><small>Select a Geography and add Filters one by one.</small></div>
        </Col>
        {
          [
            //{
            //  title: "Directory",
            //  content: <>
            //    <Link 
            //      to="/search/directory" 
            //      style={{textDecoration: "none"}} 
            //    >
            //      <Form.Control className="text-center" style={{maxWidth: "400px", margin: "0 auto"}} size="lg" type="text" placeholder="Type to search" />
            //    </Link>
            //  </>,
            //  helpText: "Search like Google or Bing for people and businesses by name."
            //},
            {
              title: "Map",
              content: <>
                <Link 
                  style={{textDecoration: "none"}} 
                  to="/search/maps"
                  className="text-center d-flex flex-column justify-content-center align-items-center"
                >
                  <MapPreview width="300" height="150">
                    <Button variant="light">Search on Map</Button>
                  </MapPreview>
                </Link>
              </>,
              helpText: "Search just like Google Maps."
            }
          ].map( (s,i) => {
            return(
              <Col key={`${s.title}_${i}`} xs={12} md={{span: 6, offset: 3}} className={`mt-5 h-100 ${s.className ? s.className : ""}`}>
                <Card className="bg-light h-100 p-3 justify-content-between align-items-middle" style={{minHeight: "250px"}}>
                  <h6 className="text-uppercase text-muted">{s.title}</h6>
                  <div>
                    {s.content}
                  </div>
                  <div className="text-muted"><small>{s.helpText}</small></div>
                </Card>
              </Col>
            )
          })
        }
        
      </Row>
    </SearchLayout>
  );
};

const SearchLayout = props => {
  const { handleState } = props;
  useEffect( () => {
    handleState({
      noDistract: true,
      isApp: true
    })
  }, [handleState]);
  return(
    <Container fluid>
      <Row className="justify-content-center">
        <Col xs="auto">
          <SearchNavbar {...props} />
        </Col>
        <Col xs={props.xs ? props.xs : ""} sm={props.sm ? props.sm : ""} md={props.md ? props.md : ""} lg={props.lg ? props.lg : ""} xl={props.xl ? props.xl : ""}>
          {props.children ? props.children : null}
        </Col>
      </Row>
    </Container>
  )
}

const AccordionToggle = props => {
  let {eventKey, callback} = props;
  const decoratedOnClick = useAccordionButton(eventKey, callback);
  
  return(
    <div className={props.className ? props.className : ""} style={props.style ? props.style : null}
      onClick={decoratedOnClick}
    >
      {props.children}
    </div>
  )
}

const ListCrmNavbar = props => {

  return(
    <Nav className={props.className ? props.className : "flex-column mt-5 sideBar border-end"}>
      <Nav.Item>
        <CreateNewListButton 
          {...props}        
        />
      </Nav.Item>
      
      <NavLink
        to="/listcrm/all"
        className="nav-link mt-md-5"
      >
        View All Records
      </NavLink>
      <NavLink
        to="/listcrm/archived"
        className="nav-link"
      >
        Archived Records
      </NavLink>
    </Nav>
  );
}

const ListCrm = props => {
  return(
    <Container fluid>
      <Row className="justify-content-center">
        <Col xs="auto">
          <ListCrmNavbar {...props} className="flex-row-reverse flex-md-column justify-content-start mt-2 mt-md-5 sideBar border-end pe-0 pe-md-5" />
        </Col>
        <Col>
          {props.children}
        </Col>
      </Row>
    </Container>
  )
}

const ListMarketNavbar = props => {

  return(
    <Nav className={props.className ? props.className : "flex-column mt-5 sideBar border-end"}>
      <Nav.Item>
        <CreateNewDatabaseButton 
          {...props}        
        />
      </Nav.Item>
      
      <NavLink
        to="/marketplace"
        className="nav-link mt-md-5"
      >
        View All Databases
      </NavLink>
      <NavLink
        to="/marketplace?type=consumer"
        className="nav-link"
      >
        Consumer Databases
      </NavLink>
      <NavLink
        to="/marketplace?type=business"
        className="nav-link"
      >
        Business Databases
      </NavLink>
      <NavLink
        to="/marketplace?use=email"
        className="nav-link"
      >
        Email Databases
      </NavLink>
      <NavLink
        to="/marketplace?use=telemarketing"
        className="nav-link"
      >
        Telemarketing Databases
      </NavLink>
      <NavLink
        to="/marketplace?use=mail"
        className="nav-link"
      >
        Direct Mail Databases
      </NavLink>
    </Nav>
  );
}

const ListMarketLayout = props => {
  return(
    <Container fluid>
      <Row className="justify-content-center">
        <Col xs="auto">
          <ListMarketNavbar {...props} className="flex-row-reverse flex-md-column justify-content-start mt-2 mt-md-5 sideBar border-end pe-0 pe-md-5" />
        </Col>
        <Col>
          {props.children}
        </Col>
      </Row>
    </Container>
  )
}

export { TopnavLss, SecondnavLss, HomeLss, UserNav, SideBar, Footer, FooterSmall, settingsPanes, TopNavWithTabs, Search, AccordionToggle, ListCrm, SearchLayout, ListMarketLayout };
