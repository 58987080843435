import {functions, storeMedia, db} from "./firebase.jsx";
import PapaParse from 'papaparse';

// creates a url suitable for fetching append data
  // Takes an api url string for a particular database that looks like:
  // "https://listshack.apps.dreamfactory.com/api/v2/businesses/_table/bizOct2017?"
  // Returns a fully formatted url that looks like:
  // <Add An Example Here>
  // Also requires a config object shaped like:
  // {
  //    queryFields: { //object that includes all the fields available for download and whether it should be included
  //      FN: false,
  //      LN: false,
  //      EMAIL: true,
  //      PHONE: true,
  //    },
  //    appendColumn: PHONE, //The actual column name to be used as the key
  //    appendInput: "####", // If submitting for processing, use the "####" placeholder, if for a preview, include the actual input value to search for in the database
  //    appendObject: {
  //      column1: input1,
  //      column2: input2,
  //      ...
  //    }, // an object that allows you to include multiple appendColumns and inputs for lookup
  //  }
  const createAppendUrl = (apiUrl, config) => {
    let fields = [];
    for (let colKey in config.queryFields) {
      if (config.queryFields[colKey]) {
        fields.push(colKey);
      }
    }
    let fieldString = `fields=${fields.join(",")}`;
    let filterString;
    if (config.appendObject) {
      let filtersArray = [];
      for (let filterKey of Object.keys(config.appendObject)) {
        //console.log(filterKey, filterKey, config.appendObject[filterKey], config.appendObject[filterKey])
        let fInput = config.appendObject[filterKey];
        // don't query for things if there's an empty cell
        if (fInput === "") {
          continue;
        }
        if (filterKey === "ADDR") {
          //console.log(`(${filterKey} like "${fInput.split(" ").slice(0, 2).join(" ")}%")`);
          filtersArray.push(`(${filterKey} like "${fInput.split(" ").slice(0, 2).join(" ")}%")`);
        } else if (filterKey === "FN") {
          //console.log(`(${filterKey} like "${fInput.substring(1, 0)}%")`);
          filtersArray.push(`(${filterKey} like "${fInput.substring(1, 0)}%")`);
        } else {
          //console.log(`(${filterKey}="${fInput}")`);
          filtersArray.push(`(${filterKey}="${fInput}")`);
        }
      }
      filterString = `filter=${filtersArray.join(" and ")}`
    } else {
      filterString = `filter=(${config.appendColumn}="${config.appendInput}")`;
    }
    //console.log("filterString: ", filterString);
    let requestString = `${apiUrl}${fieldString}&${filterString}`;
    let appendUrl = encodeURI(requestString);
    return appendUrl
  }

// Append a csv file
// Takes a queryUrl and configuration object
// Returns a firebase document id
// config = {
//    searchName: "Some string", // just a string used for the file name
//    leadsRemaining: 10000,  // This is the number leads the user has available before the append
//    queryUrl: queryUrl,  // This is a query URL to a dreamfactory or api endpoint used to fetch data
//    arrayOfInputs: [["a","b","c"], ["d","e","f"]], // The inputs from the user, an array of arrays
//    fileOfInputs: "url/to/a/csv/file", // The inputs from the user, a csv file
//    lookupIndex: 1, // The column index number used to lookup data
//    leadsMultiplier: 1, // The leads multiplier allows you to give appends for free (0) or 1 lead per append (1), or 2 leads per append (2), etc.
// }

// Fetches an append record from the dreamfactory api
const fetchAppendRecord = async (url) => {
  //console.log("url: ", url);

  const init = {
    headers: {
      authorization: `Bearer ${process.env.REACT_APP_api_token}`,
      method: "GET"
    },
    "accept": "application/json",
    "Transfer-Encoding": "chunked",
    cache: "default",
  }

  let response = await fetch(url, init);
  //console.log("response: ", response)
  let data = await response.json();
  //console.log("data.resource: ", data.resource);
  let records = [];
  for (let r of data.resource) {
    records.push(r);
  }
  return await records
}

const appendCsv = async (queryUrl, config) => {
  const appendCsv = functions.httpsCallable("appendCsv");
  let csvParams = await {
    searchName: config.searchName,
    leadsRemaining: config.leadsRemaining,
    queryUrl: queryUrl,
    arrayOfInputs: config.arrayOfInputs,
    fileOfInputs: config.fileOfInputs,
    fileOfInputsLocation: config.fileOfInputsLocation,
    lookupIndex: config.lookupIndex,
    leadsMultiplier: config.leadsMultiplier,
  };
  console.log("csvParams: ", csvParams);
  try{
    let appendDocId = await appendCsv(csvParams);
    await console.log(appendDocId);
    return await appendDocId
  } catch(err) {
    console.log("Error with appendCSv: ", err);
    return err
  }
}

const saveAppend = async (jsonData, config, downloadUrlCallback) => {
  let fileName = `${config.searchName.replace(/\s/g, "_").replace(/\//g, "-")}_${Math.round(new Date().getTime() / 1000)}.csv`;
  //console.log("fileName: ", fileName);
  try{
    let blob = new Blob([jsonData], {type: "text/csv"});
    let newFile = new File([blob], fileName, {
      type: "text/csv"
    })
    let downloadUrl;
    await storeMedia(
      `downloads/${config.uid}/${fileName}`,
      newFile,
      () => {}, // progressCallback
      (url) => {downloadUrlCallback(url)}, // downloadUrlCallback
      (metadata) => {console.log(metadata)} // metadataCallback
    )
  } catch(err) {
    console.log("Something went wrong saving the append: ", err);
  }
}

const formatJsonData = (jsonObject) => {
  const incomeDict = {
    "A": "Under $10K",
    "B": "$10K-$14K",
    "C": "$15K-$19K",
    "D": "$20K-$24K",
    "E": "$25K-$29K",
    "F": "$30K-$34K",
    "G": "$35K-$39K",
    "H": "$40K-$44K",
    "I": "$45K-$49K",
    "J": "$50K-$54K",
    "K": "$55K-$59K",
    "L": "$60K-$64K",
    "M": "$65K-$74K",
    "N": "$75K-$99K",
    "O": "$100K-$149K",
    "P": "$150K-$174K",
    "Q": "$175K-$199K",
    "R": "$200K-$249K",
    "S": "$250K+",
    "U": "Unknown",
  };
  if (jsonObject.HH_INCOME) {
    let curVal = jsonObject.HH_INCOME;
    let newVal = incomeDict[curVal];
    jsonObject.HH_INCOME = newVal;
  }
  return jsonObject
}

const formatState = (input, abrev=true, fips=false, full_name=false) => {
  const dict = [
    {abrev: "AK", name: "Alaska", fips:'2' },
    {abrev: "AL", name: "Alabama", fips:'1' },
    {abrev: "AR", name: "Arkansas", fips:'5' },
    {abrev: "AZ", name: "Arizona", fips:'4' },
    {abrev: "CA", name: "California", fips:'6' },
    {abrev: "CO", name: "Colorado", fips:'8' },
    {abrev: "CT", name: "Connecticut", fips:'9' },
    {abrev: "DC", name: "District", fips:'11' },
    {abrev: "DE", name: "Delaware", fips:'10' },
    {abrev: "FL", name: "Florida", fips:'12' },
    {abrev: "GA", name: "Georgia", fips:'13' },
    {abrev: "HI", name: "Hawaii", fips:'15' },
    {abrev: "IA", name: "Iowa", fips:'19' },
    {abrev: "ID", name: "Idaho", fips:'16' },
    {abrev: "IL", name: "Illinois", fips:'17' },
    {abrev: "IN", name: "Indiana", fips:'18' },
    {abrev: "KS", name: "Kansas", fips:'20' },
    {abrev: "KY", name: "Kentucky", fips:'21' },
    {abrev: "LA", name: "Louisiana", fips:'22' },
    {abrev: "MA", name: "Massachusetts", fips:'25' },
    {abrev: "MD", name: "Maryland", fips:'24' },
    {abrev: "ME", name: "Maine", fips:'23' },
    {abrev: "MI", name: "Michigan", fips:'26' },
    {abrev: "MN", name: "Minnesota", fips:'27' },
    {abrev: "MO", name: "Missouri", fips:'29' },
    {abrev: "MS", name: "Mississippi", fips:'28' },
    {abrev: "MT", name: "Montana", fips:'30' },
    {abrev: "NC", name: "North Carolina", fips:'37' },
    {abrev: "ND", name: "North Dakota", fips:'38' },
    {abrev: "NE", name: "Nebraska", fips:'31' },
    {abrev: "NH", name: "New Hampshire", fips:'33' },
    {abrev: "NJ", name: "New Jersey", fips:'34' },
    {abrev: "NM", name: "New Mexico", fips:'35' },
    {abrev: "NV", name: "Nevada", fips:'32' },
    {abrev: "NY", name: "New York", fips:'36' },
    {abrev: "OH", name: "Ohio", fips:'39' },
    {abrev: "OK", name: "Oklahoma", fips:'40' },
    {abrev: "OR", name: "Oregon", fips:'41' },
    {abrev: "PA", name: "Pennsylvania", fips:'42' },
    {abrev: "RI", name: "Rhode Island", fips:'44' },
    {abrev: "SC", name: "South Carolina", fips:'45' },
    {abrev: "SD", name: "South Dakota", fips:'46' },
    {abrev: "TN", name: "Tennessee", fips:'47' },
    {abrev: "TX", name: "Texas", fips:'48' },
    {abrev: "UT", name: "Utah", fips:'49' },
    {abrev: "VA", name: "Virginia", fips:'51' },
    {abrev: "VT", name: "Vermont", fips:'50' },
    {abrev: "WA", name: "Washington", fips:'53' },
    {abrev: "WI", name: "Wisconsin", fips:'55' },
    {abrev: "WV", name: "West Virginia", fips:'54' },
    {abrev: "WY", name: "Wyoming", fips:'56' },
  ]

  let obj;
  if (abrev && input.length > 2) {
    obj = dict.find(o => o.name === input);
    return obj.abrev
  } else if(fips && input.length > 2) {
    obj = dict.find(o => o.name === input);
    return obj.fips
  } else if (full_name) {
    obj = dict.find(o => o.abrev === input)
    return obj.name
  }
}

// Creates a file append on the users local machine
// Takes a file and a configuration object
// config = {
//    queryFields: { //object that includes all the fields available for download and whether it should be included
//      FN: false,
//      LN: false,
//      EMAIL: true,
//      PHONE: true,
//    },
//    appendType: ["PHONE"],
//    lookupIndex: [10],
//    searchName: "some name",
//    uid: "someUid",
//
// }
const createAppendLocally = async(file, config, completeCallback, progressCallback) => {
  console.log("config: ", config)
  console.log(file.size)

  let appendedFileObject = [];
  let appendCount = 0;
  let startCount = 0;
  let finishCount = 0;
  let totalCursor = 0;
  let paused = false;


  function queueMicrotask(fn) {
    // assuming node.
    process.nextTick(fn);
    // if we have promises
    // Promise.resolve().then(() => { fn(); });
    // or we can use setTimeout
    // setTimeout(() => { fn(); }, 0);
  }

  async function stepAsync(results, parser, continueCallback, completeCallback, progressCallback) {
    startCount += 1;
    console.log("Row data: ", results.data);
    let row = results.data;
    if (startCount === 1) {
      // push the column headings into the file
      let appendedColumns = [];
      for (let key of Object.keys(config.queryFields)) {
        if (config.queryFields[key]) {
          appendedColumns.push(key);
        }
      }
      let headerRow = row.concat(appendedColumns);
      appendedFileObject.push(headerRow);
      finishCount += 1;
      continueCallback();
    }
    if (startCount > 1 && (row.length > 1 || row[0] !== "")) {

      // create a url for fetching data
      let appendObject = {};
      for (let [i, column] of config.appendType.entries()) {
        //console.log("column: ", column);
        //console.log("input[this.state.lookupIndex[i]]", row[config.lookupIndex[i]])
        //let input = column === "ST" ? formatState(row[config.lookupIndex[i]]) : row[config.lookupIndex[i]];

        appendObject[column] = row[config.lookupIndex[i]]
      }
      console.log("appendObject: ", appendObject);

      let url = createAppendUrl(config.apiUrl, {
        queryFields: config.queryFields,
        appendObject: appendObject
      });
      //console.log("url: ", url);
      // fetch the data from the database
      let records = await fetchAppendRecord(url);
      //console.log("records: ", records);
      if (records.length > 0) {
        // append the new data to the row data
        records.map((record) => {
          //console.log("record: ", record);
          formatJsonData(record);
          //console.log("Object.values(record): ", Object.values(record));
          let appendedRow = row.concat(Object.values(record));
          // save the appended records to a local variable
          appendedFileObject.push(appendedRow);
        })
        appendCount += 1;
      } else{
        // just push the row data into the new file object as a placeholder
        appendedFileObject.push(row);
      }

      console.log("appendedFileObject: ", appendedFileObject);
      console.log("Row errors: ", results.errors);
      console.log("Row metadata: ", results.meta);

      finishCount += 1;
      console.log("startCount: ", startCount, "finishCount: ", finishCount, "results.meta.cursor: ", results.meta.cursor, "totalCursor: ", totalCursor, "file.size: ", file.size);
      totalCursor += results.meta.cursor;
      //if (results.meta.cursor === file.size) {
      //  let parsedFile = PapaParse.unparse(appendedFileObject);
      //  console.log("Done appending file: ", parsedFile);
      //  completeCallback(parsedFile, appendCount);
      //}
      progressCallback(parseInt(totalCursor/file.size * 100));
      continueCallback()
    }
  }
  // Parse the file
  try {
    let appendedCsv = await PapaParse.parse(file, {
      step: async (results, parser) => {
        //paused = startCount % 2 == 0;
        //console.log("paused: ", paused);
        parser.pause()
        stepAsync(results, parser, (err) => {
          if (err) {
            console.log("Something went wrong with the step function: ", err);
            return
          }
          queueMicrotask(() => { parser.resume(); })
        },completeCallback, progressCallback)
      },
      complete: () => {
        let parsedFile = PapaParse.unparse(appendedFileObject);
        console.log("Done appending file: ", parsedFile);
        completeCallback(parsedFile, appendCount);
      }
    });
  } catch (err) {
    console.log("Something went wrong creating the append locally: ", err);
  }
  // Send the appended data to a cloud function for recording
  //TO DO: saveAppend(jsonData, config)
}

const previewCsv = (params, callback) => {
  let {file, transformHeader} = params;
  PapaParse.parse(file, {
    header: true,
    dynamicTyping: false,
    transformHeader,
    preview: 3,
    complete: (results) => callback(null, results),
    error: (error, file) => callback(error)
  })
}

const parseCsv = (params, callback) => {
  let {file, transformHeader, header, dynamicTyping} = params;
  PapaParse.parse(file, {
    header: header ? header : true,
    dynamicTyping: dynamicTyping ? dynamicTyping : false,
    transformHeader: transformHeader ? transformHeader : (header, index) => header,
    complete: (results) => callback(null, results),
    error: (error, file) => callback(error)
  })
}

const unparseToCsv = (params, callback) => {
  let {array, columns} = params;
  try {
    let csv = PapaParse.unparse(array, {columns})
    //console.log("csv: ", csv);
    if(callback) {callback(null, csv)}
    return csv
  } catch(err) {
    console.log("Error parsing array to csv: ", err);
    if(callback) {callback(err)}
  }
}

export { previewCsv, parseCsv, unparseToCsv, formatState }
