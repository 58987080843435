import React, {useState, useEffect} from "react";
import { Row, Col, Alert } from "react-bootstrap";
import ProgressBar from "react-bootstrap/ProgressBar";

const TaskProgress = props => {
  const {taskId, message, fetchFromApi} = props;
  let [taskStatus, setTaskStatus] = useState(null);

  const fetchTaskStatus = () => fetchFromApi({
    endpoint: "/api/elastic/getTaskStatus",
    body: JSON.stringify({
      taskId,
      wait_for_completion: false
    })
  }, (error, result) => {
    if (error) {
      console.log("error fetching task status: ", error);
    }
    if (result) {
      //console.log("taskStatus: ", taskStatus);
      setTaskStatus(result);
    }
  });

  useEffect(() => {
    if (!taskStatus && taskId) {
      fetchTaskStatus();
    }
    if (taskStatus && taskStatus.completed) {
      // do nothing
    }
    if (taskStatus && taskStatus.completed === false) {
      // poll again in 20 seconds
      setTimeout( fetchTaskStatus, 5000);
    }
  });

  if (!taskStatus) {
    return null
  }
  let progress = (taskStatus.task.status.created + taskStatus.task.status.updated + taskStatus.task.status.deleted ) / taskStatus.task.status.total * 100;
  //console.log("progress: ", progress);
  let variant = progress !== 100 ? props.variant ? props.variant : "primary" : "success";

  return(
    <div>
      <ProgressBar
        variant={ variant }
        now={ progress }
        label={ progress ? `${progress.toFixed(0)}%` : ""}
      />
      {progress === 100 &&
        <p className="mt-2 lead text-success">All set!</p>
      }
      { typeof message !== "undefined" && 
        message
      }
    </div>
  )
}

export default TaskProgress;