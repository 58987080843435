import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { db } from "./firebase.jsx";
import {
  Row,
  Form,
  Accordion,
  Card,
  Table,
  Button,
  ListGroup,
} from "react-bootstrap";
import { object_equals } from "./interfaceListShackPro.jsx";
import { AccordionToggle } from "./interfaceListShackSupport.jsx";

const fetchDuplicates = async (params, callback) => {
  console.log("Checking for duplicates.");
  const { currentSearch, userDoc, apiKey, apiUrl, dataDict } = params;
  console.log("fetchDuplicates params: ", params);
  try {
    function sumbb(bb) {
      let sum = 0;
      let param = bb.find((p) => Object.keys(p).includes("geo_bounding_box"));
      // If we can't find the bounding box filter, it's likely in the must clause of a bool dslquery
      if (!param) {
        //console.log("bb: ", bb);
        if (!bb[0].bool.must) { return 0 }
        else {
          param = bb[0].bool.must.find( p => Object.keys(p).includes("geo_bounding_box") );
        }
      }
      //console.log("param: ", param);
      let paramValues = param.geo_bounding_box[dataDict.map.location];
      //console.log("paramValues: ", paramValues);
      let array = [
        paramValues.top_left.lon,
        paramValues.top_left.lat,
        paramValues.bottom_right.lon,
        paramValues.bottom_right.lat,
      ];
      //console.log("array: ", array);
      array.map((n) => (sum += parseFloat(n.toFixed(0))));
      return sum;
    }

    let duplicateSum = 0;
    let maxOffset = 0;
    let usedCrm = false;
    let fetchedDownloads = [];
    let offsets = [];
    let nonDupeCount;
    let downloadsRef = db
      .collection("downloads")
      .doc(userDoc.uid)
      .collection("files")
      .where("queryUrl.filter", "==", currentSearch.filter);
    let sameDownloads = await downloadsRef.get();
    sameDownloads.forEach((doc) => {
      let docData = doc.data();
      docData.id = doc.id;
      console.log("docData: ", docData);
      // Only show files where the database is the same
      if (docData.queryUrl.url !== apiUrl) {
        console.log("download URL doesn't match.");
        return null;
      }
      // Only show files where the dslFilters are the same
      // Check if the downloads dslFilters match
      if (docData.queryUrl.dslFilter && !currentSearch.dslFilter) {
        // check if the dslFilter matches after we add searchsuppressions
        let currentSearchwithDslFilter = addDslFilter(currentSearch, userDoc.id);
        //console.log("currentSearchwithDslFilter: ", currentSearchwithDslFilter.dslFilter);
        //console.log("docData.queryUrl.dslFilter: ", docData.queryUrl.dslFilter);
        console.log("currentSearch.dslFilter: ", currentSearch.dslFilter);
        //console.log( object_equals(docData.queryUrl.dslFilter, currentSearchwithDslFilter.dslFilter) );
        console.log( object_equals(docData.queryUrl.dslFilter, currentSearch.dslFilter) );
        // If the dslFilter doesn't match it can't be a duplicate
        if ( !object_equals(docData.queryUrl.dslFilter, currentSearchwithDslFilter.dslFilter) && !object_equals(docData.queryUrl.dslFilter, currentSearch.dslFilter) ) {
          console.log("download dslFilter doesn't match.")
          return null;
        }
      }
      // Check if the current search is a mapsearch
      if (currentSearch.dslFilter) {
        // If the download is not a map search, it can't be a duplicate
        if (!docData.queryUrl.dslFilter) {
          console.log("download dslFilter doesn't match current map dsl filter.");
          return null;
        }
        //console.log( sumbb(currentSearch.dslFilter), sumbb(docData.queryUrl.dslFilter) );
        let bbMatch =
          sumbb(currentSearch.dslFilter) === sumbb(docData.queryUrl.dslFilter);
        if (!bbMatch) {
          console.log("download bounding boxes don't match");
          return null;
        }
      }
      // Only show files where the download completed and it hasn't been reported
      if (docData.csvFile && !docData.reported) {
        fetchedDownloads.push(docData);
      }
      // check if they are actually duplicates
      if ( (!offsets.includes(docData.offset)) || docData.dslQuery ) {
        offsets.push(docData.offset);
        duplicateSum += parseInt(docData.downloadCount);
      }
      // prepare for fetching a non-duplicate count
      if (maxOffset < docData.offset) {
        maxOffset = docData.offset;
      }
      // We don't use offsets when we add leads directly to the CRM
      // So we'll indicate that we need to adjust for that here
      if (docData.dslQuery) {
        usedCrm = true;
      }
    });

    console.log("fetchedDownloads: ", fetchedDownloads, "duplicateSum: ", duplicateSum, "offsets: ", offsets, "currentSearch.filter: ", currentSearch.filter);
    if ( maxOffset > 0 || usedCrm ) {
      let newSearch = { ...currentSearch };
      // only handle this if it's needed
      if (maxOffset > 0) {
        newSearch.offset = maxOffset;
      }
      if (usedCrm) {
        //newSearch.filter = addDlby(currentSearch.filter, userDoc.id);
        newSearch = addDslFilter(newSearch,userDoc.id);
      }
      newSearch.count_only = "true";
      newSearch.fields = "count(*)";
      //console.log("newSearch: ", newSearch);
      let postBody = JSON.stringify(newSearch);
      // await response of fetch call
      const init = {
        method: "POST",
        headers: {
          authorization: `Bearer ${apiKey}`,
          "Content-Type": "application/json",
        },
        "Transfer-Encoding": "chunked",
        cache: "default",
        accept: "application/json",
        body: postBody,
      };
      try {
        let response = await fetch(apiUrl, init);
        nonDupeCount = await response.json();
        //console.log("nonDupeCount: ", nonDupeCount);
      } catch (err) {
        console.log("Something went wrong fetching nonDupeCount: ", err);
      }
    }

    let sortedDownloads = fetchedDownloads.sort((a, b) => {
      let aV = a.timestamp.seconds;
      let bV = b.timestamp.seconds;
      return aV - bV;
    });

    let duplicateDownloads = {
      duplicateSum,
      maxOffset,
      nonDupeCount,
      items: sortedDownloads,
    };
    console.log("duplicateDownloads: ", duplicateDownloads);
    if (callback) {
      console.log("ran callback: ", callback);
      callback(null, duplicateDownloads);
    }
  } catch (err) {
    console.log("Error checking for duplicate downloads: ", err);
    if (callback) {
      callback(err);
    }
  }
};

const DisplayDuplicateDownloads = (props) => {
  const {
    user,
    currentSearch,
    userDoc,
    apiKey,
    apiUrl,
    dataDict,
    userplan,
    handleUpgrade,
    dupesCallback,
    suppressSearchCallback,
    tableVisible,
    allowToggle
  } = props;

  const [suppressSearch, setSuppressSearch] = useState(false);
  const [isDuplicate, setIsDuplicate] = useState(false);
  const [duplicateDownloads, setDuplicateDownloads] = useState(null);

  useEffect(() => {
    setSuppressSearch(userplan.searchSuppressions);
    fetchDuplicates(
      {
        currentSearch,
        userDoc,
        apiKey,
        apiUrl,
        dataDict,
      },
      (error, dd) => {
        if (error) {
          console.log("Error fetching duplicate downloads: ", error);
        }
        if (dd) {
          setDuplicateDownloads(dd);
          if (dd.items.length > 0) {
            setIsDuplicate(true);
          }
        }
        if (dupesCallback) {
          dupesCallback(error, dd);
        }
      }
    );
  }, []);

  if(!user) {
    return null
  }

  if (!isDuplicate) {
    return null;
  }

  console.log("duplicateDownloads: ", duplicateDownloads);
  const { duplicateSum, items, nonDupeCount } = duplicateDownloads;
  return (
    <Form className="mb-2">
      <Accordion className="mb-2 mt-2" defaultActiveKey={ tableVisible ? "0" : null}>
        <AccordionToggle
          as={Button}
          variant="link"
          eventKey="0"
          className="text-danger text-italic"
          style={{
            fontSize: "1.1em",
            fontStyle: "italic",
          }}
        >
          You've already downloaded some of these leads &nbsp;
          <i className="fa fa-angle-down fa-1x" aria-hidden="true"></i>
        </AccordionToggle>

        <Accordion.Collapse eventKey="0">
          <Card>
            <Card.Body>
              <Table size="sm" hover responsive>
                <thead>
                  <tr>
                    <th>Search name</th>
                    <th>Date</th>
                    <th>Download</th>
                    <th className="text-end">Download count</th>
                  </tr>
                </thead>
                <tbody>
                  {items.map((download, i) => {
                    if (download.csvFile) {
                      return (
                        <tr key={`tr_${i}`}>
                          <td>{download.searchName}</td>
                          <td>
                            {new Date(
                              download.timestamp.seconds * 1000
                            ).toLocaleString()}
                          </td>
                          <td>
                            {download.dslQuery &&
                              <Link to={download.csvFile}>View list</Link>
                            }
                            {!download.dslQuery &&
                              <a
                                className="alert-link"
                                href={download.csvFile}
                                download={`${download.searchName
                                  .replace(/\s/g, "_")
                                  .replace(/\//g, "-")}.csv`}
                              >
                                csv
                              </a>
                            }
                          </td>
                          <td className="text-end">
                            {download.downloadCount}
                          </td>
                        </tr>
                      );
                    } else return null;
                  })}
                </tbody>
              </Table>
            </Card.Body>
            <ListGroup variant="flush">
              <ListGroup.Item className="d-flex justify-content-between align-items-center">
                Leads already downloaded from this search:
                <div
                  style={{
                    fontSize: "1.2em",
                  }}
                >
                  {duplicateSum.toLocaleString()}
                </div>
              </ListGroup.Item>
              <ListGroup.Item className="d-flex justify-content-between align-items-center">
                Unique leads remaining:
                <div
                  style={{
                    fontSize: "1.2em",
                  }}
                >
                  {nonDupeCount.toLocaleString()}
                </div>
              </ListGroup.Item>
            </ListGroup>
          </Card>
        </Accordion.Collapse>
      </Accordion>
      <div className="d-flex flex-row justify-content-start no-gutters">
        {allowToggle &&
          <div>
            <Form.Check
              checked={suppressSearch}
              onChange={(e) => {
                setSuppressSearch(!suppressSearch);
                if (suppressSearchCallback) {
                  suppressSearchCallback(!suppressSearch);
                }
              }}
              disabled={!userplan.searchSuppressions}
              name="suppressSearch"
              type="checkbox"
              label="Suppress duplicates"
            />
          </div>
        }
        {!userplan.searchSuppressions && (
          <div>
            <button
              type="button"
              onClick={handleUpgrade}
              className="badge badge-pill badge-info ms-1"
            >
              Upgrade
            </button>
          </div>
        )}
      </div>

      <Row className="text-start">
        <Form.Text className="text-muted text-start">
          Search suppressions help prevent you from downloading the same leads
          more than once.
        </Form.Text>
        {!userplan.searchSuppressions && (
          <small className="text-muted text-start">
            Your account doesn't have access to this feature. To avoid
            duplicates, change your search or upgrade your account.
          </small>
        )}
      </Row>
    </Form>
  );
};

const addDlby = (filter, aid) => {
  let nf = filter !== "" ? filter + ` and (DOWNLOADEDBY != '${aid}')` : `(DOWNLOADEDBY != '${aid}')`;
  return nf
}

const addDslFilter = (queryUrl, aid) => {
  let newQueryUrl = {...queryUrl};
  let newDslFilter = {
    bool: {
      must_not: [
        {
          term: {
            DOWNLOADEDBY: aid
          }
        }
      ]
    }
  }

  if (newQueryUrl.dslFilter) {
    let must = [...newQueryUrl.dslFilter];
    newDslFilter.bool.must = must;
  }
  newQueryUrl.dslFilter = [newDslFilter];
  return newQueryUrl;
}

const suppressSearchQuery = ({duplicateDownloads, queryUrl, aid}) => {
  console.log(
    "Suppressing this search with offset: ",
    duplicateDownloads.maxOffset
  );
  let sq = {...queryUrl};
  sq.offset = duplicateDownloads.maxOffset;
  sq = addDslFilter(queryUrl, aid);
  return sq;
}

export { fetchDuplicates, DisplayDuplicateDownloads, suppressSearchQuery };
