import React from "react";

function withInlineEdit(WrappedComponent, props) {
    return class extends React.Component {
      constructor(props) {
        super(props);
        this.state={
          edit: props.edit ? props.edit : false
        }
      }
  
      handleInlineEdit(bool) {
        this.setState({edit: bool});
      }
  
      render() {
        if (!this.state.edit) {
          return(
            <WrappedComponent
              {...this.props}
              inlineEdit={this.state.edit}
              handleInlineEdit={this.handleInlineEdit}
              onClick={ e => {
                if (this.props.onClick) {
                  this.props.onClick(e);
                }
                this.setState({edit: true})
              }}
              readOnly={true}
            />
          );
        } else {
          return(
            <WrappedComponent
              {...this.props}
              inlineEdit={this.state.edit}
              handleInlineEdit={this.handleInlineEdit}
              onBlur={() => {
                if (this.props.onBlur) {
                  this.props.onBlur();
                }
                this.setState({edit: false});
              }}
            />
          );
        }
      }
  }}

  export default withInlineEdit;