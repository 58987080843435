import React from "react";
import { Tooltip, OverlayTrigger, Button } from "react-bootstrap";
import { formatPhoneNumber } from "./interfaceListShackPro";

const Phone = props => {
  let {phoneObject, keyId} = props;
  return(
    <OverlayTrigger
      overlay={
        <Tooltip id={`tooltip-${keyId}`}>
          <div className="text-start">
            {["DNC_FLAG", "AREA_CODE", "CELL_FLAG"].map(
              (sf, i) => {
                return (
                  <React.Fragment
                    key={`${keyId}_${sf}_${i}`}
                  >
                    <span>
                      {sf}: <b>{`${phoneObject[sf]}`}</b>
                    </span>
                    <br />
                  </React.Fragment>
                );
              }
            )}
            {/*<span>{JSON.stringify(c)}</span>*/}
          </div>
        </Tooltip>
      }
    >
      <Button
        variant="link"
        style={{ whiteSpace: "nowrap" }}
      >
        <a
          rel="noopener noreferrer"
          target="_blank"
          href={`tel:${phoneObject["DATA"]}`}
        >
          {formatPhoneNumber(phoneObject["DATA"])}
        </a>
      </Button>
    </OverlayTrigger>
  )
};

let blankPhone = {
  DNC_FLAG: null,
  AREA_CODE: "",
  CELL_FLAG: null,
  DATA: "",
  TYPE: "PHONE"
}

const AddPhoneButton = props => {
  let {onAdd} = props;
  return(
    <Button 
      size={props.size ? props.size : "md"}
      variant={ props.variant ? props.variant : "primary"}
      style={props.style ? props.style : null}
      className={props.className ? props.className : ""}
      onClick={e => {
        //Create blank phone object
        
        // Do something with the object via a prop
        if (onAdd) {
          onAdd(blankPhone);
        }
      }}
    >
      {props.btnText ? props.btnText : "Add Phone"}
    </Button>
  );
}

export { Phone, AddPhoneButton, blankPhone };