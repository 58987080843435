import React, {useState, useEffect } from "react";
//import { OverlayTrigger, Popover, CloseButton, Button } from "react-bootstrap";
import { CloseButton, Button, Card} from "react-bootstrap";
import { Popover, ArrowContainer } from "react-tiny-popover";
import { ChevronRight, ArrowCounterclockwise } from "react-bootstrap-icons";
// The purpose of this component is to provide a simple syntax for adding a 
// Walk Me style popover tutorial to any react app
// First, add all the elements of thte tutorial directly to the 
// the code as <ShowMe /> components.  They should be wrapped around the
// the element on the page where you want the showMe to be displayed. 
// Here's an exmaple of how it would look if you wanted a showMe step to 
// be displayed near a paragraph of text

// <ShowMe 
//   showMe={showMe}
//   setShowMe={setShowMe}
//   popoverContent={"This is the text that you should read next!"}
//   stepNo={1}
//   tutorialId="myFirstShowMe"
//   placement="right"
//   hideNext={false} // Optional they won't be hidden by default
//   hideDismiss={false} // Optional they won't be hidden by default
// >
//   <p>This is the paragrah of text I want to draw attention to.</p>
// </ShowMe>

// Second, create you tutorial configuratiton that will be passed into the defaultValue object that will run the tutorial to the 
// use local storage method it should look like this:
//{
//  0: false,
//  1: false,
//  2: false,
//  3: false,
//  totalSteps: 4,
//  viewNext: "2022-02-08", // datetime stamp ISO806 compliant
//  onDismiss: "nextStep" || "waitNextDay", // defaults to nextStep
//  allowReplay: true // defaults to false
//}
// Each key'd number corresponds to a popover in the tutorial.
// When a user closes a popover it makes the popover key as true which
// The next popover will automatically be shown, either the next time
// the user comes back to the app the next day, or immediately (if the 
// user clicks next)

// Third, add the useLocalStrage method to the react component
// where you'd like the tutorial to run.  Pass in a unique tutoral id into
// the mothod along with the a defaultValue object as shown below
// let [showMe, setShowMe] = useLocalStarage("tutorialId", { defaultValueObject } )

//const ShowMeBusted = props => {
//  let {
//    stepNo, 
//    showMe, 
//    setShowMe, 
//    tutorialId, 
//    placement,
//    hideDismiss,
//    hideNext,
//    trigger,
//    closeButtonVariant
//  } = props;
//  let now = new Date();
//  const markSeen = (e, isDismiss=true) => {
//    e.preventDefault();
//    e.stopPropagation();
//    let ns = {...showMe};
//    ns[stepNo] = false;
//    if (isDismiss && (showMe.onDismess === "nextStep" || !showMe.onDismss) ) {
//      // do nothing
//    } else {
//      let today = now.getDate();
//      let tomorrow = today; // because javascript months are 0 indexed
//      let month = now.getMonth();
//      let year = now.getFullYear();
//      showMe.viewNext = new Date(year, month, tomorrow) // will result in the users timezone
//    }
//    setShowMe(ns);
//  }
//  // Show me by default if 
//  // 1) the previous step has already been seen AND
//  // 2) I have not been seen yet AND
//  // 3) If their is a viewNext requirement, the time is greater than viewNext
//  let defaultShow = ( typeof showMe[stepNo - 1] !== "undefined" ? showMe[stepNo -1] === true : true) 
//    && (showMe[stepNo] === false) 
//    && (showMe.viewNext ? now > showMe.viewNext : true );
//  console.log("defaultShow: ", defaultShow, "stepNo: ", stepNo);
//  return(
//    <OverlayTrigger
//      key={`${tutorialId}-overlayTrigger-${stepNo}`}
//      placement={placement}
//      trigger={trigger ? trigger : ["hover","focus"]}
//      defaultShow={ defaultShow } 
//      overlay={
//        props.customOverlay ? props.customOverlay 
//          : props.popoverContent ? 
//            (<Popover 
//              id={`${tutorialId}-${stepNo}`}
//              {...props}
//              show={defaultShow}
//            >
//              <Popover.Body>
//                {!hideDismiss &&
//                  <CloseButton 
//                    variant={closeButtonVariant ? closeButtonVariant : null} 
//                    onClick={e => markSeen(e, true)}
//                    className="me-2"
//                  />
//                }
//                {props.popoverContent}
//                {!hideNext &&
//                  <Button 
//                    variant="link"
//                    onClick={ e => markSeen(e, false)}
//                    style={{textDecoration: "none"}}
//                    className="text-muted d-block pull-right"
//                  >
//                    Next <ChevronRight />
//                  </Button>
//                }
//              </Popover.Body>
//            </Popover>)
//          : props.tooltipContent ? props.toolttipContent : null
//      }
//    >
//      {props.children}
//    </OverlayTrigger>
//  )
//}

const ShowMe = props => {
  let {
    stepNo, 
    showMe, 
    setShowMe, 
    tutorialId, 
    placement,
    hideDismiss,
    hideNext,
    trigger,
    closeButtonVariant,
    align
  } = props;
  let now = new Date();
  const markSeen = (e, isDismiss=true) => {
    e.preventDefault();
    e.stopPropagation();
    let ns = {...showMe};
    ns[stepNo] = true;
    if (isDismiss && (showMe.onDismess === "nextStep" || !showMe.onDismss) ) {
      // do nothing
    } else {
      let today = now.getDate();
      let tomorrow = today; // because javascript months are 0 indexed
      let month = now.getMonth();
      let year = now.getFullYear();
      showMe.viewNext = new Date(year, month, tomorrow) // will result in the users timezone
    }
    setShowMe(ns);
  }
  // Show me by default if 
  // 1) the previous step has already been seen AND
  // 2) I have not been seen yet AND
  // 3) If their is a viewNext requirement, the time is greater than viewNext
  let defaultShow = ( typeof showMe[stepNo - 1] !== "undefined" ? showMe[stepNo -1] === true : true) 
    && (showMe[stepNo] === false) 
    && (showMe.viewNext ? now > showMe.viewNext : true );
  //console.log("defaultShow: ", defaultShow, "stepNo: ", stepNo);
  return(
    <Popover
      key={`${tutorialId}-overlayTrigger-${stepNo}`}
      position={placement}
      align={align}
      trigger={trigger ? trigger : ["hover","focus"]}
      isOpen={ defaultShow } 
      content={({ position, childRect, popoverRect }) => (
        <ArrowContainer // if you'd like an arrow, you can import the ArrowContainer!
          position={position}
          childRect={childRect}
          popoverRect={popoverRect}
          arrowColor={'lightgray'}
          arrowSize={10}
          arrowStyle={{ opacity: 0.7 }}
          className='popover-arrow-container'
          arrowClassName='popover-arrow'
        >
          <Card 
              id={`${tutorialId}-${stepNo}`}
              className="p-0"
              style={{maxWidth: "400px"}}
            >
              <Card.Body className="p-3 pb-1">
                <div className={`showMePopoverContent ${tutorialId}`}>{props.popoverContent}</div>
                <div className="d-flex flex-row justify-content-between align-items-center">
                  {!hideDismiss &&
                    <CloseButton 
                      variant={closeButtonVariant ? closeButtonVariant : null} 
                      onClick={e => markSeen(e, true)}
                      className="me-2"
                    />
                  }
                  {!hideNext && (stepNo !== showMe.totalSteps) &&
                    <Button 
                      variant="link"
                      onClick={ e => markSeen(e, false)}
                      style={{textDecoration: "none"}}
                      className="text-muted d-block pull-right"
                    >
                      Next <ChevronRight />
                    </Button>
                  }
                  {showMe.allowReplay && showMe.totalSteps === stepNo &&
                    <Button 
                      variant="link"
                      onClick={ e => {
                        let ns = {...showMe}
                        Object.keys(ns).forEach( k => {
                          //console.log("typeof k", typeof parseInt(k), parseInt(k) );
                          if (typeof parseInt(k) === "number" && !isNaN(parseInt(k))) {
                            ns[k] = false;
                          }
                          if ( k === "viewNext") {
                            ns.viewNext = false;
                          }
                        })
                        setShowMe(ns);
                      }}
                      style={{textDecoration: "none"}}
                      className="text-muted d-block pull-right"
                    >
                      <ArrowCounterclockwise />
                    </Button>
                  }
                </div>
              </Card.Body>
            </Card>
        </ArrowContainer>
      )}
    >
      {props.children}
    </Popover>
  )
}

function getStorageValue(key, defaultValue) {
  if (typeof window !== "undefined") {
      // getting stored value
      const saved = localStorage.getItem(key);
      const initial = JSON.parse(saved);
      return initial || defaultValue;
  }
}

const useShowMe = (tutorialId, tutorialConfig) => {
  const [value, setValue] = useState(() => {
    return getStorageValue(tutorialId, tutorialConfig);
  });

  useEffect(() => {
    // storing input name
    localStorage.setItem(tutorialId, JSON.stringify(value));
  }, [tutorialId, value]);

  return [value, setValue];
};

export {ShowMe, useShowMe};