import sharedDict from "./sharedDict.jsx";
import image from "./media/suburbs.svg";

export default {
  dictId: "consumerJuly2021",
  index: "consumerjul2021",
  type: "Consumer",
  path: "/search/consumers",
  apiUrl: "/api/v2/data/consumerjul2021?db=consumers",
  title: "US Consumers",
  description: "ListShack's latest consumer telemarketing and direct mail database.",
  overview: "A comprehensive database of US consumers over the age of 18.  Search by geography or any one of our basic or advanced filters.  Download name, address, phone number, birth month and age.",
  themeColor: "primary",
  image,
  map: {
    latitude:  "LAT",
    longitude: "LONG",
    location: "LOCATION",
    searchTypes: {
      region: "ST",
      district: "COUNTY",
      postcode: "ZIP",
      place: "CITY"
    }
  },
  geography: {
    AREA_CODE: sharedDict.geography.AREA_CODE_Integer,
    COUNTY: sharedDict.geography.COUNTY,
    CITY: sharedDict.geography.CITY,
    ZIP: sharedDict.geography.ZIP,
    ST: sharedDict.geography.ST,
    nationwide: sharedDict.geography.nationwide
  },
  cols: {
    phonePref: {
      name: "Phone scrubbing",
      displayHeader: true,
      inputType: "select",
      filterType: "basic",
      filterCategory: "Contact preferences",
      items: [
        {index: 0, query: `(DNC_FLAG="False") and (PHONE <>"")`, value: "A", name: "DNC Scrubbed"},
        {index: 1, query: `(PHONE <>'')`, value: "B", name: "All records with phones (no scrubbing)"},
        {index: 2, value: "", name: "All records with or without phones"},
      ]
    },
    CELL_FLAG: {
      name: "Cell phones",
      displayHeader: true,
      inputType: "select",
      filterType: "basic",
      filterCategory: "Contact preferences",
      items: [
        {index: 0, value: "", name: "No preference"},
        {index: 1, query: [`(CELL_FLAG="Y")`], value: "B", name: "Only cell phones"},
        {index: 2, query: [`(CELL_FLAG="N")`], value: "C", name: "Exclude cell phones"},
      ]
    },
    PHONE_OCCURRENCE: {
      name: "Phones per household",
      displayHeader: true,
      inputType: "checkbox",
      filterType: "basic",
      filterCategory: "Contact preferences",
      items: [
        {index: 0, value: 1, name: "Unique phone records only", query: [`(PHONE_OCCURRENCE = 1) and (PHONE <> '')`]}
      ]
    },
    ADDR_OCCURRENCE: {
      name: "Address scrubbing",
      displayHeader: true,
      inputType: "checkbox",
      filterType: "basic",
      filterCategory: "Contact preferences",
      items: [
        {index: 0, value: "1", name: "One record per mailing address", query: [`(ADDR_OCCURRENCE = 1)`]},
      ]
    },
    AGE: {
      name: "Ages",
      displayHeader: true,
      inputType: "range",
      filterType: "basic",
      filterCategory: "Basic demographics",
      items: [
        {value: 18, order: 18, name: 18},
        {value: 19, order: 19, name: 19},
        {value: 20, order: 20, name: 20},
        {value: 21, order: 21, name: 21},
        {value: 22, order: 22, name: 22},
        {value: 23, order: 23, name: 23},
        {value: 24, order: 24, name: 24},
        {value: 25, order: 25, name: 25},
        {value: 26, order: 26, name: 26},
        {value: 27, order: 27, name: 27},
        {value: 28, order: 28, name: 28},
        {value: 29, order: 29, name: 29},
        {value: 30, order: 30, name: 30},
        {value: 31, order: 31, name: 31},
        {value: 32, order: 32, name: 32},
        {value: 33, order: 33, name: 33},
        {value: 34, order: 34, name: 34},
        {value: 35, order: 35, name: 35},
        {value: 36, order: 36, name: 36},
        {value: 37, order: 37, name: 37},
        {value: 38, order: 38, name: 38},
        {value: 39, order: 39, name: 39},
        {value: 40, order: 40, name: 40},
        {value: 41, order: 41, name: 41},
        {value: 42, order: 42, name: 42},
        {value: 43, order: 43, name: 43},
        {value: 44, order: 44, name: 44},
        {value: 45, order: 45, name: 45},
        {value: 46, order: 46, name: 46},
        {value: 47, order: 47, name: 47},
        {value: 48, order: 48, name: 48},
        {value: 49, order: 49, name: 49},
        {value: 50, order: 50, name: 50},
        {value: 51, order: 51, name: 51},
        {value: 52, order: 52, name: 52},
        {value: 53, order: 53, name: 53},
        {value: 54, order: 54, name: 54},
        {value: 55, order: 55, name: 55},
        {value: 56, order: 56, name: 56},
        {value: 57, order: 57, name: 57},
        {value: 58, order: 58, name: 58},
        {value: 59, order: 59, name: 59},
        {value: 60, order: 60, name: 60},
        {value: 61, order: 61, name: 61},
        {value: 62, order: 62, name: 62},
        {value: 63, order: 63, name: 63},
        {value: 64, order: 64, name: 64},
        {value: 65, order: 65, name: 65},
        {value: 66, order: 66, name: 66},
        {value: 67, order: 67, name: 67},
        {value: 68, order: 68, name: 68},
        {value: 69, order: 69, name: 69},
        {value: 70, order: 70, name: 70},
        {value: 71, order: 71, name: 71},
        {value: 72, order: 72, name: 72},
        {value: 73, order: 73, name: 73},
        {value: 74, order: 74, name: 74},
        {value: 75, order: 75, name: 75},
        {value: 76, order: 76, name: 76},
        {value: 77, order: 77, name: 77},
        {value: 78, order: 78, name: 78},
        {value: 79, order: 79, name: 79},
        {value: 80, order: 80, name: 80},
        {value: 81, order: 81, name: 81},
        {value: 82, order: 82, name: 82},
        {value: 83, order: 83, name: 83},
        {value: 84, order: 84, name: 84},
        {value: 85, order: 85, name: 85},
        {value: 86, order: 86, name: 86},
        {value: 87, order: 87, name: 87},
        {value: 88, order: 88, name: 88},
        {value: 89, order: 89, name: 89},
        {value: 90, order: 90, name: 90},
        {value: 91, order: 91, name: 91},
        {value: 92, order: 92, name: 92},
        {value: 93, order: 93, name: 93},
        {value: 94, order: 94, name: 94},
        {value: 95, order: 95, name: 95},
        {value: 96, order: 96, name: 96},
        {value: 97, order: 97, name: 97},
        {value: 98, order: 98, name: 98},
        {value: 99, order: 99, name: 99},
      ],
    },
    DOB_MON: {
      name: "Birth month",
      displayHeader: true,
      inputType: "checkbox",
      filterType: "basic",
      filterCategory: "Basic demographics",
      items: [
        {index: 0, value: 1, name: "January"},
        {index: 1, value: 2, name: "February"},
        {index: 2, value: 3, name: "March"},
        {index: 3, value: 4, name: "April"},
        {index: 4, value: 5, name: "May"},
        {index: 5, value: 6, name: "June"},
        {index: 6, value: 7, name: "July"},
        {index: 7, value: 8, name: "August"},
        {index: 8, value: 9, name: "September"},
        {index: 9, value: 10, name: "October"},
        {index: 10, value: 11, name: "November"},
        {index: 11, value: 12, name: "December"},
      ]
    },
    HH_INCOME: {
      name: "Estimated income range",
      displayHeader: true,
      inputType: "stringRange",
      filterType: "basic",
      filterCategory: "Basic demographics",
      items: [
        {value: "A", order: 1, name: "Under $10K"},
        {value: "B", order: 2, name: "$10K - $14K"},
        {value: "C", order: 3, name: "$15K - $19K"},
        {value: "D", order: 4, name: "$20K - $24K"},
        {value: "E", order: 5, name: "$25K - $29K"},
        {value: "F", order: 6, name: "$30K - $34K"},
        {value: "G", order: 7, name: "$35K - $39K"},
        {value: "H", order: 8, name: "$40K - $44K"},
        {value: "I", order: 9, name: "$45K - $49K"},
        {value: "J", order: 10, name: "$50K - $54K"},
        {value: "K", order: 11, name: "$55K - $59K"},
        {value: "L", order: 12, name: "$60K - $64K"},
        {value: "M", order: 13, name: "$65K - $74K"},
        {value: "N", order: 14, name: "$75K - $99K"},
        {value: "O", order: 15, name: "$100K - $149K"},
        {value: "P", order: 16, name: "$150K - $174K"},
        {value: "Q", order: 17, name: "$175K - $199K"},
        {value: "R", order: 18, name: "$200K+"},
      ]
    },
    HOME_OWNR: {
      name: "Home owner status",
      displayHeader: true,
      inputType: "checkbox",
      filterType: "basic",
      filterCategory: "Basic demographics",
      items: [
        {index: 0, value: "h", name: "Home owner"},
        {index: 1, value: "r", name: "Renter"}
      ]
    },
    HH_MARITAL_STATUS: {
      name: "Marital status",
      displayHeader: true,
      inputType: "checkbox",
      filterType: "basic",
      filterCategory: "Basic demographics",
      items: [
        {index: 0, value: "M", name: "Married"},
        {index: 1, value: "A", name: "Likely married"},
        {index: 2, value: "S", name: "Single"},
        {index: 3, value: "B", name: "Likely single"}
      ]
    },
    GENDER: {
      name: "Gender",
      displayHeader: true,
      inputType: "select",
      filterType: "basic",
      filterCategory: "Basic demographics",
      items: [
        {value: "", name: "No preference"},
        {value: "F", name: "Female"},
        {value: "M", name: "Male"},
      ]
    },
    HOME_MKT_VALUE: {
      name: "Estimated property value",
      displayHeader: true,
      inputType: "stringRange",
      filterCategory: "Property information",
      items: [
        {value: "A", order: 10, name: "Less than $25K"},
        {value: "B", order: 20, name: "$25K - $49K"},
        {value: "C", order: 40, name: "$50K - $74K"},
        {value: "D", order: 50, name: "$75K - $99K"},
        {value: "E", order: 70, name: "$100K - $124K"},
        {value: "F", order: 90, name: "$125K - $149K"},
        {value: "G", order: 110, name: "$150K - $174K"},
        {value: "H", order: 130, name: "$175K - $199K"},
        {value: "I", order: 150, name: "$200K - $224K"},
        {value: "J", order: 170, name: "$225K - $249K"},
        {value: "K", order: 190, name: "$250K - $274K"},
        {value: "L", order: 210, name: "$275K - $299K"},
        {value: "M", order: 230, name: "$300K - $349K"},
        {value: "N", order: 250, name: "$350K - $399K"},
        {value: "O", order: 270, name: "$400K - $449K"},
        {value: "P", order: 290, name: "$450K - $499K"},
        {value: "Q", order: 310, name: "$500K - $749K"},
        {value: "R", order: 330, name: "$750K - $999K"},
        {value: "S", order: 360, name: "$1M+"}
      ]
    },
    PROP_TYPE: {
      name: "Property type",
      displayHeader: true,
      inputType: "checkbox",
      filterCategory: "Property information",
      items: [
        {index: 0, value: "S", name: "Single family dwelling"},
        {index: 1, value: "M", name: "Multiple family dwelling"}
      ]
    },
    PROP_BLD_YR: {
      name: "Year built",
      displayHeader: true,
      inputType: "range",
      filterCategory: "Property information",
      sortItems: "reverse",
      items: [
        {value: 1949, order: 1949, name: 1949},
        {value: 1950, order: 1950, name: 1950},
        {value: 1951, order: 1951, name: 1951},
        {value: 1952, order: 1952, name: 1952},
        {value: 1953, order: 1953, name: 1953},
        {value: 1954, order: 1954, name: 1954},
        {value: 1955, order: 1955, name: 1955},
        {value: 1956, order: 1956, name: 1956},
        {value: 1957, order: 1957, name: 1957},
        {value: 1958, order: 1958, name: 1958},
        {value: 1959, order: 1959, name: 1959},
        {value: 1960, order: 1960, name: 1960},
        {value: 1961, order: 1961, name: 1961},
        {value: 1962, order: 1962, name: 1962},
        {value: 1963, order: 1963, name: 1963},
        {value: 1964, order: 1964, name: 1964},
        {value: 1965, order: 1965, name: 1965},
        {value: 1966, order: 1966, name: 1966},
        {value: 1967, order: 1967, name: 1967},
        {value: 1968, order: 1968, name: 1968},
        {value: 1969, order: 1969, name: 1969},
        {value: 1970, order: 1970, name: 1970},
        {value: 1971, order: 1971, name: 1971},
        {value: 1972, order: 1972, name: 1972},
        {value: 1973, order: 1973, name: 1973},
        {value: 1974, order: 1974, name: 1974},
        {value: 1975, order: 1975, name: 1975},
        {value: 1976, order: 1976, name: 1976},
        {value: 1977, order: 1977, name: 1977},
        {value: 1978, order: 1978, name: 1978},
        {value: 1979, order: 1979, name: 1979},
        {value: 1980, order: 1980, name: 1980},
        {value: 1981, order: 1981, name: 1981},
        {value: 1982, order: 1982, name: 1982},
        {value: 1983, order: 1983, name: 1983},
        {value: 1984, order: 1984, name: 1984},
        {value: 1985, order: 1985, name: 1985},
        {value: 1986, order: 1986, name: 1986},
        {value: 1987, order: 1987, name: 1987},
        {value: 1988, order: 1988, name: 1988},
        {value: 1989, order: 1989, name: 1989},
        {value: 1990, order: 1990, name: 1990},
        {value: 1991, order: 1991, name: 1991},
        {value: 1992, order: 1992, name: 1992},
        {value: 1993, order: 1993, name: 1993},
        {value: 1994, order: 1994, name: 1994},
        {value: 1995, order: 1995, name: 1995},
        {value: 1996, order: 1996, name: 1996},
        {value: 1997, order: 1997, name: 1997},
        {value: 1998, order: 1998, name: 1998},
        {value: 1999, order: 1999, name: 1999},
        {value: 2000, order: 2000, name: 2000},
        {value: 2001, order: 2001, name: 2001},
        {value: 2002, order: 2002, name: 2002},
        {value: 2003, order: 2003, name: 2003},
        {value: 2004, order: 2004, name: 2004},
        {value: 2005, order: 2005, name: 2005},
        {value: 2006, order: 2006, name: 2006},
        {value: 2007, order: 2007, name: 2007},
        {value: 2008, order: 2008, name: 2008},
        {value: 2009, order: 2009, name: 2009},
        {value: 2010, order: 2010, name: 2010},
        {value: 2011, order: 2011, name: 2011},
        {value: 2012, order: 2012, name: 2012},
        {value: 2013, order: 2013, name: 2013},
        {value: 2014, order: 2014, name: 2014},
        {value: 2015, order: 2015, name: 2015},
        {value: 2016, order: 2016, name: 2016},
        {value: 2017, order: 2017, name: 2017},
        {value: 2018, order: 2018, name: 2018},
        {value: 2019, order: 2019, name: 2019},
      ]
    },
    PURCH_MON: {
      name: "Purchase month range",
      displayHeader: true,
      inputType: "range",
      filterCategory: "Property information",
      items: [
        {value: 1, order: 1, name: "January"},
        {value: 2, order: 2, name: "February"},
        {value: 3, order: 3, name: "March"},
        {value: 4, order: 4, name: "April"},
        {value: 5, order: 5, name: "May"},
        {value: 6, order: 6, name: "June"},
        {value: 7, order: 7, name: "July"},
        {value: 8, order: 8, name: "August"},
        {value: 9, order: 9, name: "September"},
        {value: 10, order: 10, name: "October"},
        {value: 11, order: 11, name: "November"},
        {value: 12, order: 12, name: "December"},
      ]
    },
    PURCH_YEAR: {
      name: "Purchase year",
      displayHeader: true,
      inputType: "range",
      filterCategory: "Property information",
      sortItems: "reverse",
      items: [
        {value: 1899, order: 1899, name: 1899},
        {value: 1900, order: 1900, name: 1900},
        {value: 1901, order: 1901, name: 1901},
        {value: 1902, order: 1902, name: 1902},
        {value: 1903, order: 1903, name: 1903},
        {value: 1904, order: 1904, name: 1904},
        {value: 1905, order: 1905, name: 1905},
        {value: 1906, order: 1906, name: 1906},
        {value: 1907, order: 1907, name: 1907},
        {value: 1908, order: 1908, name: 1908},
        {value: 1909, order: 1909, name: 1909},
        {value: 1910, order: 1910, name: 1910},
        {value: 1911, order: 1911, name: 1911},
        {value: 1912, order: 1912, name: 1912},
        {value: 1913, order: 1913, name: 1913},
        {value: 1914, order: 1914, name: 1914},
        {value: 1915, order: 1915, name: 1915},
        {value: 1916, order: 1916, name: 1916},
        {value: 1917, order: 1917, name: 1917},
        {value: 1918, order: 1918, name: 1918},
        {value: 1919, order: 1919, name: 1919},
        {value: 1920, order: 1920, name: 1920},
        {value: 1921, order: 1921, name: 1921},
        {value: 1922, order: 1922, name: 1922},
        {value: 1923, order: 1923, name: 1923},
        {value: 1924, order: 1924, name: 1924},
        {value: 1925, order: 1925, name: 1925},
        {value: 1926, order: 1926, name: 1926},
        {value: 1927, order: 1927, name: 1927},
        {value: 1928, order: 1928, name: 1928},
        {value: 1929, order: 1929, name: 1929},
        {value: 1930, order: 1930, name: 1930},
        {value: 1931, order: 1931, name: 1931},
        {value: 1932, order: 1932, name: 1932},
        {value: 1933, order: 1933, name: 1933},
        {value: 1934, order: 1934, name: 1934},
        {value: 1935, order: 1935, name: 1935},
        {value: 1936, order: 1936, name: 1936},
        {value: 1937, order: 1937, name: 1937},
        {value: 1938, order: 1938, name: 1938},
        {value: 1939, order: 1939, name: 1939},
        {value: 1940, order: 1940, name: 1940},
        {value: 1941, order: 1941, name: 1941},
        {value: 1942, order: 1942, name: 1942},
        {value: 1943, order: 1943, name: 1943},
        {value: 1944, order: 1944, name: 1944},
        {value: 1945, order: 1945, name: 1945},
        {value: 1946, order: 1946, name: 1946},
        {value: 1947, order: 1947, name: 1947},
        {value: 1948, order: 1948, name: 1948},
        {value: 1949, order: 1949, name: 1949},
        {value: 1950, order: 1950, name: 1950},
        {value: 1951, order: 1951, name: 1951},
        {value: 1952, order: 1952, name: 1952},
        {value: 1953, order: 1953, name: 1953},
        {value: 1954, order: 1954, name: 1954},
        {value: 1955, order: 1955, name: 1955},
        {value: 1956, order: 1956, name: 1956},
        {value: 1957, order: 1957, name: 1957},
        {value: 1958, order: 1958, name: 1958},
        {value: 1959, order: 1959, name: 1959},
        {value: 1960, order: 1960, name: 1960},
        {value: 1961, order: 1961, name: 1961},
        {value: 1962, order: 1962, name: 1962},
        {value: 1963, order: 1963, name: 1963},
        {value: 1964, order: 1964, name: 1964},
        {value: 1965, order: 1965, name: 1965},
        {value: 1966, order: 1966, name: 1966},
        {value: 1967, order: 1967, name: 1967},
        {value: 1968, order: 1968, name: 1968},
        {value: 1969, order: 1969, name: 1969},
        {value: 1970, order: 1970, name: 1970},
        {value: 1971, order: 1971, name: 1971},
        {value: 1972, order: 1972, name: 1972},
        {value: 1973, order: 1973, name: 1973},
        {value: 1974, order: 1974, name: 1974},
        {value: 1975, order: 1975, name: 1975},
        {value: 1976, order: 1976, name: 1976},
        {value: 1977, order: 1977, name: 1977},
        {value: 1978, order: 1978, name: 1978},
        {value: 1979, order: 1979, name: 1979},
        {value: 1980, order: 1980, name: 1980},
        {value: 1981, order: 1981, name: 1981},
        {value: 1982, order: 1982, name: 1982},
        {value: 1983, order: 1983, name: 1983},
        {value: 1984, order: 1984, name: 1984},
        {value: 1985, order: 1985, name: 1985},
        {value: 1986, order: 1986, name: 1986},
        {value: 1987, order: 1987, name: 1987},
        {value: 1988, order: 1988, name: 1988},
        {value: 1989, order: 1989, name: 1989},
        {value: 1990, order: 1990, name: 1990},
        {value: 1991, order: 1991, name: 1991},
        {value: 1992, order: 1992, name: 1992},
        {value: 1993, order: 1993, name: 1993},
        {value: 1994, order: 1994, name: 1994},
        {value: 1995, order: 1995, name: 1995},
        {value: 1996, order: 1996, name: 1996},
        {value: 1997, order: 1997, name: 1997},
        {value: 1998, order: 1998, name: 1998},
        {value: 1999, order: 1999, name: 1999},
        {value: 2000, order: 2000, name: 2000},
        {value: 2001, order: 2001, name: 2001},
        {value: 2002, order: 2002, name: 2002},
        {value: 2003, order: 2003, name: 2003},
        {value: 2004, order: 2004, name: 2004},
        {value: 2005, order: 2005, name: 2005},
        {value: 2006, order: 2006, name: 2006},
        {value: 2007, order: 2007, name: 2007},
        {value: 2008, order: 2008, name: 2008},
        {value: 2009, order: 2009, name: 2009},
        {value: 2010, order: 2010, name: 2010},
        {value: 2011, order: 2011, name: 2011},
        {value: 2012, order: 2012, name: 2012},
        {value: 2013, order: 2013, name: 2013},
        {value: 2014, order: 2014, name: 2014},
        {value: 2015, order: 2015, name: 2015},
        {value: 2016, order: 2016, name: 2016},
        {value: 2017, order: 2017, name: 2017},
        {value: 2018, order: 2018, name: 2018},
        {value: 2019, order: 2019, name: 2019},
      ]
    },
    PROP_AC: {
      name: "A/C",
      displayHeader: true,
      inputType: "checkbox",
      filterCategory: "Property information",
      items: [
        {index: 0, value: "A", name: "AC CENTRAL"},
        {index: 1, value: "B", name: "AC CENTRAL AND UNIT"},
        {index: 2, value: "C", name: "AC DUAL UNIT"},
        {index: 3, value: "D", name: "AC EVAPORATIVE"},
        {index: 4, value: "E", name: "AC HEAT PUMP"},
        {index: 5, value: "F", name: "AC REFRIGERATION"},
        {index: 6, value: "G", name: "AC PACKAGE"},
        {index: 7, value: "H", name: "AC SEPARATE SYSTEM"},
        {index: 8, value: "I", name: "AC SPLIT SYSTEM"},
        {index: 9, value: "J", name: "AC WALL UNIT"},
        {index: 10, value: "K", name: "AC WINDOW UNIT"},
        {index: 11, value: "L", name: "AC WALL/WINDOW UNIT"},
      ]
    },
    PROP_POOL: {
      name: "Pool",
      displayHeader: true,
      inputType: "select",
      filterCategory: "Property information",
      items: [
        {value: "", name: "No preference"},
        {value: "Y", name: "Yes"},
      ]
    },
    PROP_FUEL: {
      name: "Fuel",
      displayHeader: true,
      inputType: "checkbox",
      filterCategory: "Property information",
      items: [
        {index: 0, value: "E", name: "ELECTRIC"},
        {index: 1, value: "G", name: "GAS"},
        {index: 2, value: "P", name: "GAS PUBLIC PIPED"},
        {index: 3, value: "O", name: "OIL"},
      ]
    },
    MR_AMT_THOU: {
      name: "Has mortgage",
      displayHeader: true,
      inputType: "select",
      filterCategory: "Mortgage information",
      items: [
        {value: "", name: "No preference"},
        {query: ['(MR_AMT_THOU > "0")'], value: "1", name: "Yes"},
        {value: "0", name: "No"}
      ]
    },
    MR_LOAN_TYP: {
      name: "Mortgage loan type",
      displayHeader: true,
      inputType: "checkbox",
      filterCategory: "Mortgage information",
      items: [
        {index: 0, value: "5", name: "COMMUNITY DEVELOPMENT AUTHORITY"},
        {index: 1, value: "C", name: "CONVENTIONAL"},
        {index: 2, value: "F", name: "FHA"},
        {index: 3, value: "P", name: "PRIVATE PARTY LENDER"},
        {index: 4, value: "S", name: "SMALL BUSINESS ADMINISTRATION"},
        {index: 5, value: "V", name: "VA"},
        {index: 6, value: "W", name: "WRAP-AROUND MORTGAGE"},
      ]
    },
    MR_RATE: {
      name: "Interest rate",
      displayHeader: true,
      inputType: "range",
      filterCategory: "Mortgage information",
      sortItems: "reverse",
      items: [
        {value: 200, order: 1, name: "2.00"},
        {value: 225, order: 2, name: "2.25"},
        {value: 250, order: 3, name: "2.50"},
        {value: 275, order: 4, name: "2.75"},
        {value: 300, order: 5, name: "3.00"},
        {value: 325, order: 6, name: "3.25"},
        {value: 350, order: 7, name: "3.50"},
        {value: 375, order: 8, name: "3.75"},
        {value: 400, order: 8, name: "4.00"},
        {value: 425, order: 10, name: "4.25"},
        {value: 450, order: 11, name: "4.50"},
        {value: 475, order: 12, name: "4.75"},
        {value: 500, order: 13, name: "5.00"},
        {value: 525, order: 14, name: "5.25"},
        {value: 550, order: 15, name: "5.50"},
        {value: 575, order: 16, name: "5.75"},
        {value: 600, order: 17, name: "6.00"},
        {value: 625, order: 18, name: "6.25"},
        {value: 650, order: 19, name: "6.50"},
        {value: 675, order: 20, name: "6.75"},
        {value: 700, order: 21, name: "7.00"},
        {value: 725, order: 22, name: "7.25"},
        {value: 750, order: 23, name: "7.50"},
        {value: 775, order: 24, name: "7.75"},
        {value: 800, order: 25, name: "8.00"},
      ]
    },
    NET_WORTH: {
      name: "Net worth",
      displayHeader: true,
      inputType: "stringRange",
      filterCategory: "Financial",
      sortItems: "reverse",
      items: [
        {value: "A", order: 1, name: "Less than $1"},
        {value: "B", order: 2, name: "$1 - $4K"},
        {value: "C", order: 3, name: "$5K - $9K"},
        {value: "D", order: 4, name: "$10K - $24K"},
        {value: "E", order: 5, name: "$25K - $49K"},
        {value: "F", order: 6, name: "$50K - $99K"},
        {value: "G", order: 7, name: "$100K - $249K"},
        {value: "H", order: 8, name: "$250K - $499K"},
        {value: "I", order: 9, name: "$499K +"},
      ]
    },
    CREDIT_RATING: {
      name: "Credit rating",
      displayHeader: true,
      inputType: "stringRange",
      filterCategory: "Financial",
      sortItems: "reverse",
      items: [
        {value: "A", order: 1, name: "800+"},
        {value: "B", order: 2, name: "750 - 799"},
        {value: "C", order: 3, name: "700 - 749"},
        {value: "D", order: 4, name: "650 - 699"},
        {value: "E", order: 5, name: "600 - 649"},
        {value: "F", order: 6, name: "550 - 599"},
        {value: "G", order: 7, name: "500 - 549"},
        {value: "H", order: 8, name: "under 499"},
      ]
    },
    LANGUAGE: {
      name: "Language",
      displayHeader: true,
      inputType: "checkbox",
      filterCategory: "Demographics",
      items: [
        {index: 0, value: "E1", name: "English"},
        {index: 1, value: "S8", name: "Spanish"},
        {index: 2, value: "K4", name: "Korean"},
        {index: 3, value: "J1", name: "Japanese"},
        {index: 4, value: "V1", name: "Vietnamese"},
        {index: 5, value: "C1", name: "Chinese (Mandarin, Cantonese, ... )"},
        {index: 6, value: "UX", name: "Russian (Bulgarian, Latvian, ... )"},
        {index: 7, value: "T6", name: "Turkish"}
      ]
    },
    RELIGION: {
      name: "Religion",
      displayHeader: true,
      inputType: "checkbox",
      filterCategory: "Demographics",
      items: [
        {index: 0, value: "B", name: "Buddhist"},
        {index: 1, value: "C", name: "Catholic"},
        {index: 2, value: "G", name: "Greek Orthodox"},
        {index: 3, value: "H", name: "Hindu"},
        {index: 4, value: "I", name: "Islamic"},
        {index: 5, value: "J", name: "Jewish"},
        {index: 6, value: "K", name: "Siku"},
        {index: 7, value: "L", name: "Lutheran"},
        {index: 8, value: "M", name: "Mormon"},
        {index: 9, value: "O", name: "Eastern Orthodox"},
        {index: 10, value: "P", name: "Protestant"},
        {index: 11, value: "S", name: "Shinto"},
        {index: 12, value: "X", name: "Unknown"},
      ]
    },
    AREA_CODE: sharedDict.geography.AREA_CODE_Integer,
    COUNTY: sharedDict.geography.COUNTY,
    CITY: sharedDict.geography.CITY,
    ZIP: sharedDict.geography.ZIP,
    ST: sharedDict.geography.ST,
    nationwide: sharedDict.geography.nationwide
  },
  defaultSearchParams: {
    phonePref: [{index: 0, query: [`(DNC_FLAG="False") and (PHONE<>"")`], value: "A", name: "DNC Scrubbed"}],
    PHONE_OCCURRENCE: [{index: 0, value: 1, name: "Unique phone records only", query: [`(PHONE_OCCURRENCE = 1) and (PHONE <> '')`]}],
    CELL_FLAG: [],
    ADDR_OCCURRENCE: [],
    AGE: [],
    DOB_MON: [],
    HH_INCOME: [],
    HOME_OWNR: [],
    HH_MARITAL_STATUS: [],
    GENDER: [],
    HOME_MKT_VALUE: [],
    PROP_TYPE: [],
    PROP_BLD_YR: [],
    PURCH_MON: [],
    PURCH_YEAR: [],
    PROP_AC: [],
    PROP_POOL: [],
    PROP_FUEL: [],
    MR_AMT_THOU: [],
    MR_LOAN_TYP: [],
    MR_RATE: [],
    NET_WORTH: [],
    CREDIT_RATING: [],
    LANGUAGE: [],
    RELIGION: [],
    AREA_CODE: [],
    COUNTY: [],
    CITY: [],
    ZIP: [],
    ST: [],
    nationwide: [],
  },
  keyOrder: { // sets the order that query URLs are built, to optimize queries for indexes
    ZIP: 100,
    FIPS_COMPOSITE: 200,
    CITY: 250,
    COUNTY: 300,
    AREA_CODE: 400,
    ST: 500,
    FIPS_ST: 600,
    AGE: 700,
    DOB_MON: 800,
    HH_INCOME: 900,
    HOME_OWNR: 1000,
    GENDER: 1100,
    phonePref: 1150,
    DNC_FLAG: 1200,
    PHONE: 1300,
    PHONE_OCCURRENCE: 1350,
    CELL_FLAG: 1400,
    ADDR_OCCURRENCE: 1500,
    RELIGION: 1600,
    LANGUAGE: 1700,
    CREDIT_RATING: 1800,
    NET_WORTH: 1900,
    MR_RATE: 2000,
    MR_LOAN_TYP: 2100,
    MR_AMT_THOU: 2200,
    PROP_FUEL: 2300,
    PROP_POOL: 2400,
    PROP_AC: 2500,
    PURCH_YEAR: 2600,
    PURCH_MON: 2700,
    PROP_BLD_YR: 2800,
    PROP_TYPE: 2900,
    HOME_MKT_VALUE: 3000,
    HH_MARITAL_STATUS: 3100,
    nationwide: 3200
  },
  previewCols: {
    display: [
      {label: "First name", content: "FN"},
      {label: "Last name", content: "LN"},
      {label: "Address", content: "ADDR"},
      {label: "City", content: "CITY"},
      {label: "Zip", content: "ZIP"},
      {label: "Phone", content: "PHONE"}],
    popover: [
      {label: "First name", content: "FN"},
      {label: "Last name", content: "LN"},
      {label: "Address", content: "ADDR"},
      {label: "City", content: "CITY"},
      {label: "State", content: "ST"},
      {label: "Zip", content: "ZIP"},
      {label: "Phone", content: "PHONE"},
    ]
  },
  dlCols: [
    {value: "FN", name: "First name", default: true},
    {value: "LN", name: "Last name", default: true},
    {value: "ADDR", name: "Street address", default: true},
    {value: "APT", name: "Apartment number", default: true},
    {value: "CITY", name: "City", default: true},
    {value: "ST", name: "State", default: true},
    {value: "ZIP", name: "Zip code", default: true},
    {value: "Z4", name: "Zip 4", default: true},
    {value: "COUNTY", name: "County", default: true},
    {value: "AREA_CODE", name: "Area code", default: true},
    {value: "PHONE", name: "Phone", default: true},
    {value: "AGE", name: "Age", default: true},
    {value: "DOB_MON", name: "Birth month", default: true},
    {value: "HH_INCOME", name: "Est income", default: true},
  ],
  selectFilterValidation: ["HOME_OWNR", "HH_MARITAL_STATUS", "PROP_TYPE", "PROP_AC", "PROP_FUEL", "MR_LOAN_TYP", "LANGUAGE", "RELIGION"],
  leadsMultiplier: 1,
  access: "Any subscription",
  directorySearchFields: [
    {
      name: "FN",
      boost: 3
    },
    {
      name: "LN",
      boost: 3
    },
    {
      name: "EMAIL",
      boost: 2
    },
    {
      name: "PHONE",
      boost: 2
    },
    {
      name: "ADDR"
    },
    {
      name: "APT"
    },
    {
      name: "CITY"
    },
    {
      name: "ST"
    },
    {
      name: "ZIP"
    },
    {
      name: "COUNTY"
    }
  ],
  summaryStats: {
    totalCount: 241983292
  }
};
