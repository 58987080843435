import React, {useState, useEffect} from "react";
import {Link} from "react-router-dom";
import {
  Button,
  Dropdown,
  Modal,
  Form,
  ButtonGroup,
  Card,
  Container,
  Row,
  Col,
  CardColumns
} from "react-bootstrap";
import {
  ThreeDots,
  BookmarkPlus,
  BookmarkStarFill,
  ChevronLeft,
  CheckSquareFill,
  DashSquareFill,
} from "react-bootstrap-icons";

const createBoard = async (params, callback) => {
  //listConfig should be shaped like:
  //{
  //  name: "string",
  //  category: ["s1", "s2"],
  //  description: true,
  //  favorite: true,
  //  showOtherTab: false,
  //  tabs: [ {fieldValue: ["s1", "s2"], tabTitle: "S1 & S2"}]
  //}

  let { config, aid, uid, currentBoards, ref } = params;
  //console.log("params: ", params);
  let defaultConfig = {
    name: `Item ${currentBoards ? currentBoards.length + 1 : ""}`,
    description: `Created ${new Date().toLocaleString()}`,
    aid,
    uid,
    createdBy: uid,
    favorite: false,
    archived: false,
    _created: Math.round(new Date().getTime() / 1000),
    lastUpdated: Math.round(new Date().getTime() / 1000),
  };
  if (!config) {
    config = defaultConfig;
  } else {
    Object.keys(defaultConfig).forEach((k) => {
      if (!config[k]) {
        return config[k] = defaultConfig[k];
      }
    });
  }
  try {
    let newBoardRef = await ref.add(config);
    config._docId = newBoardRef.id;
    config._docRef = newBoardRef;
    if (callback) {
      callback(null, config);
    }
    return config;
  } catch (err) {
    console.error("Something went wrong in createBoard: ", err);
    if (callback) {
      callback(err);
    }
  }
};

const updateBoard = async (params, callback) => {
  console.log("updateList params: ", params);
  let { config, ref } = params;
  try {
    config.lastUpdated = Math.round(new Date().getTime() / 1000);
    let update = await ref.update(config);
    if (callback) {
      callback(null, update);
    }
    return update;
  } catch (err) {
    console.log("Something went wrong updating the list: ", err);
    if (callback) {
      callback(err);
    }
  }
};

const FavoriteButton = props => {
  const { config, size } = props;
  let favorite, themeColor;
  if (config) {
    favorite = config.favorite;
    themeColor = config.themeColor;
  }
  const toggleFavorite = (config) => {
    let newDoc = { ...config };
    newDoc.favorite = !newDoc.favorite;
    newDoc.lastUpdated = Math.round(new Date().getTime() / 1000);
    newDoc._docRef.update(newDoc);
  };

  return (
    <Button
      onClick={() => toggleFavorite(config)}
      variant="link"
      className={props.className ? props.className : ""}
      style={props.style ? props.style : null}
    >
      {favorite && <BookmarkStarFill size={size} />}
      {!favorite && (
        <BookmarkPlus
          size={size}
          className={
            props.className
            ? props.className
            : themeColor
              ? themeColor === "dark"
                ? "text-white"
                : `text-${themeColor}`
              : "text-dark"
          }
        />
      )}
    </Button>
  );
};

const TrelloBoardHeader = props => {
  const { config, backPath, hideFavorite } = props;
  let favorite, themeColor;
  //let buttonThemeColor = config ? config.themeColor ? config.themeColor === "light" ? "outline-dark" : `outline-${config.themeColor}` : "outline-dark" : "outline-dark";
  if (config) {
    favorite = config.favorite;
    themeColor = config ? config.themeColor ? config.themeColor === "light" ? "dark" : config.themeColor : "odark" : "dark";

  }
  return (
    <div className="d-flex flex-row justify-content-start mx-2 mt-2 mb-5">
      <div className="d-flex flex-row justify-content-start">
        <Link
          to={backPath ? backPath : "/"}
          className={`me-2 p-0 d-flex flex-column align-items-center justify-content-center border rounded bg-light border-${
            themeColor
          }`}
        >
          <ChevronLeft size={24} className={`text-${themeColor}`} />
        </Link>

        <div
          className={`d-flex flex-row align-items-center p-1 pb-0 border rounded bg-light border-${
            themeColor
          }`}
        >
          {!hideFavorite &&
            <FavoriteButton
              size={25}
              config={config}
              className={`display-inline p-0 ${
                favorite ? `text-${themeColor} ` : ""
              }`}
            />
          }
          <span className={`mb-0 pb-0 pe-3 ${hideFavorite ? "ps-3" : ""}`} style={{ fontSize: "20px" }}>
            {config ? config.name.slice(0, 30) : "Loading Name"}
          </span>
        </div>

        <Dropdown>
          <Dropdown.Toggle
            id="list-menu"
            className={`ms-2 p-2 border rounded bg-light border-${
              themeColor
            }`}
            style={{height: "100%"}}
          >
            <ThreeDots size={24} className={`text-${themeColor}`} />
          </Dropdown.Toggle>
          <Dropdown.Menu align="left">
            {!props.hideDropMenuDefaults && 
              <>
                <ManageBoard className="dropdown-item" {...props} editBoard={true}>
                  Edit name & theme color
                </ManageBoard>
                <Link
                  className="dropdown-item text-dark"
                  to={backPath}
                  onClick={() => {
                    console.log("Archive this Board.");
                      config._docRef.update({
                        archived: true,
                        _archived: Math.round(new Date().getTime() / 1000),
                      });
                  }}
                >
                  Archive
                </Link>
              </>
            }
            {props.dropMenuItems &&
              props.dropMenuItems
            }
          </Dropdown.Menu>
        </Dropdown>
      </div>
      {props.rightButtons &&
        <ButtonGroup>
          {props.rightButtons}
        </ButtonGroup>
      }
      {props.rightNav ? props.rightNav : null}
    </div>
  );
}

const ManageBoard = props => {
  const {
    userDoc,
    user,
    editBoard,
    config,
    currentBoards,
    boardCollectionRef,
    onCreate
  } = props;
  const [newBoardName, setNewBoardName] = useState("");
  const [themeColor, setThemeColor] = useState("light");
  const [description, setDescription] = useState("");
  const [showCreateBoard, setShowCreateBoard] = useState(false);
  //console.log("ManageList listConfig: ", listConfig);
  useEffect(() => {
    if (editBoard) {
      setNewBoardName(config.name);
      setThemeColor(config.themeColor);
      setDescription(config.description);
    }
  }, [editBoard, config.name, config.themeColor, config.description]);
  return (
    <React.Fragment>
      <div
        className={props.className ? props.className : ""}
        style={props.style ? props.style : null}
        onClick={() => setShowCreateBoard(true)}
      >
        {props.children}
      </div>
      {showCreateBoard && (
        <Modal
          show={showCreateBoard}
          onHide={() => setShowCreateBoard(false)}
          //autoFocus={false}
        >
          <Modal.Body className={`bg-${themeColor}`}>
            <Form.Group>
              <Form.Control
                size="lg"
                type="text"
                value={newBoardName}
                onChange={(e) => setNewBoardName(e.target.value)}
                placeholder="Add name"
                autoFocus={true}
              />
            </Form.Group>
            <Form.Group>
              <Form.Control
                as="textarea"
                rows={2}
                value={description}
                onChange={(e) => setDescription(e.target.value)}
                placeholder="Add an optional description"
              />
            </Form.Group>
            <Form.Group className="bg-white p-2 rounded">
              <Form.Label>Select color</Form.Label>
              <div className="d-flex flex-row bg-white">
                {[
                  "light",
                  "primary",
                  "secondary",
                  "success",
                  "info",
                  "danger",
                  "warning",
                  "dark",
                ].map((t) => {
                  let cColor = t === "light" ? "dark" : "white";
                  return (
                    <div
                      key={`color-${t}`}
                      onClick={() => setThemeColor(t)}
                      className={`bg-white`}
                    >
                      {themeColor === t && (
                        <CheckSquareFill
                          className={`text-${t} m-1 bg-${cColor}`}
                          size={30}
                        />
                      )}
                      {themeColor !== t && (
                        <DashSquareFill
                          className={`text-${t} m-1 bg-${cColor}`}
                          size={30}
                        />
                      )}
                    </div>
                  );
                })}
              </div>
            </Form.Group>
          </Modal.Body>
          <Modal.Footer className="d-flex justify-content-between">
            <Button
              variant="link"
              size="sm"
              className="text-muted"
              onClick={() => {
                console.log("Clicked nevermind!");
                setShowCreateBoard(false);
              }}
            >
              Nevermind
            </Button>
            {!editBoard && (
              <Button
                onClick={async () => {
                  console.log("Clicked create new list!");
                  let newBoard = await createBoard({
                    config: {
                      name: newBoardName,
                      description,
                      themeColor,
                      ...config
                    },
                    aid: userDoc.id,
                    uid: user.uid,
                    currentBoards,
                    ref: boardCollectionRef
                  });
                  if (onCreate) {
                    onCreate(newBoard);
                  }
                  setNewBoardName("");
                  setThemeColor("light");
                  setShowCreateBoard(false);
                }}
              >
                Create
              </Button>
            )}
            {editBoard && (
              <Button
                onClick={(e) =>
                  updateBoard(
                    {
                      config: {
                        name: newBoardName,
                        themeColor,
                        description,
                        _docId: config._docId,
                      },
                      aid: userDoc.id,
                      uid: user.uid,
                      ref: config._docRef
                    },
                    (error, update) => {
                      if (error) {
                        console.log("Error updating board: ", error);
                      }
                      if (update) {
                        console.log("Board updated: ", update);
                      }
                      setShowCreateBoard(false);
                    }
                  )
                }
              >
                Update
              </Button>
            )}
          </Modal.Footer>
        </Modal>
      )}
    </React.Fragment>
  );
};

const TrelloBoardList = props => {
  const { docs, displayDate, cardPath, hideNew, cardTheme, hideFavorites, cardDecorator } = props;
  let favoriteBoards = [];
  let allBoards = [];
  docs.map((doc, i) => {
    const {
      name,
      description,
      lastUpdated,
      favorite,
      themeColor,
      _docId,
    } = doc;
    //console.log("summary: ", doc);
    let cardLink = {
      pathname: `${cardPath}/${_docId}`,
      config: doc
    };

    let card = (
      <Col key={`summary_${i}`} className="mb-3">
      <Card
        bg={cardTheme === "topBorder" ? "light" : themeColor ? themeColor : "light"}
        style={{ 
          //height: "165px",
          borderWidth: cardTheme === "topBorder" ? "12px 1px 1px 1px" : "inherit"
        }}
        className={
          themeColor
            ? cardTheme === "topBorder" ? `h-100 text-dark border-${themeColor}` : themeColor === "dark"
              ? "text-dark"
              : ""
            : "h-100 text-dark"
        }
      >
        <Card.Body
          className="d-flex flex-column justify-content-between"
          style={{ height: "165px" }}
        >
          {!hideFavorites &&
            <FavoriteButton
              size={30}
              className={
                themeColor
                  ? themeColor === "dark"
                    ? "text-dark"
                    : "text-dark"
                  : "text-dark"
              }
              style={{
                position: "absolute",
                right: "-10px",
                top: "0px",
              }}
              config={doc}
            />
          }
          <Card.Title>
            <Link
              className={
                themeColor === "dark" ? "text-dark" : "text-dark"
              }
              style={{textDecoration: "none"}}
              to={{ path:cardLink, doc}}
            >
              {name}
            </Link>
          </Card.Title>
          {cardDecorator && 
            cardDecorator(doc)
          }
          {!cardDecorator &&
            <React.Fragment>
              <Card.Text
                style={{
                  maxHeight: "48px",
                  overflow: "hidden",
                }}
              >
                {description}
              </Card.Text>
              <div></div>
              <Card.Text>
                <small>
                  {lastUpdated
                    ? `Updated ${displayDate(lastUpdated)}`
                    : ""}
                </small>
                <Link to={cardLink} className="btn btn-sm btn-round btn-light pull-right">
                  <small>View</small>
                </Link>
              </Card.Text>
            </React.Fragment>
          }
          
        </Card.Body>
      </Card>
      </Col>
    );
    if (favorite && !hideFavorites) {
      favoriteBoards.push(card);
    }
    return allBoards.push(card);
  });
  return (
    <Container>
      <Row>
        {props.children &&
          <Col xs={12}>
            {props.children}
          </Col>
        }
        <Col>
          {props.title ? props.title : null}
          {!hideFavorites &&
            <React.Fragment>
              <h5 className="mt-2">
                <BookmarkStarFill className="me-2" />
                Favorites
              </h5>
              {!favoriteBoards.length && (
                <div
                  className="d-block bg-white border rounded mb-5 p-3 pb-5"
                  style={{
                    height: "100px",
                  }}
                >
                  <p className="text-muted">
                    <small>
                      Add to your favorites by clicking the bookmark
                      on an item below.
                    </small>
                  </p>
                </div>
              )}
              {favoriteBoards.length > 0 && (
                <Row xs={1} sm={2} md={2} lg={3} className="pb-5">
                  {favoriteBoards}
                </Row>
              )}
              <h5>All</h5>
            </React.Fragment>
          }
          
          <Row xs={1} sm={1} md={2} lg={3} className="pb-5">
            {!hideNew &&
              <Col className="mb-3">
                <ManageBoard
                  {...props}
                  currentBoards={docs}
                  editBoard={false}
                  className="card bg-light h-100"
                >
                  <Card.Body className="d-flex flex-column justify-content-center align-items-middle text-center">
                    Create New
                  </Card.Body>
                </ManageBoard>
              </Col>
            }
            {allBoards}
          </Row>
        </Col>
      </Row>
    </Container>
  );
}

export {TrelloBoardList, TrelloBoardHeader, createBoard};
