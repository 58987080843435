import React from "react";
import { Button } from "react-bootstrap";

const Email = props => {
  const {emailObject, keyId} = props;
  return(
    <a
      className={props.className ? props.className : ""}
      style={props.style ? props.style : null}
      rel="noopener noreferrer"
      target="_blank"
      href={`mailto:${emailObject["DATA"]}`}
    >
      {emailObject["DATA"]}
    </a>
  )
}

let blankEmail = {
  "DATA": "",
  "OPT_IN": "",
  "TYPE": "EMAIL",
  "OPT_OUT": null
}

const AddEmailButton = props => {
  let {onAdd} = props;
  return(
    <Button 
      size={props.size ? props.size : "md"}
      variant={ props.variant ? props.variant : "primary"}
      style={props.style ? props.style : null}
      className={props.className ? props.className : ""}
      onClick={e => {
        //Create blank addr object
        
        // Do something with the object via a prop
        if (onAdd) {
          onAdd(blankEmail);
        }
      }}
    >
      {props.btnText ? props.btnText : "Add Phone"}
    </Button>
  );
}

export { Email, AddEmailButton, blankEmail };