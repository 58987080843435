const sendText = (params, callback) => {
  const {apiKey, msg, handleAlerts} = params;
  console.log("sendText params: ", params);
  if (msg.to === undefined || msg.to === "") {
    return handleAlerts(
      "",
      "Please include a 'To' phone number for your message.",
      "warning"
    );
  }
  if (msg.message === undefined || msg.message === "") {
    return handleAlerts(
      "",
      "Please include a message.",
      "warning"
    );
  }

  try {
    const init = {
      method: "POST",
      headers: {
        authorization: `Bearer ${apiKey}`,
        "Content-Type": "application/json"
      },
      "Transfer-Encoding": "chunked",
      cache: "default",
      accept: "application/json",
      body: JSON.stringify( msg )
    };
    let url = `${process.env.REACT_APP_api_url}/api/sms`;
    //console.log("url: ", url, "init: ", init);
    fetch(url, init);
    if (callback) {
      return callback(null, msg);
    }
    return;
  } catch (err) {
    console.log("There was an error sending email: ", err);
    if (callback) {
      return callback(err);
    }
  }
};



export { sendText };
