import React, { useState, useEffect, useRef } from "react";
import PropTypes from "prop-types";
import { Link, useParams } from "react-router-dom";
import { Container, Row, Col, Button, Card } from "react-bootstrap";
import AutoComplete from "./autoComplete";
import { formatPhoneNumber, object_equals, removeItemAll } from "./interfaceListShackPro";
import queryString from "query-string";
import {Search, X, PlusCircle } from "react-bootstrap-icons";
import esb from "elastic-builder";

const decodeSearchFilter = ( fvalues ) => {
  //let filter = {};
  //fvalues.forEach( v => {
  //  let pair = v.split("=>");
  //  filter[ `${pair[0]}.keyword` ] = {
  //    value: pair[1],
  //    boost: 1
  //  };
  //})
  if (!fvalues || !Array.isArray(fvalues) ) {
    console.log("There are no search filters or they are malformed! ", fvalues)
    return []
  }
  let filters = [];
  fvalues.forEach( f => {
    let triad = f.split("=>")
    filters.push(triad);
    if ( !triad[2] ) {
      triad.push("");
    }
  })
  return filters;
}

let isCurrentFilter = ({currentfilters, fieldname, fieldvalue}) => {
  //console.log("currentfilters: ", currentfilters)
  for ( let f of currentfilters) {
    if (f[1] === fieldname && f[2] === fieldvalue) {
      return true
    }
  }
  return false
}

const Directory = props => {
	console.log("Directory props: ", props);
	const { fetchFromApi, dicts, history, handleAlerts, querystring } = props;

	let searchtext, record, searchsize, searchtype, searchfields, searchfilters;
	if (Object.keys(querystring).length) {
		searchtext = querystring.searchtext;
		record = querystring.record;
		searchsize = querystring.size ? parseInt(querystring.size) : 10;
		searchtype = querystring.type;
		searchfields = querystring.fields ? querystring.fields.split(",") : "";
    searchfilters = querystring.filters ? querystring.filters.split(",") : "";
	}

	const [searchTypes, setSearchTypes] = useState(null);
	const [searchResults, setSearchResults] = useState(null);
	const [lastSearch, setLastSearch] = useState("");
	const [selectedRecord, setSelectedRecord] = useState(null);

	const fetchSearchResults = async (value, size, fields, filters) => {
		//console.log("Searching Directory searchFields: ", searchFields, )
		let index = searchTypes ? searchTypes[searchtype ? searchtype : "All" ] : dicts.map( d => d.index);

		//let fields = [];
		//dicts.forEach( d => {
		//	// Only include fields for selected indexes
		//	if (index.includes(d.index)) {
		//		d.directorySearchFields.forEach( f => {
		//			if ( searchFields.includes(f) ) { fields.push(f) }
		//		})
		//	}
		//})
		let surchFields = fields ? fields : searchfields ? calculateSearchFields(undefined,searchfields) : calculateSearchFields();
		let highlightFields = {};
		surchFields.forEach( f => {
			highlightFields[f.name] = {}
		});
		//console.log("index: ", index, "fields: ", fields);
    let surchFilter = filters ? filters : searchfilters ? decodeSearchFilter(searchfilters) : null;
    let body;
    //if (!surchFilter) {
      //body = {
        //search: {
          //index,
          //size: size ? size : searchsize ? searchsize : 10,
          //body: {
            //query: {
              //multi_match: {
                //query: `*${value}*`,
                //fields: surchFields.map( f => {
                  //if (f.boost) {
                    //return `${f.name}^${f.boost}`
                  //}
                  //return f.name
                //})
              //}
            //},
            //highlight: {
              //fields: highlightFields 
            //}
          //}
        //}
      //};
    //} 
    //if (surchFilter) {
      //body = {
        //search: {
          //index,
          //size: size ? size : searchsize ? searchsize : 10,
          //body: {
            //query: {
              //bool: { 
                //must: { 
                  //multi_match: {
                    //query: `*${value}*`,
                    //fields: surchFields.map( f => {
                      //if (f.boost) {
                        //return `${f.name}^${f.boost}`
                      //}
                      //return f.name
                    //})
                  //}
                //},
                //filter: { term: surchFilter }
              //}
            //}
          //}
        //}
      //}
    //}
    
    let searchbody = esb.boolQuery();
    searchbody = searchbody.must([
      esb.multiMatchQuery(
        surchFields.map( f => {
          if (f.boost) {
            return `${f.name}^${f.boost}`
          }
          return f.name
        }),
        `*${value}*`
      )
    ]);
    if (surchFilter) {
      console.log("surchFilters: ", surchFilter);
      surchFilter.forEach(f => {
        let operator = f[0];
        let fieldname = f[1];
        let fieldvalue = f[2];
        if (operator === "term") {
          searchbody = searchbody.filter(
            esb.termQuery(`${fieldname}.keyword`, fieldvalue)
            .boost(1)
          )
        }
        if (operator === "exists") {
          searchbody = searchbody.filter(
            esb.existsQuery(fieldname)
            .boost(1)
          )
        }
        if (operator === "mustnot") {
          searchbody = searchbody.mustNot(
            esb.termQuery(`${fieldname}.keyword`, fieldvalue)
            .boost(1)
          )
        }
      }) 
      
    }

    let requestbody = esb.requestBodySearch();
    //if (!surchFilter) {
    if (true) {
      requestbody = requestbody.highlight(esb.highlight(surchFields.map(f => f.name)));
    }
    if (surchFields) {
      requestbody = requestbody.query(searchbody)
    }
    requestbody = requestbody.toJSON();
    console.log("requestbody: ", requestbody);

    body = {
      search: {
        index,
        size: size ? size : searchsize ? searchsize : 10,
        body: requestbody
      }
    }
		console.log("body: ", body);
		let result = await fetchFromApi({
			endpoint: "/api/v2/search",
			body
		});
		console.log("result: ", result);
		if(result.status ? result.status === false : false) {
			handleAlerts("", result.status.error, "warning");
			throw new Error(result.error);
		}
		return result;
	}
	const showSearchResults = async (text) => {
		try {
			setLastSearch( window.location.search );
			let results = await fetchSearchResults(text ? text : searchtext);
			setSearchResults(results);
		} catch (err) {
			console.log("Error showSearchResults: ", err);
		}
	}

	const createUrl = ({text, size, rec, type, sfields, sfilters}) => {
    console.log("sfilters: ", sfilters);
		let sizeString = "";
		let recordString = "";
		let searchString = text || searchtext ? `?searchtext=${text ? text : searchtext}` : "";
		let typeString = type ? `&type=${type}` : searchtype ? `&type=${searchtype}` : "";
		let fieldsString = sfields ? `&fields=${Array.isArray(sfields) ? sfields.join(",") : sfields}` : searchfields ? `&fields=${searchfields.join(",")}` : "";
    let filtersString = sfilters ? `&filters=${Array.isArray(sfilters) ? sfilters.join(","): sfilters}` : searchfilters ? `&filters=${searchfilters.join(",")}` : "";
		if ( text === queryString.parse(lastSearch).searchtext || searchtext === queryString.parse(lastSearch).searchtext ) {
			sizeString = size ? `&size=${size}` : searchsize ? `&size=${searchsize}` : "";
			recordString = rec ? `&record=${rec}` : record ? `&record=${record}` : "";
		} else {
			setSelectedRecord(null);
		}
		return `${searchString}${typeString}${fieldsString}${filtersString}${sizeString}${recordString}`
	}

	const setUrl = (params) => {
		//console.log("text: ", text, "size: ", size, "rec: ", rec);
		let url = createUrl(params);
		
		history.push({
			search: url
		})
	}

	const calculateSearchTypes = () => {
		let st = {
			"All": []
		};
		dicts.forEach( d => {
			st["All"].push(d.index);
			if ( !st[d.type] ) {
				st[d.type] = [d.index];
			} else {
				st[d.type].push(d.index);
			}
		})
		return st;
	}

	const calculateSearchFields = ( selectedIndexes = searchTypes ? searchTypes[searchtype ? searchtype : "All"] : false, whiteListedFields ) => {
		// If there aren't any indexes, then return an empty array.
		// Likely the javascript is still loading and hasn't run the useEffect yet.
		if ( !selectedIndexes ) {
			return []
		}
		// Otherwise grab all the searchFields from the selected indexes and return those
		let surchFieldNames = [];
		let surchFields = [];
		dicts.forEach( d => {
			if (selectedIndexes.includes(d.index)) {
				d.directorySearchFields.forEach(f => {
					//console.log("Evaluating f.name: ", f.name);
					//console.log("surchFieldNames: ", surchFieldNames);
					//console.log("!surchFieldNames.includes(f.name): ", !surchFieldNames.includes(f.name) );
					//console.log("whiteListedFields ? whiteListedFields.includes(f.name): true => ", whiteListedFields ? whiteListedFields.includes(f.name) : true );
					//console.log("Final evaluation: ", !surchFieldNames.includes(f.name) && (whiteListedFields ? whiteListedFields.includes(f.name) : true) ); 
					//If the field name isn't already included and if the fieldname is one of the whitelisted field names
					if( !surchFieldNames.includes(f.name) && (whiteListedFields ? whiteListedFields.includes(f.name) : true) ) { 
						//Include the field in the surchfields
						surchFields.push(f)
						surchFieldNames.push(f.name);
					}
				})
			}
		})
		return surchFields;
	}

  const encodeSearchMust = ( must_obj ) => {

  }

	useEffect( () => {
		// Make sure there are searchFields and Indexes to fetch search results for
		if (!searchTypes) {
			let st = calculateSearchTypes();
			setSearchTypes(st);
			//console.log("searchfields: ", !searchfields, calculateSearchFields(st.All));
		}
		
		//console.log("querystring: ", querystring, "window.location.search: ", window.location.search, "lastSearch: ", lastSearch);
		//console.log("shouldfetch: ", window.location.search !== lastSearch);
		if ( Object.keys(querystring).length && selectedRecord ? selectedRecord._id !== record : true ) {
			let nsrecord = searchResults ? searchResults.hits.hits.find( h => h._id === record) : false;
			if (nsrecord) {
				setSelectedRecord(nsrecord)
			} else {
				setSelectedRecord("")
			}
		}
		// If the querystring has changed, fetch some search results
		if ( Object.keys(querystring).length && searchtext && searchTypes && window.location.search !== lastSearch ) {
			showSearchResults(searchtext);
		}
	});
  let displayedSearchResults = [];
  let refineSearchResultsSuggestions = [];
  if (searchResults) {
    searchResults.hits.hits.forEach( h => {
      //console.log("h: ", h);
      if (selectedRecord ? h._id === selectedRecord._id : false) {
        displayedSearchResults.push(
          <DisplayRecord 
            {...props}
            key={`directorySearchHits_${h._id}`}
            record={h} 
            className="bg-light p-2 mb-3"
            setUrl={setUrl}
            searchfilters={searchfilters}
            selectedRecordId={selectedRecord._id}
          />
        )
      } else {
        displayedSearchResults.push(
          <DisplayResult 
            {...props}
            key={`directorySearchHits_${h._id}`}
            record={h}
            className="p-2 pb-3"
            selectResult={setUrl}
            searchfilters={searchfilters}
            setUrl={setUrl}
            selectedRecordId={selectedRecord._id}
          />
        );
      }
      if (h.highlight) {
        refineSearchResultsSuggestions.push(h);
      }
    })
  }

	//console.log("searchResults: ", searchResults, "searchTypes: ", searchTypes, "lastSearch: ", lastSearch, "selectedRecord: ", selectedRecord);
	return(
		<Container id="directory">
			<Row style={{minHeight: "75vh"}}>
				<Col md={7} xs={12}>
					<AutoComplete
						className="pt-5"
						inputClassName="text-start"
						searchText={searchtext}
						onSearch={ async (value) => {
							let result = await fetchSearchResults(value, 10);
							return result.hits.hits;
						}}
						onSubmit={ text => {
							history.push({
								search: createUrl({text})
							});
						}}
						onSelect={ (h, searchText) => {
							console.log("onSelect!")
							setUrl({ text: searchText, size: searchsize, rec: h._id });
						}}
						clearSearchTextOnSelect={false}
						onClearSearch={() => {
							history.push({
								search: ""
							});
							setSearchResults(null);
						}}
						placeholder="Type a search term here"
						suggestion={ h => <DisplayResult
							{...props}
							record={h}
							className="text-muted"
							selectResult={() => setUrl({rec: h._id})}
						/>
						}
						showSubmit={true}
						submitText={<Search />}
					/>
					{searchTypes &&
						<div className="mt-2 mb-5 d-flex justify-content-start">
							{Object.keys(searchTypes).map( st => {
								return(
									<Button
										key={`searchTypeButton_${st}`}
										size="sm"
										variant={searchtype ? st === searchtype ? "primary" : "outline-primary" : st === "All" ? "primary": "outline-primary"}
										className="ms-2"
										onClick={() => {
											setUrl({type: st});
										}}
									>
										{st}
									</Button>
								)
							})}
						</div>
					}
					{searchResults &&
						<div>
							<small className="text-muted">About {searchResults.hits.total.value.toLocaleString()} results</small>
							{displayedSearchResults.slice(0,3)}
              {refineSearchResultsSuggestions.length > 0 &&
                <Card className="bg-light mb-3">
                  <Card.Body>
                    <span className="text-underline text-muted">Refine your search results</span><br />
                    <MakeSearchHighlightSuggestions 
                      records={refineSearchResultsSuggestions} 
                      searchfilters={searchfilters} 
                      setUrl={setUrl} 
                    />
                    <br />
                    <small className="text-muted pt-3">Common filters</small>
                    <br />
                    {commonFilters.map( (f,i) =>        
                      <CreateFilterSuggestionButton 
                        key={`commonFilters_${i}`}
                        {...f} 
                        setUrl={setUrl} 
                        searchfilters={searchfilters}
                      />
                    )}
                  </Card.Body>
                </Card>
              }
              {displayedSearchResults.slice(3)}
							{searchResults.hits.total.value > searchsize &&
								<div className="d-block mb-5">
									<Button variant="outline-primary"
										onClick={ () => {
											setUrl({
												size: parseInt(searchsize) + 10
											})
										}}
									>
										Load More
									</Button>
								</div>
							}
						</div>
					}
				</Col>
				<Col xs={12} md={5}>
          {(true) &&
            <Card style={{marginTop: "25%"}} className="mb-2">
              <Card.Body>
                <div className="d-flex flex-row justify-content-between align-items-center">
                  <h6 className="mb-0">Filters</h6>
                  <Button 
                    variant="link"
                    size="sm"
                    onClick={() => console.log("Add filter clicked")}
                    className="text-muted p-0"
                  >
                    <PlusCircle size={20} />
                  </Button>
                </div>
                {(searchfilters ? searchfilters.length > 0 : false) && 
                    searchfilters.map( f => {
                      let t = f.split("=>");
                      if ( !t[2] ) { t.push("") }
                      let operator = t[0];
                      let fieldname = t[1];
                      let fieldvalue = t[2];
                      console.log("filtertriad: ", f);
                      let buttonText = "";
                      if (operator === "term") {
                        buttonText = `Field ${fieldname} must be ${fieldvalue}`;
                      }
                      if (operator === "exists") {
                        buttonText = `Must include ${fieldname}`
                      }
                      if (operator === "mustnot") {
                        if (fieldname === "PHONE") {
                          buttonText = `Must include ${fieldname}`;
                        }
                        
                      }
                      return(
                        <Button 
                          key={`filter_button_${f}`}
                          size="sm"
                          variant="light"
                          onClick={ () => {
                            let sfilters = ( searchfilters ? searchfilters.filter(nf => nf !== f) : "" );
                            console.log("new search fields: ", sfilters);
                            setUrl({
                              sfilters
                            })
                          }}
                          className="m-1 d-flex flex-row justify-content-between align-items-start"
                        >
                          <span className="text-start">{buttonText}</span> <X />
                        </Button>
                      )
                    })
                  }
              </Card.Body>
            </Card>
          }
					{searchTypes &&
						<Card style={ (searchfilters) ? null : {marginTop: "25%"}}>
							<Card.Body>
                <div className="d-flex flex-row justify-content-between align-items-center">
                  <h6>Searching the following fields</h6>
                  <Button 
                    variant="link"
                    size="sm"
                    onClick={() => setUrl({sfields: calculateSearchFields().map(f => f.name) })}
                  >
                    Reset
                  </Button>
                </div>
									{calculateSearchFields().map( f => {
                    if ( (searchfields ? searchfields.includes(f.name) : true ) )
										return(
											<Button 
												key={`field_button_${f.name}`}
												size="sm"
												variant="light"
												onClick={ () => {
													let sfields = ( searchfields ? searchfields.filter(nf => nf !== f.name) : calculateSearchFields().filter( nf => nf.name !== f.name).map( f => f.name) );
													console.log("new search fields: ", sfields);
													setUrl({
														sfields
													})
												}}
												className="m-1"
											>
												{f.name} <X />
											</Button>
										)
									})}
							</Card.Body>
						</Card>
					}
				</Col>
			</Row>
		</Container>
	)
}

const RecordView = props => {
	const {fetchFromApi, dicts, selectedRecord} = props;
	let {indexid, recordid} = useParams();
	let [record, setRecord] = useState(selectedRecord ? selectedRecord : null);
	console.log("RecordView props: ", props, "indexid", indexid, "recordid: ", recordid);

	useEffect( () => {
		if (recordid && indexid && !record) {
			fetchFromApi({
				endpoint: "/api/v2/search",
				body: {
					search: {
						index: indexid,
						body: {
							query: {
								ids: {
									values: [recordid]
								}
							}
						}
					}
				}
			},
				(error, record) => {
					if (record) {
						console.log("record: ", record);
						setRecord(record);
					}
					if (error) {
						console.log("Error fetching record from elasticsearch: ", error);
					}
				}
			);
		}
	})

	if (!record) {
		return null
	}
	return(
		<Container id="recordview">
			<Row style={{minHeight: "75vh"}}> 
				<Col xs={12} sm={10} md={8}>
					<DisplayRecord {...props} record={record.hits.hits[0]} hideViewBtn={true} />
				</Col>
			</Row>
		</Container>
	)
}

const DisplaySearchHighlight = props => {
	let {record} = props;
	if (!record.highlight) {
		return null
	}
  //console.log("record.highlight: ", record.highlight)
  let highlights = [];
  let hvalues = Object.values(record.highlight);
  Object.keys(record.highlight).forEach( (highlight,i) => {
    let hv = hvalues[i].map( v => v.toLowerCase() );
    //console.log("i, highlight, hv: ", i, highlight, hv);
    highlights.push(
      <React.Fragment key={`displaysearchhighlight_${record._id}_${i}`}>
        <span className="ms-1" dangerouslySetInnerHTML={{__html: highlight}} />: <span className="text-capitalize" dangerouslySetInnerHTML={{__html: hv }} />
      </React.Fragment>
    )
  })
	return highlights
}

const MakeSearchHighlightSuggestions = props => {
  let {records, searchfilters, setUrl} = props;
  //console.log("MakeSearchHighlightSuggestions props: ", props);
  let excludedFields = [];
  let excludedValues = [];
  let suggestions = [];
  if (!records) { return null}
  let currentfilters = decodeSearchFilter(searchfilters);

  records.forEach( (record,i) => {
    if (!record.highlight) {
      return null
    }
    let hvalues = Object.values(record.highlight);
    Object.keys(record.highlight).forEach( (fieldname,index) => {
      if ( excludedFields.includes(fieldname) ) {
        return null;
      }
      if ( isCurrentFilter({
        currentfilters, 
        fieldname, 
        fieldvalue: record._source[fieldname]
      }) ) {
        return null
      }
      //console.log("record: ", record);
      let hv = hvalues[index].map( v => v.toLowerCase() );
      if (fieldname === "SIC_6") {
        if ( excludedValues.includes(record._source[fieldname]) ) {
          return null;
        }
        suggestions.push(
          <CreateFilterSuggestionButton
            key={`createfiltersuggestionbutton_${fieldname}${i}${index}`}
            setUrl={setUrl}
            searchfilters={searchfilters}
            fieldname={fieldname}
            fieldvalue={record._source[fieldname]}
            operator="term"
            buttonText={<small>Only show me businesses of the category: <span className="text-capitalize" dangerouslySetInnerHTML={{__html: hv }} /></small>}
          />
        )
        excludedValues.push(record._source[fieldname]);
      }
    })
  })
  //console.log("suggestions: ", suggestions);
  return suggestions;
}

let commonFilters = [
  {
    buttonText: <small>Must include email</small>,
    operator: "exists",
    fieldname: "EMAIL",
    className: "me-3 p-0"
  },
  {
    buttonText: <small>Must include phone</small>,
    operator: "mustnot",
    fieldname: "PHONE",
    fieldvalue: "",
    className: "me-3 p-0"
  },
  {
    buttonText: <small>Must include address</small>,
    operator: "exists",
    fieldname: "ADDR",
    className: "me-3 p-0"
  }
]

const CreateFilterSuggestionButton = ({
  fieldname, //required
  buttonText, //required
  operator, //required
  fieldvalue, //require based on operator type
  className, //optional
  style, //optional
  variant, //optional
  size, //optional
  callback, //optional
  setUrl, //required
  searchfilters //required
}) => {
  if ( isCurrentFilter({
    currentfilters: decodeSearchFilter(searchfilters),
    fieldname,
    fieldvalue
  }) ) {
    return null
  }
  return(
    <Button
      key={`filter_sugg_btn_${fieldname}_${fieldvalue}`}
      className={className ? className : "p-0 text-start"}
      style={style ? style : null}
      variant={ variant ? variant : "link"}
      onClick={() => {
        //setFilter
        let newfilter = `${operator}=>${fieldname}${fieldvalue ? `=>${fieldvalue}` : ""}`
        setUrl({
          sfilters: searchfilters ? [...searchfilters, newfilter] : [newfilter]
        })
        if (callback) { return callback(null, ({searchfilters, newfilter})) }
        return
      }}
      size={ size ? size : "sm" }
    >
      {buttonText}
    </Button>
  )
  return null
}

const DisplayResult = props => {
	const {record, dicts, selectResult, selectedRecordId} = props;
	let h = record;
	//console.log("DisplayResult props: ", props);
	// Display search result for a consumer

	if (dicts.find(d => d.index === h._index).type === "Consumer") {
		let {FN, LN, AGE, CITY, ST, HOME_OWNR} = h._source;
		return(
			<div className={props.className ? props.className : ""} style={props.style ? props.style : null}
				key={`directorySearchHits_${h._id}`}
        id={record._id}
			>
				<a className="text-dark" style={{textDecoration: "none"}} onClick={() => selectResult({rec: h._id})}
				>
					<small className="text-muted">CONSUMER</small><br />
					<DisplaySearchHighlight record={h} />
					<p style={{ fontSize: ".8em", margin: "0px" }}>
						<span className="name text-capitalize">Name: {FN.toLowerCase()} {LN.toLowerCase()}</span>
						<span className="ms-2">Age: {AGE}</span>
						<span className="ms-2">Location: {CITY}, {ST}</span>
						<span className="ms-2">Homeowner: {HOME_OWNR}</span>
					</p>
				</a>
        <MakeSearchHighlightSuggestions {...props} records={[h]} />
			</div>
		)
	}
	// Display search result for a business
	if (dicts.find(d => h._index === d.index).type === "Business") {
		return(
			<div className={props.className ? props.className : ""} style={props.style ? props.style : null}
				key={`directorySearchHits_${h._id}`}
			>
				<a className="text-dark" style={{textDecoration: "none"}} onClick={() => selectResult({rec: h._id})}
				>
					<small className="text-muted">BUSINESS</small><br />
					<DisplaySearchHighlight record={h} />
					<p style={{ fontSize: ".8em", margin: "0px" }}>
						<span>Business: {h._source.NAME}</span>
						<span className="ms-2">Employees: {h._source.NUM_EMPLOYEES}</span>
						<span className="ms-2">Sales: {h._source.SALES_VOL}</span>
						<span className="ms-2">Type: {h._source.SIC_DESCRIPTION}</span>
					</p>
				</a>
        <MakeSearchHighlightSuggestions {...props} records={[h]} />
			</div>
		)
	}
	return null
}

const DisplayRecord = props => {
	//console.log("DisplayRecord props: ", props);
	const { record, lists, dicts, addToCrm, user, userDoc, handleAlerts, hideViewBtn, selectedRecordId } = props;
	const [selectedList, setSelectedList] = useState(null);

  const selectedRecordRef = useRef(null);
  
	useEffect(() => {
		if (lists ? lists.length > 0 : false) {
			let defaultList = lists.find( l => l._docId === "leads");
			if (defaultList) {
				setSelectedList(defaultList)
			}
		}
    console.log("record._id: ", record._id, "selectedRecordId: ", selectedRecordId);
    if ( record._id === selectedRecordId) {
      //console.log("Should Scroll selectedRecordRef: ", selectedRecordRef);
      window.setTimeout( () => {
        selectedRecordRef.current.scrollIntoView({behavior: "smooth"});
      }, 300)
    }
	}, [lists, selectedRecordId]);

	let {CO_NAME, FULL_NAME, SIC_DESCRIPTION, SIC_6, NUM_EMPLOYEES, SALES_VOL, WEB_ADDRESS, PHONE, EMAIL, FN, LN, ADDR, APT, CITY, ST, ZIP} = record._source;
	return(
		<div className={props.className ? props.className : ""} style={props.style ? props.style : null}
      ref={selectedRecordRef}
      id={record._id}
    >
			{ (dicts.find( d => d.index === record._index).type === "Business") &&
				<React.Fragment>
					<small className="text-muted">BUSINESS</small><br />
					<DisplaySearchHighlight record={record} />
					<h1><span className="coname">{CO_NAME.toLowerCase()}{CO_NAME && FULL_NAME ? " - " : ""}</span><span className="name">{FULL_NAME.toLowerCase()}</span></h1>
					<p>
							{SIC_DESCRIPTION} <span className="text-muted ms-2">SIC:</span> {SIC_6} <span className="text-muted ms-2">Employees:</span> {NUM_EMPLOYEES} <span className="text-muted ms-2">Sales: </span>{SALES_VOL} <br />
							{WEB_ADDRESS}<br />
							{ADDR}, {CITY} {ST} {ZIP}
					</p>
					<p className="mt-4">
							<span className="text-capitalize">{FULL_NAME.toLowerCase()}</span><br />
							{formatPhoneNumber(PHONE)}<br />
							{EMAIL ? EMAIL.toLowerCase() : ""}
					</p>
				</React.Fragment>
			}
			{ (dicts.find( d => d.index === record._index).type === "Consumer") &&
				<React.Fragment>
					<small className="text-muted">CONSUMER</small><br />
					<DisplaySearchHighlight record={record} />
					<h1><span className="name">{FN.toLowerCase()} {LN.toLowerCase()}</span></h1>
					<p>
							{PHONE ? <>{formatPhoneNumber(PHONE)}<br /></> : 
                <Button
                  size="sm"
                  variant="link"
                  className="p-0"
                  onClick={() => {
                    //setUrl({
                    //  sfilters: ``
                    //})
                  }}
                >

                </Button>
              }
							{EMAIL ? <>{EMAIL.toLowerCase()}<br /></> : ""}
							{ADDR}, {APT ? `${APT},` : ""} {CITY} {ST} {ZIP}
					</p>
					<p className="mt-4">
							Some other stuff goes here
					</p>
				</React.Fragment>
			}
			{selectedList &&
				<div className="d-flex justify-content-end">
          {!hideViewBtn &&
            <Link
              className="btn btn-outline-info btn-sm me-2"
              to={`/search/directory/${record._index}/${record._id}`}
            >
              View
            </Link>
          }
					<Button 
						size="sm"
						className="text-end"
						variant="outline-success"
						onClick={() => {
							addToCrm({
								list: selectedList.listName,
								data: record._source,
								uid: user.uid,
								aid: userDoc.id,
								source: [{
									_index: record._index,
									_id: record._id,
									_type: record._type,
									type: "directory",
									_created: Math.round(new Date().getTime() / 1000)
								}]
							},
								() => {
									handleAlerts("", `Yay, you added record ${record._id} to the ${selectedList.listName} list!`, "success");
								}
							)
						}}
					>
						Add to List: {selectedList.listName}
					</Button>
				</div>
			}
			
		</div>
	)
}

export { Directory, RecordView };